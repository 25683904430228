<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{ background: color, overflow: 'hidden', minHeight: height }"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Total de produtores por Ano e Unidade</span>
    </v-card-title>

    <v-tabs
      v-show="collected.length > 0"
      v-model="tab"
      align-with-title
      background-color="transparent"
      class="transparent flex-grow-0"
      dark
    >
      <v-tabs-slider color="cyan" />
      <v-tab><v-icon>analytics</v-icon></v-tab>
      <v-tab><v-icon>list</v-icon></v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="flex-grow-1 milk-average-tabs-items"
    >
      <!-- Gráfico -->
      <v-tab-item>
        <template v-if="collected.length > 0">
          <v-chart
            type="bar"
            :options="optionsCollected"
            autoresize
            :style="{ flexGrow: 1, flexShrink: 0, minHeight: '200px', width: '100%', height: '100%' }"
          />
        </template>
        <div
          v-else
          class="flex-grow-1 d-flex flex-column mt-4 mb-6"
        >
          <span class="display-2 font-weight-bold mt-16 cyan--text">
            Nenhum dado disponível
          </span>
        </div>
      </v-tab-item>

      <!-- Relatório -->
      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="listWithTotal"
          dark
          class="elevation-1 report-table"
          dense
          hide-default-footer
          disable-pagination
          :style="{ overflowX: 'auto', maxWidth: '100%' }"
        >
          <template #[`item`]="{ item }">
            <tr>
              <td
                v-for="header in headers"
                :key="header.value"
                class="text-center"
              >
                {{ header.value === 'unidade_nome' ? item[header.value] : formatNumber(item[header.value] || 0) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style>
.v-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.v-tab-item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
}

.milk-average-tabs-items {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
}

v-chart {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.report-table {
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
  table-layout: fixed;
}
</style>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "rgba(0, 0, 0, 0.4)",
    },
    height: {
      type: String,
      default: "250px",
    },
  },

  data() {
    return {
      loading: false,
      tab: 0,
      collected: [],
      headers: [],
    };
  },
  computed: {
    totalProducers() {
      return this.collected.reduce((total, item) => {
        const totalByUnit = Object.keys(item)
          .filter((key) => key.startsWith("year_"))
          .reduce((sum, yearKey) => sum + (item[yearKey] || 0), 0);
        return total + totalByUnit;
      }, 0);
    },
    listWithTotal() {
      const totals = this.headers.reduce((acc, header) => {
        if (header.value.startsWith("year_")) {
          acc[header.value] = this.collected.reduce(
            (sum, item) => sum + (item[header.value] || 0),
            0
          );
        }
        return acc;
      }, {});

      return [
        ...this.collected,
        {
          unidade_nome: "Total",
          ...totals,
        },
      ];
    },
    optionsCollected() {
      const years = this.headers
        .filter((header) => header.value.startsWith("year_"))
        .map((header) => header.text);

      const laticinios = this.collected.map((item) => item.unidade_nome || "Sem produtores");

      const colors = [
        "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40",
        "#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#00FFFF", "#FF00FF", "#FF6384",
      ];

      const seriesData = this.collected.map((item, index) => ({
        name: item.unidade_nome || "Sem produtores",
        type: "bar",
        data: years.map((year) => item[`year_${year}`] || 0),
        itemStyle: {
          color: colors[index % colors.length],
        },
      }));

      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          confine: true,
        },
        legend: {
          data: laticinios,
          show: false,
          textStyle: { color: "#ddd" },
          bottom: 10,
        },
        grid: {
          top: 30,
          bottom: 90,
          left: 50,
          right: 30,
        },
        xAxis: {
          type: "category",
          data: years,
          axisLabel: { color: "#ddd" },
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "#ddd" },
        },
        series: seriesData,
      };

    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const response = await this.$axios.get("/collect/reports/producers-by-year");
        const reportData = response.data?.relatorio || [];
        const years = new Set();

        this.collected = reportData.map((item) => {
          const formattedItem = {
            unidade_nome: item.unidade_nome,
          };

          Object.entries(item).forEach(([key, value]) => {
            if (key.startsWith("year_")) {
              years.add(key);
              formattedItem[key] = value;
            }
          });

          return formattedItem;
        });

        this.headers = [
          { text: "Unidade", value: "unidade_nome", cellClass: "cell-border" },
          ...[...years].sort().map((year) => ({
            text: year.replace("year_", ""),
            value: year,
            align: "center",
          })),
        ];
      } catch (error) {
        console.error("Erro ao carregar relatório:", error);
        this.$snotify.error(
          "Não foi possível carregar os dados. Verifique a conexão e tente novamente.",
          "Erro"
        );
      } finally {
        this.loading = false;
      }
    },
    formatNumber(value) {
      return new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 0,
      }).format(value);
    },
  },
};
</script>

