<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="1000px"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        Embalagem/Pesagem
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <packing-weighing
          v-if="dialog"
          ref="packingWeighingRef"
          :settings-id="props.settingsId"
          :only-shipped="onlyShipped"
          :custom-tags="customTags"
          @created="onPackingCreated"
        />

        <div class="d-flex">
          <span class="flex-grow-1 text-h5 mt-4 black--text">Caixas/Embalagens</span>
        </div>

        <v-divider class="mb-4" />

        <v-data-table
          :items="packages"
          :headers="headers"
          hide-default-footer
          disable-pagination
          disable-sort
          disable-filtering
        >
          <template #[`item.quantity`]="{ value, item }">
            {{ formatNumber(value) }} {{ item.measurement }}
          </template>
          <template #[`item.grossWeight`]="{ value }">
            {{ formatNumber(value) + ' Kg' }}
          </template>
          <template #[`item.tareWeight`]="{ value }">
            {{ formatNumber(value) + ' Kg' }}
          </template>
          <template #[`item.netWeight`]="{ value }">
            {{ formatNumber(value) + ' Kg' }}
          </template>
          <template #[`item.stock.expirationDate`]="{ value }">
            {{ formatDate(value, 'DD/MM/YYYY') }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              right
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="print(item)"
                >
                  <v-list-item-icon>
                    <v-icon>print</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Imprimir Etiqueta</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="removeBox(idx)"
                >
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click.native="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-menu
          v-if="hasSettingsPermission"
          v-model="settings.dialog"
          :close-on-content-click="false"
          top
          left
          content-class="settings-menu"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              small
              @click="openSettings"
            >
              <v-icon>settings</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Configurações</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="settings.computerId"
                    label="ID Computador"
                    outlined
                    small
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="settings.tagModel"
                    label="Modelo Etiqueta"
                    :items="tagOptions"
                    outlined
                    small
                    clearable
                    hide-details
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="settings.autoWeighing"
                    label="Peso automático"
                    :items="[
                      { text: 'Sim', value: true },
                      { text: 'Não', value: false }
                    ]"
                    outlined
                    small
                    hide-details
                    :disabled="!hasManualWeighingPermission"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="settings.autoPrinting"
                    label="Etiqueta automática"
                    :items="[
                      { text: 'Sim', value: true },
                      { text: 'Não', value: false }
                    ]"
                    outlined
                    small
                    hide-details
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="settings.packingScale"
                    label="Balança Embalagem"
                    :items="[
                      { text: 'Balança 1', value: 'weighingScale' },
                      { text: 'Balança 2', value: 'weighingScale2' }
                    ]"
                    outlined
                    small
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="settings.autoWeighing"
                  cols="6"
                >
                  <masked-text-field
                    v-model="settings.minBoxWeight"
                    label="Peso Bruto Mínimo Caixa"
                    suffix="Kg"
                    :mask="masks.float"
                    unmask
                    inputmode="numeric"
                    outlined
                    small
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="settings.scaleDelay"
                    label="Delay Balança"
                    suffix="seg"
                    :mask="{ mask: Number, min: 0.5, max: 3, scale: 1 }"
                    unmask="typed"
                    inputmode="numeric"
                    outlined
                    small
                    hint="Atraso de 0,5 a 3,0 segundos para leitura do peso"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="saveSettings"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.settings-menu {
  min-width: 350px !important;
  max-width: 350px !important;
}
</style>

<script setup>
import { reactive, ref, computed, nextTick, watch, onMounted } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import { usePermissions } from '@/Support/Composables/permissions.js'
import { useDialogHistory } from '@/Support/Composables/dialogHistory.js'
import axios from '@/Support/Resources/axios-instance.js'
import moment from 'moment'

import { usePacking } from '@/Domains/Shipment/Composable/Packing.js'

import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import PackingWeighing from '@/Domains/Shipment/StorageControl/Components/PackingWeighing.vue'

const { progressBar, notify, confirm } = useUtils()

const { hasPermission } = usePermissions()

const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)
const formatDate = (value, format) => !value ? '-' : moment(value).format(format)

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

// eslint-disable-next-line
const props = defineProps({
  warehouseId: String,
  onlyShipped: Boolean,
  settingsId: {
    type: String,
    default: 'WEIGHING'
  },
})

const { packingSettings, savePackingSettings } = usePacking(props.settingsId)

// eslint-disable-next-line no-undef
const emit = defineEmits(['close'])

const dialog = ref(false)

useDialogHistory(dialog)

const headers = [
  { text: 'Produto', value: 'item.description' },
  { text: 'Lote', value: 'stock.lotNumber' },
  { text: 'Quantidade', value: 'quantity' },
  { text: 'Peso Bruto', value: 'grossWeight', mask: '#,##0.00' },
  { text: 'Peso Tara', value: 'tareWeight', mask: '#,##0.00' },
  { text: 'Peso Líquido', value: 'netWeight', mask: '#,##0.00' },
  { text: 'Código', value: 'code', align: 'center' },
  { text: 'Código de Barras', value: 'barcode', align: 'center' },
  { text: 'Validade', value: 'stock.expirationDate' },
  { text: '', value: 'actions' },
]

const packages = ref([])
const orderId = ref(null)

const packingWeighingRef = ref()

const show = async (data = null) => {
  dialog.value = true
  packages.value = []
  orderId.value = data?.orderId

  await nextTick ()

  packingWeighingRef.value?.init(data)
}

watch(dialog, (isOpen) => {
  if (!isOpen) { emit('close') }
})

const close = () => {
  dialog.value = false
}

const onPackingCreated = (packings) => {
  packages.value.push(...packings)
}

const removeBox = async (idx) => {
  const packing = packages.value[idx]

  if (!(await confirm(
    `Remover embalagem?`,
    `Esta ação não pode ser desfeita.`,
    { color: 'red' }
  ))) {
    return;
  }

  try {
    progressBar.loading();

    await axios.delete(`packing/${packing.id}`);

    packages.value.splice(idx, 1);

    notify.success('Embalagem removida com sucesso', 'Sucesso', { timeout: 6000 });
  } catch (error) {
    console.warn(error);
    const message = error?.response?.data?.message || 'Erro ao remover a embalagem'
    notify.error(message, 'Atenção', { timeout: 6000 })
  } finally {
    progressBar.hide();
  }
}

const print = (packing) => {
  packingWeighingRef.value?.print(packing)
}

const hasManualWeighingPermission = computed(() => hasPermission('manual-weighing'))
const hasSettingsPermission = computed(() => hasPermission('packing-settings'))

const tagOptions = computed(() => {
  const options =  [
    { text: 'Sistema (10x5)', value: 'SYSTEM_10X5' },
  ]

  if (orderId.value) {
    options.push({ text: 'Produção (10x15)', value: 'PRODUCTION_10X15' })
  }

  if (customTags.value.length > 0) {
    options.push(...customTags.value.map(label => ({ text: label.descricao, value: label.id })))
  }

  return options
})

const settings = reactive({
  dialog: false,
  computerId: null,
  tagModel: null,
  autoWeighing: false,
  autoPrinting: false,
  minBoxWeight: null,
  packingScale: null,
  scaleDelay: null,
})

const openSettings = () => {
  settings.dialog = true
  settings.computerId = packingSettings.value.computerId
  settings.tagModel = packingSettings.value.tagModel
  settings.autoWeighing = packingSettings.value.autoWeighing || !hasManualWeighingPermission.value
  settings.autoPrinting = packingSettings.value.autoPrinting
  settings.minBoxWeight = packingSettings.value.minBoxWeight
  settings.packingScale = packingSettings.value.packingScale
  settings.scaleDelay = packingSettings.value.scaleDelay
}

const saveSettings = async () => {
  try {
    progressBar?.saving()

    await savePackingSettings({
      computerId: settings.computerId,
      tagModel: settings.tagModel,
      autoWeighing: settings.autoWeighing,
      autoPrinting: settings.autoPrinting,
      minBoxWeight: settings.minBoxWeight,
      packingScale: settings.packingScale,
      scaleDelay: settings.scaleDelay,
    })

  } catch (error) {
    console.error(error)
    const message = error?.response?.data?.message || 'Erro ao salvar as configurações'
    notify.error(message, 'Atenção', { timeout: 6000 })
  } finally {
    progressBar?.hide()
    settings.dialog = false
  }
}

const customTags = ref([])

const loadCustomTags = async () => {
  try {
    const { data } = await axios.get(`/packing/labels`)

    customTags.value = data
  } catch (error) {
    notify.error('Erro ao carregar etiquetas personalizadas', 'Atenção')
    console.log(error)
  }
}

onMounted(() => {
  loadCustomTags()
})

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
