<template>
  <v-container xs>
    <v-card
      dark
      color="transparent"
    >
      <v-col class="px-0">
        <card-title-settings>
          Parâmetros Análise de Rotina
        </card-title-settings>
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="3"
                class="pa-1"
                style="border-right: 1px solid rgba(0, 0, 0, 0.07);overflow-x: hidden; "
              >
                <analysis-tree
                  v-model="analysis"
                  custom-class="adjust-layout-analyze-routine"
                  :settings.sync="settings"
                  :raw-material-id="rawMaterialId"
                />
              </v-col>
              <v-col
                cols="9"
                class="pa-1"
              >
                <analysis-settings
                  v-model="analysis"
                  custom-class="adjust-layout-analyze-routine"
                  :settings.sync="settings"
                  @onValidation="onValidation"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn
              text
            >
              Cancelar
            </v-btn>
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click.prevent="save"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-overlay :value="loading">
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<style>
.adjust-layout-analyze-routine {
  background-color: unset !important;
}

</style>

<script>
import * as _ from "lodash";
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue";
import AnalysisTree from "@/Domains/Settings/Components/Analysis/AnalysisTree.vue";
import AnalysisSettings from "@/Domains/Settings/Components/Analysis/AnalysisSettings.vue";

export default {
  components: {
    CardTitleSettings,
    AnalysisSettings,
    AnalysisTree
  },

  data() {
    return {
      rawMaterialId: null,
      settings: {
        showRestriction: false,
        automaticallyMarkOutOfRange: false,
      },

      loading: false,

      hasErrorAnalysis: true,

      analysis: [],
    }
  },

  mounted() {
    this.rawMaterialId = this.$store.state.settings?.gerais?.id_materia_prima_principal
  },

  methods: {
    async save() {
      try {
        this.loading = true;

        // Verifica se existe alguma validação pendente
        if (!this.hasErrorAnalysis) {
          this.$snotify.error("Verificar os campos de parametrização !", "Atenção");
          return false;
        }

        let allAnalysis = [];
        // Faz o parse das analises para salvar no banco
        this.analysis.forEach(param => {

          // Se for um parametro depende verifica se o pai existe
          // e se não exister faz o push
          if (_.has(param, 'depend') && !_.find(allAnalysis, o => o.parametro === param.depend)) {
            allAnalysis.push({
              parametro: param.depend,
              padrao: false,
            });
          }

          const analysis = param.analysis;

          /**
           * Caso uma analise com padrão boolean(presença/ausencia) for macada para exibir
           * ela sempre será FALSE, normalmente estas analises indicam fralde no leite
           */
          let standard = false;

          if (_.isObject(analysis.standard)) {
            standard = this.validationAndConversion(analysis.standard);
          }

          const analysisBase =  {
            parametro: analysis.param,
            padrao: standard,
            descricao_padrao: analysis.standardDescription,
          };

          if (this.settings.showRestriction && !_.isEmpty(analysis.restriction)) {
            analysisBase.restricao = analysis.restriction
          }

          if (_.has(analysis, "units")) {
            const unitFind = _.find(analysis.units, p => p.selected);

            analysisBase.unidade_medida = {
              descricao: unitFind.description,
              unidade: unitFind.unit,
            };

          }

          allAnalysis.push(analysisBase);
        });

        const payload = {
          id_materia_prima: this.rawMaterialId,
          parametros_analise: allAnalysis,
          configuracao: {
            possui_config_restricao: this.settings.showRestriction,
            marcar_automaticamente: this.settings.automaticallyMarkOutOfRange,
          },
          analise_rotina: true,
        };

        const { data } = await this.$axios.post(`/configuracao/salvaParametrosAnalise`, payload);

        if (data.codigo !== 1) {
          throw "Erro ao salvar analise";
        }

        this.$snotify.success("Parâmetros salvos com sucesso!", "Sucesso");
        this.UpdateAnalysisParametersStore(payload);
      } catch (e) {
        console.log(e);
        this.$snotify.error("Erro ao salvar parâmetros", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    /**
     * Trata validação das analises
     */
    onValidation(event) {
      this.hasErrorAnalysis = event.valid;
    },

    /**
     * Atualiza store
     */
    async UpdateAnalysisParametersStore(payload) {
      try {
        const parametros_analise = this.$store.state.settings.plataforma.parametros_analise;

        _.set(parametros_analise.analise_rotina, `materia_prima.${payload.id_materia_prima}`, payload.parametros_analise)
        _.set(parametros_analise.analise_rotina, `configuracao.${payload.id_materia_prima}`, payload.configuracao)

        this.$store.commit("setAnalysisParameters", {
          parametros_analise
        });
      } catch (e) {
        console.warn(e);
      }
    },
    validationAndConversion(obj) {
      const resultado = {};

      if ('min' in obj) {
        resultado.min = parseFloat(obj.min);
      }

      if ('max' in obj) {
        resultado.max = parseFloat(obj.max);
      }

      if ('default' in obj) {
        resultado.default = parseFloat(obj.default);
      }

      return resultado;
    },
  }
};
</script>
