<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                v-if="isSupplier"
                cols="12"
                md="4"
                class="pt-0"
              >
                <v-autocomplete
                  v-model="filters.show"
                  :items="[
                    { text: 'Ativos', value: 'ATIVOS' },
                    { text: 'Inativos', value: 'INATIVOS' },
                    { text: 'Todos', value: 'TODOS' },
                  ]"
                  label="Exibir"
                  placeholder=" "
                  hide-details
                  dark
                  filled
                  @change="loadPersons()"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.search"
                  prepend-inner-icon="search"
                  label="Buscar"
                  filled
                  single-line
                  hide-details
                  dark
                />
              </v-col>
            </v-row>
          </v-card-title>
          <data-table
            :headers="headers"
            :items="items"
            :search="filters.search"
            dark
            class="elevation-1 report-table"
            show-custom-group
            :item-class="(item) => isSupplier && !item.active ? 'text-decoration-line-through' : ''"
            @click:row="onPersonClick"
          >
            <template #[`item.description`]="{ value }">
              {{ value.toUpperCase() }}
            </template>
            <template #[`item.status`]="{ value }">
              {{ statusList[value] }}
            </template>
            <template #[`item.businessUnit`]="{ value }">
              {{ getBusinessType(value) }}
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    small
                    @click="onPersonClick(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.active"
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="isSupplier && !item.active"
                    @click="activate(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>person_add</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Reativar</v-list-item-title>
                  </v-list-item>

                  <template v-if="type === 'CUSTOMER'">
                    <v-list-item
                      v-for="(text, status) in statusList"
                      v-show="status != item.status"
                      :key="status"
                      @click="changeStatus(item.id, status)"
                    >
                      <v-list-item-icon>
                        <v-icon>sync_alt</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ text }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>
    <person-dialog
      v-model="showDialog"
      :edit-id="editId"
      :type="type"
      @change="loadPersons"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-3"
          v-on="on"
          @click="newPerson()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Novo</span>
    </v-tooltip>
    <confirm ref="confirm" />
  </v-container>
</template>

<script>
import PersonDialog from './PersonDialog.vue';
import Confirm from '@/Support/Components/Confirm.vue';

import moment from 'moment';
import { isArray } from 'lodash';

export default {
  name: 'persons',

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format)
  },

  components: {
    PersonDialog,
    Confirm
  },

  props: {
    type: {
      type: String,
      validator: (value) => ['SUPPLIER', 'CUSTOMER', 'DAIRY', 'COOPERATIVE', 'COMPETING-DAIRY'].includes(value)
    },
  },

  data() {
    return {
      showDialog: false,

      filters: {
        show: 'ATIVOS',
        search: '',
      },

      items: [],

      editId: null,

      statusList: {
        ATIVO: 'Ativo',
        PROSPECTADO: 'Prospectado',
        INATIVO: 'Inativo',
        LEAD: 'Observação',
      }
    };
  },

  computed: {
    headers() {
      const headers = [
        { text: 'Código', value: 'code', align: 'left'   },
        { text: 'Nome', value: 'name', align: 'left'   },
        { text: 'CPF/CNPJ', value: 'document', align: 'left'   },
        { text: 'Telefone', value: 'phone', align: 'left'   },
      ];

      if (this.type === 'CUSTOMER' || this.type === 'COOPERATIVE') {
        headers.unshift({ text: 'C. Laticínio', value: 'code', align: 'center' });
      }

      if (this.type === 'DAIRY') {
        headers.unshift({ text: 'Tipo de Negócio', value: 'businessUnit', align: 'left' });
      }

      if (this.type !== 'DAIRY') {
        headers.push({ text: 'E-mail',   value: 'email',    align: 'center' });
      }

      if (this.type === 'CUSTOMER') {
        headers.push({ text: 'Status', value: 'status' });
      }

      headers.push({ text: 'Opções', value: 'action', width: 30, align: 'center' });

      return headers;
    },

    endpoint() {
      const types = {
        'SUPPLIER': 'listaFornecedores',
        'COMPETING-DAIRY': 'listaFornecedores',
        'CUSTOMER': 'listaClientes',
        'DAIRY': 'listaUnidadesLaticinio',
        'COOPERATIVE': 'listaCooperativa',
      };

      return types[this.type];
    },

    isSupplier() {
      return ['SUPPLIER', 'COMPETING-DAIRY'].includes(this.type);
    },
  },

  created() {
    this.loadPersons();
  },

  methods: {
    async loadPersons() {
      try {
        this.$root.$progressBar.loading();

        const payload = {};

        if (this.type === 'DAIRY') {
          payload.filtrarLaticinio = false;
        }

        if (this.isSupplier) {
          payload.exibir = this.filters.show;
        }

        if (this.type === 'COMPETING-DAIRY') {
          payload.grupo = 'LATICINIO';
        }

        const { data } = await this.$axios.post(`/pessoa/${this.endpoint}`, payload);

        if (!isArray(data)) {
          throw data;
        }

        this.items = data.map(person => {
          return {
            id: person.id_pessoa,
            code: person.codigo_laticinio,
            name: person.nome.trim(),
            companyName: person.razao_social,
            type: person.tipo_pessoa,
            document: person.cnpj_cpf,
            stateRegistration: person.inscricao_estadual,
            municipalRegistration: person.inscricao_municipal,
            email: person.email,
            phone: person.telefone,
            address: {
              zipCode: person.end_cep,
              state: person.end_estado,
              city: person.end_cidade,
              street: person.end_rua,
              number: person.end_numero,
              neighborhood: person.end_bairro,
            },
            location: {
              lat: person.end_latitude || null,
              lng: person.end_longitude || null,
            },
            groupName: person.nome_item_grupo,
            status: person.status || 'ATIVO',
            businessUnit: person.tipo_negocio,
            active: !!person.ativo,
          };
        });
      } catch (err) {
        console.warn(err)
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async changeStatus(id_pessoa, status) {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.post(`/pessoa/alterarStatus`, { id_pessoa, status });

        if (!data.codigo) {
          throw data;
        }

      } catch (err) {
        console.warn(err)
      } finally {
        this.$root.$progressBar.hide();
        this.loadPersons();
      }
    },

    newPerson() {
      this.editId = null,
      this.showDialog = true;
    },

    onPersonClick(item) {
      if (this.isSupplier) {
        if (item.active) {
          this.edit(item)
        } else {
          this.activate(item)
        }
      } else {
        this.edit(item);
      }
    },

    edit(item) {
      this.editId = item.id,

      this.showDialog = true;
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Inativar cadastro', 'Tem certeza que deseja inativar este cadastro?', { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.post(`/pessoa/inativa`, {
          id_pessoa: id,
        });

        const [ response ] = data;

        if (!response.codigo) {
          throw data;
        }

        this.$snotify.success('Cadastro inativado com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error('Erro ao inativar cadastro', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadPersons();
      }
    },

    async activate({ id }) {
      if (!(await this.$root.$confirm('Reativar cadastro', 'Tem certeza que deseja reativar este cadastro?', { color: 'green' }))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.post(`/pessoa/reativar`, {
          id_pessoa: id,
        });

        this.$snotify.success('Cadastro reativado com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error('Erro ao reativar cadastro', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadPersons();
      }
    },

    getBusinessType(value) {
      const businessTypes = {
        'INDUSTRIA': 'INDÚSTRIA',
        'DISTRIBUICAO': 'CENTRO DE DISTRIBUIÇÃO',
        'RESFRIAMENTO': 'POSTO DE RESFRIAMENTO',
      };

      return businessTypes[value];
    }
  },
};
</script>
