<template>
  <div>
    <v-col
      class="px-0"
    >
      <card-title-settings>
        Avisos
      </card-title-settings>
    </v-col>

    <v-card
      dark
      outlined
      color="rgba(255, 255, 255, 0.08)"
    >
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-select
              v-model="alert.type"
              prepend-inner-icon="api"
              :items="[
                { 'text': 'success', value: 'success' },
                { 'text': 'info', value: 'info' },
                { 'text': 'warning', value: 'warning' },
                { 'text': 'error', value: 'error' },
              ]"
              label="Tipo:"
              placeholder=" "
              :loading="isLoading"
              dense
              filled
              dark
            />
          </v-col>

          <v-col cols="2">
            <v-text-field
              v-model.number="alert.showInterval"
              prepend-inner-icon="alarm"
              label="Intervalo de Exibição (Horas):"
              placeholder=" "
              :loading="isLoading"
              dense
              filled
              dark
            />
          </v-col>

          <v-col cols="2">
            <masked-text-field
              v-model="alert.startAt"
              label="Data/Hora início exibição"
              prepend-inner-icon="event"
              placeholder="00/00/0000 00:00"
              :mask="{ mask: '00/00/0000 00:00' }"
              :rules="[ rules.dateTime ]"
              :loading="isLoading"
              :max-length="16"
              clearable
              filled
              dense
            />
          </v-col>

          <v-col cols="2">
            <masked-text-field
              v-model="alert.endAt"
              label="Data/Hora fim exibição"
              prepend-inner-icon="event"
              placeholder="00/00/0000 00:00"
              :mask="{ mask: '00/00/0000 00:00' }"
              :rules="[ rules.dateTime ]"
              :loading="isLoading"
              :max-length="16"
              clearable
              filled
              dense
            />
          </v-col>

          <v-spacer />

          <v-switch
            v-model="alert.show"
            :loading="isLoading"
            inset
          />
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="alert.title"
              label="Título"
              prepend-inner-icon="last_page"
              :loading="isLoading"
              clearable
              filled
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="alert.message"
              label="Mensagem"
              prepend-inner-icon="last_page"
              clearable
              filled
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-3">
        <v-spacer />
        <v-btn
          outlined
          rounded
          text
          @click="save()"
        >
          <v-icon left>
            save
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      dark
      color="transparent"
      class="mt-4"
    >
      <v-card-title class="text-subtitle-1">
        Notas de Versões
        <v-spacer />
        <v-btn
          outlined
          rounded
          text
          @click="addVersion()"
        >
          <v-icon left>
            add
          </v-icon>
          Nova Versão
        </v-btn>
      </v-card-title>
      <v-data-table
        dark
        :sort-by="'nome'"
        :sort-desc="false"
        :items-per-page="15"
        :headers="versionsHeaders"
        :items="versionsItems"
        @click:row="editVersion"
      >
        <template #[`item.data`]="{ value }">
          {{ formatDate(value, "LL") }}
        </template>

        <template #[`item.modulos`]="{ value }">
          <v-chip
            v-for="module in value"
            :key="module.id"
            x-small
          >
            {{ module.descricao }}
          </v-chip>
        </template>

        <template #[`item.notas`]="{ value }">
          {{ truncate(value, { length: 100 }) }}
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="editVersion(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="removeVersion(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="versionDialog.show"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>Novidades</v-card-title>
        <v-card-subtitle>{{ formatDate(versionDialog.form.data, 'LL') }}</v-card-subtitle>
        <v-card-text>
          <v-form
            ref="versionFormRef"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <date-text-field
                  v-model="versionDialog.form.data"
                  label="Data *"
                  prepend-inner-icon="event"
                  max=""
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  v-model="versionDialog.form.modulos"
                  label="Módulos *"
                  :items="versionModules"
                  prepend-inner-icon="apps"
                  item-value="id"
                  item-text="descricao"
                  multiple
                  clearable
                  chips
                  small-chips
                  deletable-chips
                  return-object
                  :rules="[v => !!v?.length || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-textarea
                  v-model="versionDialog.form.notas"
                  label="Notas da Versão *"
                  auto-grow
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="versionDialog.show = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="saveVersion()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay v-model="isLoading">
      Carregando ...
    </v-overlay>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance  } from "vue";

import moment from "moment";
import isEmpty from "lodash/isEmpty";
import truncate from "lodash/truncate";

import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import DateTextField from "@/Support/Components/DateTextField.vue";
import { useAxios } from "@/Support/Resources/axios-instance.js";

const $axios = useAxios();

const { proxy: { $snotify, $root } } = getCurrentInstance();

const isLoading = ref(false);

const alert = ref({
  show: false,
  type: '',
  showInterval: '',
  startAt: '',
  endAt: '',
  title: '',
  message: '',
});

const rules = reactive({
  dateTime: v =>  !v || !!v && v.length === 16 && dateValidate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!',
});

onMounted(async () => {
  await load();
});

async function load() {
  try {
    isLoading.value = true;

    const { data }  = await $axios.get(`/settings/web`);

    if (isEmpty(data)) {
      return;
    }

    const alertData = data.aviso || {};

    alert.value = {
      ...alert.value,
      ...alertData,
      startAt: !isEmpty(alertData.startAt) ? moment(alertData.startAt, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : null,
      endAt: !isEmpty(alertData.endAt) ? moment(alertData.endAt, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : null,
    };

  } catch (e) {
    console.error(e);

    $snotify.error("Erro ao buscar as configurações", "Atenção");
  } finally {
    isLoading.value = false;
  }
}

async function save() {
  try {
    isLoading.value = true;

    await $axios.post(`/settings/web`, {
      aviso: {
        ...alert.value,
        startAt: moment(alert.value.startAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
        endAt: moment(alert.value.endAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
      },
    });

    $snotify.success("Configurações salvas com sucesso", "Sucesso");
  } catch (e) {
    console.error(e);

    $snotify.error("Erro ao salvar as configurações", "Atenção");
  } finally {
    isLoading.value = false;
  }
}

function dateValidate(value, format) {
  if (isEmpty(value)) {
    return '';
  }

  return moment(value, format).isValid();
}

const versionsHeaders = [
  { text: 'Data', value: 'data' },
  { text: 'Módulos', value: 'modulos' },
  { text: 'Notas', value: 'notas' },
  { text: '', value: 'actions', width: 30 }
]

const versionsItems = ref([])

const versionModules = ref([])

const versionDialog = reactive({
  show: false,
  form: {
    data: moment().format('YYYY-MM-DD'),
    modulos: [],
    notas: '',
  },
})

const versionFormRef = ref()

const addVersion = () => {
  versionDialog.show = true
  versionDialog.form = {
    id: null,
    data: moment().format('YYYY-MM-DD'),
    modulos: [],
    notas: '',
  }
  versionFormRef.value?.resetValidation()
}

const editVersion = (data) => {
  versionDialog.show = true
  versionDialog.form = {
    ...data,
  }
  versionFormRef.value?.resetValidation()
}

const loadModules = async () => {
  try {
    const { data } = await $axios.get(`/configuracaoMilkup/listaModulo`)

    versionModules.value = data
      .filter(item => item.descricao)
      .map(item => ({
        id: item.id_modulo,
        descricao: item.descricao.toUpperCase(),
      }))
      .sort((a, b) => a.descricao.localeCompare(b.descricao))
  } catch (error) {
    $snotify.error(error, 'Atenção')
    console.warn(error)
  }
}

const loadVersions = async () => {
  try {
    const { data } = await $axios.get(`/settings/web-versions`)
    versionsItems.value = data
  } catch (error) {
    $snotify.error(error, 'Atenção')
    console.warn(error)
  }
}

const saveVersion = async () => {
  try {
    if (!await versionFormRef.value?.validate()) {
      return
    }

    $root.$progressBar.saving()

    const id = versionDialog.form.id
    const payload = { ... versionDialog.form }

    const data = id ? await updateVersion(id, payload) : await storeVersion(payload)

    if (id) {
      const index = versionsItems.value.findIndex(item => item.id === id)

      versionsItems.value.splice(index, 1, payload)
    } else {
      versionsItems.value.unshift({
        ...payload,
        id: data.id
      })
    }

    $snotify.success('Salvo com sucesso!', 'Sucesso')
    versionDialog.show = false
  } catch (error) {
    $snotify.error(error, 'Atenção')
    console.warn(error)
  } finally {
    $root.$progressBar.hide()
  }
}

const storeVersion = async (payload) => {
  const { data } = await $axios.post(`/settings/web-versions`, payload)
  return data
}

const updateVersion = async (id, payload) => {
  const { data } = await $axios.put(`/settings/web-versions/${id}`, payload)
  return data
}

const removeVersion = async ({ id }) => {
  try {
    if (!(await $root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
      return
    }

    $root.$progressBar.loading()

    await $axios.delete(`/settings/web-versions/${id}`)

    const index = versionsItems.value.findIndex(item => item.id === id)

    versionsItems.value.splice(index, 1)

    $snotify.success('Registro excluído com sucesso', 'Sucesso')
  } catch (error) {
    $snotify.error('Erro ao excluir registro', 'Atenção')
    console.warn(error)
  } finally {
    $root.$progressBar.hide()
  }
}

const formatDate = (value, format) => value ? moment(value).format(format) : ''

loadModules()
loadVersions()
</script>
