<template>
  <v-card
    outlined
    light
    style="min-width: calc(100vw - 500px);"
  >
    <v-card-text>
      <v-form
        ref="form2"
        lazy-validation
      >
        <v-row>
          <v-col
            v-for="(tank, idx) in tanks"
            :key="idx"
            cols="12"
            sm="6"
            md="3"
            lg="2"
            class="pb-0"
          >
            <v-text-field
              v-model.number="tank.vol"
              :label="`Tanque ${tank.index}`"
              prepend-inner-icon="opacity"
              placeholder=" "
              required
              type="number"
              :disabled="idx == (tanks.length - 1)"
              :rules="getRules(idx)"
              @keypress="disableDotAndComma"
              @input="onChangeVolume(idx)"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="2"
            class="pb-0 d-flex justify-center"
          >
            <v-btn
              v-if="tanks.length > 1"
              icon
              color="red"
              @click="removeTank"
            >
              <v-icon>remove</v-icon>
            </v-btn>
            <v-btn
              v-if="tanks.length < 7"
              icon
              color="blue"
              @click="addTank"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="grey darken-1"
        outlined
        @click="$emit('cancel')"
      >
        Cancelar
      </v-btn>

      <v-spacer />

      <v-btn
        color="blue darken-1"
        outlined
        @click="$emit('save')"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {

  props: {
    value: Array,
    volumeTotal: Number
  },

  computed: {
    tanks: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    settings() {
      const generalSettings = this.$store.state.settings.gerais || {};
      return generalSettings.parametros_analise_descarga || {};
    },
  },

  mounted() {
    if (this.tanks.length === 0) {
      this.addTank()
    }
  },

  methods: {
    addTank() {
      this.tanks.push({
        index: this.tanks.length + 1,
        analysis: {
          temp: {
            value: null,
            rule: this.settings.temperatura,
            unit: `ºC`,
            decimals: 1,
          },
          acidity: {
            value: null,
            rule: this.settings.acidez,
            unit: `ºD`,
            decimals: 3,
          },
          cryoscopy: {
            value: -0.5,
            rule: this.settings.crioscopia,
            unit: `ºC`,
            decimals: 3,
          },
          waterPercentage: {
            value: null,
            rule: this.settings.porcentagem_agua,
          },
          waterLiters: {
            value: null,
            rule: this.settings.volume_agua,
            decimals: 2,
          },
          density: {
            value: null,
            rule: this.settings.densidade,
            unit: `g/ml`,
            decimals: 1,
          },
          esd: {
            value: null,
            rule: this.settings.esd,
            unit: `g/100g`,
            decimals: 2,
          },
          fat: {
            value: null,
            rule: this.settings.gordura,
            unit: `%/100g`,
            decimals: 3,
          },
          protein: {
            value: null,
            rule: this.settings.proteina,
            unit: `g/100g`,
            decimals: 2,
          },
          ph: {
            value: null,
            rule: this.settings.ph,
            decimals: 2,
          },
          alizarol: {
            value: null,
            rule: this.settings.alizarol,
            unit: `°GL`,
            decimals: 0,
          },
          totalSolids: {
            value: null,
            rule: this.settings.solidos_totais,
            decimals: 2,
          },
          brix: {
            value: null,
            rule: this.settings.brix,
            decimals: 2,
          },
          est: {
            value: null,
            rule: this.settings.est,
            decimals: 2,
          },
          acidityRatio: {
            value: null,
            rule: this.settings.acidityRatio,
            decimals: 3,
          },
          lactose: {
            value: null,
            rule: this.settings.lactose,
            decimals: 2,
          },
          alizarolQualit: {
            value: null,
            rule: this.settings.alizarol_qualit,
            hint: 'Negativo',
          },
          alcohol: {
            value: null,
            rule: this.settings.alcohol,
            hint: 'Negativo',
          },
          alkalinePhosphatase: {
            value: null,
            rule: this.settings.fosfatase,
            hint: 'Positivo',
          },
          clumps: {
            value: null,
            rule: this.settings.grumos,
            hint: 'Ausência',
          },
          clots: {
            value: null,
            rule: this.settings.coagulos,
            hint: 'Ausência',
          },
          antibiotic: {
            value: null,
            rule: this.settings.antibiotico,
          },
          betaLactams: {
            value: null,
            rule: this.settings.betalactamico,
          },
          tetracycline: {
            value: null,
            rule: this.settings.tetraciclina,
          },
          cefalosporina: {
            value: null,
            rule: this.settings.cefalosporina,
          },
          tianfenicol: {
            value: null,
            rule: this.settings.tianfenicol,
          },
          florfenicol: {
            value: null,
            rule: this.settings.florfenicol,
          },
          sulphonamide: {
            value: null,
            rule: this.settings.sulfaamida,
          },
          chlorides: {
            value: null,
            rule: this.settings.cloretos,
          },
          mastitis: {
            value: null,
            rule: this.settings.mastite,
          },
          sodiumHydroxide: {
            value: null,
            rule: this.settings.soda,
          },
          bicarbonateOfSoda: {
            value: null,
            rule: this.settings.bicarbonato,
          },
          formol: {
            value: null,
            rule: this.settings.formol,
          },
          rapidFormol: {
            value: null,
            rule: this.settings.formol_rapido,
          },
          peroxide: {
            value: null,
            rule: this.settings.peroxido,
          },
          chlorine: {
            value: null,
            rule: this.settings.cloro,
          },
          starch: {
            value: null,
            rule: this.settings.amido,
          },
          sucrose: {
            value: null,
            rule: this.settings.sacarose,
          },
          neutralising: {
            value: null,
            rule: this.settings.neutralizantes,
            hint: `Ausência`,
          },
          restoratives: {
            value: null,
            rule: this.settings.reconstituintes,
            hint: `Ausência`,
          },
          conservatives: {
            value: null,
            rule: this.settings.conservantes,
            hint: `Ausência`,
          },
          sensorial: {
            value: null,
            rule: this.settings.conservantes,
            hint: `Característico`,
          },
          phenolphthalein: {
            value: null,
            rule: this.settings.fenolftaleina,
            hint: `Ausência`,
          },
          bloodPresence: {
            value: null,
            rule: this.settings.presenca_sangue,
            hint: `Ausência`,
          },
          rosolicAcid: {
            value: null,
            rule: this.settings.acido_rosolico,
            hint: `Ausência`,
          },
          quinolones: {
            value: null,
            rule: this.settings.quinolonas,
          },
          aminoglykosides: {
            value: null,
            rule: this.settings.aminoglicosideos,
          },
          macrolides: {
            value: null,
            rule: this.settings.macrolideos,
          },
          anfenicois: {
            value: null,
            rule: this.settings.anfenicois,
          },
          reductase: {
            value: null,
            rule: this.settings.redutase,
          },
          numero_lacre: {
            value: null,
            rule: this.settings.numero_lacre,
            decimals: 0,
          },
          alkali: {
            value: null,
            rule: this.settings.alcalino,
          },
          others: {
            value: null,
            rule: this.settings.outros,
          },
          peroxidase: {
            value: null,
            rule: this.settings.peroxidase,
            hint: 'Positivo',
          },
          solubilidade: {
            value: null,
            rule: this.settings.solubilidade,
          },
          base_seca: {
            value: null,
            rule: this.settings.base_seca,
          },
          neomicina: {
            value: null,
            rule: this.settings.neomicina,
          },
          otherSpecies: {
            value: null,
            rule: this.settings.outras_especies,
          },
          ureia: {
            value: null,
            rule: this.settings.ureia,
            hint: 'Negativo',
          },
        }
      });
      this.updateTanks();
    },

    removeTank() {
      this.tanks.splice(-1, 1);
      this.updateTanks();
    },

    updateTanks() {
      // Quantidade de tanques
      const noTanks = this.tanks.length;
      // Volume total
      const volumeTotal = this.volumeTotal;
      // Volume de cada tanque
      const volume = Math.floor(volumeTotal / noTanks);
      // Diferença do rateio de cada tanque
      const rest = volumeTotal - (volume * noTanks);
      // Na último tanque deve ser adicionado a diferença
      const lastVolume = parseInt(volume + rest);

      for (let i = 0; i < noTanks; i++) {
        this.tanks[i].vol =  i < (noTanks - 1) ? volume : lastVolume;
      }
    },

    /**
     * Verifica o volume do tanque atual e rateia o resto entre os tanques posteriores
     */
    onChangeVolume(idx) {
      if (idx == this.tanks.length - 1) {
        return;
      }
      // Índice para dividir este tanque dos posteriores
      const splitTank = idx + 1;
      // Quantidade de tanques
      const noTanks = this.tanks.length;
      // Volume total
      const volumeTotal = this.volumeTotal;
      // Soma o volume deste tanque com os tanques anteriores
      const curVolume = this.tanks.slice(0, splitTank).reduce((acc, cur) => acc + (parseInt(cur.vol) || 0), 0);
      // A diferença será rateada nos tanques posteriores
      const restVol = volumeTotal - curVolume;
      // Quantidade de tanques restantes
      const restNoTanks = noTanks - splitTank;
      // Volume de cado tanque (para baixo)
      const volume = Math.floor(restVol / restNoTanks);
      // Diferença dos tanques
      const rest = restVol - (volume * restNoTanks);
      // Na último tanque deve ser adicionado a diferença
      const lastVolume = parseInt(volume + rest);

      for (let i = splitTank; i < noTanks; i++) {
        this.tanks.splice(i, 1, {
          ...this.tanks[i],
          vol: i < (noTanks - 1) ? volume : lastVolume
        })
      }
    },

    /**
     * Retorna as regras de validações de cada tanque
     */
    getRules(idx) {
      // Volume total
      const volumeTotal = this.volumeTotal;
      // Soma o volume deste tanque com os tanques anteriores
      const curVolume = this.tanks.slice(0, idx + 1).reduce((acc, cur) => acc + (parseInt(cur.vol) || 0), 0);
      // Volume do tanque
      const volume = parseInt(this.tanks[idx].vol) || 0;
      return [
        () => volume >= 0 || 'O volume não pode ser negativo',
        () => curVolume <= volumeTotal || 'O volume deve ser menor ou igual ao volume total',
      ]
    },

    disableDotAndComma(e) {
      [44, 46, 101].includes(e.charCode) && e.preventDefault();
    },

  },

}
</script>
