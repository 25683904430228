<template>
  <div>
    <v-card
      dark
      class="v-tamanho"
      color="transparent"
    >
      <v-card-title class="py-3">
        <v-spacer />
        <v-col
          cols="3"
          class="pa-0"
        >
          <v-text-field
            v-model="filters.search"
            prepend-inner-icon="search"
            label="Busca"
            single-line
            hide-details
          />
        </v-col>
      </v-card-title>

      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="filteredUnloads"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, -1]
        }"
        mobile-breakpoint="1000"
        class="elevation-1"
      >
        <template #[`item.itinerary.type`]="{ value, item }">
          <v-chip small>
            {{ itineraryTypes[value] }}
          </v-chip>
          <v-icon
            v-if="!!item.originId"
          >
            settings_backup_restore
          </v-icon>
        </template>

        <template #item.driver.name="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ (value || '').toUpperCase() | truncate(15) }}
              </v-chip>
            </template>

            {{ value }}
          </v-tooltip>
        </template>

        <template #item.rawProduct="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ (value.rawName || value.name || '').toUpperCase() | truncate(15) }}
              </v-chip>
            </template>

            {{ value.rawName || value.name }}
          </v-tooltip>
        </template>

        <template #item.operation="{ item }">
          <v-chip small>
            <v-avatar left>
              <v-icon
                v-if="item.operation === 'DESCARGA'"
                color="red"
                v-text="'arrow_circle_up'"
              />
              <v-icon
                v-if="item.operation === 'CARGA'"
                color="green"
                v-text="'arrow_circle_down'"
              />
            </v-avatar>

            <span>
              {{ item.operation }}
            </span>
          </v-chip>
        </template>

        <template v-slot:item.unloadOperations="{ item }">
          <unload-operations
            :ref="`operations-${item.id}`"
            :row="item"
            class="pa-3"
            prefetch
            :raw-products="rawProducts"
            @onRawProductUpdated="onRawProductUpdated"
            @onTanksUpdated="onTanksUpdated"
            @onUnloadConclude="onUnloadConclude"
          />
        </template>

        <template #[`item.operationTime`]="{ value }">
          <v-chip
            v-if="value"
            small
          >
            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="showUnloadAnalysisReportDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>receipt</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Laudos
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isUnloadUnitTransferAccessVisible(item)"
                @click="showUnloadTransferConfirmationDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>call_split</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Alterar Unidade
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasInactivateAccess && item.operation === 'CARGA' && item.tanks.length > 0"
                @click="chargebackLoad(item)"
              >
                <v-list-item-icon>
                  <v-icon>settings_backup_restore</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Estornar carga
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="onPrint(item)"
              >
                <v-list-item-icon>
                  <v-icon>receipt_long</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Imprimir Recibo
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasInactivateAccess"
                @click="excludeUnload(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      fab
      dark
      bottom
      right
      open-on-hover
      class="mr-2"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue"
        @click="loadWaitingUnloadsList()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>

          Recarregar
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="hasItineraryTransferRegistrationAccess"
        color="blue darken-4"
        dark
        fab
        @click="() => showUnitTransferDialog = true"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              multiple_stop
            </v-icon>
          </template>
          Novo itinerário de Transferência
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="hasItinerarySpotRegistrationAccess"
        color="purple"
        dark
        fab
        @click="() => showSpotTransferDialog = true"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              business
            </v-icon>
          </template>
          Novo itinerário de Spot
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="hasItineraryTransshipmentRegistrationAccess"
        color="teal"
        dark
        fab
        @click="() => showTransshipmentDialog = true"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              save_alt
            </v-icon>
          </template>
          Novo itinerário de Transbordo
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="hasChecklistsAccess"
        color="orange"
        dark
        fab
        @click="showChecklistPlatformPrintDialog()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              checklist
            </v-icon>
          </template>
          Checklists Plataforma
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <itinerary-registration-dialog
      v-model="showUnitTransferDialog"
      type="transferencia"
      @newItinerary="loadWaitingUnloadsList"
    />

    <itinerary-registration-dialog
      v-model="showSpotTransferDialog"
      type="spot"
      @newItinerary="loadWaitingUnloadsList"
    />

    <itinerary-transshipment-registration-dialog
      v-model="showTransshipmentDialog"
      @newItinerary="loadWaitingUnloadsList"
    />

    <unload-analysis-report-dialog
      ref="unloadAnalysisReportDialog"
    />

    <checklist-platform-print-dialog
      ref="checklistPlatformPrintDialog"
    />

    <print-settings-dialog
      ref="print-settings"
      @print="print"
    />

    <pdf-viewer-dialog
      ref="pdfViewer"
    />

    <v-dialog
      v-model="confirmationDialog.show"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Atenção
        </v-card-title>
        <v-card-text>
          <v-form
            ref="confirmationDialogForm"
            lazy-validation
            @submit.prevent=""
          >
            <div v-html="confirmationDialog.content" />

            <dairy-autocomplete-filter
              v-if="confirmationDialog.showDairySelect"
              v-model="confirmationDialog.dairy"
              label="Unidade de Destino *"
              hide-current-dairy
              :hide-details="false"
              :filled="false"
              placeholder=" "
              :rules="[
                rules.required
              ]"
            />

            <v-checkbox
              v-if="originTransfer == idLaticinioItinerary"
              v-model="confirmationDialog.collectionItinerary"
              label="Gostaria de transferir Itinerário/Coleta?"
            />

            <div
              v-if="confirmationDialog.collectionItinerary === true"
              v-html="confirmationDialog.collection"
            />

            <div v-html="confirmationDialog.subContent" />

            <v-text-field
              v-model.number="confirmationDialog.typedToken"
              :rules="[v => (v && v === confirmationDialog.confirmationToken) || 'Token de confirmação incorreto!']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialogs()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmationDialog.handler()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import UnloadOperations from "@/Domains/Platform/Unload/Components/UnloadOperations.vue";
import ItineraryRegistrationDialog from "@/Domains/Itineraries/Components/ItineraryRegistrationDialog.vue";
import ItineraryTransshipmentRegistrationDialog from "@/Domains/Itineraries/Components/ItineraryTransshipmentRegistrationDialog.vue";
import DairyAutocompleteFilter from "@/Domains/Itineraries/Components/DairyAutocompleteFilter.vue";
import UnloadAnalysisReportDialog from "@/Domains/Platform/Unload/Components/UnloadAnalysisReportDialog.vue";
import ChecklistPlatformPrintDialog from "@/Domains/Platform/Unload/Components/ChecklistPlatformPrintDialog.vue";
import PrintSettingsDialog from '@/Support/Components/PrintSettingsDialog.vue'
import PdfViewerDialog from '@/Support/Components/PdfViewerDialog.vue'

export default {

  components: {
    UnloadAnalysisReportDialog,
    DairyAutocompleteFilter,
    UnloadOperations,
    ItineraryRegistrationDialog,
    ItineraryTransshipmentRegistrationDialog,
    ChecklistPlatformPrintDialog,
    PrintSettingsDialog,
    PdfViewerDialog,
  },

  filters: {

    date(value, format) {

      if (_.isEmpty(value)) {
        return '';
      }

      return moment(value).format(format);
    },

    truncate(value, length) {
      return _.truncate(value, { length });
    },

  },

  data() {
    return {
      // Loaders
      loading: true,

      // Dados brutos do backend
      unloads: [],

      // Filtros da tabela
      filters: {
        search: '',

        vehicle: {},

        driver: {},

        route: {},
      },

      // Matérias primas disponíveis
      rawProducts: [],

      showUnitTransferDialog: false,
      showSpotTransferDialog: false,
      showTransshipmentDialog: false,

      confirmationDialog: {
        show: false,
        unload: {},
        content: '',
        subContent: '',
        handler: () => {},
        showDairySelect: false,
        dairy: {},
        confirmationToken: '',
        collectionItinerary: false,
        collection: '',
      },

      rules: {
        required: v => !!v || !_.isEmpty(v) || 'Campo obrigatório!',
      },

      weightMeasurementModal: {
        show: false,
        unload: {},
      },

      itineraryTypes: {
        'coleta': 'COLETA',
        'transferencia': 'TRANSFERÊNCIA',
        'spot': 'SPOT',
      },

      itineraryId: null,
      originTransfer: null,
      idLaticinioItinerary: null,
    };
  },

  computed: {

    headers() {

      if (this.$vuetify.breakpoint.md) {
        return [
          { align: 'start', text: 'Motorista', value: 'driver.name' },
          { text: 'Placa', value: 'vehicle.plate' },
          { text: 'Origem/Destino/Rota', value: 'route.description' },
          { align: 'end', text: '', value: 'unloadOperations', width: 320, sortable: false },
          { align: 'end', text: '', value: 'actions', width: 50, sortable: false },
        ];
      }

      return [
        { align: 'start', text: 'Código', value: 'itinerary.code' },
        { align: 'start', text: 'Nº Nota', value: 'invoiceNumber' },
        { align: 'start', text: 'Motorista', value: 'driver.name' },
        { text: 'Tipo', value: 'itinerary.type' },
        { text: 'Matéria Prima', value: 'rawProduct' },
        { text: 'Operação', value: 'operation' },
        { text: 'Placa', value: 'vehicle.plate' },
        { text: 'Data/Hora Operação', value: 'operationTime', width: 165 },
        { text: 'Origem/Destino/Rota', value: 'route.description' },
        { align: 'end', text: '', value: 'unloadOperations', width: 340, sortable: false },
        { align: 'end', text: '', value: 'actions', width: 50, sortable: false },
      ];
    },

    /**
     * Exibe as rotas com base nos filtros selecionados em tela
     */
    filteredUnloads() {
      if (!this.filters.search) {
        return this.unloads;
      }

      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;
      return this.unloads.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasSearch;
      });
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasItinerarySpotRegistrationAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "itinerary-spot-insert" && o.tipo === "COMPONENTE");
    },

    hasItineraryTransferRegistrationAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "itinerary-transfer-insert" && o.tipo === "COMPONENTE");
    },

    hasItineraryTransshipmentRegistrationAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "itinerary-transshipment-insert" && o.tipo === "COMPONENTE");
    },

    hasUnloadUnitTransferAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-unload-unit-transfer" && o.tipo === "COMPONENTE");
    },

    hasInactivateAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-unload-inactivate" && o.tipo === "COMPONENTE");
    },

    hasChecklistsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-checklist" && o.tipo === "COMPONENTE");
    },

    hasItineraryData() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'hide-itinerary-data' && o.tipo === "COMPONENTE");
    },

    isJustificationEnabled() {
      const settings = this.$store.state.settings.plataforma || {};

      return settings.justificar_edicao_analise || false
    },
  },

  async mounted() {
    await this.loadRawProductsList();

    await this.loadWaitingUnloadsList();
  },

  methods: {

    async onUnloadConclude() {
      return this.loadWaitingUnloadsList();
    },

    async loadRawProductsList() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: 'TODOS'
        } });

        this.rawProducts = data.map(rawProduct => {
          return {
            id: rawProduct.id_item,
            name: rawProduct.nome,
            type: rawProduct.tipo,
            rawMaterialGroupId: rawProduct.id_agrupador_materia_prima,
          };
        });
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as matérias primas!",
          "Atenção"
        );

        console.log(err);
      } finally {

        this.loading = false;
      }
    },

    /**
     * Método responsável por carregar todas as rotas disponíveis para o laticínio
     * @returns {Promise<void>}
     */
    async loadWaitingUnloadsList() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/descargaColeta/listaResumida`,
          { estado: [ 'DESCARREGANDO', 'PROCESSANDO' ] }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        const availableStatus = {
          'DESCARREGANDO': 'WAITING',
          'PROCESSANDO': 'PROCESSING',
        }

        this.unloads = data.map(item => {

          const analysts = JSON.parse(item.analistas) || [];

          let rawProduct = this.rawProducts.find(rawProduct => rawProduct.id === item.id_materia_prima);
          const rawProductInput = { ...rawProduct };

          // Caso seja materia prima agrupada é realizada a busca pelo id da materia prima pai
          if (rawProduct.rawMaterialGroupId) {
            rawProduct = {
              rawId: rawProduct.id,
              rawName: rawProduct.name,
              ...this.rawProducts.find(({ id }) => id === rawProduct.rawMaterialGroupId),
            }
          }

          return {
            id: item.id_descarga_coletas,
            status: availableStatus[item.estado],
            departureTime: item.data_hora_inicio,
            operationTime: item.data_hora_descarga, // Não formatar data, pois é usado na ordenação do datatable
            arrivalTime: item.data_hora_descarga ? moment(item.data_hora_descarga).format("DD/MM/YYYY HH:mm") : '',
            entrancedAt: item.data_hora_baixa_portaria ? moment(item.data_hora_baixa_portaria).format("DD/MM/YYYY HH:mm") : '',
            departureAt: item.data_hora_saida_portaria ? moment(item.data_hora_saida_portaria).format("DD/MM/YYYY HH:mm") : '',
            sampleTakenAt: item.hora_retirada_amostra ? moment(item.hora_retirada_amostra).format("DD/MM/YYYY HH:mm") : '',
            releasedAt: item.hora_liberacao ? moment(item.hora_liberacao).format("DD/MM/YYYY HH:mm") : '',
            inletWeight: parseInt(item.peso_entrada) || 0,
            outletWeight: parseInt(item.peso_saida) || 0,
            vale: parseInt(item.peso_vale) || 0,
            measured: parseInt(item.peso_medido) || 0,
            difference: parseInt(item.diferenca_peso) || 0,
            discount: parseInt(item.desconto) || 0,
            obs: item.observacao,
            reports: JSON.parse(item.anexos) || [],
            operation: item.operacao,
            invoiceNumber: item.numero_nota,
            cargaLacre: item.carga_lacre,
            cip: item.cip,
            analysts: analysts.map(analyst => {
              return {
                id: analyst.id_pessoa,
                name: analyst.nome_pessoa,
                signature: analyst.assinatura,
              };
            }),
            driver: {
              id: item.id_motorista,
              name: item.nome_motorista,
            },
            vehicle: {
              plate: item.placa_veiculo,
            },
            route: {
              id: item.id_rota,
              description: item.tipo === 'transferencia' && item.operacao === 'DESCARGA' ? item.nome_transferencia_origem : item.nome_rota
            },
            itinerary: {
              id: item.id_itinerario,
              type: item.tipo,
              code: item.cod_itinerario,
              totalSample: item.totalamostra,
              totalObservation: item.totalobservacao,
              totalJustification: item.totalmotivo,
            },
            transfers: [],
            tanks: [],
            adjustment: parseInt(item.peso_vale || 0) - parseInt(item.peso_vale_original || 0),
            rawProduct: rawProduct || {},
            rawProductInput,
            hasTanksError: item.inconformidades_tanque,
            hasProducersError: item.inconformidades_produtor,
            originId: item.id_descarga_origem,
            originTransferId: item.id_laticinio,
            idLaticinioItinerario: item.laticinio_itinerario,
          };
        });
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as rotas!",
          "Atenção"
        );

        console.log(err);
      } finally {

        this.loading = false;
      }
    },

    async onUnloadProcessing(item) {
      return this.$emit("onUnloadProcessing", item);
    },

    async showUnloadTransferConfirmationDialog(unload) {
      try {
        this.loading = true;

        this.confirmationDialog.unload = unload;
        this.confirmationDialog.show = true;
        this.confirmationDialog.handler = this.moveUnitUnload;
        this.confirmationDialog.showDairySelect = true;
        this.confirmationDialog.content = `
          Deseja realmente transferir esta descarga para uma outra unidade?
            <br /> <br />
          - Rota: <b>${unload.route.description}</b>
            <br />
          - Motorista: <b>${unload.driver.name}</b>
            <br />
          - Veiculo: <b>${unload.vehicle.plate || ''}</b>
            <br /> <br />
        `;

        this.confirmationDialog.collection = `
          - Total de Amostra: <b>${unload.itinerary.totalSample}</b>
            <br />
          - Total Impedimento de Coletas: <b>${unload.itinerary.totalObservation}</b>
            <br />
          - Total de Justificativa Itinerário: <b>${unload.itinerary.totalJustification}</b>
            <br /> <br />
        `;

        this.confirmationDialog.subContent = `
            <br />
          Esta ação não poderá ser revertida!
            <br /> <br />
          Para continuar, por favor digite <b>TRANSFERIR</b> no campo abaixo:
        `;
        this.confirmationDialog.confirmationToken = 'TRANSFERIR';

        this.itineraryId = unload.itinerary.id;
        this.originTransfer = unload.originTransferId;
        this.idLaticinioItinerary = unload.idLaticinioItinerario;
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir a descarga!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    showUnloadAnalysisReportDialog(unload) {
      return this.$refs.unloadAnalysisReportDialog.show(unload);
    },

    async excludeUnload(unload) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente excluir esta descarga?
          <br /> <br />
        - Rota: <b>${unload.route.description}</b>
          <br />
        - Motorista: <b>${unload.driver.name}</b>
          <br />
        - Veiculo: <b>${unload.vehicle.plate || ''}</b>
          <br /> <br />
        Esta ação não poderá ser revertida!
        <br /> <br />`,
        { color: 'red', token: 'EXCLUIR' }
      ))) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/descargaColeta/inativa`,
          { id_descarga_coletas: unload.id }
        );

        if (!_.isObject(data)) {
          throw data;
        }

        const { codigo } = data;

        if (codigo !== 1) {
          throw data;
        }
        if (data.codigo === 2) {
          this.$snotify.warning(
            data.mensagem,
            "Atenção"
          );
          return false;
        }

        await this.loadWaitingUnloadsList();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir a descarga!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async chargebackLoad(unload) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente estornar esta carga?<br><br>
        - Rota: <b>${unload.route.description}</b><br>
        - Motorista: <b>${unload.driver.name}</b><br>
        - Veiculo: <b>${unload.vehicle.plate || ''}</b><br><br>
        <div class="v-alert v-sheet v-sheet--outlined theme--light elevation-0 v-alert--dense v-alert--outlined red--text body-2">
          As análises efetuadas serão excluídas!
        </div>
        Esta ação não poderá ser revertida!<br><br>`,
        { color: 'red', token: 'ESTORNAR' }
      ))) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/descargaColeta/reverteDescargaFila`,
          { id_descarga_coletas: unload.id }
        );

        if (!_.isObject(data)) {
          throw data;
        }

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        const row = this.unloads.find(item => item.id === unload.id);

        row.tanks = [];

        this.$refs[`operations-${row.id}`].loadDetails();

      } catch (e) {
        console.warn(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao estornar a carga!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    onTanksUpdated(id, tanks) {
      const row = this.unloads.find(item => item.id === id);

      row.tanks = [...tanks];
    },

    onRawProductUpdated(id, rawProduct) {
      const row = this.unloads.find(item => item.id === id);

      row.rawProduct = { ...rawProduct };
    },

    async moveUnitUnload() {
      try {
        this.loading = true;

        const valid = this.$refs.confirmationDialogForm.validate();

        if (!valid) {
          return;
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/transfereDescargaUnidade`,
          {
            id_descarga_coletas: this.confirmationDialog.unload.id,
            id_laticinio_destino: this.confirmationDialog.dairy.id,
            collection_itinerary: this.confirmationDialog.collectionItinerary,
            itinerary_id: this.itineraryId,
            origin_transfer_id: this.originTransfer,
          }
        );

        if (!_.isObject(data)) {
          throw data;
        }

        const { codigo } = data;

        if (codigo !== 1) {
          throw data;
        }

        await this.loadWaitingUnloadsList();

        return this.closeDialogs();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir a descarga!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    closeDialogs() {
      this.confirmationDialog = {
        show: false,
        unload: {},
        content: '',
        subContent: '',
        handler: () => {},
        showDairySelect: false,
        dairy: {},
        confirmationToken: '',
      };
    },

    isUnloadUnitTransferAccessVisible(unload) {

      if (!this.hasUnloadUnitTransferAccess) {
        return false;
      }

      return unload.operation !== 'CARGA';
    },

    showChecklistPlatformPrintDialog() {
      return this.$refs.checklistPlatformPrintDialog.show();
    },

    onPrint({ id }) {
      const params = [
        { title: 'Movimentações do Silo', key: 'mostra_movimentacoes_silo', value: false },
      ];

      if (this.hasItineraryData) {
        params.push({ title: 'Dados do Itinerário', key: 'mostra_dados_itinerario', value: false },)
      }

      if (this.isJustificationEnabled) {
        params.push({ title: 'Justificativas de edição', key: 'mostra_justificativas', value: false })
      }

      if (this.hasChecklistsAccess) {
        params.push({ title: 'Checklists', key: 'mostra_checklists', value: false })
      }

      this.$refs['print-settings'].show({
        item: {
          ids: [id],
        },
        params
      });
    },

    async print({ item: { ids },  params }) {
      this.$refs['pdfViewer'].show({
        title: 'Controle de Recebimento de Matéria Prima',
        api: async () => {
          const { data } = await this.$axios.post(`/descargaColeta/reciboDescarga`, { ids, params })
          return data
        }
      })
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

  },

};
</script>
