<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Extrato Financeiro do Produtor
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        color="transparent"
        dark
        class="mt-5"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              md
            >
              <v-menu
                v-if="!hasProductor"
                ref="menu"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="dateRefFilter"
                    label="Mês de Referência"
                    prepend-inner-icon="event"
                    readonly
                    filled
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filters.date"
                  :max="maxDateRef"
                  type="month"
                  no-title
                  scrollable
                  @change="loadReport"
                />
              </v-menu>

              <v-date-range-picker
                v-if="hasProductor"
                v-model="filters.dateRange"
                :ranges="dateRanges"
                format="DD/MM/YYYY"
                @change="loadReport"
              />
            </v-col>
            <v-col
              cols="12"
              md
            >
              <person-autocomplete-filter
                v-model="filters.producers"
                label="Produtor"
                type="PRODUCER"
                multiple
                :return-object="false"
                @change="onProductorChange"
              />
            </v-col>
            <v-col
              cols="12"
              md
            >
              <v-select
                v-model="filters.status"
                :items="statusList"
                prepend-inner-icon="list"
                label="Status"
                hide-details
                clearable
                filled
                @change="loadReport"
              />
            </v-col>
            <v-col
              cols="12"
              md
            >
              <v-select
                v-model="filters.advancePayment"
                :items="[
                  { text: 'Sim', value: 1 },
                  { text: 'Não', value: 0 },
                ]"
                prepend-inner-icon="list"
                label="Adiantamento de Pagamento"
                hide-details
                clearable
                filled
                @change="loadReport"
              />
            </v-col>
            <v-col
              cols="12"
              md
            >
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                hide-details
                clearable
                filled
              />
            </v-col>
          </v-row>
        </v-card-title>
        <data-table
          ref="report"
          :headers="headers"
          :items="items"
          :search="filters.search"
          :loading="loading"
          item-key="id_produtor"
          show-expand
          :items-per-page="50"
          @item-expanded="onDetails"
        >
          <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
            <v-btn
              v-if="item.valor != 0"
              icon
              link
              transition="fab-transition"
              @click="expand(!isExpanded)"
            >
              <v-icon>
                {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </v-icon>
            </v-btn>
          </template>

          <template #[`item.volume`]="{ value, item }">
            <span :class="{ 'text-decoration-line-through': item.volume_bonus }">
              {{ formatNumber(value) }} L
            </span>
          </template>
          <template #[`item.saldo_anterior`]="{ value }">
            <b :class="{'green--text': value > 0, 'red--text': value < 0, 'text--accent-3': true}">
              {{ formatCurrency(value) }}
            </b>
          </template>
          <template #[`item.valor`]="{ value }">
            <b :class="{'green--text': value > 0, 'red--text': value < 0, 'text--accent-3': true}">
              {{ formatCurrency(value) }}
            </b>
          </template>
          <template #[`item.saldo`]="{ value }">
            <b :class="{'green--text': value > 0, 'red--text': value < 0, 'text--accent-3': true}">
              {{ formatCurrency(value) }}
            </b>
          </template>
          <template #[`item.saldo_final`]="{ value }">
            <v-chip
              small
              :color="(value < 0) ? 'red accent-3' : 'green'"
            >
              {{ formatCurrency(value) }}
            </v-chip>
          </template>
          <template #[`item.adiantamento`]="{ value }">
            <v-chip
              small
              :color="(value) ? 'blue' : 'orange'"
            >
              {{ value ? 'Sim' : 'Não' }}
            </v-chip>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-row no-gutters>
                <v-col
                  v-if="!item.details"
                  cols="12"
                >
                  Carregando...
                </v-col>
                <v-col
                  v-else
                  cols="12"
                >
                  <v-card
                    class="mt-3"
                    dark
                    color="transparent"
                  >
                    <v-card-title>
                      <span class="text-subtitle-2">{{ item.nome_produtor }}</span>
                      <v-spacer />
                      <v-btn
                        fab
                        outlined
                        small
                        color="green accent-3"
                        class="mr-4"
                        @click="exportExcelDetails(item)"
                      >
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <v-icon v-on="on">
                              backup_table
                            </v-icon>
                          </template>
                          Download (Excel)
                        </v-tooltip>
                      </v-btn>

                      <v-btn
                        fab
                        outlined
                        small
                        color="orange accent-3"
                        @click="printDetails(item)"
                      >
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <v-icon v-on="on">
                              print
                            </v-icon>
                          </template>

                          Imprimir
                        </v-tooltip>
                      </v-btn>
                    </v-card-title>

                    <v-data-table
                      :headers="headersDetails"
                      :items="item.details"
                      class="technical-visit-table"
                      dark
                      dense
                      hide-default-footer
                      disable-pagination
                    >
                      <template #[`item.data_emissao`]="{ value }">
                        {{ formatDate(value, 'DD/MM/YYYY') }}
                      </template>
                      <template #[`item.data_vencimento`]="{ value }">
                        {{ formatDate(value, 'DD/MM/YYYY') }}
                      </template>
                      <template #[`item.valor`]="{ value }">
                        {{ formatCurrency(value) }}
                      </template>
                      <template #[`item.saldo`]="{ value }">
                        {{ formatCurrency(value) }}
                      </template>

                      <template #[`item.conta`]="{ item }">
                        <v-btn
                          icon
                          @click.stop="showBill(item)"
                        >
                          <v-icon>
                            attach_money
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </td>
          </template>
        </data-table>
        <v-card-title>
          <v-spacer />
        </v-card-title>
      </v-card>
    </v-flex>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      :type="billDialog.type"
      @input="billDialog.id = null"
      @removeBill="loadReport"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="purple"
        @click="addBill('CONVENIO')"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>
          Novo Convênio
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="red"
        @click="addBill('DESCONTO')"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>
          Novo Desconto
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="blue"
        @click="addBill('VALE')"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>
          Novo Vale
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import ReportMixin from '@/Support/Mixins/ReportMixin.js';
import moment from 'moment-timezone';
import _ from 'lodash';

export default {

  components: {
    BillDialog,
    PersonAutocompleteFilter,
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      hasProductor: false,

      isDetails: false,

      statusList: [
        'APROVADO',
        'DESVINCULADO',
        'SUSPENSO',
      ],

      dateRanges: {
        'Hoje': [
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD")
        ],
        'Ontem': [
          moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD"),
          moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD")
        ],
        'Este Mês': [
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD")
        ],
        'Mês Anterior': [
          moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
          moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
        ],
        'Este Ano': [
          moment().startOf('year').format('YYYY-MM-DD'),
          moment().endOf('year').format('YYYY-MM-DD')
        ],
      },

      // Filtros usados para buscar a rota
      filters: {
        date: moment().subtract(1, 'month').format('YYYY-MM'),
        advancePayment: null,
        search: '',

        dateRange: [
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD")
        ],
      },

      headers: [
        { text: 'Cód. Laticínio', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome_produtor' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Adiantamento', value: 'adiantamento', align: 'center' },
        { text: 'Volume', value: 'volume', align: 'end', formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
        { text: 'Saldo Anterior', value: 'saldo_anterior', align: 'end', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Valor Mês', value: 'valor', align: 'end', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Saldo Mês', value: 'saldo', align: 'end', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Saldo Final', value: 'saldo_final', align: 'end', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
      ],

      items: [],

      headersDetails: [
        { text: '#', value: 'cod_parcela' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Observação', value: 'observacao' },
        { text: 'Data Emissão', value: 'data_emissao', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Data Vencimento', value: 'data_vencimento', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Valor', value: 'valor', align: 'end', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Saldo', value: 'saldo', align: 'end', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Conta', value: 'conta', align: 'center', width: 100, sortable: false },
      ],

      // Exibe dialogo de financeiro
      billDialog: {
        show: false,
        type: 'VALE_PRESTADOR',
        id: null,
      },
    };
  },

  computed: {

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format('YYYY-MM');
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, 'YYYY-MM').format('MM/YYYY');
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async loadReport() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/financial/reports/producer-statement`, { params: {
          month_reference: this.filters.date,
          productor_status: this.filters.status,
          productor: this.filters.producers,
          period_reference: this.filters.dateRange,
          advance_payment: this.filters.advancePayment,
        } });

        this.items = data;

        if (this.isDetails) {
          this.isDetails = false;

          for (let i = 0; i < this.items.length; i += 1) {
            const item = this.items[i];
            const objProductor = {
              'item': item,
              'value': true,
            };
            this.onDetails(objProductor);
          }
        }
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error('Erro ao carregar o relatório', 'Atenção');
      }
      finally {
        this.loading = false;
      }
    },

    async onProductorChange() {
      if (!_.isEmpty(this.filters.producers)) {
        this.hasProductor = true;
      }
      else {
        this.hasProductor = false;
      }

      this.loadReport();
    },

    async onDetails({ item, value }) {
      try {
        console.log(item);
        // Não faz nada quando fechar
        if (!value) {
          return;
        }

        // Verifica se os dados já foram carregados do servidor
        if (item.details) {
          return;
        }

        this.isDetails = true;

        const { data } = await this.$axios.get(`/financial/reports/producer-statement/details`, { params: {
          month_reference: this.filters.date,
          producer_id: item.id_produtor,
          period_reference: this.filters.dateRange,
          has_period: this.filters.producers,
        } });

        const index = this.items.findIndex(report => report.id_produtor === item.id_produtor);
        this.items.splice(index, 1, { details: data, ...this.items[index] });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os detalhes!", "Atenção");

        console.error(err);
      }
    },

    showBill(item) {
      this.billDialog.type = item.tipo;
      this.billDialog.id = item.id_titulo;
      this.billDialog.show = true;
    },

    addBill(type) {
      this.billDialog.type = type;
      this.billDialog.id = null;
      this.billDialog.show = true;
    },

    print() {
      const title = `Extrato - ${moment(this.filters.date, 'YYYY-MM').format('MMM-YY').toUpperCase()}`;

      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = `Extrato - ${moment(this.filters.date, 'YYYY-MM').format('MM-YYYY')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    async printDetails(producer) {
      const title = `Extrato - ${producer.nome_produtor} - ${moment(this.filters.date, 'YYYY-MM').format('MMM-YY').toUpperCase()}`;

      const report = producer.details.map(item => ({
        '#': item.cod_parcela,
        'Tipo': item.tipo,
        'Descrição': item.descricao,
        'Observação': item.observacao,
        'Data Emissão': this.formatDate(item.data_emissao, 'DD/MM/YYYY'),
        'Data Vencimento': this.formatDate(item.data_vencimento, 'DD/MM/YYYY'),
        'Valor': this.formatCurrency(item.valor),
        'Saldo': this.formatCurrency(item.saldo),
      }))

      if (report.length === 0) {
        return;
      }

      const body = [];

      body.push({
        tag: 'section',
        class: 'row',
        children: [{
          tag: 'datatable',
          headers: Object.keys(report[0])
            .map(header => ({
              key: header,
              label: header,
              class: 'text-left'
            })),
          items: report,
        }]
      });

      const totals = {
        '': '<b>Total:</b>',
        'Volume': this.formatNumber(producer.volume) + ' L',
        'Saldo Anterior': this.formatCurrency(producer.saldo_anterior),
        'Valor Mês': this.formatCurrency(producer.valor),
        'Saldo Mês': this.formatCurrency(producer.saldo),
        'Saldo Final': this.formatCurrency(producer.saldo_final),
      };

      body.push({
        tag: 'section',
        class: 'row',
        children: [
          {
            tag: 'div',
            class: 'six columns',
            contents: '&nbsp;'
          },
          {
            tag: 'div',
            class: 'six columns',
            children: [{
              tag: 'datatable',
              headers: Object.keys(totals)
                .map(header => ({
                  key: header,
                  label: header,
                  class: 'text-left'
                })),
              items: [totals],
            }]
          },
        ]
      });

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.printPdf({ pages: [{ title, header, body }] });
    },

    exportExcelDetails(producer) {
      const title = `Extrato - ${producer.nome_produtor} - ${moment(this.filters.date, 'YYYY-MM').format('MM-YYYY')}`;

      const headers = this.headersDetails.filter(h => h.value !== 'conta');
      const report = producer.details
        .map(item => headers.reduce((acc, header) => ({
          ...acc,
          [header.text]: ('formatter' in header) && !('mask' in header)
            ? header.formatter(_.get(item, header.value, ''))
            : _.get(item, header.value, ''),
        }), {}))

      const worksheet = this.XLSX.utils.json_to_sheet([]);
      const workbook = this.XLSX.utils.book_new();

      this.XLSX.utils.sheet_add_json(worksheet, report);
      this.applyStyles(worksheet, report, null);
      this.applyMasks(worksheet, headers, report.length);

      {
        const totals = [{
          '': 'Total',
          ' ': '',
          '  ': '',
          'Volume': parseFloat(producer.volume),
          'Saldo Anterior': parseFloat(producer.saldo_anterior),
          'Valor Mês': parseFloat(producer.valor),
          'Saldo Mês': parseFloat(producer.saldo),
          'Saldo Final': parseFloat(producer.saldo_final),
        }];

        const totalsMasks = [
          {}, {}, {}, { mask: '#,##0' }, { mask: 'R$ #,##0.00' }, { mask: 'R$ #,##0.00' }, { mask: 'R$ #,##0.00' }, { mask: 'R$ #,##0.00' },
        ];

        const currentRow = report.length + 3;

        this.XLSX.utils.sheet_add_json(worksheet, totals, { origin: currentRow });
        this.applyStyles(worksheet, totals, null, false, currentRow);
        this.applyMasks(worksheet, totalsMasks, totals.length, currentRow);
      }

      worksheet['!cols'] = [ { wch: 10 }, { wch: 15 }, { wch: 25 }, { wch: 25 }, { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 12 } ];

      this.XLSX.utils.book_append_sheet(workbook, worksheet, _.truncate(title, { length: 31 }));
      this.XLSX.writeFile(workbook, `${title}.xlsx`);
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format('MMM/YY')),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
