<template>
  <div>
    <v-autocomplete
      v-model="input"
      :items="competitors"
      item-text="name"
      item-value="id"
      prepend-inner-icon="supervised_user_circle"
      :label="label"
      placeholder="Laticínio concorrente"
      :loading="loading"
      :rules="rules"
      :filter="(competitor, queryText) => (`${competitor.code || ''} - ${(competitor.name || '').toLocaleLowerCase()}`).indexOf(queryText.toLocaleLowerCase()) > -1"
      clearable
      dense
      @change="onChange"
    >
      <template #item="{ item }">
        <v-list-item-content>
          <v-list-item-title
            v-if="item.code"
            v-text="`${item.code} - ${item.name}`"
          />
          <v-list-item-title
            v-else
            v-text="item.name"
          />
        </v-list-item-content>
      </template>

      <template #selection="{item}">
        <div v-if="item.code">
          {{ item.code }} - {{ item.name }}
        </div>
        <div v-else>
          {{ item.name }}
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {

  props: {
    value: {
      type: [Object, String],
    },

    label: {
      type: String,
      default: 'Veículo',
    },

    filled: {
      type: Boolean,
      default: true,
    },

    rules: {
      type: [Array],
      default: () => ([]),
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    returnObject: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // técnicos do Formulário (Autocomplete)
      competitors: [],
    };
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  async mounted() {
    this.input = this.value;

    await this.loadCompetitors();
  },

  methods: {
    async loadCompetitors() {
      try {
        const { data } = await this.$axios.get(`/laticinio/listaJson`);

        this.competitors = data.map(item => ({
          id: item.id_laticinio,
          code: item.codigo_laticinio,
          name: item.nome,
        }));
      } catch (error) {
        console.warn(error);
      }
    },

    onChange(event) {

      const newValue = event || {};

      // atualiza o v-model do componente
      this.$emit("input", newValue);

      this.$emit("change");
    },
  },
};
</script>
