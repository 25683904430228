
import axios from "@/Support/Resources/axios-instance.js";
import { isObject, isArray } from "lodash";

export default {
  state: {
    menuEditable: false,

    searchMenu: "",
    menuItems: [],
    favorites: [],

    // lactobom, dalia, etc...
    sistema: "",

    // itens do cabeçalho
    laticinios: [],
    laticinio: null,
    produtor: null,
    coordenadas: {
      lat: 0,
      lng: 0,
    },
    tipoAcesso: "",
    user: {},
    gerais: {},
    plataforma: {},
    filters: {},
    bill: {},
    reports: [],
    fiscalOperations: [],

    alert: {
      show: false,
      showInterval: 1,
      type: '',
      startAt: '',
      endAt: '',
      title: '',
      message: '',
    },

    versionNotes: {
      show: false,
      date: '',
      notes: '',
    },
  },
  mutations: {
    toggleMenuEditable(state) {
      state.menuEditable = !state.menuEditable;
    },
    setLaticinios(state, laticinios) {
      state.laticinios = laticinios || [];
    },
    setLaticinio(state, laticinio) {
      state.laticinio = laticinio;
    },
    setProdutor(state, produtor) {
      state.produtor = produtor;
    },
    setCoordenadas(state, coordenadas) {
      state.coordenadas = coordenadas;
    },
    setSistema(state, sistema) {
      state.sistema = sistema;
    },
    setMenuItems(state, menuItems) {
      state.menuItems = menuItems;
    },
    setSearchMenu(state, searchMenu) {
      state.searchMenu = searchMenu;
    },
    setFavorites(state, favorites) {
      state.favorites = favorites;
    },
    setTipoAcesso(state, tipoAcesso) {
      state.tipoAcesso = tipoAcesso;
    },
    setUserData(state, user) {
      state.user = user;
    },
    setGeneralSettings(state, settings) {
      state.gerais = settings;
    },
    setRecursosUsuario(state, recursos) {
      state.recursosUsuario = recursos;
    },
    setPlataformSettings(state, settings) {
      state.plataforma = settings;
    },
    setFilter(state, filter) {
      state.filters = {
        ...state.filters,
        ...filter
      };
    },
    setBill(state, bill) {
      state.bill = {
        ...state.bill,
        ...bill
      };
    },
    setAnalysisParameters(state, analyses) {
      state.plataforma = {
        ...state.plataforma,
        analyses
      };
    },
    setReportsSettings(state, settings) {
      state.reports = settings;
    },
    updateReportSettings(state, settings) {
      state.reports = {
        ...state.reports || {},
        ...settings
      };
    },
    setFiscalOperations(state, operations) {
      state.fiscalOperations = operations;
    },
    setWebSettings(state, { aviso = {} }) {
      state.alert = aviso;
    },
    setVersionNotes(state, data = {}) {
      state.versionNotes.show = !!data.data;
      state.versionNotes.date = data.data;
      state.versionNotes.notes = data.notas?.replace(/\n/g, '<br>');
    },
  },
  actions: {
    async updateReportsSettings({ commit }) {
      try {
        const { data } = await axios.get(`/configuracao/listaConfiguracaoRelatorio`);

        if (!data.codigo) {
          return commit("setReportsSettings", {});
        }

        const settings = data.configuracoes.reduce((acc, cur) => ({
          ...acc,
          [cur.relatorio]: cur.configuracao
        }), {})

        commit('setReportsSettings', settings);
      } catch (e) {
        console.warn(e);
      }
    },
    async updateStoreSetting({ commit }) {
      try {
        const { data } = await axios.get(`/configuracao/listaConfiguracoesLaticinio`);

        if (!isObject(data) || data.codigo === 0) {
          return commit("setGeneralSettings", {});
        }

        commit('setGeneralSettings', data);
      } catch (e) {
        console.warn(e);
      }
    },
    async updatePlatformSettings({ commit }) {
      try {
        const { data } = await axios.get(`/configuracao/listaConfiguracoesPlataforma`);

        if (!isObject(data) || data.codigo === 0) {
          return commit("setPlataformSettings", {});
        }

        return commit("setPlataformSettings", data);
      } catch (e) {
        console.warn(e);
      }
    },
    async updateFiscalOperations({ commit }) {
      try {
        const { data } = await axios.get(`/fiscal/operation`);

        if (!isArray(data)) {
          return commit("setFiscalOperations", []);
        }

        return commit("setFiscalOperations", data);
      } catch (e) {
        console.warn(e);
      }
    },
    async updateWebSettings({ commit }) {
      try {
        const { data } = await axios.get(`/settings/web/cached`);

        if (!isObject(data)) {
          return commit("setWebSettings", []);
        }

        return commit("setWebSettings", data);
      } catch (e) {
        console.warn(e);
      }
    },
    async updateVersionNotes({ commit }) {
      try {
        const { data } = await axios.get(`/settings/web-versions/notes`);

        if (!isObject(data)) {
          return commit("setVersionNotes", {});
        }

        return commit("setVersionNotes", data);
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
