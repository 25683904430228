<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.project"
          :items="projectList"
          label="Descrição"
          item-value="id"
          item-text="text"
          return-object
          hide-details
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="loadDateRange"
        />
      </v-col>

      <v-col
        md="3"
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onFilterDate"
        />
      </v-col>

      <v-col
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-select
          v-model="producerStatus"
          dark
          filled
          item-text="text"
          item-value="value"
          return-object
          label="Status Produtor"
          :items="[
            {text:'Todos',value:'*'},
            {text:'Ativos',value:'ativo'},
            {text:'Suspenso',value:'suspenso'},
            {text:'Prospectado',value:'prospectado'},
            {text:'Desvinculado',value:'desvinculado'},
          ]"
          multiple
          @change="onLoad"
        />
      </v-col>
      <v-col
        v-if="filter.project.formula.length>0 && $store.state.settings.laticinio.name == 'STARMILK'"
        cols="12"
        md="12"
      >
        <p> *Formula (CCS x peso) + (CPP x peso) + (Gordura x peso) + (Proteina x peso)</p>
      </v-col>
      <v-col
        v-else-if="filter.project.formula.length>0 "
        cols="12"
        md="12"
      >
        <p> *Formula ( volume X 1 ) + (volume x 1) se CPP < 100 + (volume x 1) se CSS < 500</p>
      </v-col>
      <v-col
        v-else
        cols="12"
        md="12"
      >
        <h1>*Sem fórmula configurada</h1>
      </v-col>
    </v-row>

    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        {{ `Produtores : ${ producers.length}` }}
        <v-spacer />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="producers"
        :search="filter.search"
        item-key="id_produtor"
        class="elevation-1"
        :items-per-page="-1"
        hide-default-footer
        show-expand
        single-expand
        :expanded.sync="expanded"
        @item-expanded="showDetails"
      >
        <template #[`item.saldo_anterior`]="{value}">
          {{ formatNumber(value) }}
        </template>
        <template #[`item.saldo_mes`]="{value}">
          {{ formatNumber(value) }}
        </template>
        <template #[`item.saldo_total`]="{value}">
          {{ formatNumber(value) }}
        </template>
        <template
          #[`item.data_entrada_laticinio`]="{ value }"
        >
          {{ formatDate(value, "DD/MM/YYYY") }}
        </template>
        <template #[`item.status`]="{item}">
          <v-chip
            v-if="item.status.description == 'DESVINCULADO'"
            color="red"
            small
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  left
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>cancel</v-icon>
                </v-avatar>
                D
              </template>
              <span>
                Saída em {{ formatDate(item.data_saida, 'DD/MM/YYYY') }}
              </span>
            </v-tooltip>
          </v-chip>
          <v-chip
            v-else-if="item.status.description == 'SUSPENSO'"
            color="orange"
            small
          >
            <v-avatar
              left
              small
            >
              <v-icon>remove_circle</v-icon>
            </v-avatar>
            S
          </v-chip>
          <v-chip
            v-else
            color="green"
            small
          >
            <v-avatar
              left
              small
            >
              <v-icon>check_circle</v-icon>
            </v-avatar>
            A
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            dark
            color="blue"
            @click="showAwardsDialog(item)"
          >
            <v-icon>refresh</v-icon>Resgatar
          </v-btn>
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="onPrint(item)">
                <v-list-item-icon>
                  <v-icon>print</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Imprimir
                </v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="showAwardsDialog(item)">
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Resgatar Prêmio
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
        <template #expanded-item="{headers}">
          <td
            :colspan="headers.length"
            class="pa-5 text-center"
          >
            <v-row>
              <v-col
                md="6"
                class="pb-12"
              >
                <v-card
                  color="transparent"
                  dark
                  class="mt-5"
                >
                  <v-card-title>
                    Resultado dos indicadores do mês
                    <v-spacer />
                  </v-card-title>
                  <v-row
                    v-for="i in details.indicators"
                    :key="i.id_produtor"
                  >
                    <v-col cols="2">
                      {{ i.nome_faixa }}
                    </v-col>
                    <v-col cols="3">
                      Resultado: {{ i.resultado }}
                    </v-col>
                    <v-col cols="3">
                      Peso: {{ i.peso }}
                    </v-col>
                    <v-col cols="3">
                      Peso x Resultado: {{ i.resultado*i.peso }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col
                md="6"
                class="pb-12"
              >
                <v-card
                  color="transparent"
                  dark
                  class="mt-5"
                >
                  <v-card-title>
                    Histórico
                    <v-spacer />
                  </v-card-title>
                  <v-data-table
                    :headers="details.headers"
                    :items="details.items"
                    class="elevation-1"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template #[`item.data_classificacao`]="{value}">
                      {{ formatDate(value,'DD/MM/YY') }}
                    </template>

                    <template #[`item.resultado`]="{value}">
                      <v-chip
                        v-if="value.entrada_saida == 0 "
                        cols="3"
                        color="red"
                      >
                        -{{ formatNumber(value.valor) }}
                      </v-chip>
                      <v-chip
                        v-else
                        color="green"
                      >
                        {{ formatNumber(value.valor) }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <v-card-title>
        <v-spacer />
      </v-card-title>
    </v-card>

    <award-rescue-dialog
      v-model="edit"
      :producer="editProducer"
      :id-assistencia="filter.project.id"
      @load="onLoad"
    />

    <v-overlay
      v-model="loading"
      relative
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";
import AwardRescueDialog from "@/Domains/Projects/Dairy/AwardPoints/Components/AwardRescueDialog.vue";

import moment from "moment-timezone";

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import  _ from "lodash";
import printJS from 'print-js';

export default {

  name: "producer-rating",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
    AwardRescueDialog,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    parseEmpty: (value) => !value ? '-' : value,
  },

  mixins: [ReportMixin],
  props: {
    view: String,
  },

  data() {
    return {
      loading: false,

      filter: {
        project: {
          description: null,
          id: '',
          formula: [],
        },

        date: {
          input: "thisMonth",
          month: null,
        },

        technician: {
          id: "",
          description: ""
        },
        routes: [],

        volume: false,

        search: null,
      },
      months: [],

      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Nome', value: 'nome_produtor' },
        { text: 'Entrada', value: 'data_entrada_laticinio' },
        { text: 'Rota', value: 'rota_principal' },
        { text: 'Status', value: 'status'  },
        { text: 'Saldo Anterior', value: 'saldo_anterior' },
        { text: 'Saldo Mês', value: 'saldo_mes' },
        { text: 'Saldo Total', value: 'saldo_total' },
        { align: 'end', text: '', value: 'actions', sortable: false },
      ],

      projectList: [],

      producers: [],

      expanded: [],

      producerStatus: [{ text: 'Ativos', value: 'ativo' }],

      details: {
        headers: [
          { text: 'Data', value: 'data_classificacao' },
          { text: "Descrição", value: "descricao" },
          { text: "Pontuação", value: "resultado" },
        ],
        items: [],
        indicators: [],
      },

      // Usado no dialog de para resgate de pontos
      edit: false,
      editProducer: {
        nome_produtor: '',
        id_produtor: '',
        saldo_total: '',
      },

    };
  },

  created() {
    this.loadProject();
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onFilterDate([month]) {
      this.filter.date.month = month;
      this.onLoad();
    },
    async onLoad() {
      if (this.filter.project.id && this.filter.date.month) {
        this.loadProducerRating();
      }

    },

    async loadDateRange() {
      this.filter.min = moment().add('years', -2).format('YYYY-MM');
      this.filter.max = moment().format('YYYY-MM');
      this.filter.range = [moment().format('YYYY-MM'), moment().format('YYYY-MM')];
    },

    async loadProject() {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "Dairy-Award",
            view: 'rating',
          }
        );

        this.projectList = data.map((proj) => {
          return {
            text: proj.descricao,
            id: proj.id_assistencia,
            formula: proj.formula ? JSON.parse(proj.formula) : [],
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];

          if (this.filter.project.formula.length > 0 ) { //so carrega se tiver formula cadastrada
            await this.loadDateRange();
            await this.loadProducerRating();
          }
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadProducerRating() {
      try {
        this.loading = true;

        this.expanded = [];
        this.details.indicators = [];
        this.details.items = [];

        let id_assistencia   = this.filter.project.id;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/listaProdutoresPremiacaoPeriodo`,
          {
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            id_assistencia: id_assistencia,
            id_pessoa: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            produtor_status: this.producerStatus ? _.mapValues(this.producerStatus, 'value') : '*',
          }
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.producers = data.map(p => {

          let saldo_mes = (p.entrada - p.saida);
          let saldo_anterior = (p.entrada_anterior - p.saida_anterior);

          return {
            ...p,
            saldo_mes: saldo_mes,
            saldo_anterior: saldo_anterior,
            saldo_total: (saldo_mes + saldo_anterior),
            status: {
              description: p.status,
              date_end: p.data_saida ? moment(p.data_saida, 'YYYY-MM-DD').format("DD/MMM/YY")  : '',
            },
          }
        });

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtores!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async showDetails({ item }) {

      this.details.indicators = item.indicadores;

      try {
        this.loading = true;
        let id_assistencia   = this.filter.project.id;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/extratoPremiacaoPeriodo`,
          {
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            id_assistencia: id_assistencia,
            id_produtor: item.id_produtor
          }
        );

        this.details.items = data.map(p => {

          return {
            data_classificacao: p.data_classificacao,
            descricao: p.descricao,
            resultado: {
              valor: p.valor,
              entrada_saida: p.entrada_saida,
            },
          }
        });

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },

    /**
     * Mostra o dialog para a edição de uma região da lista
     */
    showAwardsDialog(producer) {
      this.edit = true;
      this.editProducer = producer;
    },

    async onPrint(item) {
      try {
        this.$root.$progressBar.loading();

        let id_assistencia   = this.filter.project.id;

        const { data } = await this.$axios.post(`/recomendacoesQualidade/impressaoExtratoPremiacaoPeriodo`, {
          id_produtor: item.id_produtor,
          mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
          id_assistencia: id_assistencia,
        });

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (error) {
        this.$snotify.error('Erro ao imprimir questionário', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
