<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="bonus-extra"
  >
    <v-row>
      <v-col
        v-for="(item, i) in extra"
        :key="i"
        cols="12"
      >
        <v-card
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-title>
            {{ item.text }}
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
            >
              <v-row>
                <v-col md="5">
                  <v-text-field
                    v-model="item.text"
                    type="text"
                    label="Descrição *"
                    hide-selected
                    hide-details
                    :rules="[v => !!v || 'Informe a descrição']"
                  />
                </v-col>
                <v-col>
                  <date-text-field
                    v-model="item.date"
                    label="Data Início *"
                    manual
                    max=""
                    :rules="[v => !!v || 'Informe a data inicial']"
                  />
                </v-col>
                <v-col>
                  <date-text-field
                    v-model="item.endDate"
                    label="Data Fim"
                    manual
                    max=""
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.value"
                    type="number"
                    label="Valor"
                    hide-selected
                    hide-details
                    :rules="[v => !!v || 'Informe o valor']"
                    @keypress="disableDot"
                  />
                </v-col>
                <div class="d-flex flex-column justify-end align-self-center pa-2">
                  <v-btn
                    icon
                    color="white"
                  >
                    <v-icon
                      @click="removeExtra(i)"
                    >
                      delete_outline
                    </v-icon>
                  </v-btn>
                </div>
              </v-row>
              <div>
                <v-checkbox
                  v-model="item.allProducers"
                  label="Aplicar em todos os produtores"
                />
              </div>
              <div
                v-if="!item.allProducers"
              >
                <v-autocomplete
                  v-model="item.producers"
                  outlined
                  multiple
                  label="Produtores *"
                  :items="producers"
                  return-object
                  item-text="nome"
                  item-value="id_pessoa"
                  counter
                  :filter="(person, queryText, itemText) => (`${person.codigo_laticinio || ''} - ${person.nome.toLocaleLowerCase()}`).indexOf(queryText.toLocaleLowerCase()) > -1"
                  :rules="[() => item.producers?.length > 0 || 'Informe ao menos um produtor']"
                >
                  <template #item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <span
                          v-if="data.item.codigo_laticinio"
                          class="mx-1"
                        >
                          {{ data.item.codigo_laticinio }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <template #selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      dark
                      @click="data.select"
                      @click:close="removeChipProducer(data.item, i)"
                    >
                      <v-icon
                        left
                        small
                      >
                        person
                      </v-icon>
                      <span
                        v-if="data.item.codigo_laticinio"
                        class="mr-1 font-weight-light"
                      >
                        {{ data.item.codigo_laticinio }} -
                      </span>
                      {{ data.item.nome }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </div>
              <div
                class="text-right"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      outlined
                      rounded
                      text
                      class="mr-3"
                      v-on="on"
                      @click.stop="imporProducers(i)"
                    >
                      <v-icon left>
                        system_update_alt
                      </v-icon>
                      Importar
                    </v-btn>
                  </template>
                  Importar produtores
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      outlined
                      rounded
                      text
                      v-on="on"
                      @click.stop="save(i)"
                    >
                      <v-icon left>
                        save
                      </v-icon>
                      Salvar
                    </v-btn>
                  </template>
                  Salvar extras
                </v-tooltip>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <import-excel-dialog
      v-model="importDialog.show"
      title="Importar produtores"
      :fields="[{ text: 'CPF', keys: ['CPF'], required: true }]"
      show-model
      @import="onImportProducers"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue darken-4"
        @click.stop="addExtra"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Inserir Bonificação Extra
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.bonus-planning {
  .graph-card {
    margin-top: 5px !important;
    background: rgba(0, 0, 0, 0.5) !important;

    > .v-card__title {
      color: #fff;
      background: unset;
    }

    > .v-card__text {
      color: #fff !important;
    }
  }
}
</style>

<script>
import DateTextField from '@/Support/Components/DateTextField.vue'
import ImportExcelDialog from '@/Support/Components/ImportExcelDialog.vue';

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "bonus-extra",

  components: {
    DateTextField,
    ImportExcelDialog
  },

  filters: {
    ellipse: (string, length) => string.length > length ? string.substring(0, length) + "..." : string,
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  data() {
    return {
      loading: false,

      id: null,

      producers: [],

      extra: [],

      importDialog: {
        show: false,
        idx: null
      }

    };
  },

  mounted() {
    this.loadProducers();
    this.loadExtras();
  },

  methods: {

    async loadProducers() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/produtores/listaProdutoresAtivos`);

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.producers = data.map(o => ({
          id_pessoa: o.id_pessoa,
          nome: o.nome,
          codigo_laticinio: o.codigo_laticinio,
          status: o.status,
          cpf: (o.cnpj_cpf || '').replace(/\D/g, ''),
        })).sort((a, b) => a.nome.localeCompare(b.nome));

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadExtras() {
      try {
        this.loading = true;
        const { data } = await this.$axios.get(`/bonus/price-extra/list`);

        if (_.isString(data)) {
          throw "PHP error";
        }

        //busca tabela de precos ativa para gravar bonus extra nela
        this.extra = data.map(o => ({
          id: o.id_tabela_preco_extra,
          text: o.descricao,
          value: o.valor,
          allProducers: o.todos || false,
          producers: o.produtores || [],
          date: o.data,
          endDate: o.data_fim,
        }));
      }
      catch (err) {
        console.warn(err)
      } finally {
        this.loading = false;
      }
    },

    addExtra() {
      this.extra.push({
        id: '',
        text: '',
        value: '',
        allProducers: false,
        producers: [],
        date: '',
      })
    },

    async removeExtra(idx) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Esta ação irá impactar em <b>TODOS</b> os produtores escolhidos para receber a bonificação extra.<br>`
      ))
      ) {
        return;
      }

      try {
        this.loading = true;
        if (this.extra[idx].id) {
          await this.$axios.get(`/bonus/price-extra/delete/` + this.extra[idx].id );
        }

        this.$snotify.success("Bonificação removida com sucesso", "Sucesso");
        this.extra.splice(idx, 1);
      } catch (error) {
        this.$snotify.error("Erro ao remover Bonificação", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    disableDot (e) {
      if (e.charCode === 46) {
        e.preventDefault()
      }
    },

    async save(idx) {
      const form = this.$refs.form[idx];

      if (!form.validate()) {
        this.$snotify.warning('Preencha todos os campos obrigatórios', 'Atenção');
        return;
      }

      try {
        this.loading = true;
        let payload = {
          id: this.extra[idx].id || '',
          valor: this.extra[idx].value,
          descricao: this.extra[idx].text,
          produtores: !this.extra[idx].allProducers ? this.extra[idx].producers : [],
          todos: this.extra[idx].allProducers,
          data: this.extra[idx].date,
          data_fim: this.extra[idx].endDate,
        }

        const data = await this.$axios.post(`/bonus/price-extra`, payload);

        this.extra[idx].id = data.data.id;

        this.$snotify.success("Bonificação salva com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar Bonificação", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    //remove chip do autocomplete
    removeChipProducer(producer, idx) {
      this.extra[idx].producers = _.remove(this.extra[idx].producers, (data) => data.id_pessoa !== producer.id_pessoa);
    },

    imporProducers(idx) {
      this.importDialog = {
        show: true,
        idx
      }
    },

    onImportProducers({ data }) {
      const { idx } = this.importDialog;

      this.importDialog.show = false;

      for (let item of data) {
        const cpf = (item['CPF'] || '').replace(/\D/g, '');

        const producer = this.producers.find(producer => producer.cpf === cpf);
        const alreadyAdded = this.extra[idx].producers.find(producer => producer.cpf === cpf);

        if (producer && !alreadyAdded) {
          this.extra[idx].producers.push({ ...producer });
        }
      }
    },

    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
};
</script>
