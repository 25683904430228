<template>
  <div
    class="unload-data-info"
  >
    <span
      class="overline"
      style="line-height: 15px !important; font-size: 10px !important;"
    >
      Dados da Descarga
    </span>

    <v-divider
      class="pb-1"
      style="margin-top: 0 !important; margin-bottom: 0 !important;"
    />
    <v-row>
      <v-col
        cols="3"
        md
        lg
        class="pb-1"
      >
        <v-select
          v-if="rawProductEditable"
          v-model="unload.rawProductInput"
          label="Matéria Prima"
          :items="rawProducts"
          item-value="id"
          item-text="name"
          hide-details
          return-object
          :disabled="!hasRawMaterialEditAccess || unload.transfers?.length > 0 || unload.hasSpotPlanning"
          dense
          style="max-width: 180px"
        />
        <v-text-field
          v-else
          :value="unload.rawProductInput?.name"
          label="Matéria Prima"
          hide-details
          disabled
          dense
        />
      </v-col>
      <v-col
        cols="3"
        md
        lg
        class="pb-1"
      >
        <v-text-field
          :value="unload.itinerary.code"
          label="Itinerário"
          hide-details
          disabled
          dense
        />
      </v-col>
      <v-col
        cols="3"
        md
        lg
        class="pb-1"
      >
        <v-text-field
          :value="unload.route.description"
          label="Origem/Destino/Rota"
          hide-details
          disabled
          dense
        />
      </v-col>
      <v-col
        cols="3"
        md
        lg
        class="pb-1"
      >
        <v-text-field
          :value="unload.vehicle.plate"
          label="Placa"
          hide-details
          disabled
          dense
        />
      </v-col>
      <v-col
        cols="3"
        md
        lg
        class="pb-1"
      >
        <v-text-field
          :value="unload.driver.name"
          label="Motorista"
          hide-details
          return-unmasked
          disabled
          dense
        />
      </v-col>
      <v-col
        v-if="settings.measurementParam === 'balanca'"
        class="pb-1"
      >
        <masked-input
          v-model.number="unload.inletWeight"
          label="Peso Entrada (Kg)"
          :mask="masks.NumberMask"
          :max-length="8"
          hide-details
          return-unmasked
          disabled
          dense
        />
      </v-col>
      <v-col
        v-if="settings.measurementParam === 'balanca'"
        class="pb-1"
      >
        <masked-input
          v-model.number="unload.outletWeight"
          label="Peso Saída (Kg)"
          :mask="masks.NumberMask"
          :max-length="8"
          hide-details
          return-unmasked
          disabled
          dense
        />
      </v-col>
      <v-col
        v-if="settings.measurementParam === 'balanca'"
        class="pb-1"
      >
        <masked-input
          v-model="unload.netWeight"
          label="Peso Liquido (Kg)"
          :mask="masks.NumberMask"
          :max-length="8"
          disabled
          hide-details
          return-unmasked
          dense
        />
      </v-col>
      <v-col
        class="pb-1"
      >
        <masked-input
          v-model="unload.vale"
          label="Vale (L)"
          :mask="masks.NumberMask"
          :max-length="8"
          disabled
          hide-details
          return-unmasked
          dense
        />
      </v-col>
      <v-col
        class="pb-1"
      >
        <masked-input
          v-model="unload.measured"
          label="Medidos (L)"
          :mask="masks.NumberMask"
          return-unmasked
          hide-details
          disabled
          dense
        />
      </v-col>
      <v-col
        class="pb-1"
      >
        <masked-input
          v-model="unload.difference"
          label="Diferença (L)"
          :mask="masks.NumberMask"
          disabled
          return-unmasked
          hide-details
          dense
        />
      </v-col>
      <v-col
        class="pb-1"
      >
        <masked-input
          v-model="unload.discount"
          label="Desconto (L)"
          :mask="masks.NumberMask"
          disabled
          return-unmasked
          hide-details
          dense
        />
      </v-col>
      <v-col
        v-if="spotTanksEditable && unload.itinerary.type === 'spot' && hasSpotTankEditAccess"
        cols="1"
        class="py-1 d-flex justify-end"
      >
        <v-menu class="mb-2">
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="emit('editSpotTanks')"
            >
              <v-list-item-icon>
                <v-icon>local_shipping</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Editar tanques
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider
      class="mb-1"
      style="margin-top: 0 !important; margin-bottom: 0 !important;"
    />
  </div>
</template>

<style lang="scss">
.unload-data-info {

  .v-input--is-disabled {

    .v-input__control {
      color: #1f1f1f !important;

      .v-input__slot::before {
        border: none !important;
      }

      .v-label {
        color: #1f1f1f !important;
      }

      input {
        color: #1f1f1f !important;
      }
    }
  }

  .v-select.v-input--is-disabled .v-select__selections {
    color: #1f1f1f !important;
  }

  .v-select .v-select__selection--disabled {
    color: #1f1f1f !important;
  }

}

</style>

<script setup>
import { computed } from 'vue'
import store from '@/Support/Resources/vuex.js'

import MaskedInput from '@/Support/Components/MaskedInput.vue'

import { usePermissions } from '@/Support/Composables/permissions.js'

const { hasPermission } = usePermissions()

// eslint-disable-next-line no-undef
defineProps({
  unload: {
    type: Object,
    default: () => ({}),
  },
  rawProductEditable: Boolean,
  spotTanksEditable: Boolean,
  rawProducts: Array,
});

// eslint-disable-next-line
const emit = defineEmits(['editSpotTanks'])

const hasRawMaterialEditAccess = computed(() => hasPermission('platform-unload-raw-material-edit'))
const hasSpotTankEditAccess = computed(() => hasPermission('platform-unload-spot-tank-edit'))

const settings = computed(() => {
  const settings = store.state.settings?.plataforma || {};

  return {
    measurementParam: settings.medicao_descarga_plataforma || 'balanca',
  };
})

const masks = {
  NumberMask: {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        signed: true,
        scale: 2,
        max: 99000,
      },
    },
  },
}

</script>
