<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="freighter-vouchers"
  >
    <v-row>
      <v-spacer />
      <v-col md="4">
        <v-date-range-picker
          v-model="filter.range"
          dark
          @change="getBills"
        />
      </v-col>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Descontos a Receber
            <v-spacer />
            <v-col
              cols="4"
              class="pa-0"
            >
              <v-text-field
                v-model="bills.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <data-table
            ref="bills"
            name="Contas Financeiro"
            :headers="bills.headers"
            :items="bills.items"
            :search="bills.search"
            :loading="loading"
            :sort-by.sync="filter.sort.by"
            :sort-desc.sync="filter.sort.desc"
            dark
            dense
            class="elevation-1 report-table"
            @click:row="showBill"
          >
            <template #[`item.type`]="{ value }">
              <v-chip
                x-small
                color="primary"
              >
                {{ value }}
              </v-chip>
            </template>
            <template #[`item.button`]="{ item }">
              <v-btn
                v-if="item.balance != 0"
                block
                small
                color="blue darken-1"
                @click.stop="discharge(item)"
              >
                Receber
              </v-btn>
              <v-btn
                v-else
                block
                small
                color="orange"
                @click.stop="chargeback(item)"
              >
                Estornar
              </v-btn>
            </template>
          </data-table>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Recebimentos
            <v-spacer />
            <v-col
              cols="4"
              class="pa-0"
            >
              <v-text-field
                v-model="billeds.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <data-table
            ref="billeds"
            name="Recebimentos Financeiro"
            :headers="billeds.headers"
            :items="billeds.items"
            :search="billeds.search"
            :loading="loading"
            :sort-by.sync="filter.sort.by"
            :sort-desc.sync="filter.sort.desc"
            dark
            dense
            class="elevation-1 report-table"
            @click:row="showBill"
          >
            <template #[`item.button`]="{ item }">
              <v-btn
                block
                small
                color="orange"
                @click.stop="chargebackPayment(item)"
              >
                Estornar
              </v-btn>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      type="DESCONTO_PRESTADOR"
      @input="billDialog.id = null, getBills()"
    />

    <discharge-dialog
      v-model="dischargeDialog.show"
      :installment-id="dischargeDialog.id"
      :recurrence-id="dischargeDialog.recurrenceId"
      :recurrence-due-date="dischargeDialog.recurrenceDueDate"
      @input="dischargeDialog.id = null, dischargeDialog.recurrenceId = null"
      @newPayment="getBills"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="deep-orange"
        @click="newBill"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>
          Novo Desconto
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="teal darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import DischargeDialog from '@/Domains/Financial/Components/DischargeDialog.vue';
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import truncate from "lodash/truncate";

import moment from "moment-timezone";

moment.locale('pt');

export default {
  name: "freighter-vouchers",

  components: {
    VDateRangePicker,
    BillDialog,
    DischargeDialog,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        sort: { by: 'issueDate', desc: false }
      },

      bills: {
        search: '',
        headers: [
          { text: '#', value: 'code' },
          { text: 'GUID', value: 'installmentId', show: false },
          { text: 'Tipo', value: 'type' },
          { text: 'Prestador', value: 'person' },
          { text: 'Descrição', value: 'description' },
          { text: 'Emissão', value: 'issueDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Vencimento', value: 'dueDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Valor', value: 'amount', formatter: value => 'R$ ' + this.formatCurrency(value), mask: 'R$ #,##0.00' },
          { text: 'Saldo', value: 'balance', formatter: value => 'R$ ' + this.formatCurrency(value), mask: 'R$ #,##0.00' },
          { text: '', altText: 'Ações', value: 'button', width: 100 },
        ],
        items: []
      },

      billeds: {
        search: '',
        headers: [
          { text: '#', value: 'code' },
          { text: 'Prestador', value: 'person' },
          { text: 'Descrição', value: 'description' },
          { text: 'Emissão', value: 'issueDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Recebimento', value: 'paymentDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Valor', value: 'amount', formatter: value => 'R$ ' + this.formatCurrency(value), mask: 'R$ #,##0.00' },
          { text: '', altText: 'Ações', value: 'button', width: 100 },
        ],
        items: []
      },

      billDialog: {
        show: false,
        id: null,
      },

      dischargeDialog: {
        show: false,
        id: null,
        recurrenceId: null,
        recurrenceDueDate: null,
      },
    };
  },

  methods: {
    async getBills() {
      const [startDate, endDate] = this.filter.range;

      let [{ data: bills }, { data: billeds }, { data: recurrences }] = await Promise.all([
        await this.$axios.post(
          `/financeiro/listaParcelas`,
          { tipo: 'DESCONTO_PRESTADOR', data_inicio: startDate, data_fim: endDate }
        ),
        await this.$axios.post(
          `/financeiro/listaLancamentos`,
          { tipo: 'DESCONTO_PRESTADOR', data_inicio: startDate, data_fim: endDate }
        ),
        await this.$axios.post(
          `/financeiro/listaRecorrencias`,
          { tipo: 'DESCONTO_PRESTADOR', data_inicio: startDate, data_fim: endDate }
        ),
      ]);

      bills = bills.map(p => ({
        type: 'PARCELA',
        recurrenceId: null,
        installmentId: p.id_parcela,
        accountId: p.id_titulo,
        code: p.cod_parcela,
        person: p.nome_pessoa,
        description: p.descricao || p.observacao,
        issueDate: p.data_emissao,
        dueDate: p.data_vencimento,
        amount: parseFloat(p.valor),
        balance: parseFloat(p.saldo),
      }))

      recurrences = recurrences.map(o => ({
        type: 'RECORRENTE',
        recurrenceId: o.id_titulo_recorrencia,
        installmentId: o.id_parcela || null,
        accountId: o.id_titulo,
        code: o.cod_titulo_recorrencia,
        person: o.nome_pessoa,
        description: o.descricao || o.observacao,
        issueDate: o.data_emissao,
        dueDate: o.data_vencimento,
        amount: parseFloat(o.valor),
        balance: parseFloat(o.saldo),
      }))

      this.bills.items = [...bills, ...recurrences];

      this.billeds.items = billeds.map(o => ({
        id: o.id_lancamento,
        accountId: o.id_titulo,
        code: o.cod_parcela,
        person: o.nome_pessoa,
        description: o.descricao || o.observacao,
        issueDate: o.data_emissao,
        paymentDate: o.data_lancamento,
        amount: parseFloat(o.valor),
      }))
    },

    newBill() {
      this.billDialog.id = null;
      this.billDialog.show = true;
    },

    showBill({ accountId }) {
      this.billDialog.id = accountId;
      this.billDialog.show = true;
    },

    discharge({ recurrenceId, dueDate, installmentId }) {
      this.dischargeDialog.id = installmentId;
      this.dischargeDialog.show = true;
      this.dischargeDialog.recurrenceId = recurrenceId;
      this.dischargeDialog.recurrenceDueDate = dueDate;
    },

    async chargeback({ installmentId }) {
      if (!(await this.$root.$confirm('Estornar Parcela', 'Tem certeza que deseja estornar esta parcela?', { color: 'orange' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/financeiro/estorno`, { id_parcela: installmentId });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Estorno efetuado com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao estornar a parcela", "Atenção");
        console.warn(error);
      } finally {
        this.getBills();
      }
    },

    async chargebackPayment({ id }) {
      if (!(await this.$root.$confirm('Estornar Recebimento', 'Tem certeza que deseja estornar este recebimento?', { color: 'orange' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/financeiro/estorno`, { id_lancamento: id });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Estorno efetuado com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao estornar o recebimento", "Atenção");
        console.warn(error);
      } finally {
        this.getBills();
      }
    },

    getBillsJson(isExcel = false) {
      return this.$refs.bills.getReportJson(isExcel);
    },

    getBilledsJson(isExcel = false) {
      return this.$refs.billeds.getReportJson(isExcel);
    },

    getReportTitle() {
      const [startDate, endDate] = this.filter.range;
      return `Descontos Prestador - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      const bills = this.getBillsJson(true);
      const billeds = this.getBilledsJson(true);
      const title = this.getReportTitle();

      if (bills.length === 0 && billeds.length === 0) {
        return;
      }

      const worksheet = this.XLSX.utils.json_to_sheet([]);
      const workbook = this.XLSX.utils.book_new();

      let currentRow = 0;

      if (bills.length > 0) {
        const headers = this.$refs.bills.filteredHeaders.filter(header => !!header.text);
        const json = bills

        worksheet[`A${currentRow + 1}`] = { v: 'Descontos' };
        currentRow += 1;

        this.XLSX.utils.sheet_add_json(worksheet, json, { origin: currentRow });
        this.applyStyles(worksheet, json, null, false, currentRow);
        this.applyMasks(worksheet, headers, json.length, currentRow);

        currentRow += json.length + 2;
      }

      if (billeds.length > 0) {
        const headers = this.$refs.billeds.filteredHeaders.filter(header => !!header.text);
        const json = billeds

        worksheet[`A${currentRow + 1}`] = { v: 'Recebimentos' };
        currentRow += 1;

        this.XLSX.utils.sheet_add_json(worksheet, json, { origin: currentRow });
        this.applyStyles(worksheet, json, null, false, currentRow);
        this.applyMasks(worksheet, headers, json.length, currentRow);
      }

      const billCols = this.getColsWidth(bills)
      const billedCols = this.getColsWidth(billeds)

      worksheet['!cols'] = Array.from({ length: Math.max(billCols.length, billedCols.length) })
        .map((_, i) => ({
          width: Math.max(billCols[i]?.width || 0, billedCols[i]?.width || 0)
        }))

      this.XLSX.utils.book_append_sheet(workbook, worksheet, truncate(title, { length: 31 }));
      this.XLSX.writeFile(workbook, `${title}.xlsx`);
    },

    async print() {
      const bills = this.getBillsJson();
      const billeds = this.getBilledsJson();
      const title = this.getReportTitle();

      if (bills.length === 0 && billeds.length === 0) {
        return;
      }

      const body = [];

      if (bills.length > 0) {
        body.push({
          tag: 'section',
          class: 'row',
          children: [{
            title: 'Descontos',
            tag: 'datatable',
            headers: Object.keys(bills[0])
              .filter(text => text)
              .map(header => ({
                key: header,
                label: header,
                class: 'text-left'
              })),
            items: bills
          }]
        });
      }

      if (billeds.length > 0) {
        body.push({
          tag: 'section',
          class: 'row',
          children: [{
            title: 'Recebimentos',
            tag: 'datatable',
            headers: Object.keys(billeds[0])
              .filter(text => text)
              .map(header => ({
                key: header,
                label: header,
                class: 'text-left'
              })),
            items: billeds
          }]
        });
      }

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.printPdf({ pages: [{ title, header, body }] });
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
};
</script>
