<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Controle de armazenamento
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#packing">
            Embalagem/Pesagem
          </v-tab>
          <v-tab href="#pallets">
            Paletização
          </v-tab>
          <v-tab href="#location">
            Armazenamento
          </v-tab>

          <v-tabs-items
            v-model="tab"
            touchless
          >
            <v-tab-item value="packing">
              <PackingList />
            </v-tab-item>
            <v-tab-item value="pallets">
              <PalletList />
            </v-tab-item>
            <v-tab-item value="location">
              <StorageLocation v-if="tab === 'location'" />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PackingList from "@/Domains/Shipment/StorageControl/Views/PackingList.vue";
import PalletList from "@/Domains/Shipment/StorageControl/Views/PalletList.vue";
import StorageLocation from "@/Domains/Shipment/StorageControl/Views/StorageLocation.vue";

export default {

  components: {
    PackingList,
    PalletList,
    StorageLocation,
  },

  data() {
    return {
      tab: 'pallets',
    };
  },
};
</script>
