<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    hide-overlay
  >
    <v-card
      light
      elevation="1"
    >
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          ref="form"
        >
          <v-row>
            <v-col
              md="2"
            >
              <v-text-field
                v-model="form.codigo_laticinio"
                prepend-inner-icon="subtitles"
                label="Código"
                dense
                :loading="loading"
              >
              </v-text-field>
            </v-col>

            <v-col
              md="6"
            >
              <v-text-field
                v-model="form.nome"
                prepend-inner-icon="person"
                label="Nome *"
                :rules="validation.rules.required"
                dense
                autofocus
                :loading="loading"
              >
              </v-text-field>
            </v-col>

            <v-col
              md="4"
            >
              <masked-input-form
                v-model="form.cnpj_cpf"
                prepend-inner-icon="assignment_ind"
                label="CPF"
                :mask="CPFMask"
                maxlength="14"
                dense
                :loading="loading"
                :rules="[ v => validateCpfRule(v) ]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              md="4"
            >
              <v-autocomplete
                v-model="form.parentesco"
                :items="kinshipDegrees"
                item-text="grau_parentesco"
                item-value="id_grau_parentesco"
                prepend-inner-icon="person"
                label="Parentesco"
                dense
                :loading="loading"
                :filter="(degree, queryText) => (`${degree.codigo_parentesco || ''} - ${(degree.grau_parentesco || '').toLocaleLowerCase()}`).indexOf(queryText.toLocaleLowerCase()) > -1"
                return-object
              >
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="`${item.codigo_parentesco} - ${item.grau_parentesco}`" />
                  </v-list-item-content>
                </template>

                <template #selection="{item}">
                  {{ item.codigo_parentesco }} - {{ item.grau_parentesco }}
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              md="4"
            >
              <masked-input-form
                v-model.trim="form.data_nascimento"
                prepend-inner-icon="event"
                :label="`Data de nascimento`"
                :mask="{
                  mask: '00/00/0000',
                  maxlength: 14,
                }"
                maxlength="10"
                dense
                :return-unmasked="false"
                :loading="loading"
                :rules="validation.rules.date"
              />
            </v-col>

            <v-col
              md="4"
            >
              <v-select
                v-model="form.sexo"
                label="Sexo"
                :items="[
                  { value: 'M', text: 'Masculino' },
                  { value: 'F', text: 'Feminino' },
                ]"
                prepend-inner-icon="person"
                dense
                :loading="loading"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="v-btt2 black--text pa-0"
          text
          color="blue-grey"
          @click.stop="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="v-btt2 black--text pa-0"
          text
          color="blue"
          @click.stop="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay v-model="loading">
      Salvando...
    </v-overlay>
  </v-dialog>
</template>

<script>
import moment from "moment";
import isEmpty from "lodash/isEmpty";

import MaskedInputForm from "@/Support/Components/MaskedTextField.vue";

import { documentValidate } from "@/Support/Resources/utils";

const emptyForm = {
  id_pessoa: null,
  codigo_laticinio: null,
  nome: null,
  cnpj_cpf: null,
  parentesco: {},
  data_nascimento: null,
  sexo: null,
};

export default {
  components: {
    MaskedInputForm,
  },

  props: {
    kinshipDegrees: {
      type: Array,
      default: () => ([]),
    }
  },

  data() {
    return {
      dialog: false,

      loading: false,

      title: '',

      form: { ...emptyForm },

      CPFMask: {
        mask: "000.000.000-00",
        maxlength: 14,
      },

      validation: {
        rules: {
          required: [(v) => !!v || "Campo obrigatório"],
          date: [v => (v ? this.isDateValid(v, 'DD/MM/YYYY') : true) || 'Data Inválida!'],
        },
      },
    }
  },

  methods: {
    async show(title, form) {
      this.dialog = true;

      this.title = title;

      this.form = {
        ...this.form,
        ...form,
      };

      // Aguarda atualização do DOM
      await this.$nextTick();
    },

    close() {
      this.dialog = false;

      this.form = { ...emptyForm }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      this.$emit('save', this.form);

      return this.close();
    },

    isDateValid(value, format) {
      if (!value || value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },

    validateCpfRule(value) {
      if (!value) {
        return true;
      }

      const validate = documentValidate(value);

      return validate.validation || validate.msg;
    }
  }
};
</script>
