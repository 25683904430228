<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col
        cols="12"
        class="text-xs-center"
      >
        <h2 class="menu-header white--text mt-0 mb-2">
          Média Análises de Movimentações de Carga
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="6"
                sm="3"
                md="2"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filters.range"
                  :ranges="dateRanges"
                  dark
                  @change="onFilter"
                />
              </v-col>
              <v-col
                cols="6"
                sm="3"
                md="2"
                class="pt-0"
              >
                <v-select
                  v-model="filters.silos"
                  :items="silos"
                  clearable
                  filled
                  item-value="id"
                  item-text="label"
                  hide-details
                  label="Silos"
                  multiple
                  prepend-inner-icon="panorama_vertical_select"
                  @change="onFilter"
                />
              </v-col>

              <v-col
                cols="6"
                sm="3"
                md="2"
                class="pt-0"
              >
                <raw-material-autocomplete
                  v-model="filters.rawMaterialId"
                  label="Matéria Prima"
                  only-parents
                  clearable
                  filled
                  hide-details
                  @change="onFilter"
                />
              </v-col>

              <v-spacer />

              <v-col
                cols="12"
                sm="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.search"
                  prepend-inner-icon="search"
                  filled
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-title>

          <data-table
            ref="report"
            :headers="headers"
            :items="items"
            :loading="loading"
            :search="filters.search"
            dark
          >
            <template #[`item.rawMaterial.description`]="{ value }">
              <v-chip
                v-if="value"
                small
              >
                {{ value }}
              </v-chip>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      fab
      dark
      bottom
      right
      open-on-hover
      class="mr-2"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue';
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import moment from 'moment';
import _ from 'lodash';

export default {

  components: {
    RawMaterialAutocomplete,
    VDateRangePicker,
  },

  data() {
    return {
      loading: false,

      filters: {
        range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        silos: [],
        rawMaterialId: null,
        search: '',
      },

      silos: [],

      dateRanges: {
        'Hoje': [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        'Ontem': [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
        'Esta semana': [moment().isoWeekday(0).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        'Semana anterior': [moment().isoWeekday(0).subtract(7, 'days').format('YYYY-MM-DD'), moment().isoWeekday(0).subtract(1, 'days').format('YYYY-MM-DD')],
      },

      headers: [
        { text: 'Nº Lote', value: 'lotNumber' },
        { text: 'Silo', value: 'silo.description' },
        { text: 'Matéria Prima', value: 'rawMaterial.description' },
        { text: 'Data Início', value: 'startedAt', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
        { text: 'Data Fim', value: 'endedAt', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
        { text: 'Temp. (°C)', value: 'temperatura', formatter: value => value !== null ? `${this.formatNumber(value, 1)} °C` : '-', mask: '#,##0.0' },
        { text: 'Acidez (°D)', value: 'acidez', formatter: value => value !== null ? `${this.formatNumber(value, 3)} °D` : '-', mask: '#,##0.000' },
        { text: 'Alizarol (°GL)', value: 'alizarol', formatter: value => value !== null ? `${this.formatNumber(value)} °GL` : '-', mask: '#,##0' },
        { text: 'Crioscopia (°C)', value: 'crioscopia', formatter: value => value !== null ? `${this.formatNumber(value, 3)} °C` : '-', mask: '#,##0.000' },
        { text: 'Densidade (g/ml)', value: 'densidade', formatter: value => value !== null ? `${this.formatNumber(value, 1)} g/ml` : '-', mask: '#,##0.0' },
        { text: 'E.S.D (g/100g)', value: 'esd', formatter: value => value !== null ? `${this.formatNumber(value, 2)} g/100g` : '-', mask: '#,##0.00' },
        { text: 'Gordura (%)', value: 'gordura', formatter: value => value !== null ? `${this.formatNumber(value, 3)} %` : '-', mask: '#,##0.000' },
        { text: 'Proteína (g/100g)', value: 'proteina', formatter: value => value !== null ? `${this.formatNumber(value, 2)} g/100g` : '-', mask: '#,##0.00' },
        { text: 'pH', value: 'ph', formatter: value => value !== null ? this.formatNumber(value, 2) : '-', mask: '#,##0.00' },
        { text: 'Redutase', value: 'redutase', formatter: value => value !== null ? this.formatNumber(value) : '-', mask: '#,##0' },

      ],

      items: [],
    };
  },

  mounted() {
    this.loadSilos();
  },

  methods: {
    onFilter: _.debounce(function () {
      this.loadReport();
    }, 1000),

    getReportTitle() {
      return `Fluxo Movimentações do Silo`;
    },

    async print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title, true);
    },

    exportExcel() {
      const title = this.getReportTitle();
      this.$refs.report.exportExcel(null, title);
    },

    async loadSilos() {
      try {
        const { data } = await this.$axios.post(`/silo/principal`);

        if (!data.silo) {
          throw 'PHP Error';
        }

        this.silos = data.silo
          .map(silo => ({
            index: (silo.descricao || '').replace(/\D/g, ''),
            id: silo.id_silo,
            label: silo.descricao,
            vol: parseInt(silo.volume_atual),
            capacity: parseInt(silo.volume_total),
          }))
          .sort((a, b) => a.index - b.index);
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao carregar os silos!', 'Atenção');
      }
    },

    async loadReport() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.range;

        const { data } =  await this.$axios.get(`/industry/silo/unload-analysis`, { params: {
          start_date: startDate,
          end_date: endDate,
          silos: this.filters.silos,
          rawMaterialId: this.filters.rawMaterialId,
        } });

        this.items = data.map(history => ({
          lotNumber: history.numero_lote,
          silo: {
            id: history.id_silo,
            description: history.nome_silo,
          },
          rawMaterial: {
            id: history.id_materia_prima,
            description: history.nome_materia_prima,
          },
          startedAt: history.data_inicio,
          endedAt: history.data_fim,

          temperatura: history.temperatura !== null ? _.round(history.temperatura, 1) : null,
          acidez: history.acidez !== null ? _.round(history.acidez, 3) : null,
          alizarol: history.alizarol !== null ? _.round(history.alizarol, 0) : null,
          crioscopia: history.crioscopia !== null ? _.round(history.crioscopia, 3) : null,
          densidade: history.densidade !== null ? _.round(history.densidade, 1) : null,
          esd: history.esd !== null ? _.round(history.esd, 2) : null,
          gordura: history.gordura !== null ? _.round(history.gordura, 3) : null,
          proteina: history.proteina !== null ? _.round(history.proteina, 2) : null,
          ph: history.ph !== null ? _.round(history.ph, 2) : null,
          redutase: history.redutase !== null ? _.round(history.redutase, 0) : null,
        }))
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao carregar as movimentações!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatNumber: (value, decimals = 0) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },

};
</script>
