<template>
  <div>
    <v-dialog
      v-model="isDialogVisible"
      width="400"
      @keydown.esc="closeDialog"
    >
      <v-card>
        <v-card-title>
          Novidades
          <v-spacer />
          <v-btn
            icon
            depressed
            @click="closeDialog()"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>{{ formatDate(versionNotes.date, 'LL') }}</v-card-subtitle>
        <v-divider />
        <v-card-text
          class="text-body-1 pt-4"
          v-html="versionNotes.notes"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import moment from 'moment';

import store from '@/Support/Resources/vuex.js'

const versionNotes = computed(() => {
  return store.state.settings?.versionNotes || {};
});

const storageKey = 'last_version_notes';

const isDialogVisible = computed(() => {
  if (!versionNotes.value.show) {
    return false;
  }

  const lastVersionNote = localStorage.getItem(storageKey);

  if (!lastVersionNote) {
    return true;
  }

  const versionDate = versionNotes.value.date

  // Exibe se a data do alerta for diferente da última visualizada pelo usuário
  return lastVersionNote !== versionDate;
});

function closeDialog() {
  localStorage.setItem(storageKey, versionNotes.value.date);

  versionNotes.value.show = false;
}

const formatDate = (value, format) => value ? moment(value).format(format) : ''
</script>
