<template>
  <v-container class="form-settings">
    <v-row>
      <v-col cols="12">
        <card-title-settings class="mb-3">
          Parâmetros do cadastro
        </card-title-settings>
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-select
                  v-model="producerSettings.autoincrementa_codigo_laticinio"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Autoincrementa código laticínio:"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-select
                  v-model="producerSettings.validar_cadastro_prospeccao"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Validar cadastro de prospecção no Mobile"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div
      v-for="(section, id) in sections"
      :key="id"
      style="margin: 0 5px"
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          class="pa-1"
        >
          <card-title-settings>
            {{ labels[id].text }}
          </card-title-settings>
        </v-col>
      </v-row>
      <v-row
        dense
        class="form-grid"
      >
        <v-col
          v-for="(item, field) in section"
          :key="field"
          class="pa-1"
        >
          <v-card
            dark
            outlined
            class="text-left card-field"
            :class="{ disabled: !item.show && !item.visible, blocked: item.blocked }"
          >
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ labels[id].fields[field] }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-row>
                    <v-col
                      cols="6"
                      class="py-0"
                    >
                      <span>Web</span>
                      <v-switch
                        v-model="item.show"
                        :label="item.show ? 'Exibir' : 'Não exibir'"
                        :disabled="item.blocked"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                      <v-switch
                        v-model="item.required"
                        :label="item.required ? 'Obrigatório' : 'Não obrigatório'"
                        :disabled="item.blocked || !item.show"
                        dense
                        hide-details
                        class="mt-0"
                        color="red accent-2"
                      />
                      <template v-if="field === 'codigo_laticinio'">
                        <v-switch
                          v-model="item.editable"
                          :label="item.editable ? 'Editável' : 'Não editável'"
                          :disabled="!item.show"
                          dense
                          hide-details
                          class="mt-0"
                          color="purple"
                        />
                        <v-switch
                          v-model="producerSettings.codigo_laticinio_multiplo"
                          :label="producerSettings.codigo_laticinio_multiplo ? 'Duplicável' : 'Não duplicável'"
                          :disabled="!item.show"
                          dense
                          hide-details
                          class="mt-0"
                          color="teal"
                        />
                      </template>
                      <template v-if="field === 'cnpj_cpf'">
                        <v-switch
                          v-model="producerSettings.cnpj_cpf_multiplo"
                          :label="producerSettings.cnpj_cpf_multiplo ? 'Duplicável' : 'Não duplicável'"
                          :disabled="!item.show"
                          dense
                          hide-details
                          class="mt-0"
                          color="teal"
                        />
                      </template>
                    </v-col>
                    <v-divider
                      inset
                      vertical
                    />
                    <v-col
                      v-if="'visible' in item"
                      cols="6"
                      class="py-0"
                    >
                      <span>Mobile</span>
                      <v-switch
                        v-model="item.visible"
                        :label="item.visible ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                      <v-switch
                        v-model="item.mandatory"
                        :label="item.mandatory ? 'Obrigatório' : 'Não obrigatório'"
                        :disabled="!item.visible"
                        dense
                        hide-details
                        class="mt-0"
                        color="red accent-2"
                      />
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          :loading="saving"
          v-on="on"
          @click.stop="save();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>
  </v-container>
</template>

<style lang="scss">
.form-settings {
  .card-field {
    background: rgba(255, 255, 255, 0.08);
    height: 100%;

    &.disabled {
      opacity: 0.65;
    }

    &.blocked {
      border-style: dashed;
    }
  }
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  position: relative;

  .group-label {
    position: absolute;
    top: 5px;
    left: 5px;
  }
}

</style>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

export default {

  components: {
    CardTitleSettings
  },

  data() {
    return {
      saving: false,
      /**
       * Labels dos campos do formulário
       */
      labels: {
        personal: {
          text: 'Informações Pessoais',
          fields: {
            nome: 'Nome',
            codigo_laticinio: 'Código laticínio',
            historico_codigo_laticinio: 'Histórico Código laticínio',
            email: 'E-mail',
            celular: 'Celular',
            telefone: 'Telefone',
            cnpj_cpf: 'CPF/CNPJ do titular',
            codigo_sda: 'Código SDA',
            cidasc: 'CIDASC/IMA',
            rg: 'N° de Identidade (RG)',
            orgao_expedidor: 'Órgão expedidor',
            uf_expedicao: 'UF Expedição',
            nirf: 'N° NIRF',
            nrp: 'Código NRP',
            inscricao_estadual: 'Inscrição estadual',
            data_nascimento: 'Data de nascimento',
            sif: 'Sif',
            cooperativa: 'Cooperativa',
            cad_pro: 'Cadastro de Produtores Rurais (CAD_PRO)',
            id_rota: 'Rota Principal de coleta',
            observacao_tecnica: 'Observações',
            carta_anuencia: 'Carta de Anuência',
            emite_nota_propria: 'Tipo Emissão Nota',
            estado_civil: 'Estado Civil',
            escolaridade: 'Escolaridade',
            assentado: 'Produtor Assentado?',
            codigo_nucleo: 'Código do Núcelo',
          }
        },
        address: {
          text: 'Endereço',
          fields: {
            nome_propriedade: 'Nome Propriedade',
            end_cep: 'CEP',
            end_rua: 'Rua',
            end_numero: 'Número',
            end_estado: 'Estado',
            end_cidade_codigo: 'Cod. Município',
            end_cidade: 'Cidade',
            end_bairro: 'Bairro',
            end_complemento: 'Complemento',
            id_regiao: 'Rota região',
            localizacao: 'Localização',
            id_localidade: 'Localidade'
          },
        },
        payment: {
          text: 'Pagamentos e descontos',
          fields: {
            dia_pagamento: 'Dia do Pagamento',
            ignorar_bonificacao: 'Ignorar pagamento (bonificação)',
            adiantamento: 'Adiantamento de Pagamento',
            nome_titular: 'Nome titular da conta',
            cnpj_cpf_titular: 'CPF/CNPJ do titular da conta',
            banco: 'Banco',
            agencia: 'Agência',
            conta: 'Nº Conta',
            tipo_conta: 'Tipo de Conta',
            tipo_forma_pagamento: 'Forma de pagamento',

            //descontos
            fundesa: 'Fundesa',
            rat: 'Rat',
            senar: 'Senar',
            funrural: 'Funrural',
            administrativa: 'Administrativa',
            inss: 'INSS',

            //tributação
            tributacao: 'Tributação',
            desconta_senar: 'Desconta SENAR',
            debita_inss: 'Debita INSS',
          },
        },
        dataProduction: {
          text: 'Dados de produção',
          fields: {
            numero_ordenha: 'Número de Ordenhas',
            frequencia_coleta: 'Frequência coleta',
            numero_tarro: 'Tarro n°',
            quantidade_tanques: 'Quantidade de resfriadores',
            capacidade_resf_inte: 'Capacidade dos resfriadores (L)',
            cooling_type: 'Tipo de resfriamento',
            tipo_ordenha: 'Tipo de Ordenha',
            hora_inicio_coleta: 'Hora Início Coleta',
            hora_fim_coleta: 'Hora Fim Coleta',
            proteina: 'Proteina',
            gordura: 'Gordura',
            data_inicio_coleta: 'Data inicio coleta',
            data_fim_coleta: 'Data fim coleta',
            motivo_fim_coleta: 'Motivo fim produção',
            qualidade_leite_contrato: 'Tipo de leite que produz',
            qualidade_leite_entrega: 'Tipo de leite que entrega',
            id_laticinio_concorrente: 'Laticínio concorrente entrega',
            id_ramo_atividade: 'Ramo de Atividade',
          },
        },
        additionalInformation: {
          text: 'Informações Adicionais',
          fields: {
            nome_pai: 'Nome do Pai',
            nome_mae: 'Nome da Mãe',
            nome_conjuge: 'Nome conjuge',
            cpf_conjuge: 'CPF conjuge',
            identidade_conjuge: 'N° de Identidade conjuge',
            orgao_expedidor_conjuge: 'Órgão expedidor',
            uf_expedicao_conjuge: 'UF Expedição',
            cpf_nome_filhos: 'Dados dos filhos',
            produtor_assina_contrato: 'Contrato Assinado?',
            associado: 'Produtor Associado?',
          },
        },
      },

      /**
       * Campos do formulário de cadastro
       * Web: show e required
       * Mobile: visible e mandatory
       */
      sections: {
        personal: {
          nome: { show: true, required: true, blocked: true },
          codigo_laticinio: { show: true, required: true, editable: true },
          historico_codigo_laticinio: { show: true, required: true, editable: true },
          email: { show: false, required: false, visible: true, mandatory: false },
          celular: { show: true, required: false, visible: true, mandatory: false },
          telefone: { show: true, required: false, visible: true, mandatory: false },
          cnpj_cpf: { show: true, required: true, visible: true, mandatory: false },
          codigo_sda: { show: true, required: false, visible: true, mandatory: false },
          cidasc: { show: true, required: false, visible: true, mandatory: false },
          rg: { show: true, required: false, visible: true, mandatory: false },
          orgao_expedidor: { show: true, required: false, visible: true, mandatory: false },
          uf_expedicao: { show: true, required: false, visible: true, mandatory: false },
          nirf: { show: true, required: false, visible: true, mandatory: false },
          nrp: { show: true, required: false, visible: true, mandatory: false },
          inscricao_estadual: { show: true, required: false, visible: true, mandatory: false },
          data_nascimento: { show: true, required: false, visible: true, mandatory: false },
          sif: { show: true, required: false, visible: true, mandatory: false },
          cooperativa: { show: true, required: false },
          cad_pro: { show: true, required: false, visible: true, mandatory: false },
          id_rota: { show: true, required: false, visible: true, mandatory: false },
          observacao_tecnica: { show: true, required: false, visible: true, mandatory: false },
          carta_anuencia: { show: false, required: false, visible: false, mandatory: false },
          emite_nota_propria: { show: true, required: false, visible: false, mandatory: false },
          estado_civil: { show: true, required: false, visible: false, mandatory: false },
          escolaridade: { show: true, required: false, visible: false, mandatory: false },
          assentado: { show: true, required: false, visible: true, mandatory: false },
          codigo_nucleo: { show: true, required: false, visible: true, mandatory: false },
        },
        address: {
          nome_propriedade: { show: true, required: false, visible: true, mandatory: false },
          end_cep: { show: true, required: false, visible: true, mandatory: false },
          end_rua: { show: true, required: false, visible: true, mandatory: false },
          end_numero: { show: true, required: false, visible: true, mandatory: false },
          end_estado: { show: true, required: false },
          end_cidade_codigo: { show: true, required: true },
          end_cidade: { show: true, required: false, visible: true, mandatory: false },
          end_bairro: { show: true, required: false, visible: true, mandatory: false },
          end_complemento: { show: true, required: false, visible: true, mandatory: false },
          id_regiao: { show: true, required: true, visible: true, mandatory: false },
          localizacao: { show: true, required: false },
          id_localidade: { show: true, required: false, visible: true, mandatory: false },
        },
        payment: {
          dia_pagamento: { show: true, required: false, visible: true, mandatory: false },
          ignorar_bonificacao: { show: true, required: false },
          adiantamento: { show: true, required: false },
          nome_titular: { show: true, required: false, visible: true, mandatory: false },
          cnpj_cpf_titular: { show: true, required: false, visible: true, mandatory: false },
          banco: { show: true, required: false, visible: true, mandatory: false },
          agencia: { show: true, required: false, visible: true, mandatory: false },
          conta: { show: true, required: false, visible: true, mandatory: false },
          tipo_conta: { show: true, required: false, visible: true, mandatory: false },
          tipo_forma_pagamento: { show: true, required: false, visible: true, mandatory: false },

          //descontos
          fundesa: { show: true, required: false },
          rat: { show: true, required: false },
          senar: { show: true, required: false },
          funrural: { show: true, required: false },
          administrativa: { show: true, required: false },
          inss: { show: true, required: false },

          //tributação
          tributacao: { show: true, required: false },
          desconta_senar: { show: true, required: false },
          debita_inss: { show: true, required: false },
        },
        dataProduction: {
          numero_ordenha: { show: true, required: false, visible: true, mandatory: false },
          frequencia_coleta: { show: true, required: false, visible: true, mandatory: false },
          numero_tarro: { show: true, required: false, visible: true, mandatory: false },
          quantidade_tanques: { show: true, required: false, visible: true, mandatory: false },
          capacidade_resf_inte: { show: true, required: false, visible: true, mandatory: false },
          cooling_type: { show: true, required: false, visible: true, mandatory: false },
          tipo_ordenha: { show: true, required: false, visible: true, mandatory: false },
          hora_inicio_coleta: { show: true, required: false, visible: true, mandatory: false },
          hora_fim_coleta: { show: true, required: false, visible: true, mandatory: false },
          proteina: { show: true, required: false, visible: true, mandatory: false },
          gordura: { show: true, required: false, visible: true, mandatory: false },
          data_inicio_coleta: { show: true, required: false, visible: true, mandatory: false },
          data_fim_coleta: { show: true, required: false, visible: true, mandatory: false },
          motivo_fim_coleta: { show: true, required: false, visible: true, mandatory: false },
          qualidade_leite_contrato: { show: true, required: false, visible: true, mandatory: false },
          qualidade_leite_entrega: { show: true, required: false, visible: true, mandatory: false },
          id_laticinio_concorrente: { show: true, required: false, visible: true, mandatory: false },
          id_ramo_atividade: { show: true, required: false, visible: true, mandatory: false },
        },
        additionalInformation: {
          nome_pai: { show: true, required: false, visible: true, mandatory: false },
          nome_mae: { show: true, required: false, visible: true, mandatory: false },
          nome_conjuge: { show: true, required: false, visible: true, mandatory: false },
          cpf_conjuge: { show: true, required: false, visible: true, mandatory: false },
          identidade_conjuge: { show: true, required: false, visible: true, mandatory: false },
          orgao_expedidor_conjuge: { show: true, required: false, visible: true, mandatory: false },
          uf_expedicao_conjuge: { show: true, required: false, visible: true, mandatory: false },
          cpf_nome_filhos: { show: true, required: false, visible: true, mandatory: false },
          produtor_assina_contrato: { show: true, required: false },
          associado: { show: true, required: false, visible: true, mandatory: false },
        },
      },

      producerSettings: {
        autoincrementa_codigo_laticinio: false,
        validar_cadastro_prospeccao: false,
        codigo_laticinio_multiplo: false,
        cnpj_cpf_multiplo: false,
      }
    }
  },

  mounted() {
    this.getSettings();
  },

  methods: {

    getSettings() {
      const settings = this.$store.state.settings.gerais.cadastro_produtor;
      if (settings) {
        this.sections.personal = Object.keys(this.sections.personal)
          .reduce((acc, key) => ({
            ...acc, [key]: {
              ...this.sections.personal[key],
              ...settings.pessoal[key],
            }
          }), {});
        this.sections.address = Object.keys(this.sections.address)
          .reduce((acc, key) => ({
            ...acc, [key]: {
              ...this.sections.address[key],
              ...settings.endereco[key],
            }
          }), {});
        this.sections.payment = Object.keys(this.sections.payment)
          .reduce((acc, key) => ({
            ...acc, [key]: {
              ...this.sections.payment[key],
              ...settings.pagamento[key],
            }
          }), {});
        this.sections.dataProduction = Object.keys(this.sections.dataProduction)
          .reduce((acc, key) => ({
            ...acc, [key]: {
              ...this.sections.dataProduction[key],
              ...settings.dados_producao[key],
            }
          }), {});
        this.sections.additionalInformation = Object.keys(this.sections.additionalInformation)
          .reduce((acc, key) => ({
            ...acc, [key]: {
              ...this.sections.additionalInformation[key],
              ...settings.informacoes_adicionais[key],
            }
          }), {});
        this.producerSettings = {
          ...this.producerSettings,
          ...settings.configuracoes,
        };
      }
    },

    async save() {
      this.saving = true;
      try {
        const payload = {
          configuracaoQualidade: JSON.stringify([
            {
              cadastro_produtor: {
                configuracoes: this.producerSettings,
                pessoal: this.sections.personal,
                endereco: this.sections.address,
                pagamento: this.sections.payment,
                dados_producao: this.sections.dataProduction,
                informacoes_adicionais: this.sections.additionalInformation,
              }
            }
          ]),
        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          this.$qs.stringify(payload)
        );

        [data] = data;

        if (!data.codigo) {
          throw data;
        }

        this.$store.dispatch('updateStoreSetting');
        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar configurações", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>
