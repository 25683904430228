<template>
  <div>
    <v-dialog
      v-if="dialog.show"
      v-model="dialog.show"
      width="80%"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title
          class="pb-1"
        >
          Análise de Tanque
          <v-spacer />
          <v-btn
            icon
            small
            depressed
            @click="hide()"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <unload-data-info
            :unload="unload"
            :raw-products="rawProducts"
            raw-product-editable
            spot-tanks-editable
            @editSpotTanks="editSpotTanks"
          />

          <tanks-analysis
            ref="tanksAnalysis"
            :unload="unload"
            :tanks.sync="tanks"
            :loading.sync="loading"
            :editing="editing"
            :visible-analysis-params="visibleAnalysisParams"
            :type="unload.itinerary.type"
            :ignore-empty="ignoreEmpty"
            :ignore-errors="ignoreErrors"
            :auto-mark-out-of-range="autoMarkOutOfRange"
            @onTankUpdated="onTankUpdated"
            @onTanksAnalysisSave="onTanksAnalysisSave"
            @close="hide()"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-3"
            text
            @click="hide()"
          >
            CANCELAR
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            outlined
            :disabled="!tanksAnalysisEnable"
            @click="save()"
          >
            SALVAR
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay :value="loading">
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import UnloadDataInfo from "@/Domains/Platform/Unload/Components/UnloadDataInfo.vue";
import TanksAnalysis from "@/Domains/Platform/Unload/Components/UnloadSteps/Steps/UnloadStep1.vue";

export default {

  components: {
    TanksAnalysis,
    UnloadDataInfo,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },

    visibleAnalysisParams: {
      type: Array,
      default: () => ([]),
    },

    type: String,

    ignoreErrors: {
      type: Array,
      default: () => ([]),
    },

    ignoreEmpty: {
      type: Array,
      default: () => ([]),
    },

    rawProducts: {
      type: Array,
      default: () => ([]),
    },

    autoMarkOutOfRange: Boolean,
    tanksAnalysisEnable: Boolean,
  },

  data() {
    return {
      loading: false,

      // Dados referentes ao Dialog
      dialog: {
        show: false,
      },

      unload: {},

    };
  },

  computed: {
    tanks: {
      get() {
        return this.unload.tanks.filter(tank => tank.vol > 0);
      },
      set(tanks) {
        this.unload.tanks = tanks;
      },
    },

  },

  methods: {

    show(unload) {
      this.dialog.show = true;
      this.unload = unload;
    },

    save() {
      this.$refs.tanksAnalysis.save();
    },

    hide() {
      this.dialog.show = false;
      this.unload = {};

      return this.$emit('reload');
    },

    onTankUpdated(updatedTank) {
      this.unload.tanks = this.unload.tanks.map(tank => {

        if (tank.index === updatedTank.index) {
          return updatedTank;
        }

        return tank;
      });
    },

    onTanksDiscarded(discardedTanks) {
      this.hide();

      return this.$emit('onTanksDiscarded', discardedTanks);
    },

    onTanksAnalysisSave() {
      return this.$emit('onTanksAnalysisSave');
    },

    editSpotTanks() {
      this.$refs.tanksAnalysis.editSpotTanks()
    }

  },

}
</script>
