<template>
  <v-form
    ref="form"
    v-model="validation.valid"
    lazy-validation
  >
    <v-row>
      <v-col
        v-if="isVisible('nome_propriedade')"
        cols="12"
      >
        <v-text-field
          v-model="address.nome_propriedade"
          :label="`Nome Propriedade ${isRequired('nome_propriedade') ? '*' : ''}`"
          :rules="getRules('nome_propriedade')"
          prepend-inner-icon="pin_drop"
          dense
          hide-details
        />
      </v-col>
      <v-col
        v-if="isVisible('end_cep')"
        cols="4"
      >
        <zip-code-input
          v-model="address.end_cep"
          prepend-inner-icon="subtitles"
          :label="`CEP ${isRequired('end_cep') ? '*' : ''}`"
          :rules="getRules('end_cep')"
          dense
          hide-details
          @address="onAddress"
        />
      </v-col>
      <v-col
        v-if="isVisible('end_rua')"
        cols="8"
      >
        <v-text-field
          v-model="address.end_rua"
          prepend-inner-icon="subtitles"
          :label="`Endereço ${isRequired('end_rua') ? '*' : ''}`"
          :rules="getRules('end_rua')"
          dense
          hide-details
        />
      </v-col>
      <v-col
        v-if="isVisible('end_numero')"
        cols="4"
      >
        <v-text-field
          v-model="address.end_numero"
          prepend-inner-icon="subtitles"
          :label="`Número ${isRequired('end_numero') ? '*' : ''}`"
          :rules="getRules('end_numero')"
          dense
          hide-details
        />
      </v-col>
      <v-col
        v-if="isVisible('end_estado')"
        cols="3"
      >
        <states-select
          v-model="address.end_estado"
          prepend-inner-icon="villa"
          :label="`Estado ${isRequired('end_estado') ? '*' : ''}`"
          :rules="getRules('end_estado')"
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col
        v-if="isVisible('end_cidade_codigo')"
        cols="2"
      >
        <countys-select
          v-model="address.end_cidade_codigo"
          :uf="address.end_estado"
          prepend-inner-icon="subtitles"
          :label="`Codigo Cidade ${isRequired('end_cidade_codigo') ? '*' : ''}`"
          :rules="getRules('end_cidade_codigo')"
          dense
          hide-details
          clearable
          @change="onCode"
        />
      </v-col>
      <v-col
        v-if="isVisible('end_cidade')"
        cols="3"
      >
        <cities-select
          v-model="address.end_cidade"
          :uf="address.end_estado"
          prepend-inner-icon="domain"
          :label="`Cidade ${isRequired('end_cidade') ? '*' : ''}`"
          :rules="getRules('end_cidade')"
          dense
          hide-details
          clearable
          @change="onCity"
        />
      </v-col>
      <v-col
        v-if="isVisible('end_bairro')"
        cols="4"
      >
        <v-text-field
          v-model="address.end_bairro"
          prepend-inner-icon="subtitles"
          :label="`Bairro ${isRequired('end_bairro') ? '*' : ''}`"
          :rules="getRules('end_bairro')"
          dense
          hide-details
        />
      </v-col>
      <v-col
        v-if="isVisible('end_complemento')"
        cols="4"
      >
        <v-text-field
          v-model="address.end_complemento"
          prepend-inner-icon="subtitles"
          :label="`Complemento ${isRequired('end_complemento') ? '*' : ''}`"
          :rules="getRules('end_complemento')"
          dense
          hide-details
        />
      </v-col>
      <v-col
        v-if="isVisible('id_localidade')"
        cols="2"
      >
        <locality-select
          v-model="address.id_localidade"
          :uf="address.end_estado"
          :city="address.end_cidade"
          :label="`Localidade ${isRequired('id_localidade') ? '*' : ''}`"
          prepend-inner-icon="domain"
          persistent-hint
          :rules="getRules('id_localidade')"
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col
        v-if="isVisible('id_regiao')"
        cols="2"
      >
        <v-select
          v-model="address.id_regiao"
          prepend-inner-icon="icon-rota"
          :label="`Rota região ${isRequired('id_regiao') ? '*' : ''}`"
          :rules="getRules('id_regiao')"
          :items="regionItems"
          item-text="nome"
          item-value="id_regiao"
          dense
          hide-details
          auto-select-first
          clearable
          @change="onRegionChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card
          flat
          dense
          class="map-card"
          style="background: #0277bd"
          elevation="12"
          rounded="rounded-bl-xl"
        >
          <v-card-title
            class="pa-0"
            style="background: rgb(0, 0, 0, 0.2)"
          >
            <v-row align="end">
              <v-col
                style="padding-bottom: 0px !important; margin-left: 10px: margin-bottom:0px !important"
                md="4"
                class="pa-1"
              >
                <v-text-field
                  v-model.number="address.end_latitude"
                  :label="`Latitude ${isRequired('localizacao') ? '*' : ''}`"
                  :rules="getRules('localizacao')"
                  prepend-inner-icon="map"
                  dark
                  filled
                  rounded
                  dense
                  style="padding-top: 12px"
                />
              </v-col>
              <v-col
                style="padding-bottom: 0px !important; margin-left: 10px"
                md="4"
                class="pa-1"
              >
                <v-text-field
                  v-model.number="address.end_longitude"
                  :label="`Longitude ${isRequired('localizacao') ? '*' : ''}`"
                  :rules="getRules('localizacao')"
                  prepend-inner-icon="map"
                  style="padding-top: 12px"
                  dark
                  filled
                  rounded
                  dense
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                md="12"
                class="pa-0"
              >
                <route-map
                  ref="map"
                  :center="$store.state.settings.coordenadas"
                  clickable
                  :value="[]"
                  :markers="marker"
                  :draggable="true"
                  @onSelectPosition="handleSelectPosition"
                  @onPositionChange="handleSelectPosition"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import * as _ from 'lodash';

import StatesSelect from "@/Support/Components/StatesSelect.vue";
import ZipCodeInput from "@/Support/Components/ZipCodeInput.vue";
import CitiesSelect from "@/Support/Components/CitiesSelect.vue";
import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";
import LocalitySelect from "@/Domains/Projects/HealthExamination/Components/LocalitySelect.vue";
import CountysSelect from "@/Support/Components/CountysSelect.vue";

export default {
  components: {
    StatesSelect,
    CitiesSelect,
    ZipCodeInput,
    RouteMap,
    LocalitySelect,
    CountysSelect
  },
  props: {
    dataProducer: {
      required: true,
    },
  },
  data() {
    return {
      CEPMask: {
        mask: "00000-000",
        maxlength: 14,
      },

      regionItems: [],

      validation: {
        valid: true,
        rules: {
          required: [(v) => !!v || "Campo obrigatório"],
        },
      },

      defaultRequiredFields: ['id_regiao']
    };
  },
  computed: {
    address: {
      get() {
        return this.dataProducer;
      },

      set(newValue) {
        return this.$emit("update:dataProducer", newValue);
      },
    },

    // Configurações do marker do mapa
    marker() {
      return [
        {
          location: {
            lat: parseFloat(_.get(this.address, 'end_latitude') || _.get(this.$store.state.settings.coordenadas, 'lat')) || null,
            lng: parseFloat(_.get(this.address, 'end_longitude') || _.get(this.$store.state.settings.coordenadas, 'lng')) || null,
          },
          visible: true,
          color: "#00838F",
          draggable: true,
          icon: "person_outline",
        },
      ];
    },

    settings() {
      if (_.isEmpty(this.$store.state.settings.gerais.cadastro_produtor)) {
        return {}
      }
      return this.$store.state.settings.gerais.cadastro_produtor.endereco;
    },
  },
  watch: {
    // Monitora a validação do form
    validation: {
      handler: function ({ valid }) {
        this.$emit("onValidation", {
          valid: valid,
          tab: "address",
        });
      },
      deep: true,
    },
  },

  mounted() {
    this.getRegion();

    // Valida campos do formulário
    this.$refs.form.validate();
  },

  methods: {
    isVisible(field) {
      if (!this.settings[field]) {
        return true;
      }

      return this.settings[field].show;
    },

    isRequired(field) {
      if (!this.settings[field]) {
        return this.defaultRequiredFields.includes(field);
      }

      return this.settings[field].required;
    },

    getRules(field) {
      const rules = [];

      if (this.isRequired(field)) {
        rules.push(v => !!v || "Campo obrigatório");
      }

      return rules;
    },

    /**
     * @void
     * Buscas as regioções do sistema
     */
    async getRegion() {
      try {
        const { data } = await this.$axios.post(`/regiao/listaJson`, this.$qs.stringify({
          tipo: 'CAPTACAO'
        }));

        this.regionItems = data;
      } catch (error) {
        console.warn(error);
      }
    },

    /**
     * Trata o evento de click do mapa e posiciona o novo marker
     */
    handleSelectPosition({ position }) {
      this.address = {
        ...this.address,
        end_latitude: parseFloat(position.lat.toFixed(7)),
        end_longitude: parseFloat(position.lng.toFixed(7)),
      }
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    /**
     * @void
     * Valida os campos do formulários
     */
    formValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    formResetValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta o formulário
     */
    formReset() {
      this.$refs.form.reset()
    },

    /**
     * @void
     * Evento para atualizar a região
     */
    onRegionChange() {
      const region = this.regionItems.find(o => o.id_regiao === this.address.id_regiao);
      this.address = {
        ...this.address,
        regiao: region ? region.nome : '',
      }
    },

    /**
     * @void
     * Atualiza o endereço com a API do viacep
     */
    async onAddress(data) {
      this.address = {
        ...this.address,
        end_rua: data.logradouro,
        end_bairro: data.bairro,
        end_cidade: data.localidade,
        end_estado: data.uf,
        end_cidade_codigo: data.ibge
      }
    },
    /**
     * @void
     * Atualiza as informações da cidade
     */
    onCity(data) {
      if (data.city) {
        this.address.end_cidade_codigo = data.code;
      }

    },

    onCode(data) {
      if (data.code) {
        this.address.end_cidade = data.city;
      }
    },
  },
};
</script>
