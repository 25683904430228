<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            <v-row>
              <v-col>
                <v-menu
                  ref="menu"
                  transition="scale-transition"
                  offset-y
                  content-class="menu-select"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :value="dateRefFilter(filters.monthReference)"
                      label="Mês de Referência"
                      prepend-inner-icon="event"
                      persistent-hint
                      clearable
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="filters.monthReference"
                    :max="maxDateRef"
                    type="month"
                    no-title
                    scrollable
                    @change="loadParticularities"
                  />
                </v-menu>
              </v-col>
              <v-col>
                <person-autocomplete-filter
                  v-model="filters.producer"
                  label="Produtor"
                  type="PRODUCER"
                  persistent-hint
                  :filled="false"
                  :clearable="true"
                  @change="loadParticularities"
                />
              </v-col>
              <v-col style="text-align: right;">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      v-on="on"
                      @click="showParticularityDialog = true"
                    >
                      <v-icon dark>
                        add
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Nova particularidade</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filters.search"
            dark
            @click:row="edit"
          >
            <template #[`item.considerBonus`]="{ value }">
              {{ value ? 'Sim' : 'Não' }}
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="edit(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showParticularityDialog"
      persistent
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Particularidades do Produtor
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent="save"
            >
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-menu
                    ref="menu"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        :value="dateRefFilter(form.date.picker)"
                        label="Mês de Referência *"
                        prepend-inner-icon="event"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="form.date.picker"
                      :max="maxDateRef"
                      type="month"
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <person-autocomplete-filter
                    v-model="form.producer"
                    label="Produtor *"
                    type="PRODUCER"
                    :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    persistent-hint
                    :filled="false"
                    :clearable="false"
                  />
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    v-model="form.qualityItemSelected"
                    :items="qualityItems"
                    :rules="[v => !!v && !!v || 'Campo obrigatório!']"
                    item-text="item"
                    label="Inserir item *"
                    prepend-inner-icon="search"
                    return-object
                    persistent-hint
                    :filled="false"
                    :clearable="false"
                  />
                </v-col>

                <v-col cols="6">
                  <v-select
                    v-model="form.considerBonus"
                    :items="configurationType"
                    :rules="[v => v !== null && v !== undefined || 'Campo obrigatório!']"
                    :item-text="'text'"
                    :item-value="'value'"
                    persistent-hint
                    :filled="false"
                    :clearable="false"
                    label="Tipo configuração"
                    prepend-inner-icon="info"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="form.motive"
                    label="Motivo "
                    persistent-hint
                    filled
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="showParticularityDialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-5"
          v-on="on"
          @click.stop="add()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Nova particularidade</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

import moment from "moment-timezone";
import _ from "lodash";
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "producer-particularities",

  components: {
    PersonAutocompleteFilter
  },

  props: {
    rawMaterialId: String,
  },

  data() {
    return {
      headers: [
        { text: 'Produtor', value: 'name' },
        { text: 'Mês de Referência', value: 'monthReference' },
        { text: 'Item', value: 'item' },
        { text: 'Tipo', value: 'type' },
        { text: 'Considerar para Bonificação', value: 'considerBonus' },
        { text: 'Motivo', value: 'motive' },
        { text: '', value: 'action', width: 30 },
      ],

      configurationType: [
        { text: 'Considerar para Bonificação', value: 1 },
        { text: 'Não considerar para Bonificação', value: 0 }
      ],

      items: [],

      showParticularityDialog: false,

      form: {
        saving: false,
        date: {
          picker: null,
        },
        considerBonus: '',
        qualityItemSelected: '',
      },

      filters: {
        producer: '',
        monthReference: moment().format('YYYY-MM'),
      },

      qualityItems: [],
    };
  },

  computed: {
    maxDateRef() {
      return moment().format('YYYY-MM');
    },
  },

  computed: {
    maxDateRef() {
      return moment().format('YYYY-MM');
    },
  },

  created() {
    this.loadParticularities();
    this.loadQualities();
  },

  methods: {
    async loadParticularities() {
      try {
        const { data } = await this.$axios.get(`/tabelaPreco/listaParticularidades`, {
          params: {
            id_produtor: this.filters.producer.id,
            mes_referencia: moment(this.filters.monthReference, 'YYYY-MM').format('YYYY-MM'),
          },
        });

        if (_.isString(data)) {
          throw "PHP error";
        }

        this.items = data.map(item => ({
          id: item.id_particularidade_bonificacao,
          name: item.producer.nome,
          monthReference: moment(item.mes_referencia, 'YYYY-MM').format('MM-YYYY'),
          item: item.item,
          type: item.tipo,
          considerBonus: item.considerar_bonificacao,
          motive: item.motivo,
        }));
      }
      catch (err) {
        this.$snotify.error("Erro ao carregar dados", "Atenção");
        console.warn(err)
      }
    },

    add() {
      this.form = {
        dialog: true,
        id: null,
        startDate: null,
        producer: {},
        ignoreItems: [],
        incentives: [],
      };
      this.$refs.form?.resetValidation();
    },

    edit(data) {
      this.form = {
        dialog: true,
        ...cloneDeep(data),
        startDate: data.startDate ? moment(data.startDate).format('YYYY-MM') : null,
      };
      this.$refs.form?.resetValidation();
    },

    close() {
      this.form.dialog = false
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        let { data } = await this.$axios.post(
          `/tabelaPreco/insereParticularidadeProdutor`,
          this.$qs.stringify({
            mes_referencia: this.form.date.picker,
            id_produtor: this.form.producer.id,
            motivo: this.form.motive ? this.form.motive : '',
            considerar_bonificacao: this.form.considerBonus,
            id_qualidade: this.form.qualityItemSelected.id,
            item: this.form.qualityItemSelected.item,
            tipo: this.form.qualityItemSelected.type,
            id_materia_prima: this.rawMaterialId,
          })
        );

        if (data.codigo !== 1) {
          throw new Error(data.message);
        }

        this.showParticularityDialog = false;
        this.loadParticularities();
        this.form = {
          saving: false,
          date: {
            picker: null,
          },
          considerBonus: '',
          qualityItemSelected: '',
        };

        this.$snotify.success("Particularidade salva com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadParticularities();
      }
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/tabelaPreco/removeParticularidadeProdutor`, this.$qs.stringify({ id: id }));

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.loadParticularities();
        this.$snotify.success("Dados excluídos", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir dados: " + error, "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadParticularities();
      }
    },

    dateRefFilter(date) {
      if (isEmpty(date)) {
        return null;
      }

      return moment(date, 'YYYY-MM').format('MM/YYYY');
    },

    async loadQualities() {
      try {
        const { data } = await this.$queries.post(`/qualidade/listaQualidadeJson`, { nome: '%' });

        if (_.isString(data)) {
          throw "PHP error";
        }

        this.qualityItems = data.map(({ id_qualidade, item }) => ({
          id: id_qualidade,
          item,
          type: this.getType(id_qualidade)
        }));

      }
      catch (err) {
        console.warn(err)
      }
    },

    getType(value) {
      if ([20, 21].includes(value)) {
        return 'EXAME';
      }

      if (value === 24) {
        return 'FRETE';
      }

      return 'AMOSTRA';
    },
  },
};
</script>
