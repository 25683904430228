<template>
  <div>
    <v-dialog
      v-model="show"
      width="1060"
      transition="dialog-bottom-transition"
      class="dialog-producer"
      fullscreen
      persistent
    >
      <v-card
        flat
        class="pa-0 dialog-card"
      >
        <v-toolbar
          flat
          color="#1976D2"
          max-height="60px"
          class="pa-0"
        >
          <img
            src="@/Assets/logo-transparent-dark.png"
            height="30"
          >
          <v-spacer />
          <v-icon
            v-if="producerId"
            @click="toPrint(producerId)"
          >
            vertical_align_bottom
          </v-icon>
        </v-toolbar>
        <v-tabs
          v-model="tab"
          fixed-tabs
          background-color="#1976D2"
          dark
        >
          <v-tab href="#personal-tab">
            Dados Pessoais
          </v-tab>

          <v-tab
            v-if="sourceScreen!='PROJETOS'"
            href="#address-tab"
          >
            Endereço
          </v-tab>

          <v-tab
            v-if="sourceScreen!='PROJETOS'"
            href="#access-tab"
          >
            Acessos
          </v-tab>

          <v-tab
            v-if="(!isGroupingType || financialTabIsVisible ) && sourceScreen!='PROJETOS' "
            href="#financial-tab"
          >
            Pagamentos e descontos
          </v-tab>

          <v-tab
            v-if="hasFiscalAccess"
            href="#fiscal-tab"
          >
            Fiscal
          </v-tab>

          <v-tab
            v-if="sourceScreen!='PROJETOS'"
            href="#production-tab"
          >
            Dados de produção
          </v-tab>

          <v-tab
            v-if="!isGroupingType "
            href="#additional-tab"
          >
            Informações adicionais
          </v-tab>

          <!--Se não tiver id é um produtor novo-->
          <v-tab
            v-if="producerId && !isGroupingType"
            href="#negotiation-tab"
          >
            Preço/negociação
          </v-tab>

          <v-tab
            v-if="producerId && !isGroupingType"
            href="#attachments-tab"
          >
            Anexos
          </v-tab>

          <v-tab
            v-if="producerId && sourceScreen!='PROJETOS'"
            href="#historic-tab"
          >
            Histórico
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item
              value="personal-tab"
              justify-center
              class="pa-6"
            >
              <personal-data-tab
                ref="personalForm"
                :data-producer.sync="dataProducer"
                :data-group-settings.sync="groupSettings"
                :producer-id="producerId"
                :type="type"
                :source-screen="sourceScreen"
                :need-save.sync="needSave"
                @onValidation="onValidationTab"
                @openExistingProducer="onOpenExistingProducer"
                @fillProducerData="onFillProducerData"
              />
            </v-tab-item>

            <v-tab-item
              value="address-tab"
              justify-center
              eager
              class="pa-6"
            >
              <address-tab
                ref="addressForm"
                :data-producer.sync="dataProducer"
                @onValidation="onValidationTab"
              />
            </v-tab-item>

            <v-tab-item
              value="access-tab"
            >
              <v-col
                class="tab-item-body  mt-4"
                align-self="center"
              >
                <v-form ref="aba-access">
                  <v-row>
                    <v-col
                      md="4"
                      class="pb-2"
                    >
                      <v-autocomplete
                        v-model="dataProducer.id_laticinio"
                        label="Unidade"
                        prepend-inner-icon="house_siding"
                        outlined
                        hide-details
                        :items="dairies"
                        item-text="nome"
                        item-value="id_pessoa"
                        clearable
                        disabled
                      >
                        <template #selection="{item}">
                          <v-chip
                            small
                            dark
                            color="cyan darken-3"
                            class="mt-1"
                          >
                            {{ item.nome }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-spacer />
                    <v-col
                      v-if="!dataProducer.id_condominio"
                      class="d-flex align-end align-self-end"
                      style="max-width: 160px"
                    >
                      <v-btn
                        color="primary"
                        outlined
                        @click="editingUserAccess = !editingUserAccess"
                      >
                        <v-icon left>
                          edit
                        </v-icon>
                        Editar Acessos
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="editingUserAccess">
                    <v-col
                      cols="12"
                      class="pt-3"
                    >
                      <v-autocomplete
                        v-model="dataProducer.pessoa_acesso"
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        prepend-inner-icon="groups"
                        hide-details
                        label="Usuarios com Acesso"
                        :items="accessUsers"
                        return-object
                        item-text="nome_usuario"
                        item-value="id_usuario"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col
                      cols="12"
                      class="pt-3"
                    >
                      <v-combobox
                        :value="accessDrivers"
                        label="Motoristas com Acesso"
                        prepend-inner-icon="local_shipping"
                        outlined
                        chips
                        small-chips
                        multiple
                        hide-details
                        disabled
                      >
                        <template #selection="{ item }">
                          <v-chip
                            small
                            dark
                            color="primary"
                            class="mt-1"
                          >
                            {{ item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pt-3"
                    >
                      <v-combobox
                        :value="accessTechnicians"
                        label="Técnicos com Acesso"
                        prepend-inner-icon="engineering"
                        outlined
                        chips
                        small-chips
                        multiple
                        hide-details
                        disabled
                      >
                        <template #selection="{ item }">
                          <v-chip
                            small
                            dark
                            color="primary"
                            class="mt-1"
                          >
                            {{ item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col
                      v-if="accessOthersUsers.length > 0"
                      cols="12"
                      class="pt-3"
                    >
                      <v-combobox
                        :value="accessOthersUsers"
                        label="Usuários com Acesso"
                        prepend-inner-icon="groups"
                        outlined
                        chips
                        small-chips
                        multiple
                        hide-details
                        disabled
                      >
                        <template #selection="{ item }">
                          <v-chip
                            small
                            dark
                            color="primary"
                            class="mt-1"
                          >
                            {{ item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-tab-item>

            <v-tab-item
              v-if="!isGroupingType || financialTabIsVisible"
              value="financial-tab"
              justify-center
              eager
              class="pa-6"
            >
              <financial-data-tab
                ref="financialForm"
                :data-taxes.sync="tax"
                :data-alternative-bank.sync="dataAlternativeBank"
                :data-main-account.sync="mainAccount"
                :data-tributacoes.sync="tributacoes"
                :data-producer.sync="dataProducer"
                @onValidation="onValidationTab"
              />
            </v-tab-item>

            <v-tab-item
              v-if="hasFiscalAccess"
              value="fiscal-tab"
              justify-center
              eager
              class="pa-6"
            >
              <fiscal-data-tab
                v-if="!!producerId"
                :producer-id="producerId"
                :data-fiscal.sync="fiscal"
                @onValidation="onValidationTab"
              />
              <div
                v-else
                class="d-flex justify-center"
              >
                <v-container class="ma-6">
                  <v-alert
                    dense
                    outlined
                    prominent
                    border="left"
                    elevation="2"
                    type="warning"
                  >
                    <div class="text-h5">
                      Atenção
                    </div>
                    Para configurar o Fiscal, você deve salvar o produtor primeiro
                  </v-alert>
                </v-container>
              </div>
            </v-tab-item>

            <v-tab-item
              value="production-tab"
            >
              <v-col
                class="pb-0"
              >
                <v-form
                  v-model="valid.dataProduction"
                  lazy-validation
                >
                  <div class="pa-2">
                    <v-row>
                      <v-col
                        v-if="isVisible('numero_ordenha', 'dados_producao')"
                        md="3"
                        class="pl-1 pr-2"
                      >
                        <v-text-field
                          v-model="dataProducer.numero_ordenha"
                          type="number"
                          :label="`Número de Ordenhas ${isRequired('numero_ordenha', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('numero_ordenha', 'dados_producao')"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('tipo_ordenha', 'dados_producao')"
                        md="3"
                        class="pl-1 pr-2"
                      >
                        <v-autocomplete
                          v-model="dataProducer.tipo_ordenha"
                          :items="tipos_ordenha_options"
                          :label="`Tipo de Ordenha ${isRequired('tipo_ordenha', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('tipo_ordenha', 'dados_producao')"
                          hint
                          dense
                          clearable
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('frequencia_coleta', 'dados_producao')"
                        md="3"
                        class="pl-1 pr-2"
                      >
                        <v-select
                          v-model="dataProducer.frequencia_coleta"
                          :items="['12','24','36','48']"
                          :label="`Realizar coleta do leite a cada ${isRequired('frequencia_coleta', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('frequencia_coleta', 'dados_producao')"
                          hint
                          dense
                          clearable
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('numero_tarro', 'dados_producao')"
                        md="3"
                        class="pl-1 pr-2"
                      >
                        <v-text-field
                          v-model="dataProducer.numero_tarro"
                          type="number"
                          :label="`Tarro n° ${isRequired('numero_tarro', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('numero_tarro', 'dados_producao')"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('quantidade_tanques', 'dados_producao')"
                        md="3"
                        class="pl-1 pr-1"
                      >
                        <masked-text-field
                          v-model="dataProducer.quantidade_tanques"
                          :mask="{ mask: Number, min: 1, max: 5, scale: 0, signed: false }"
                          :label="`Quantidade de resfriadores ${isRequired('quantidade_tanques', 'dados_producao') ? '*' : ''}`"
                          :rules="[...getRules('quantidade_tanques', 'dados_producao'), (v) => v <= 5 || 'A quantidade de Resfriadores máxima é 5']"
                          dense
                          @input="onChangeAmountCoolers()"
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('qualidade_leite_contrato', 'dados_producao')"
                        md="3"
                        class="pl-1 pr-2"
                      >
                        <v-select
                          v-model="dataProducer.qualidade_leite_contrato"
                          :items="['B','C']"
                          :label="`Tipo de leite que produz ${isRequired('qualidade_leite_contrato', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('qualidade_leite_contrato', 'dados_producao')"
                          hint
                          dense
                          clearable
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('qualidade_leite_entrega', 'dados_producao')"
                        md="3"
                        class="pl-1 pr-2"
                      >
                        <v-select
                          v-model="dataProducer.qualidade_leite_entrega"
                          :items="['B','C']"
                          :label="`Tipo de leite que entrega ${isRequired('qualidade_leite_entrega', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('qualidade_leite_entrega', 'dados_producao')"
                          hint
                          dense
                          clearable
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('id_laticinio_concorrente', 'dados_producao')"
                        cols="12"
                        sm="6"
                        md="3"
                        class="pb-1"
                      >
                        <dairy-competitor-autocomplete-filter
                          v-model="dataProducer.id_laticinio_concorrente"
                          :label="`Laticínio concorrente entrega ${isRequired('id_laticinio_concorrente', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('id_laticinio_concorrente', 'dados_producao')"
                        />
                      </v-col>

                      <v-col
                        v-if="isVisible('id_ramo_atividade', 'dados_producao')"
                        md="3"
                      >
                        <v-autocomplete
                          v-model="dataProducer.id_ramo_atividade"
                          :items="lineOfBusiness"
                          item-text="ramo_atividade"
                          item-value="id_ramo_atividade"
                          prepend-inner-icon="apartment"
                          :label="`Ramo de Atividade ${isRequired('id_ramo_atividade', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('id_ramo_atividade', 'dados_producao')"
                          dense
                          :filter="(degree, queryText) => (`${degree.codigo_atividade || ''} - ${(degree.ramo_atividade || '').toLocaleLowerCase()}`).indexOf(queryText.toLocaleLowerCase()) > -1"
                        >
                          <template #item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title v-text="`${item.codigo_atividade} - ${item.ramo_atividade}`" />
                            </v-list-item-content>
                          </template>

                          <template #selection="{item}">
                            {{ item.codigo_atividade }} - {{ item.ramo_atividade }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        md="2"
                        class="pl-1 pr-2"
                      >
                        <masked-text-field
                          v-model.number="dataProducer.cbt"
                          :mask="FloatNumberMask"
                          unmask
                          label="CPP"
                          :disabled="productionData"
                          dense
                        />
                      </v-col>
                      <v-col
                        md="2"
                        class="pl-1 pr-2"
                      >
                        <masked-text-field
                          v-model.number="dataProducer.ccs"
                          :mask="FloatNumberMask"
                          unmask
                          label="CCS"
                          :disabled="productionData"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('gordura', 'dados_producao')"
                        md="2"
                        class="pl-1 pr-2"
                      >
                        <masked-text-field
                          v-model.number="dataProducer.gordura"
                          :mask="FloatNumberMask"
                          unmask
                          label="Gordura"
                          :disabled="productionData"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('proteina', 'dados_producao')"
                        md="2"
                        class="pl-1 pr-2"
                      >
                        <masked-text-field
                          v-model.number="dataProducer.proteina"
                          :mask="FloatNumberMask"
                          unmask
                          label="Proteína"
                          :disabled="productionData"
                          dense
                        />
                      </v-col>
                      <v-col
                        md="4"
                        class="pl-1 pr-1"
                      >
                        <masked-text-field
                          v-model.number="dataProducer.litros"
                          :mask="FloatNumberMask"
                          unmask
                          label="Volume em litros"
                          :disabled="productionData"
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        v-if="isVisible('data_inicio_coleta', 'dados_producao')"
                        cols="12"
                        sm="6"
                        md="3"
                        class="pb-1"
                      >
                        <masked-text-field
                          v-model.trim="dataProducer.data_inicio_coleta"
                          prepend-inner-icon="event"
                          :label="`Data inicio coleta ${isRequired('data_inicio_coleta', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('data_inicio_coleta', 'dados_producao')"
                          :mask="DateMask"
                          maxlength="10"
                          dense
                          :return-unmasked="false"
                        />
                      </v-col>

                      <v-col
                        v-if="isVisible('data_fim_coleta', 'dados_producao')"
                        cols="12"
                        sm="6"
                        md="3"
                        class="pb-1"
                      >
                        <masked-text-field
                          v-model.trim="dataProducer.data_fim_coleta"
                          prepend-inner-icon="event"
                          :label="`Data fim coleta ${isRequired('data_fim_coleta', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('data_fim_coleta', 'dados_producao')"
                          :mask="DateMask"
                          maxlength="10"
                          dense
                          :return-unmasked="false"
                        />
                      </v-col>

                      <v-col
                        v-if="isVisible('motivo_fim_coleta', 'dados_producao')"
                        cols="12"
                        md="6"
                        class="pb-1"
                      >
                        <v-select
                          v-model="dataProducer.motivo_fim_coleta"
                          :items="[
                            { text: '1 - Morte', value: 'MORTE' },
                            { text: '2 - Desvio de Produção', value: 'DESVIO_DE_PRODUCAO' },
                            { text: '3 - Transferência', value: 'TRANSFERENCIA' },
                            { text: '4 - Parou Atividade', value: 'PAROU_ATIVIDADE' },
                          ]"
                          :label="`Motivo fim produção ${isRequired('motivo_fim_coleta', 'dados_producao') ? '*' : ''}`"
                          :rules="getRules('motivo_fim_coleta', 'dados_producao')"
                          dense
                          clearable
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        v-if="isVisible('cooling_type', 'dados_producao')"
                        class="pb-0"
                      >
                        <h4>
                          Tipo de resfriamento: {{ isRequired('cooling_type', 'dados_producao') ? ' *' : '' }}
                        </h4>
                        <v-row style="padding-left: 10px">
                          <v-col
                            md="12"
                            sm="12"
                          >
                            <v-radio-group
                              v-model="coolingType"
                              row
                            >
                              <v-radio
                                label="Tarro"
                                value="Tarro"
                              />
                              <v-radio
                                label="Expansão"
                                value="Expansao"
                              />
                              <v-radio
                                label="Imersão"
                                value="Imersao"
                              />
                              <v-radio
                                label="Comunitário"
                                value="Comunitario"
                              />
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        class="pb-0"
                      >
                        <h4>
                          Janela de Coleta
                        </h4>
                        <v-row style="padding-left: 10px">
                          <v-col
                            v-if="isVisible('hora_inicio_coleta', 'dados_producao')"
                            cols="1"
                          >
                            <masked-text-field
                              v-model="dataProducer.hora_inicio_coleta"
                              :label="`Hora Inicio ${isRequired('hora_inicio_coleta', 'dados_producao') && type === 'PROSPECTION' ? '*' : ''}`"
                              prepend-inner-icon="schedule"
                              :mask="{
                                mask: '00:00',
                              }"
                              :max-length="5"
                              :rules="generateTimeValidationRules('hora_inicio_coleta', 'dados_producao')"
                              :disabled="type !== 'PROSPECTION'"
                            />
                          </v-col>

                          <v-col
                            v-if="isVisible('hora_fim_coleta', 'dados_producao')"
                            cols="1"
                          >
                            <masked-text-field
                              v-model="dataProducer.hora_fim_coleta"
                              :label="`Hora Fim ${isRequired('hora_fim_coleta', 'dados_producao') && type === 'PROSPECTION' ? '*' : ''}`"
                              prepend-inner-icon="schedule"
                              :mask="{
                                mask: '00:00',
                              }"
                              :max-length="5"
                              :rules="generateEndTimeValidationRules('hora_inicio_coleta', 'hora_fim_coleta', 'dados_producao')"
                              :disabled="type !== 'PROSPECTION'"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        v-if="parseInt(dataProducer.quantidade_tanques || 0) > 0"
                        cols="12"
                        class="subtitle-1"
                      >
                        <div class="d-flex justify-between">
                          Resfriadores
                          <div class="ml-auto">
                            Capacidade total (L): {{ getTotalCoolersCapacity() }}
                          </div>
                        </div>
                        <v-divider />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        v-if="isGroupingType"
                        class="pb-0"
                      >
                        <h4>
                          Vinculação Grupo
                        </h4>
                        <v-row style="padding-left: 10px">
                          <v-col
                            cols="2"
                          >
                            <masked-text-field
                              v-model="dataProducer.data_inicio_vinculo_grupo"
                              :label="`Data Inicio de Vínculo`"
                              prepend-inner-icon="schedule"
                              :mask="{
                                mask: '00/00/0000',
                              }"
                              :rules="[v => !v || dateValidate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!']"
                            />
                          </v-col>

                          <v-col
                            cols="2"
                          >
                            <masked-text-field
                              v-model="dataProducer.data_fim_vinculo_grupo"
                              :label="`Data Fim de Vínculo`"
                              prepend-inner-icon="schedule"
                              :mask="{
                                mask: '00/00/0000',
                              }"
                              :rules="[v => !v || dateValidate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!']"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <transition-group
                      v-if="parseInt(dataProducer.quantidade_tanques || 0) > 0"
                      name="scroll-y-transition"
                    >
                      <v-row
                        v-for="number in parseInt(dataProducer.quantidade_tanques || 0)"
                        :key="number"
                        class="mt-0"
                      >
                        <v-col
                          class="py-0 flex-grow-1"
                        >
                          <v-row>
                            <v-col
                              cols="4"
                              class="pb-0"
                            >
                              <v-text-field
                                :value="number"
                                type="number"
                                label="Resfriador"
                                filled
                                dense
                                disabled
                              />
                            </v-col>
                            <v-col
                              cols="8"
                              class="pb-0"
                            >
                              <v-text-field
                                v-model="coolers[number - 1]"
                                type="number"
                                prepend-inner-icon="opacity"
                                label="Capacidade (L) *"
                                :rules="validation.rules.required"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </transition-group>
                  </div>
                </v-form>
              </v-col>
            </v-tab-item>

            <v-tab-item
              v-if="!isGroupingType"
              value="additional-tab"
            >
              <v-col>
                <v-form>
                  <div class="pa-2">
                    <v-row
                      v-if="isSpouseDataVisible"
                    >
                      <v-col cols="12">
                        <h4>Dados do Cônjuge</h4>
                      </v-col>
                      <v-col
                        v-if="isVisible('nome_conjuge', 'informacoes_adicionais')"
                        md="4"
                      >
                        <v-text-field
                          v-model="additionalData.conjuge.nome"
                          :label="`Nome ${isRequired('nome_conjuge', 'informacoes_adicionais') ? '*' : ''}`"
                          :rules="getRules('nome_conjuge', 'informacoes_adicionais')"
                          prepend-inner-icon="person"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('cpf_conjuge', 'informacoes_adicionais')"
                        md="2"
                      >
                        <masked-text-field
                          v-model="additionalData.conjuge.cpf"
                          :label="`CPF ${isRequired('cpf_conjuge', 'informacoes_adicionais') ? '*' : ''}`"
                          :rules="getRules('cpf_conjuge', 'informacoes_adicionais')"
                          :mask="CPFMask"
                          prepend-inner-icon="assignment_ind"
                          maxlength="14"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('rg_conjuge', 'informacoes_adicionais')"
                        md="2"
                      >
                        <v-text-field
                          v-model="additionalData.conjuge.rg"
                          :label="`N° de Identidade (RG) ${isRequired('rg_conjuge', 'informacoes_adicionais') ? '*' : ''}`"
                          :rules="getRules('rg_conjuge', 'informacoes_adicionais')"
                          prepend-inner-icon="assignment_ind"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('orgao_expedidor_conjuge', 'informacoes_adicionais')"
                        md="1"
                      >
                        <v-text-field
                          v-model="additionalData.conjuge.orgao_expedidor_rg"
                          :label="`Órgão expedidor ${isRequired('orgao_expedidor_conjuge', 'informacoes_adicionais') ? '*' : ''}`"
                          :rules="getRules('orgao_expedidor_conjuge', 'informacoes_adicionais')"
                          prepend-inner-icon="assignment_ind"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('uf_expedicao_conjuge', 'informacoes_adicionais')"
                        md="1"
                      >
                        <v-text-field
                          v-model="additionalData.conjuge.uf_rg"
                          :label="`UF Expedição ${isRequired('uf_expedicao_conjuge', 'informacoes_adicionais') ? '*' : ''}`"
                          :rules="getRules('uf_expedicao_conjuge', 'informacoes_adicionais')"
                          prepend-inner-icon="assignment_ind"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="isParentsDataVisible"
                    >
                      <v-col cols="12">
                        <h4>Dados dos Pais</h4>
                      </v-col>
                      <v-col
                        v-if="isVisible('nome_pai', 'informacoes_adicionais')"
                        md="4"
                      >
                        <v-text-field
                          v-model="additionalData.pais.nome_pai"
                          :label="`Nome do Pai ${isRequired('nome_pai', 'informacoes_adicionais') ? '*' : ''}`"
                          :rules="getRules('nome_pai', 'informacoes_adicionais')"
                          prepend-inner-icon="person"
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('nome_mae', 'informacoes_adicionais')"
                        md="4"
                      >
                        <v-text-field
                          v-model="additionalData.pais.nome_mae"
                          :label="`Nome da Mãe ${isRequired('nome_mae', 'informacoes_adicionais') ? '*' : ''}`"
                          :rules="getRules('nome_mae', 'informacoes_adicionais')"
                          prepend-inner-icon="person"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="isAdditionalDataVisible"
                    >
                      <v-col cols="12">
                        <h4>Informações Adicionais</h4>
                      </v-col>

                      <v-col
                        v-if="isVisible('produtor_assina_contrato', 'informacoes_adicionais')"
                        cols="3"
                        md="2"
                      >
                        <v-switch
                          v-model="dataProducer.produtor_assina_contrato"
                          :label="`Contrato Assinado? ${isRequired('produtor_assina_contrato', 'informacoes_adicionais') ? '*' : ''}`"
                          dense
                          class="mt-0"
                        />
                      </v-col>
                      <v-col
                        v-if="isVisible('associado', 'informacoes_adicionais')"
                        cols="3"
                        md="2"
                      >
                        <v-switch
                          v-model="dataProducer.associado"
                          :label="`Produtor Associado? ${isRequired('associado', 'informacoes_adicionais') ? '*' : ''}`"
                          dense
                          class="mt-0"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        class="d-flex"
                      >
                        <h4>Dependentes</h4>

                        <v-spacer />

                        <v-btn
                          fab
                          small
                          color="blue"
                          dark
                          @click="onDependentAddClick"
                        >
                          <v-icon dark>
                            add
                          </v-icon>
                        </v-btn>
                      </v-col>

                      <v-col
                        v-if="isVisible('cpf_nome_filhos', 'informacoes_adicionais')"
                        class="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="additionalData.filhos"
                          :label="`Dados dos filhos ${isRequired('cpf_nome_filhos', 'informacoes_adicionais') ? '*' : ''}`"
                          :rules="getRules('cpf_nome_filhos', 'informacoes_adicionais')"
                          dense
                        />
                      </v-col>

                      <v-col
                        md="12"
                      >
                        <data-table
                          :headers="dependentsHeaders"
                          :items="filteredDependents"
                          class="elevation-1"
                          disable-filtering
                          multi-sort
                          item-key="id_pessoa"
                          hide-default-footer
                          disable-pagination
                          @click:row="onDependentEditClick"
                        >
                          <template #[`item.sexo`]="{ value }">
                            {{ genders[value] }}
                          </template>

                          <template #[`item.actions`]="{ item }">
                            <v-menu>
                              <template #activator="{ on }">
                                <v-btn
                                  icon
                                  v-on="on"
                                >
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item
                                  @click="onDependentEditClick(item)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>
                                      edit
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Editar
                                  </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                  @click="onDependentExcludeClick(item)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>
                                      delete
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Excluir
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </template>
                        </data-table>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-col>
            </v-tab-item>

            <v-tab-item
              v-if="!isGroupingType"
              value="negotiation-tab"
            >
              <v-container
                fluid
                grid-list-md
                text-xs-center
                class="flex-wrap"
              >
                <historical-tab
                  :historical="historical"
                />
              </v-container>
            </v-tab-item>

            <v-tab-item
              v-if="!isGroupingType"
              value="attachments-tab"
            >
              <v-container
                fluid
                grid-list-md
                text-xs-center
                class="flex-wrap"
              >
                <attachments-tab
                  :attachments="attached"
                  show-upload
                  @fileRemoved="updateProducerAttachment"
                />
              </v-container>
            </v-tab-item>

            <v-tab-item
              value="historic-tab"
            >
              <v-container
                fluid
                grid-list-md
                text-xs-center
              >
                <historical-status
                  v-if="tab === 'historic-tab'"
                  :history="historico_status"
                  :producer-id="producerId"
                  :producer="dataProducer"
                  @save="statusDialogClose"
                />
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>

        <small
          v-if="tab != 'historico-tab'"
          class="caption ml-4 mb-1"
        >
          * Campo obrigatório
        </small>
        <v-divider class="ma-0" />
        <v-card-actions
          class="pa-1"
          width="90px"
        >
          <template v-if="type === 'PROSPECTION'">
            <v-btn
              class="mx-2"
              text
              color="teal"
              @click="openLinkDialog()"
            >
              Vincular o Produtor
            </v-btn>
          </template>
          <v-spacer />
          <v-btn
            color="blue-grey darken-1"
            style="font-size:16px"
            text
            @click.stop="
              show = false;
              tab = 0;
            "
          >
            Cancelar
          </v-btn>
          <template v-if="tab != 'historico-tab'">
            <v-btn
              color="blue darken-3"
              text
              style="font-size:16px"
              @click.stop="handleSaveProducer"
            >
              Salvar
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>

      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>

    <status-producer-dialog
      ref="statusProducerDialog"
      @save="statusProducerClose"
    />

    <print-settings-dialog
      ref="print-settings"
      @print="print"
    />

    <dependent-dialog
      ref="dependentDialog"
      :kinship-degrees="kinshipDegrees"
      @save="onDependentSave"
    />
  </div>
</template>

<style lang="scss">

.dialog-card {
  display: flex;
  flex-direction: column;
}

.label-required {
  position: absolute;
  bottom: 50px;
  left: 25px;
}

</style>
<script>
import _ from "lodash";
import moment from "moment";
import printJS from "print-js";
import { v4 as uuidv4 } from 'uuid';

import AddressTab from "@/Domains/Producer/Management/Components/Form/AddressTab.vue";
import PersonalDataTab from "@/Domains/Producer/Management/Components/Form/PersonalDataTab.vue";
import FinancialDataTab from "@/Domains/Producer/Management/Components/Form/FinancialDataTab.vue";
import FiscalDataTab from "@/Domains/Producer/Management/Components/Form/FiscalDataTab.vue";
import HistoricalTab from "@/Domains/Producer/Management/Components/Form/HistoricalTab.vue";
import AttachmentsTab from "@/Domains/Registrations/Person/Components/AttachmentsTab.vue";
import HistoricalStatus from "@/Domains/Producer/Management/Components/Form/HistoricalStatus.vue";
// import Evolution from "@/Domains/Producer/Management/Components/Form/Evolution.vue";

import StatusProducerDialog from "@/Domains/Producer/Management/Components/Dialogs/StatusProducerDialog.vue";
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import DairyCompetitorAutocompleteFilter from "@/Support/Components/Filters/DairyCompetitorAutocompleteFilter.vue";

import DependentDialog from "@/Domains/Producer/Management/Components/Dialogs/DependentDialog.vue";

const GenderEnum = {
  'M': 'Masculino',
  'F': 'Feminino',
}

export default {
  name: "InfoProducer",

  components: {
    AddressTab,
    PersonalDataTab,
    FinancialDataTab,
    FiscalDataTab,
    HistoricalTab,
    // Evolution,
    AttachmentsTab,
    StatusProducerDialog,
    PrintSettingsDialog,
    HistoricalStatus,
    MaskedTextField,
    DependentDialog,
    DairyCompetitorAutocompleteFilter,
  },

  props: {
    visible: Boolean,
    producerId: String,
    linkProducer: Boolean,
    openLink: Boolean,
    showPassword: Boolean,
    type: {
      type: String,
      default: "ACTIVE",
      validator: function(value) {
        return ["ACTIVE", "UNLINKED", "PROSPECTION", "SUSPENDED", "CONDOMINIUM", "COMMUNITY-TANK", "FAMILY-GROUP"].includes(value);
      }
    },
    sourceScreen: {
      type: String,
    },
  },

  data() {
    return {
      attached: [],

      historical: [],

      activetab: "",

      loading: false,

      dataProducer: {},

      groupSettings: {},

      coolers: [],

      dairies: [],

      accessUsers: [],

      lineOfBusiness: [],

      editingUserAccess: false,

      valid: {
        personal: true,
        address: true,
        financial: true,
        fiscal: true,
        dataProduction: true,
      },

      // Flag para verificar se é necessário salvar antes de efetuar algum evento como vincular produtor
      needSave: false,

      isLinking: false,

      //tarro | expansao | imersao | comunitario
      coolingType: null,

      dateSign: moment().format("YYYY-MM-DD"),

      tab: null,

      searchRegion: null,

      loadingRegion: false,

      regionItems: [],

      routes: {},

      genders: GenderEnum,

      DateMask: {
        mask: "00/00/0000",
        maxlength: 14,
      },

      CPFMask: {
        mask: "000.000.000-00",
        maxlength: 14,
      },

      PhoneMask: {
        mask: "(00) 00000-0000",
      },

      IntNumberMask: {
        mask: Number,
        scale: 0,
        thousandsSeparator: '.',
        min: -999999,
        max: 999999,
      },

      FloatNumberMask: {
        mask: Number,
        scale: 2,
        thousandsSeparator: '.',
        min: -999999,
        max: 999999,
      },

      everHadTax: false,

      // Dados de desconto
      tax: {
        local: false,
        fundesa: 0,
        administrativa: 0,
        senar: 0,
        funrural: 0,
        rat: 0,
        frete: 0,
        inss: 0,
      },

      fiscal: {
        operationType: null,
        series: null,
        environment: null,
        certificate: null,
      },

      kinshipDegrees: [],

      dependentsHeaders: [
        { text: "Código", value: "codigo_laticinio", width: 30 },
        { text: "Nome", value: "nome", width: 140 },
        { text: "CPF", value: "cnpj_cpf", align: 'center', width: 140 },
        { text: "Parentesco", value: "parentesco.grau_parentesco", align: 'center', width: 140 },
        { text: "Data de Nascimento", value: "data_nascimento", align: 'center', width: 140 },
        { text: "Sexo", value: "sexo", align: 'center', width: 140 },
        { text: "", value: "actions", align: 'center', width: 30 },
      ],

      additionalData: {
        conjuge: {
          nome: '',
          cpf: '',
          rg: '',
          uf_rg: '',
          orgao_expedidor_rg: '',
        },
        filhos: '',
        pais: {
          nome_pai: '',
          nome_mae: '',
        }
      },

      // Dependentes
      dependents: [],

      validation: {
        rules: {
          required: [(v) => !!v || "Campo obrigatório"],
        },
      },

      dataAlternativeBank: [{}, {}],

      mainAccount: 0,
      tributacoes: {
        dia_pagamento: null,
        ignorar_bonificacao: null,
        adiantamento: null,
        tributacao: "ISENTO",
        desconta_senar: "SIM",
        debita_inss: "SIM",
      },

      defaultRequiredFields: [],

      historico_status: [],

      data_cadastro: null,

      tipos_ordenha_options: [
        { text: 'ORDENHA MANUAL',   value: 'Manual'   },
        { text: 'ORDENHA MECANIZADA', value: 'Mecanizada' }
      ],

      financialTabIsVisible: false,
    };
  },
  computed: {
    //
    show: {
      get() {
        /**
         * consulta os dados do produtor
         */
        if (this.visible) {
          this.loadData();
        }

        return this.visible;
      },
      set(value) {
        if (!value) {
          this.dataProducer = {};
          this.attached = [];
          this.historico_status = [];
          this.coolers = [];
          this.tab = 0;
          this.isLinking = false;

          this.$emit("close");
          return false;
        }
      },
    },

    /**
     * @returns {Boolean}
     * Verifica se existe algum formulário com erro
     */
    formsValid() {
      if (!this.valid.personal) {
        return {
          hasError: true,
          tab: "personal-tab",
        };
      }

      if (!this.valid.address) {
        return {
          hasError: true,
          tab: "address-tab",
        };
      }

      if (!this.valid.financial) {
        return {
          hasError: true,
          tab: "financial-tab",
        };
      }

      if (!this.valid.fiscal) {
        return {
          hasError: true,
          tab: "fiscal-tab",
        };
      }

      if (!this.valid.dataProduction) {
        return {
          hasError: true,
          tab: 4,
        };
      }

      return {
        hasError: false,
      };
    },

    isGroupingType() {
      return ['CONDOMINIUM', 'COMMUNITY-TANK', 'FAMILY-GROUP'].includes(this.type);
    },

    isSpouseDataVisible() {
      const fields = [
        'nome_conjuge',
        'cpf_conjuge',
        'rg_conjuge',
        'orgao_expedidor_conjuge',
        'uf_expedicao_conjuge',
      ];

      return fields.some(field => this.isVisible(field, 'informacoes_adicionais'));
    },

    isParentsDataVisible() {
      const fields = [
        'nome_pai',
        'nome_mae',
      ];

      return fields.some(field => this.isVisible(field, 'informacoes_adicionais'));
    },

    isAdditionalDataVisible() {
      const fields = [
        'produtor_assina_contrato',
        'associado',
      ];

      return fields.some(field => this.isVisible(field, 'informacoes_adicionais'));
    },

    settings() {
      if (_.isEmpty(this.$store.state.settings.gerais.cadastro_produtor)) {
        return {
          dados_producao: {},
          informacoes_adicionais: {},
        };
      }

      return this.$store.state.settings.gerais.cadastro_produtor;
    },

    productionData() {
      if (this.type === 'PROSPECTION') {
        return false;
      }

      return true;
    },

    accessDrivers() {
      if (!this.dataProducer.pessoa_acesso) {
        return [];
      }

      return this.dataProducer.pessoa_acesso
        .filter(person => person.id_cargo === 6)
        .map(person => person.nome_usuario);
    },

    accessTechnicians() {
      if (!this.dataProducer.pessoa_acesso) {
        return [];
      }

      return this.dataProducer.pessoa_acesso
        .filter(person => [3, 4, 15, 16].includes(person.id_cargo))
        .map(person => person.nome_usuario);
    },

    accessOthersUsers() {
      if (!this.dataProducer.pessoa_acesso) {
        return [];
      }

      return this.dataProducer.pessoa_acesso
        .filter(person => ![3, 4, 15, 16, 6].includes(person.id_cargo))
        .map(person => person.nome_usuario);
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasFiscalAccess() {
      if (this.type === 'PROSPECTION' || this.dataProducer.tipo_emissao_nota !== 'MILKUP') {
        return false;
      }

      return this.isAdmin || this.userResources.some(o => o.recurso === 'producer-fiscal-access' && o.tipo === 'COMPONENTE');
    },

    filteredDependents() {
      return this.dependents.filter(dependent => !dependent.excluido);
    },
  },
  watch: {
    'dataProducer.cnpj_cpf': function(val) {
      this.setTax(val);
    },

    'groupSettings.bonificacao_individual'(newValue) {
      this.financialTabIsVisible = !newValue;

      if (this.financialTabIsVisible && this.dataProducer.cnpj_cpf) {
        if (!this.dados_forma_pagamento) {
          this.onFillProducerAccount(this.dataProducer.cnpj_cpf);
        }
      } else {
        this.clear();
      }
    }
  },
  methods: {
    async loadData() {
      this.editingUserAccess = false;

      await Promise.all([
        this.loadProducer(),
        this.getRegion(),
        this.loadDairies(),
        this.loadAccessUsers(),
        this.loadKinshipDegrees(),
        this.loadLineOfBusiness(),
      ])

      if (!this.producerId) {
        this.setTax();
      }

      if (this.linkProducer && this.openLink) {
        await this.$nextTick();
        this.openLinkDialog();
      }
    },

    isVisible(field, section) {
      if (!this.settings[section][field]) {
        return true;
      }

      return this.settings[section][field].show;
    },

    isRequired(field, section) {
      if (!this.settings[section][field]) {
        return this.defaultRequiredFields.includes(field);
      }

      return this.settings[section][field].required;
    },

    getRules(field, section) {
      const rules = [];

      if (this.isRequired(field, section)) {
        rules.push(v => !!v || "Campo obrigatório");
      }

      return rules;
    },

    disableDotAndComma(e) {
      if (e.charCode === 46 || e.charCode === 44) {
        e.preventDefault();
      }
    },
    async loadProducer() {
      try {

        // Verifica se existe id do produtor
        if (!this.producerId) {
          if (this.isGroupingType) {
            this.dataProducer = {
              condominio: []
            };
          }
          return false;
        }

        this.loading = true;

        const { data } = await this.$axios.post(
          `/produtores/produtorDetalhe`,
          { id_pessoa: this.producerId }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.handleData(data);
      } catch (e) {
        this.$snotify.error("Erro ao carregar informações do produtor", "Atenção");
        console.error(e);
      } finally {
        this.loading = false;
        await this.$nextTick();
        await (this.$refs.personalForm && this.$refs.personalForm.getNextProducerCode());
      }
    },

    /**
     * @void
     * Trata as informações do produtor
     */
    handleData(data) {

      // Caso for vazio não é necessário fazer tratamento
      if (_.isEmpty(data)) {
        return false;
      }

      this.dataProducer = data;
      this.data_cadastro = data.data_entrada_laticinio;
      this.attached = data.anexos;
      this.historical = data.historico;
      this.dataProducer.pessoa_acesso = data.pessoa_acesso;
      this.coolingType = data.tipo_resfriador ? _.upperFirst(data.tipo_resfriador) : null;
      this.dataProducer.data_inicio_coleta = data.data_inicio_coleta ? moment(data.data_inicio_coleta).format('DD/MM/YYYY') : null;
      this.dataProducer.data_fim_coleta = data.data_fim_coleta ? moment(data.data_fim_coleta).format('DD/MM/YYYY') : null;
      this.dataProducer.data_inicio_vinculo_grupo = data.data_inicio_vinculo_grupo ? moment(data.data_inicio_vinculo_grupo).format('DD/MM/YYYY') : null;
      this.dataProducer.data_fim_vinculo_grupo = data.data_fim_vinculo_grupo ? moment(data.data_fim_vinculo_grupo).format('DD/MM/YYYY') : null;
      this.dataProducer.data_nascimento = !_.isEmpty(data.data_nascimento)
        ? moment(_.get(data, "data_nascimento")).format("DD/MM/YYYY")
        : null;
      this.dataProducer.data_cadastro_app = !_.isEmpty(data.data_cadastro_app) ? moment(_.get(data, "data_cadastro_app")).format("DD/MM/YYYY") : null;
      this.dataProducer.data_entrada_laticinio = !_.isEmpty(data.data_entrada_laticinio) ? moment(_.get(data, "data_entrada_laticinio")).format("DD/MM/YYYY") : null;
      this.historico_status = JSON.parse(data.historico_status || '[]');
      this.dataProducer.hora_inicio_coleta = data.hora_inicio_coleta;
      this.dataProducer.hora_fim_coleta = data.hora_fim_coleta;

      this.dataProducer.tipo_emissao_nota = data.tipo_emissao_nota;
      this.dataProducer.tipo_certificado = data.tipo_certificado;
      this.fiscal.operationType = data.tipo_natureza_operacao;
      this.fiscal.series = data.serie_nfpe;

      this.dataProducer.password = {
        current: "",
        rewhite: "",
      }

      this.dataProducer.cooperativa = data.cooperativa;
      this.dataProducer.condominio = data.condominio;

      this.tributacoes.dia_pagamento = data.dia_pagamento;
      this.tributacoes.ignorar_bonificacao = data.ignorar_bonificacao;
      this.tributacoes.adiantamento = data.adiantamento;
      this.tributacoes.tributacao = data.tributacoes;
      this.tributacoes.desconta_senar = data.desconta_senar;
      this.tributacoes.debita_inss = data.debita_inss;

      if (this.dataProducer.id_laticinio_concorrente) {
        this.dataProducer.laticinio_concorrente = {
          id: this.dataProducer.id_laticinio_concorrente,
        }
      }

      /**
       * Caso for produtor prospectado deve pegar a rota da pessoa
       */
      if (_.get(data, "ativo") === 0 && !_.isEmpty(data.rota)) {
        this.dataProducer.rotas = [
          {
            id_rota: "",
            descricao: _.get(data, "rota"),
          },
        ];
      }

      if (!_.isEmpty(data.dados_forma_pagamento)) {
        let bank = data.dados_forma_pagamento;

        if (_.isArray(bank)) {
          bank = _.head(bank);
        }

        this.dataAlternativeBank.splice(0, 3, {
          banco: { code: bank.codigo_banco, name: bank.banco },
          agencia: bank.agencia,
          nome_titular: bank.nome_titular,
          cnpj_cpf: bank.cnpj_cpf,
          conta: bank.conta,
          tipo_conta: bank.tipo_conta,
          forma_pagamento: bank.forma_pagamento,
          tipo_chave: bank.tipo_chave,
          chave: bank.chave,
        });
      }

      const contas_alternativas = data.contas_alternativas || [];

      if (contas_alternativas.length === 0) {
        contas_alternativas.push({});
      }

      this.dataAlternativeBank.splice(1, 1, ...contas_alternativas.map(bank => ({
        banco: { code: bank.codigo_banco, name: bank.banco },
        agencia: bank.agencia,
        nome_titular: bank.nome_titular,
        cnpj_cpf: bank.cnpj_cpf,
        conta: bank.conta,
        tipo_conta: bank.tipo_conta,
        forma_pagamento: bank.forma_pagamento,
        tipo_chave: bank.tipo_chave,
        chave: bank.chave,
      })));

      if (!_.isEmpty(data.taxas)) {
        let discount = data.taxas;

        if (_.isArray(discount)) {
          discount = _.head(discount);
        }

        this.tax = {
          local: data.config_taxas_local || false,
          senar: _.get(discount, "senar"),
          fundesa: _.get(discount, "fundesa"),
          funrural: _.get(discount, "funrural"),
          rat: _.get(discount, "rat"),
          administrativa: _.get(discount, "administrativa"),
          frete: _.get(discount, "frete"),
          inss: _.get(discount, "inss"),
        };

        this.everHadTax = true;
      } else {
        this.setTax();
      }

      const dependents = data.dependentes || [];

      this.dependents = dependents.map(dependent => {
        const parentesco = this.kinshipDegrees.find((kinshipDegree) => kinshipDegree.id_grau_parentesco === dependent.id_grau_parentesco) || null;

        return {
          ...dependent,
          parentesco,
          data_nascimento: moment(dependent.data_nascimento).format('DD/MM/YYYY'),
        }
      })

      this.additionalData = {
        conjuge: {
          nome: data.informacoes_adicionais?.conjuge?.nome || '',
          cpf: data.informacoes_adicionais?.conjuge?.cpf || '',
          rg: data.informacoes_adicionais?.conjuge?.rg || '',
          uf_rg: data.informacoes_adicionais?.conjuge?.uf_rg || '',
          orgao_expedidor_rg: data.informacoes_adicionais?.conjuge?.orgao_expedidor_rg || '',
        },
        filhos: data.informacoes_adicionais?.filhos || '',
        pais: {
          nome_pai: data.informacoes_adicionais?.pais?.nome_pai || '',
          nome_mae: data.informacoes_adicionais?.pais?.nome_mae || '',
        }
      };

      this.coolers = this.dataProducer.resfriadores_tanque_comunitario || [];

      if (this.isGroupingType) {
        this.groupSettings = {
          ...(this.dataProducer.configuracoes_agrupamento || {})
        };

        this.dataProducer.quantidade_tanques = this.coolers.length || null;
      } else {
        if (this.dataProducer.capacidade_resf_inte > 0) {
          if (!this.dataProducer.quantidade_tanques) {
            this.dataProducer.quantidade_tanques = 1;
          }

          if (this.coolers.length === 0) {
            const totalCapacity = parseInt(this.dataProducer.capacidade_resf_inte);
            const tanksQty = parseInt(this.dataProducer.quantidade_tanques);
            // Capacidade de cado tanque
            const capacity = Math.trunc(totalCapacity / tanksQty);
            // Diferença dos tanques
            const rest = totalCapacity - (capacity * tanksQty);
            // Na último tanque deve ser adicionado a diferença
            const lastCapacity = parseInt(capacity + rest);

            for (let i = 0; i < tanksQty; i++) {
              const value = i < (tanksQty - 1) ? capacity : lastCapacity;
              this.coolers.push(value);
            }
          }
        }
      }
    },

    async handleSaveProducer() {
      try {
        this.validatingForms();

        // Verifica se todos os campos foram preenchidos corretamente
        if (this.formsValid.hasError && (this.type !== "PROSPECTION" || this.isLinking)) {
          this.switchTab(this.formsValid.tab);
          this.$snotify.error("Oops, Existem Campos obrigatórios não preenchidos!", "Atenção");
          return;
        }

        this.loading = true;

        const payload = { ...this.dataProducer };

        const dependents = this.dependents.map(({ parentesco, ...rest }) => {
          const data = {
            ...rest,
            id_grau_parentesco: parentesco ? parentesco.id_grau_parentesco : null,
            data_nascimento: moment(rest.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          };

          return data;
        })

        payload.dia_pagamento = this.payDay;

        payload.informacoes_adicionais = JSON.stringify(this.additionalData);
        payload.dependentes = JSON.stringify(dependents);
        payload.laticinio = this.dairies.find(unit => unit.id_pessoa === this.dataProducer.id_laticinio)?.nome;

        // Verifica se é necessário enviar a senha
        if (this.showPassword) {
          payload.senha = _.get(this.dataProducer, 'password.current');
        }

        // Verificas se foi selecionado cooperativa e atribui aos campos certos
        payload.id_cooperativa  = this.dataProducer?.cooperativa?.id_cooperativa;
        payload.cooperativa  = this.dataProducer?.cooperativa?.nome_cooperativa;

        if (this.dataProducer.condominio) {
          payload.condominio = this.dataProducer.condominio.map((person) => {
            return {
              id_pessoa: person.id_pessoa,
              nome: person.nome,
              codigo_laticinio: person.codigo_laticinio,
              percentual: parseFloat(person.percentual) || null,
            };
          });
        }

        const dataAlternativeBank = [ ...this.dataAlternativeBank ];
        const [dataBank] = dataAlternativeBank.splice(this.mainAccount, 1);

        payload.dados_forma_pagamento = JSON.stringify({
          ...dataBank,
          codigo_banco: _.get(dataBank.banco, 'code'),
          banco: _.get(dataBank.banco, 'name')
        });

        payload.contas_alternativas = dataAlternativeBank.map(item => ({
          codigo_banco: _.get(item.banco, 'code'),
          banco: _.get(item.banco, 'name'),
          agencia: item.agencia,
          nome_titular: item.nome_titular,
          cnpj_cpf: item.cnpj_cpf,
          conta: item.conta,
          tipo_conta: item.tipo_conta,
          forma_pagamento: item.forma_pagamento,
          tipo_chave: item.tipo_chave,
          chave: item.chave,
        }));

        // caso o dialog for referente a um cadatro de condominio ou tanque comunitário
        if (this.isGroupingType) {
          payload.tipo_condominio = 1;
        }

        // é necessário remover os que não são necessário enviar
        delete payload.password;
        delete payload.historico;

        payload.tipo_resfriador = this.coolingType;
        payload.tipo_forma_pagamento = dataBank.forma_pagamento == 'Deposito' ? 1 : dataBank.forma_pagamento == 'Cheque' ? 2 : (dataBank.forma_pagamento == 'Pix' ? 3 : null);
        payload.tipo_conta = dataBank.tipo_conta == 'CONTA_CORRENTE' ? 1 : dataBank.tipo_conta == 'CONTA_POUPANCA' ? 2 : (dataBank.tipo_conta == 'CONTA_SALARIO' ? 3 : null);
        payload.pessoa_acesso = JSON.stringify(this.dataProducer.pessoa_acesso);
        payload.condominio = JSON.stringify(this.dataProducer.condominio);
        payload.data_inicio_coleta = !this.dataProducer.data_inicio_coleta ? null : moment(this.dataProducer.data_inicio_coleta, 'DD/MM/YYYY').format('YYYY-MM-DD');
        payload.data_fim_coleta = !this.dataProducer.data_fim_coleta ? null : moment(this.dataProducer.data_fim_coleta, 'DD/MM/YYYY').format('YYYY-MM-DD');
        payload.data_inicio_vinculo_grupo = !this.dataProducer.data_inicio_vinculo_grupo ? null : moment(this.dataProducer.data_inicio_vinculo_grupo, 'DD/MM/YYYY').format('YYYY-MM-DD');
        payload.data_fim_vinculo_grupo = !this.dataProducer.data_fim_vinculo_grupo ? null : moment(this.dataProducer.data_fim_vinculo_grupo, 'DD/MM/YYYY').format('YYYY-MM-DD');
        payload.data_nascimento = _.isEmpty(this.dataProducer.data_nascimento) ? null : moment(this.dataProducer.data_nascimento, "DD/MM/YYYY").format("YYYY-MM-DD");
        payload.anexos = JSON.stringify(this.dataProducer.anexos);
        payload.config_taxas_local = this.tax.local || false;
        payload.taxas = this.tax.local ? JSON.stringify({
          senar: _.get(this.tax, 'senar'),
          fundesa: _.get(this.tax, 'fundesa'),
          funrural: _.get(this.tax, 'funrural'),
          rat: _.get(this.tax, 'rat'),
          administrativa: _.get(this.tax, 'administrativa'),
          frete: _.get(this.tax, 'frete'),
        }) : null;

        payload.dia_pagamento = this.tributacoes.dia_pagamento;
        payload.ignorar_bonificacao = this.tributacoes.ignorar_bonificacao;
        payload.adiantamento = this.tributacoes.adiantamento;
        payload.tributacoes = this.tributacoes.tributacao;
        payload.desconta_senar = this.tributacoes.desconta_senar;
        payload.debita_inss = this.tributacoes.debita_inss;
        payload.data_cadastro_app = _.isEmpty(this.dataProducer.data_cadastro_app) ? null : moment(this.dataProducer.data_cadastro_app, "DD/MM/YYYY").format("YYYY-MM-DD");
        payload.data_entrada_laticinio = _.isEmpty(this.dataProducer.data_entrada_laticinio) ? null : moment(this.dataProducer.data_entrada_laticinio, "DD/MM/YYYY").format("YYYY-MM-DD");
        payload.historico_status = JSON.stringify(this.historico_status);

        payload.tipo_emissao_nota = this.dataProducer.tipo_emissao_nota;
        payload.tipo_certificado = this.dataProducer.tipo_certificado;
        payload.tipo_natureza_operacao = this.fiscal.operationType;
        payload.serie_nfpe = this.fiscal.series;

        if (this.isGroupingType) {
          const types = {
            'CONDOMINIUM': 'CONDOMINIO',
            'COMMUNITY-TANK': 'TANQUE',
            'FAMILY-GROUP': 'FAMILIAR',
          };

          payload.grupo = types[this.type];

          if (!this.groupSettings.bonificacao_individual) {
            this.groupSettings.valor_retencao_por_litro = undefined;
          }

          payload.configuracoes_agrupamento = JSON.stringify(this.groupSettings);

          payload.tanque_comunitario = JSON.stringify(this.dataProducer.tanque_comunitario);
        }

        payload.resfriadores_tanque_comunitario = JSON.stringify(this.coolers.slice(0, this.dataProducer.quantidade_tanques));
        payload.capacidade_resf_inte = this.getTotalCoolersCapacity();

        if (!_.isEmpty(this.producerId)) {
          payload.id_pessoa = this.producerId;
        }

        const response = await this.saveProducer(payload);

        if (_.isEmpty(this.producerId) ) {
          const id_pessoa = response.id_pessoa;

          if (!_.isEmpty(id_pessoa)) {
            await this.$axios.post(`/produtores/atualizaStatusProdutor`, {
              id_produtor: id_pessoa,
              nome_produtor: payload.nome,
              status: payload.alreadyRegistered ? "PENDENTE" : "APROVADO",
              altera_status_atual_produtor: true,
            });

            if (payload.alreadyRegistered) {
              this.$emit('pending', id_pessoa);
            }
          }
        }

        await this.saveFiscalSettings();

        if (this.type === "PROSPECTION" && !this.isLinking) {
          this.show = false;
        }

        if (!this.isLinking) {
          this.$emit("onSave");
        }

      } catch (error) {
        console.log(error);
        this.$snotify.error("Erro ao salvar informações do produtor", "Atenção");

      } finally {
        this.loading = false;
      }
    },

    /**
     * @void
     *
     * Insere ou atualiza informações do produtor
     */
    async saveProducer(dataProducer) {
      const { data } = await this.$axios.post(
        `/produtores/salvaProdutor`,
        {
          produtor: [ dataProducer ],
        }
      );

      if (!_.isObject(data)) {
        throw new Error(data);
      }

      const response = _.head(data);

      if (_.get(response, "codigo") !== 1) {
        throw new Error(response.mensagem || data);
      }

      this.needSave = false;

      this.resetFormsValidations();

      this.$snotify.success("Informações salvas com sucesso", "Sucesso");

      return response;
    },

    async saveFiscalSettings() {
      if (!this.hasFiscalAccess || !this.producerId) {
        return ;
      }

      const payLoad = {
        id_empresa: this.producerId,
        tipo: 'PRODUTOR',
        ambiente: this.fiscal.environment,
        regime_tributario: 3, // NORMAL
        certificado_digital: this.fiscal.certificate,
      };

      if (this.fiscal.password) {
        payLoad.senha_certificado = this.fiscal.password
      }

      await this.$axios.post(`/fiscal/settings`, payLoad);
    },

    /**
     *
     */
    switchTab(tab) {
      this.tab = tab;
    },

    update(newTags) {
      this.autocompleteItems = [];
      this.tags = newTags;
    },

    resetFormsValidations() {
      _.forEach(this.$refs, (ref) => {
        if (_.has(ref, 'resetValidation')) {
          ref.resetValidation();
        }
      });
    },

    async getRegion() {
      try {
        const { data } = await this.$axios.post(`/regiao/listaJson`);

        this.regionItems = data.map((data) => {
          return data.nome;
        });
      } catch (error) {
        console.warn(error);
      }
    },

    openLinkDialog() {
      this.isLinking = true;

      this.validatingForms();

      // Verifica se todos os campos foram preenchidos corretamente
      if (this.formsValid.hasError) {
        this.switchTab(this.formsValid.tab);
        this.needSave = true;
        this.$snotify.error("Oops, Existem Campos obrigatórios não preenchidos!", "Atenção");
        return false;
      }

      // Verificar se existe informações que não foram salvas no banco e estão apenas em tela
      if (this.needSave) {
        this.$snotify.info("Oops, Algumas informações não foram salvas em nossa base! Por favor, Salve antes de vincular produtor ", "Atenção");
        return false;
      }

      this.isLinking = false;

      return this.$refs.statusProducerDialog.show({
        title: 'Vincular Produtor',
        producer: {
          id: this.producerId,
          name: this.dataProducer.nome,
        },
        form: {
          status: 'APROVADO',
          params: {
            showStatusSelect: false,
            minInputDateLimit: null,
            maxInputDateLimit: null,
          },
        }
      });
    },

    toPrint(id) {
      this.$refs['print-settings'].show({
        item: {
          id
        },
        params: [
          { title: 'Endereço', key: 'mostra_endereco', value: true },
          { title: 'Dados de Pagamento', key: 'mostra_dados_pagamento', value: true },
          { title: 'Dados de Produção', key: 'mostra_dados_producao', value: true },
          { title: 'Checklist Prospecção', key: 'mostra_checklist', value: false },
          { title: 'Documentos assinados', key: 'mostra_documentos_assinados', value: false },
          { title: 'Preço/Negociação', key: 'mostra_negociacoes_preco', value: false },
          { title: 'Visita Prospecção', key: 'mostra_ultima_visita', value: false },
        ]
      });
    },

    async print({ item,  params }) {
      try {
        this.loading = true;

        if (_.isEmpty(item.id)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/produtores/printDetalheProdutor`,
          {
            id_produtor: item.id,
            params
          }
        );

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * @void
     * Captura evento de validação do form Components
     */
    onValidationTab(event) {
      _.set(this.valid, `${event.tab}`, event.valid);
    },

    /**
     * Valida os formulários
     */
    async validatingForms() {

      // Para produtores prospectados não é necessário validar no momento de salvar
      if (this.type === "PROSPECTION" && !this.isLinking) {
        return false;
      }

      this.$refs.personalForm.formValidate();
      this.$refs.addressForm.formValidate();

      if (this.isGroupingType) {
        return false;
      }

      this.$refs.financialForm.formValidate();
    },

    /**
     * Lista unidades dos laticinio
     * @returns {array}
     */
    async loadDairies() {
      try {
        const { data } = await this.$axios.post(
          `/pessoa/listaUnidadesLaticinio`,
          {
            filtrarLaticinio: false
          }
        );

        this.dairies = data;
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * @void
     * Trata evendo de close do dialog de status
     */
    statusProducerClose() {
      this.show = false;
      this.tab = 0;
      this.loading = false;
      this.$emit("onSave");
      //this.loadProducer();
    },

    /**
     * Seta as aliquotas correspodente ao produtor
     */
    setTax(cnpjCpf = '') {
      const general = this.$store.state.settings.gerais;

      // caso o produtor já existe e já tem as taxas setadas
      if (!!this.producerId && this.everHadTax) {
        return true;
      }

      cnpjCpf = (cnpjCpf || '').replace(/[^0-9]/g, '');

      //obs: posteriormente deve ajuste a aliquota do funrural
      // para não ter problema no próximo fechamentos, estamos passando o INSS no funrural como estavamos
      // fazendo antes

      // pessoa jurifica
      if (cnpjCpf.length === 14) {
        this.tax = {
          local: false,
          fundesa: _.get(general, "fundesa_aliquota", 0.00063),
          rat: _.get(general, "rat_pessoa_juridica_aliquota", 0.1),
          funrural: _.get(general, "funrural_pessoa_juridica", 1.5),
          senar: _.get(general, "senar_pessoa_juridica_aliquota", 0.25),
          inss: _.get(general, "inss_pessoa_juridica_aliquota", 1.7),
          administrativa: _.get(general, "administrativa_aliquota", 0.0),
          frete: _.get(general, "frete_aliquota", 0.0),
        };
      }
      else {
        // pessoa fisica
        this.tax = {
          local: false,
          fundesa: _.get(general, "fundesa_aliquota", 0.00063),
          rat: _.get(general, "rat_pessoa_fisica_aliquota", 0.1),
          funrural: _.get(general, "funrural_pessoa_fisica", 1.5),
          senar: _.get(general, "senar_pessoa_fisica_aliquota", 0.2),
          inss: _.get(general, "inss_pessoa_fisica_aliquota", 1.2),
          administrativa: _.get(general, "administrativa_aliquota", 0.0),
          frete: _.get(general, "frete_aliquota", 0.0),
        };
      }

    },

    onOpenExistingProducer(producerId) {
      this.$emit("update:producerId", producerId);

      this.loading = true;

      this.$nextTick(() => {
        this.loadProducer();
      })
    },

    async onFillProducerData(producerId) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/produtores/produtorDetalhe`,
          { id_pessoa: producerId }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.dataProducer = {
          ...this.dataProducer,
          nome: data.nome,
          cnpj_cpf: data.cnpj_cpf,
          responsavel_agrupamento: data.nome,
          celular: data.celular,
          telefone: data.telefone,
          id_rota: data.id_rota,
          rota: data.rota,
          nome_propriedade: data.nome_propriedade,
          end_cep: data.end_cep,
          end_rua: data.end_rua,
          end_numero: data.end_numero,
          end_estado: data.end_estado,
          end_cidade: data.end_cidade,
          end_cidade_codigo: data.end_cidade_codigo,
          end_bairro: data.end_bairro,
          end_complemento: data.end_complemento,
          id_regiao: data.id_regiao,
          regiao: data.regiao,
          end_latitude: data.end_latitude,
          end_longitude: data.end_longitude,
          numero_ordenha: data.numero_ordenha,
          tipo_ordenha: data.tipo_ordenha,
          frequencia_coleta: data.frequencia_coleta,
          numero_tarro: data.numero_tarro,
          estado_civil: data.estado_civil,
          id_ramo_atividade: data.id_ramo_atividade,
        }
        this.coolers = data.resfriadores_tanque_comunitario || [];
        this.dataProducer.quantidade_tanques = this.coolers.length || null;
        this.coolingType = data.tipo_resfriador ? _.upperFirst(data.tipo_resfriador) : null;
        this.tributacoes.tributacao = data.tributacoes;
        this.tributacoes.desconta_senar = data.desconta_senar;
        this.tributacoes.debita_inss = data.debita_inss;
        if (!_.isEmpty(data.dados_forma_pagamento)) {
          let bank = data.dados_forma_pagamento;

          if (_.isArray(bank)) {
            bank = _.head(bank);
          }

          this.dataAlternativeBank.splice(0, 3, {
            banco: { code: bank.codigo_banco, name: bank.banco },
            agencia: bank.agencia,
            nome_titular: bank.nome_titular,
            cnpj_cpf: bank.cnpj_cpf,
            conta: bank.conta,
            tipo_conta: bank.tipo_conta,
            forma_pagamento: bank.forma_pagamento,
            tipo_chave: bank.tipo_chave,
            chave: bank.chave,
          });
        }

        const contas_alternativas = data.contas_alternativas || [];

        if (contas_alternativas.length === 0) {
          contas_alternativas.push({});
        }

        this.dataAlternativeBank.splice(1, 1, ...contas_alternativas.map(bank => ({
          banco: { code: bank.codigo_banco, name: bank.banco },
          agencia: bank.agencia,
          nome_titular: bank.nome_titular,
          cnpj_cpf: bank.cnpj_cpf,
          conta: bank.conta,
          tipo_conta: bank.tipo_conta,
          forma_pagamento: bank.forma_pagamento,
          tipo_chave: bank.tipo_chave,
          chave: bank.chave,
        })));

        if (!_.isEmpty(data.taxas)) {
          let discount = data.taxas;

          if (_.isArray(discount)) {
            discount = _.head(discount);
          }

          this.tax = {
            local: data.config_taxas_local || false,
            senar: _.get(discount, "senar"),
            fundesa: _.get(discount, "fundesa"),
            funrural: _.get(discount, "funrural"),
            rat: _.get(discount, "rat"),
            administrativa: _.get(discount, "administrativa"),
            frete: _.get(discount, "frete"),
          };

          this.everHadTax = true;
        } else {
          this.setTax();
        }

      } catch (e) {
        this.$snotify.error('Erro ao preencher informações do produtor', 'Atenção');
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async updateProducerAttachment() {
      try {
        this.loading = true;
        const payload = {
          anexos: this.dataProducer.anexos
        };
        await this.$axios.put(`/producer/${this.producerId}`, payload);

        this.$emit("onSave");
      } catch (e) {
        this.$snotify.error('Erro ao atualizar anexos do produtor', 'Atenção');
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async onFillProducerAccount(producerCnpjCpf) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/produtores/produtorDadosBancarios`,
          { cnpj_cpf: producerCnpjCpf }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.tributacoes.tributacao = data.tributacoes;
        this.tributacoes.desconta_senar = data.desconta_senar;
        this.tributacoes.debita_inss = data.debita_inss;
        this.tributacoes.dia_pagamento = data.dia_pagamento;
        this.tributacoes.ignorar_bonificacao = data.ignorar_bonificacao;
        this.tributacoes.adiantamento = data.adiantamento;


        if (!_.isEmpty(data.dados_forma_pagamento)) {
          let bank = data.dados_forma_pagamento;

          if (_.isArray(bank)) {
            bank = _.head(bank);
          }

          this.dataAlternativeBank.splice(0, 3, {
            banco: { code: bank.codigo_banco, name: bank.banco },
            agencia: bank.agencia,
            nome_titular: bank.nome_titular,
            cnpj_cpf: bank.cnpj_cpf,
            conta: bank.conta,
            tipo_conta: bank.tipo_conta,
            forma_pagamento: bank.forma_pagamento,
            tipo_chave: bank.tipo_chave,
            chave: bank.chave,
          });
        }

        const contas_alternativas = data.contas_alternativas || [];

        if (contas_alternativas.length === 0) {
          contas_alternativas.push({});
        }

        this.dataAlternativeBank.splice(1, 1, ...contas_alternativas.map(bank => ({
          banco: { code: bank.codigo_banco, name: bank.banco },
          agencia: bank.agencia,
          nome_titular: bank.nome_titular,
          cnpj_cpf: bank.cnpj_cpf,
          conta: bank.conta,
          tipo_conta: bank.tipo_conta,
          forma_pagamento: bank.forma_pagamento,
          tipo_chave: bank.tipo_chave,
          chave: bank.chave,
        })));

        if (!_.isEmpty(data.taxas)) {
          let discount = data.taxas;

          if (_.isArray(discount)) {
            discount = _.head(discount);
          }

          this.tax = {
            local: data.config_taxas_local || false,
            senar: _.get(discount, "senar"),
            fundesa: _.get(discount, "fundesa"),
            funrural: _.get(discount, "funrural"),
            rat: _.get(discount, "rat"),
            administrativa: _.get(discount, "administrativa"),
            frete: _.get(discount, "frete"),
          };

          this.everHadTax = true;
        } else {
          this.setTax();
        }

      } catch (e) {
        this.$snotify.error('Erro ao preencher pagamentos e descontos', 'Atenção');
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async loadAccessUsers() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaUsuarioAcesso0`, { acesso_todos: 0 });

        this.accessUsers = data.map((person) => ({
          id_usuario: person.id_pessoa,
          nome_usuario: person.codigo_laticinio ? `${person.codigo_laticinio} - ${person.nome}` : person.nome,
          id_cargo: person.id_cargo,
        }));
      } catch (error) {
        this.$snotify.error('Erro ao carregar usuários', 'Atenção');
        console.warn(error);
      }
    },

    clear() {
      this.tributacoes.tributacao = '';
      this.tributacoes.desconta_senar = '';
      this.tributacoes.debita_inss = '';
      this.tributacoes.dia_pagamento = null;
      this.tributacoes.ignorar_bonificacao = null;
      this.tributacoes.adiantamento = null;
      this.dataAlternativeBank = [{}, {}];
      this.tax = {
        local: false,
        fundesa: 0,
        administrativa: 0,
        senar: 0,
        funrural: 0,
        rat: 0,
        frete: 0,
      };
    },

    statusDialogClose() {
      this.loadProducer();
    },

    getTotalCoolersCapacity() {
      const coolers = this.coolers.slice(0, this.dataProducer.quantidade_tanques);
      return _.sumBy(coolers, (capacity) => parseFloat(capacity) || 0);
    },

    onChangeAmountCoolers() {
      const coolers = parseInt(this.dataProducer.quantidade_tanques) || 0;

      for (let i = 0; i < coolers; i++) {
        if (!this.coolers[i]) {
          this.coolers.splice(i, 1, null)
        }
      }
      this.$forceUpdate();
    },

    dateValidate(value, format) {
      if (_.isEmpty(value)) {
        return '';
      }

      return moment(value, format).isValid();
    },

    generateTimeValidationRules(field, section) {
      if (this.type !== 'PROSPECTION') {
        return [];
      }

      const rules = [];

      if (this.isRequired(field, section)) {
        rules.push(v => !!v || 'Campo obrigatório');
      }

      rules.push(v => !v || (v.length === 5 && this.dateValidate(v, 'HH:mm')) || 'Hora Inválida!');

      return rules;
    },

    generateEndTimeValidationRules(startField, endField, section) {
      if (this.type !== 'PROSPECTION') {
        return [];
      }

      const rules = this.generateTimeValidationRules(startField, section);
      rules.push(v => this.endDateRule(v, startField, endField) || 'A hora de fim é menor que a hora de início!');
      return rules;
    },

    endDateRule(v) {
      if (!v || v.length !== 5) {
        return true;
      }

      const endedAt = moment(v, 'HH:mm').format('HH:mm');
      const startedAt = moment(this.dataProducer.hora_inicio_coleta, 'HH:mm').format('HH:mm');

      if (endedAt < startedAt) {
        return 'A data final é menor que a inicial!';
      }

      return true;
    },

    async loadKinshipDegrees() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/pessoa/listaGrausParentesco`);

        this.kinshipDegrees = data;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os Gráus de Parentesco!", "Atenção");

        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Lista de ramos de atividade
     */
    async loadLineOfBusiness() {
      try {
        const { data } = await this.$axios.get(`/ramo-atividade`);

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        this.lineOfBusiness = data;
      } catch (error) {
        console.log(error);
      }
    },

    onDependentAddClick() {
      return this.$refs.dependentDialog.show('Adicionar Dependente', {});
    },

    onDependentEditClick(dependent) {
      return this.$refs.dependentDialog.show('Editando Dependente', dependent);
    },

    onDependentExcludeClick(dependent) {
      this.dependents = this.dependents.map(dependentData => {
        if (dependentData.id_pessoa !== dependent.id_pessoa) {
          return dependentData;
        }

        // Caso tenha sido criado e não salvo ainda, simplesmente não mandar para o back
        if (dependentData.criado) {
          return;
        }

        return {
          ...dependentData,
          excluido: true,
        };
      }).filter(n => n);
    },

    onDependentSave(dependent) {
      if (_.isEmpty(dependent.id_pessoa)) {
        this.dependents = [
          ...this.dependents,
          {
            ...dependent,
            id_pessoa: uuidv4(),
            criado: true,
          },
        ];

        return;
      }

      this.dependents = this.dependents.map(dependentData => {
        if (dependentData.id_pessoa !== dependent.id_pessoa) {
          return dependentData;
        }

        if (dependentData.criado) {
          return dependent;
        }

        return {
          ...dependent,
          atualizado: true,
        };
      });
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
