<template>
  <div>
    <v-card
      class="graph-card"
      color="transparent"
      outlined
      dark
    >
      <v-card-title>
        {{ questionnaireName }}
        <v-spacer />
        <v-switch
          v-model="filters.prospected"
          label="Exibir prospectados"
          dark
          class="ma-1 pb-1"
          color="teal accent-3"
          hide-details
          @change="loadResponses"
        />
        <v-spacer />
        <month-range-picker
          v-model="filters.range"
          :ranges.sync="filters.ranges"
          :min="filters.min"
          :max="filters.max"
          dark
          @change="loadResponses"
        />
      </v-card-title>
      <v-card-subtitle>
        *considera somente as últimas respostas dos produtores ativos e suspensos
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col
            offset="9"
            md="3"
          >
            <v-autocomplete
              v-model="filters.id_producer"
              :items="listProducer"
              :loading="loading"
              clearable
              dark
              filled
              label="Produtor"
              prepend-inner-icon="person_outline"
              item-text="nome_produtor"
              item-value="id_produtor"
              @change="loadResponses"
            />
          </v-col>
          <v-col
            cols="12"
            class="md-12"
          >
            <v-card
              v-for="(c , index) in checklist"
              :key="index"
              class="graph-card"
              color="transparent"
              outlined
              dark
            >
              <v-card-text
                class="pb-12 pt-12"
              >
                <v-chart
                  ref="chart"
                  :options="generateGraphOptions(c.items,c.text)"
                  :style="{width: '100%', height: `${c.height }px !important`}"
                  autoresize
                  :loading="loading"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-if="showChecklistTotals"
        >
          <v-col
            cols="6"
            class="md-6"
          >
            <v-card
              class="graph-card"
              color="transparent"
              outlined
              dark
            >
              <v-card-text
                class="pb-12 pt-12"
              >
                <v-chart
                  ref="chart2"
                  :options="generateGraphPie(totalGeral)"
                  :style="{width: '100%', height: `300px !important`}"
                  autoresize
                  :loading="loading"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-for="(c , i) in checklistTotals"
            :key="i"
            cols="6"
            class="md-6"
          >
            <v-card
              class="graph-card"
              color="transparent"
              outlined
              dark
            >
              <v-card-text
                class="pb-12 pt-12"
              >
                <v-chart
                  ref="chart2"
                  :options="generateGraphPie(c)"
                  :style="{width: '100%', height: `300px !important`}"
                  autoresize
                  :loading="loading"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-overlay
      v-model="loading"
    >
      Carregando checklists...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";

export default {
  name: "questionnaire-graphic",

  components: {
    MonthRangePicker
  },

  props: {
    questionnaireId: String,
    questionnaireName: String,
    questionnaireDateStart: String,
    questionnaireDateEnd: String,

  },

  data() {
    return {

      filters: {
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],
        min: moment().subtract(5, 'years').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),
        prospected: null,
        id_producer: null,
      },

      loading: true,

      checklist: [],

      checklistTotals: [],

      showChecklistTotals: true,

      totalGeral: {
        totals: [],
        text: 'Total Geral'
      },
      listProducer: [],
    };
  },

  async mounted() {
    this.filters.range = [moment(this.questionnaireDateStart).format('YYYY-MM'), moment(this.questionnaireDateEnd).format('YYYY-MM')];
    this.loadResponses();

    await this.loadProducer();
  },

  methods: {

    async loadProducer() {
      try {
        this.loading = true;
        let id_formulario = this.questionnaireId;
        if (!id_formulario) {
          return false;
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaRespostasFormulario`,
          {
            id_formulario: id_formulario,
            agrupa_respostas: true,
            data_inicio: moment(this.filters.range[0]).startOf('month').format('YYYY-MM-DD') || moment().startOf('month').format("YYYY-MM-DD"),
            data_fim: moment(this.filters.range[1]).endOf('month').format('YYYY-MM-DD') || moment().endOf('month').format('YYYY-MM-DD'),
          }
        );

        // traz somente os produtores que ja responderam esse checklist no periodo do projeto
        this.listProducer = [...new Map(data.map(item => [item['id_produtor'], item])).values()];

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadResponses() {
      let id_formulario = this.questionnaireId;
      if (!id_formulario) {
        return false;
      }
      this.loading = true;
      try {

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaRotulosRespondidosPeriodo`,
          this.$qs.stringify({
            id_formulario: id_formulario,
            data_inicio: moment(this.filters.range[0]).startOf('month').format('YYYY-MM-DD') || moment().startOf('month').format("YYYY-MM-DD"),
            data_fim: moment(this.filters.range[1]).endOf('month').format('YYYY-MM-DD') || moment().endOf('month').format('YYYY-MM-DD'),
            prospectado: this.filters.prospected,
            id_produtor: this.filters.id_producer,
          })
        );

        this.checklist = [];
        this.checklistTotals = [];

        _.forEach(data, (question) => {

          if (question.tipo === "TITULO" ) {
            this.checklist.push({
              text: question.rotulo,
              items: [],
              AnswerLength: 0,
            });
          }
          if (_.isEmpty(this.checklist)) { //nao tem subtitulo/grupo no formulario
            this.checklist.push({
              text: this.questionnaireName,
              items: [],
              AnswerLength: 0,
            });
          }
          if (question.tipo === "CHECKBOX") {
            let lastGroup = _.last(this.checklist);

            //se tamanho das respostas forem diferentes por grupo de questoes entao vai criar outros grupos para nao dar erro no grafico
            if (lastGroup.AnswerLength > 0 && lastGroup.AnswerLength != (question.valores).length) {

              this.showChecklistTotals = false ; // nao pode mostrar totais pois cada pergunta tem um tipo de resposta.

              this.checklist.push({
                text: lastGroup.text, //outro grafico com o mesmo titulo do anterior
                items: [],
                AnswerLength: 0,
              });
              lastGroup = _.last(this.checklist);

            }

            let max_words = 15; //quebra linha a cada 10 palavras
            let words = question.rotulo.split(' ');
            let rotulo = question.rotulo ;

            if (words.length  > max_words) { //insere quebra de linhas em rotulos com mais de max_words
              rotulo = '';
              for (var x = 0; x < words.length / max_words; x++) {
                let part = words.slice(x * max_words, (x + 1) * max_words).join(' ');
                rotulo = _.concat(rotulo, part + '\n').join(' ');
              }
            }

            lastGroup.AnswerLength = (question.valores).length; //para saber o tamanho do cabecalho da questao
            lastGroup.items.push({
              rotulo: rotulo,
              valores: question.valores,
              respostas: question.respostas,
            });

            lastGroup.height = (lastGroup.items.length * 50 ) >= 120 ? lastGroup.items.length * 50 : 150;

          }
        });

        if (this.checklist.length > 0 && this.showChecklistTotals) {

          let respostas = this.checklist[0].items[0].valores;
          this.totalGeral.totals = respostas.map(r => {
            return {
              name: r,
              value: 0,
            }});

          _.forEach(this.checklist, (checklist) => {
            let Totals = [];
            _.forEach(respostas, (r, key) => {
              let arrayValues = _.compact(checklist.items.map(o => o.respostas[key].quantidade || 0 ));
              let sum = arrayValues.reduce((acc, cur) => acc + cur, 0)
              Totals.push({
                name: r,
                value: sum,
              });
              const tg = _.find(this.totalGeral.totals, { "name": r });
              tg.value += sum;
            });

            this.checklistTotals.push({
              text: checklist.text,
              totals: Totals,
              count: checklist.items.length
            });
          });
        }

      }
      catch (err) {
        console.warn(err);
        //this.$snotify.error("Erro ao carregar checklist", "Atenção");

      }
      finally {
        this.loading = false;
      }
    },

    generateGraphOptions(data, title) {
      if (_.isEmpty(data)) {
        return;
      }

      let series = [];
      let respostas = data[0].valores;
      _.forEach(respostas, (r, key) => {
        series.push({
          name: r,
          type: 'bar',
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            lineHeight: 20,
            height: 20,
            backgroundColor: '#6a7985',
            color: '#fff',
            borderRadius: 0,
            position: 'top',
            formatter: '{c}',
            fontSize: 10,
          },
          data: data.map(o => o.respostas[key].quantidade || 0),
        });
      });
      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(229, 57, 53, 0.7)',   'rgba(140, 23, 23, 1)',  'rgba(255, 255, 0, 0.7)'],
        title: {
          text: `${title}`,
          left: "center",
          textStyle: { color: '#ddd', fontSize: 14 },
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 1200,
          textStyle: { color: '#ddd', fontSize: 9 },
          padding: [20, 0, 0, 0],
        },
        tooltip: {
          rigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 9,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {

                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });
                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff',
            align: 'end',
          }
        },
        yAxis: {
          type: 'category',
          data: data.map(o => o.rotulo),
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 9,
            // height: 30,
            // width: 300,
          }
        },
        series: series
      };

    },

    generateGraphPie(data) {
      if (data.length === 0) {
        return ;
      }

      return  {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(229, 57, 53, 0.7)'],
        title: {
          text: data.text,
          textStyle: { color: '#ddd' },
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} :<br> {c} ({d}%)'
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: '#ddd',
          }
        },
        series: [
          {
            name: 'conforme/não conforme',
            type: 'pie',
            radius: '50%',
            data: data.totals,
            labelLine: { show: false },
            label: {
              show: true,
              position: 'inside',
              formatter: '{c}',
              textShadowColor: '#000',
              textShadowBlur: 0.5,
            },
          }
        ]
      }
    },
    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),

    formatDate: (value) => _.capitalize(moment(value, 'YYYY-MM-DD').format("DD/MM/YY")),
  },
};
</script>
