<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="quality-standard"
  >
    <v-row>
      <v-col
        cols="12"
        md
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onDateFilter"
        />
      </v-col>
      <v-col
        md
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          hide-details
          @change="loadReport"
        />
      </v-col>
      <v-col
        md
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          type="visita,coleta"
          multiple
          hide-details
          @change="loadReport"
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-autocomplete
          v-model="filter.producer"
          :items="listProducer"
          :loading="loading"
          clearable
          dark
          filled
          label="Produtor"
          prepend-inner-icon="person_outline"
          item-text="name"
          item-value="id_produtor"
        />
      </v-col>
      <v-col
        md
      >
        <v-select
          v-model="filter.curve"
          dark
          filled
          return-object
          label="Indicadores"
          clearable:items="curveItem"
          @change="loadTabs"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
      @change="onChangeTab"
    >
      <v-tab>
        Gráficos
      </v-tab>
      <v-tab>
        Relatórios
      </v-tab>
      <v-tab>
        <v-icon>settings</v-icon>
      </v-tab>

      <v-tab-item>
        <v-row>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="bar"
                  :options="withinTheStandardGraph.options"
                  :style="{width: '100%', height: `${withinTheStandardGraph.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            v-if="ranges['CCS'] && ranges['CCS'].list.length > 0"
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="ccsPie.options"
                  :style="{width: '100%', height: `${ccsPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            v-if="ranges['CPP'] && ranges['CPP'].list.length > 0"
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="cbtPie.options"
                  :style="{width: '100%', height: `${cbtPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            v-if="ranges['Gordura'] && ranges['Gordura'].list.length > 0"
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="gorduraPie.options"
                  :style="{width: '100%', height: `${gorduraPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            v-if="ranges['Proteína'] && ranges['Proteína'].list.length > 0"
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="proteinaPie.options"
                  :style="{width: '100%', height: `${proteinaPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            v-if="ranges['Caseína'] && ranges['Caseína'].list.length > 0"
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="caseinaPie.options"
                  :style="{width: '100%', height: `${caseinaPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            v-if="ranges['Sólidos Totais'] && ranges['Sólidos Totais'].list.length > 0"
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="solidosTotaisPie.options"
                  :style="{width: '100%', height: `${solidosTotaisPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            v-if="ranges['Volume'] && ranges['Volume'].list.length > 0"
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="coletaPie.options"
                  :style="{width: '100%', height: `${coletaPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            v-if="ranges['Distância'] && ranges['Distância'].list.length > 0"
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="distanciaPie.options"
                  :style="{width: '100%', height: `${distanciaPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-tabs
            v-model="tabGraphBar"
            dark
            centered
            background-color="transparent"
            class="transparent"
          >
            <v-tab
              v-if="ranges['CCS'] && ranges['CCS'].list.length > 0"
            >
              CCS
            </v-tab>
            <v-tab
              v-if="ranges['CPP'] && ranges['CPP'].list.length > 0"
            >
              CPP
            </v-tab>
            <v-tab
              v-if="ranges['Gordura'] && ranges['Gordura'].list.length > 0"
            >
              Gordura
            </v-tab>
            <v-tab
              v-if="ranges['Proteína'] && ranges['Proteína'].list.length > 0"
            >
              Proteína
            </v-tab>
            <v-tab
              v-if="ranges['Caseína'] && ranges['Caseína'].list.length > 0"
            >
              Caseína
            </v-tab>
            <v-tab
              v-if="ranges['Sólidos Totais'] && ranges['Sólidos Totais'].list.length > 0"
            >
              Sólidos Totais
            </v-tab>
            <v-tab
              v-if="ranges['Volume'] && ranges['Volume'].list.length > 0"
            >
              Volume
            </v-tab>
            <v-tab
              v-if="ranges['Distância'] && ranges['Distância'].list.length > 0"
            >
              Distância
            </v-tab>

            <v-tab-item
              v-if="ranges['CCS'] && ranges['CCS'].list.length > 0"
              background-color="transparent"
              dark
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="ccsGraph.options"
                    :style="{width: '100%', height: `${ccsGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item
              v-if="ranges['CPP'] && ranges['CPP'].list.length > 0"
              background-color="transparent"
              dark
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="cbtGraph.options"
                    :style="{width: '100%', height: `${cbtGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item
              v-if="ranges['Gordura'] && ranges['Gordura'].list.length > 0"
              background-color="transparent"
              dark
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="gorduraGraph.options"
                    :style="{width: '100%', height: `${gorduraGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item
              v-if="ranges['Proteína'] && ranges['Proteína'].list.length > 0"
              background-color="transparent"
              dark
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="proteinaGraph.options"
                    :style="{width: '100%', height: `${proteinaGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item
              v-if="ranges['Caseína'] && ranges['Caseína'].list.length > 0"
              background-color="transparent"
              dark
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="caseinaGraph.options"
                    :style="{width: '100%', height: `${caseinaGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item
              v-if="ranges['Sólidos Totais'] && ranges['Sólidos Totais'].list.length > 0"
              background-color="transparent"
              dark
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="solidosTotaisGraph.options"
                    :style="{width: '100%', height: `${solidosTotaisGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item
              v-if="ranges['Volume'] && ranges['Volume'].list.length > 0"
              background-color="transparent"
              dark
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="coletaGraph.options"
                    :style="{width: '100%', height: `${coletaGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item
              v-if="ranges['Distância'] && ranges['Distância'].list.length > 0"
              background-color="transparent"
              dark
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="distanciaGraph.options"
                    :style="{width: '100%', height: `${distanciaGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-tabs
          v-model="tabGraphBar"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab
            v-if="ranges['CCS'] && ranges['CCS'].list.length > 0"
          >
            CCS
          </v-tab>
          <v-tab
            v-if="ranges['CPP'] && ranges['CPP'].list.length > 0"
          >
            CPP
          </v-tab>
          <v-tab
            v-if="ranges['Gordura'] && ranges['Gordura'].list.length > 0"
          >
            Gordura
          </v-tab>
          <v-tab
            v-if="ranges['Proteína'] && ranges['Proteína'].list.length > 0"
          >
            Proteína
          </v-tab>
          <v-tab
            v-if="ranges['Caseína'] && ranges['Caseína'].list.length > 0"
          >
            Caseína
          </v-tab>
          <v-tab
            v-if="ranges['Sólidos Totais'] && ranges['Sólidos Totais'].list.length > 0"
          >
            Sólidos Totais
          </v-tab>
          <v-tab
            v-if="ranges['Volume'] && ranges['Volume'].list.length > 0"
          >
            Volume
          </v-tab>
          <v-tab
            v-if="ranges['Distância'] && ranges['Distância'].list.length > 0"
          >
            Distância
          </v-tab>

          <v-tab-item
            v-if="ranges['CCS'] && ranges['CCS'].list.length > 0"
            background-color="transparent"
            dark
          >
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title class="flex-column align-start">
                <div>CCS</div>
                <div class="body-2">
                  Produtores: {{ ccsReport.items.length }}
                </div>
              </v-card-title>
              <v-data-table
                :headers="ccsReport.headers"
                :items="ccsReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="ccsReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-tab-item>

          <v-tab-item
            v-if="ranges['CPP'] && ranges['CPP'].list.length > 0"
            background-color="transparent"
            dark
          >
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title class="flex-column align-start">
                <div>CPP</div>
                <div class="body-2">
                  Produtores: {{ ccsReport.items.length }}
                </div>
              </v-card-title>
              <v-data-table
                :headers="cbtReport.headers"
                :items="cbtReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="cbtReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-tab-item>

          <v-tab-item
            v-if="ranges['Gordura'] && ranges['Gordura'].list.length > 0"
            background-color="transparent"
            dark
          >
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title class="flex-column align-start">
                <div>Gordura</div>
                <div class="body-2">
                  Produtores: {{ ccsReport.items.length }}
                </div>
              </v-card-title>
              <v-data-table
                :headers="gorduraReport.headers"
                :items="gorduraReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="gorduraReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-tab-item>

          <v-tab-item
            v-if="ranges['Proteína'] && ranges['Proteína'].list.length > 0"
            background-color="transparent"
            dark
          >
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title class="flex-column align-start">
                <div>Proteína</div>
                <div class="body-2">
                  Produtores: {{ ccsReport.items.length }}
                </div>
              </v-card-title>
              <v-data-table
                :headers="proteinaReport.headers"
                :items="proteinaReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="proteinaReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-tab-item>

          <v-tab-item
            v-if="ranges['Caseína'] && ranges['Caseína'].list.length > 0"
            background-color="transparent"
            dark
          >
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title class="flex-column align-start">
                <div>Caseína</div>
                <div class="body-2">
                  Produtores: {{ ccsReport.items.length }}
                </div>
              </v-card-title>
              <v-data-table
                :headers="caseinaReport.headers"
                :items="caseinaReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="caseinaReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-tab-item>

          <v-tab-item
            v-if="ranges['Sólidos Totais'] && ranges['Sólidos Totais'].list.length > 0"
            background-color="transparent"
            dark
          >
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title class="flex-column align-start">
                <div>Sólidos Totais</div>
                <div class="body-2">
                  Produtores: {{ ccsReport.items.length }}
                </div>
              </v-card-title>
              <v-data-table
                :headers="solidosTotaisReport.headers"
                :items="solidosTotaisReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="solidosTotaisReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-tab-item>

          <v-tab-item
            v-if="ranges['Volume'] && ranges['Volume'].list.length > 0"
            background-color="transparent"
            dark
          >
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title class="flex-column align-start">
                <div>Volume</div>
                <div class="body-2">
                  Produtores: {{ ccsReport.items.length }}
                </div>
              </v-card-title>
              <v-data-table
                :headers="coletaReport.headers"
                :items="coletaReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="coletaReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-tab-item>

          <v-tab-item
            v-if="ranges['Distância'] && ranges['Distância'].list.length > 0"
            background-color="transparent"
            dark
          >
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title class="flex-column align-start">
                <div>Distância</div>
                <div class="body-2">
                  Produtores: {{ ccsReport.items.length }}
                </div>
              </v-card-title>
              <v-data-table
                :headers="distanciaReport.headers"
                :items="distanciaReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="distanciaReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-tab-item>
        </v-tabs>

        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template v-slot:activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>

          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel()"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>
        </v-speed-dial>
      </v-tab-item>
      <v-tab-item>
        <quality-settings />
      </v-tab-item>
    </v-tabs>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.quality-standard {
  .graph-card {
    margin-top: 5px !important;
    background: rgba(0, 0, 0, 0.5) !important;

    > .v-card__title {
      color: #fff;
      background: unset;
    }

    > .v-card__text {
      color: #fff !important;
    }
  }
  .report-card {
    > .v-card__title {
      color: #fff;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

import QualitySettings from "@/Domains/Settings/Views/QualitySettings.vue";
import QualityMixins from "@/Domains/Quality/Mixins/QualityMixins.js";
import QualityStandardMixin from "@/Domains/Quality/Mixins/QualityStandardMixin.js";

import XLSX from "xlsx-js-style";

export default {
  name: "quality-meta-report",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
    QualitySettings,
  },

  mixins: [QualityMixins, QualityStandardMixin],

  data() {
    return {
      loading: true,

      tab: null,

      tabGraphBar: null,

      filter: {
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        status: 'ATIVOS',
      },

      ccs: [],
      cbt: [],
      gordura: [],
      proteina: [],
      caseina: [],
      solidosTotais: [],
      coleta: [],
      distancia: [],

      ranges: {}
    };
  },

  computed: {
    withinTheStandardGraph() {
      return this.generateWithinTheStandardGraph(this.ranges, this.ccs, this.cbt, this.gordura, this.proteina, this.caseina, this.solidosTotais)
    },

    ccsPie() {
      return this.generateGraphPie('CCS', this.ccs, 'ccs', this.ranges['CCS']);
    },

    cbtPie() {
      return this.generateGraphPie('CPP', this.cbt, 'cbt', this.ranges['CPP']);
    },

    gorduraPie() {
      return this.generateGraphPie('Gordura', this.gordura, 'gordura', this.ranges['Gordura'], null, true);
    },

    proteinaPie() {
      return this.generateGraphPie('Proteína', this.proteina, 'proteina', this.ranges['Proteína'], null, true);
    },

    caseinaPie() {
      return this.generateGraphPie('Caseína', this.caseina, 'caseina', this.ranges['Caseína'], null, true);
    },

    solidosTotaisPie() {
      return this.generateGraphPie('Sólidos Totais', this.solidosTotais, 'solidosTotais', this.ranges['Sólidos Totais'], null, true);
    },

    coletaPie() {
      return this.generateGraphPie('Volume', this.coleta, 'coleta', this.ranges['Volume'], null, true);
    },

    distanciaPie() {
      return this.generateGraphPie('Distância', this.distancia, 'distancia', this.ranges['Distância']);
    },

    ccsGraph() {
      return this.generateGraphBar('CCS', this.ccs, 'ccs', this.ranges['CCS']);
    },

    cbtGraph() {
      return this.generateGraphBar('CPP', this.cbt, 'cbt', this.ranges['CPP']);
    },

    gorduraGraph() {
      return this.generateGraphBar('Gordura', this.gordura, 'gordura', this.ranges['Gordura'], null, true);
    },

    proteinaGraph() {
      return this.generateGraphBar('Proteína', this.proteina, 'proteina', this.ranges['Proteína'], null, true);
    },

    caseinaGraph() {
      return this.generateGraphBar('Caseína', this.caseina, 'caseina', this.ranges['Caseína'], null, true);
    },

    solidosTotaisGraph() {
      return this.generateGraphBar('Sólidos Totais', this.solidosTotais, 'solidosTotais', this.ranges['Sólidos Totais'], null, true);
    },

    coletaGraph() {
      return this.generateGraphBar('Volume', this.coleta, 'coleta', this.ranges['Volume'], 'Volume coletado', true);
    },

    distanciaGraph() {
      return this.generateGraphBar('Distância', this.distancia, 'distancia', this.ranges['Distância'], 'Distância da industria');
    },

    ccsReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'CCS', value: 'ccs', align: 'center', width: 100, },
        ],
        items: this.ccs
      }
    },

    cbtReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'CPP', value: 'cbt', align: 'center', width: 100, },
        ],
        items: this.cbt
      }
    },

    gorduraReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'Gordura', value: 'gordura', align: 'center', width: 100, },
        ],
        items: this.gordura
      }
    },

    proteinaReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'Proteína', value: 'proteina', align: 'center', width: 100, },
        ],
        items: this.proteina
      }
    },

    caseinaReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'Caseína', value: 'caseina', align: 'center', width: 100, },
        ],
        items: this.caseina
      }
    },

    solidosTotaisReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'Sólidos Totais', value: 'solidosTotais', align: 'center', width: 100, },
        ],
        items: this.solidosTotais
      }
    },

    coletaReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'Volume', value: 'coleta', align: 'center', width: 100, },
        ],
        items: this.coleta
      }
    },

    distanciaReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'Distância', value: 'distancia', align: 'center', width: 100, },
        ],
        items: this.distancia
      }
    },
  },

  mounted() {
    this.loadReport();
  },

  updated() {
    this.$nextTick(function () {
      this.loading = false;
    })
  },

  methods: {

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter([month]) {
      this.filter.date.month = month;
      this.loadReport();
    },

    /**
     * Caso as faixas de qualidades sejam alteradas na aba de configurações (idx=2)
     * Atualizamos os ranges dos gráficos
     * Esse evento também é acionado automaticamente quando o compomente é renderizado
     */
    onChangeTab(idx) {
      if (idx == 0) {
        this.loadRanges();
      }
    },

    async loadRanges() {

      try {
        this.loading = true;

        this.ranges = await this.getRanges();

      } catch (error) {
        this.ranges = [];
        this.$snotify.error("Erro ao carregar as configurações", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadReport() {
      try {
        this.loading = true

        const month = this.filter.date.month;
        const technicianId = this.filter.technician.id;
        const routes = this.filter.routes.map(({ id }) => id);
        const status = this.filter.status;

        let { cbt, ccs, gordura, proteina, caseina, solidosTotais, distancia, coleta } = await this.getQualityOutOfStandardReport(month, technicianId, routes, status);

        this.cbt = cbt;
        this.ccs = ccs;
        this.gordura = gordura;
        this.proteina = proteina;
        this.caseina = caseina;
        this.solidosTotais = solidosTotais;
        this.distancia = distancia;
        this.coleta = coleta;

        this.loading = false
      }
      catch (err) {
        this.loading = false
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {

      let worksheet = XLSX.utils.book_new();

      worksheet["!merges"] = [];
      worksheet['!cols'] = [];
      let idx = 0;

      if (this.ranges['CCS'] && this.ranges['CCS'].list.length > 0) {
        const col = idx * 4;

        // Add title to each quality
        XLSX.utils.sheet_add_aoa(worksheet, [['CCS']], { origin: { r: 0, c: col } });

        XLSX.utils.sheet_add_json(worksheet, this.ccs.map(item => ({
          'Produtor': item.nome_produtor,
          'CCS': item.ccs,
        })), { origin: { r: 2, c: col } });

        worksheet["!merges"].push({ s: { r: 0, c: col }, e: { r: 0, c: col + 1 } });
        worksheet['!cols'].push({ wch: 30 }, { wch: 7 }, { wch: 10 }, { wch: 10 });

        idx++;
      }

      if (this.ranges['CPP'] && this.ranges['CPP'].list.length > 0) {
        const col = idx * 4;

        // Add title to each quality
        XLSX.utils.sheet_add_aoa(worksheet, [['CPP']], { origin: { r: 0, c: col } });

        XLSX.utils.sheet_add_json(worksheet, this.cbt.map(item => ({
          'Produtor': item.nome_produtor,
          'CPP': item.cbt,
        })), { origin: { r: 2, c: col } });

        worksheet["!merges"].push({ s: { r: 0, c: col }, e: { r: 0, c: col + 1 } });
        worksheet['!cols'].push({ wch: 30 }, { wch: 7 }, { wch: 10 }, { wch: 10 });

        idx++;
      }

      if (this.ranges['Gordura'] && this.ranges['Gordura'].list.length > 0) {
        const col = idx * 4;

        // Add title to each quality
        XLSX.utils.sheet_add_aoa(worksheet, [['Gordura']], { origin: { r: 0, c: col } });

        XLSX.utils.sheet_add_json(worksheet, this.gordura.map(item => ({
          'Produtor': item.nome_produtor,
          'Gordura': item.gordura,
        })), { origin: { r: 2, c: col } });

        worksheet["!merges"].push({ s: { r: 0, c: col }, e: { r: 0, c: col + 1 } });
        worksheet['!cols'].push({ wch: 30 }, { wch: 7 }, { wch: 10 }, { wch: 10 });

        idx++;
      }

      if (this.ranges['Proteína'] && this.ranges['Proteína'].list.length > 0) {
        const col = idx * 4;

        // Add title to each quality
        XLSX.utils.sheet_add_aoa(worksheet, [['Proteína']], { origin: { r: 0, c: col } });

        XLSX.utils.sheet_add_json(worksheet, this.proteina.map(item => ({
          'Produtor': item.nome_produtor,
          'Proteína': item.proteina,
        })), { origin: { r: 2, c: col } });

        worksheet["!merges"].push({ s: { r: 0, c: col }, e: { r: 0, c: col + 1 } });
        worksheet['!cols'].push({ wch: 30 }, { wch: 7 }, { wch: 10 }, { wch: 10 });

        idx++;
      }

      if (this.ranges['Caseína'] && this.ranges['Caseína'].list.length > 0) {
        const col = idx * 4;

        // Add title to each quality
        XLSX.utils.sheet_add_aoa(worksheet, [['Caseína']], { origin: { r: 0, c: col } });

        XLSX.utils.sheet_add_json(worksheet, this.caseina.map(item => ({
          'Produtor': item.nome_produtor,
          'Caseína': item.caseina,
        })), { origin: { r: 2, c: col } });

        worksheet["!merges"].push({ s: { r: 0, c: col }, e: { r: 0, c: col + 1 } });
        worksheet['!cols'].push({ wch: 30 }, { wch: 7 }, { wch: 10 }, { wch: 10 });

        idx++;
      }

      if (this.ranges['Sólidos Totais'] && this.ranges['Sólidos Totais'].list.length > 0) {
        const col = idx * 4;

        // Add title to each quality
        XLSX.utils.sheet_add_aoa(worksheet, [['Sólidos Totais']], { origin: { r: 0, c: col } });

        XLSX.utils.sheet_add_json(worksheet, this.solidosTotais.map(item => ({
          'Produtor': item.nome_produtor,
          'Sólidos Totais': item.solidosTotais,
        })), { origin: { r: 2, c: col } });

        worksheet["!merges"].push({ s: { r: 0, c: col }, e: { r: 0, c: col + 1 } });
        worksheet['!cols'].push({ wch: 30 }, { wch: 7 }, { wch: 10 }, { wch: 10 });

        idx++;
      }

      if (this.ranges['Volume'] && this.ranges['Volume'].list.length > 0) {
        const col = idx * 4;

        // Add title to each quality
        XLSX.utils.sheet_add_aoa(worksheet, [['Volume']], { origin: { r: 0, c: col } });

        XLSX.utils.sheet_add_json(worksheet, this.coleta.map(item => ({
          'Produtor': item.nome_produtor,
          'Volume': item.coleta,
        })), { origin: { r: 2, c: col } });

        worksheet["!merges"].push({ s: { r: 0, c: col }, e: { r: 0, c: col + 1 } });
        worksheet['!cols'].push({ wch: 30 }, { wch: 7 }, { wch: 10 }, { wch: 10 });

        idx++;
      }

      if (this.ranges['Distância'] && this.ranges['Distância'].list.length > 0) {
        const col = idx * 4;

        // Add title to each quality
        XLSX.utils.sheet_add_aoa(worksheet, [['Distância']], { origin: { r: 0, c: col } });

        XLSX.utils.sheet_add_json(worksheet, this.distancia.map(item => ({
          'Produtor': item.nome_produtor,
          'Distância': item.distancia,
        })), { origin: { r: 2, c: col } });

        worksheet["!merges"].push({ s: { r: 0, c: col }, e: { r: 0, c: col + 1 } });
        worksheet['!cols'].push({ wch: 30 }, { wch: 7 }, { wch: 10 }, { wch: 10 });

        idx++;
      }

      const workbook = XLSX.utils.book_new();

      const filename = "fora_de_padrao";

      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
};
</script>
