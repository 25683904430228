<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-card
      dark
      class="v-tamanho route-list"
      color="transparent"
    >
      <v-card-title>
        <v-col
          md="4"
          class="pa-0"
        >
          <route-types-form-item
            v-model="filters.types"
            tab="aba-rotas"
            :types="type === 'CAPTACAO' ? ['COLETA_LEITE', 'VISITA_TECNICA', 'ASSISTENCIA_TECNICA'] : ['VISITA_COMERCIAL']"
            chips
          />
        </v-col>
        <v-col md="4">
          <person-autocomplete-filter
            v-model="filters.user"
            label="Técnico/Motorista"
            type="DRIVER"
            @change="loadRoutes"
          />
        </v-col>
        <v-col
          md="4"
          class="pa-0"
        >
          <v-text-field
            v-model="filters.search"
            label="Pesquisar"
            single-line
            prepend-inner-icon="search"
            filled
            hide-details
            clearable
          />
        </v-col>
      </v-card-title>

      <data-table
        v-model="selectedRoutes"
        name="Rotas"
        :loading="onLoading"
        :headers="headers"
        :items="filteredRoutes"
        :search="filters.search"
        item-key="id_rota"
        :show-select="hasRouteSimulateAccess"
        class="elevation-1 lista-rotas-table"
        @click:row="onRouteEditing"
        @item-selected="onRouteSelect"
      >
        <template v-slot:header.data-table-select />

        <template v-slot:header.litros="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                Vol. <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Média das últimas coletas</span>
          </v-tooltip>
        </template>

        <template v-slot:header.media_ccs="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                CCS <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Ultima média geométrica</span>
          </v-tooltip>
        </template>

        <template v-slot:header.media_cbt="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                CPP <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Ultima média geométrica</span>
          </v-tooltip>
        </template>

        <template v-slot:item.tipos="{ item }">
          <v-chip
            v-for="(tipo, i) in item.tipos"
            :key="i"
            x-small
          >
            {{ tipo.descricao }}
          </v-chip>
        </template>

        <template v-slot:item.regioes="{ item }">
          <div
            v-for="(regiao, i) in item.regioes"
            :key="i"
          >
            <v-chip
              v-if="regiao.id_regiao"
              x-small
              @click="onRegionSelect(regiao)"
            >
              {{ regiao.descricao }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.publicado="{ item }">
          <v-icon
            v-if="item.publicado || item.publicado === null"
            color="green darken-1"
          >
            verified
          </v-icon>
          <v-icon
            v-if="!item.publicado"
            color="orange darken-1"
          >
            pending_actions
          </v-icon>
        </template>

        <template v-slot:item.acoes="{ item }">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="type === 'CAPTACAO'"
                @click="showRouteReport(item)"
              >
                <v-list-item-icon>
                  <v-icon>print</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Relatório
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="type === 'CAPTACAO'"
                @click="emptyReport(item)"
              >
                <v-list-item-icon>
                  <v-icon>print</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Relatório sem informações de coleta
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="onRouteEditing(item)">
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasRouteExcludeAccess"
                @click="showConfirmationDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="onPrintQRcodes(item)"
              >
                <v-list-item-icon>
                  <v-icon>qr_code_2</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  QR codes coleta
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="onPrintDownloadInstructions(item)"
              >
                <v-list-item-icon>
                  <v-icon>security_update</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Instruções de download
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>

      <div
        class="text-caption text-left ml-4"
        style="margin-top: -58px; height: 58px; line-height: 58px;"
      >
        Total de
        <v-chip
          small
          class="mx-2"
        >
          {{ totalProducers }}
        </v-chip> produtor(es) em
        <v-chip
          small
          class="mx-2"
        >
          {{ totalRoutes }}
        </v-chip> rota(s)
      </div>
    </v-card>

    <v-tooltip
      v-if="selectedRoutes.length > 1"
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="orange mr-5"
          v-on="on"
          @click="onRouteSimulation()"
        >
          <v-icon>view_column</v-icon>
        </v-btn>
      </template>

      <span>Simular Rotas</span>
    </v-tooltip>

    <v-tooltip
      v-else
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="onRouteCreating()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Nova rota</span>
    </v-tooltip>

    <v-dialog
      v-model="onExcluding"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Atenção
        </v-card-title>
        <v-card-text>
          Deseja realmente excluir a rota <b>{{ excluding.descricao }}</b> ?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="closeDialogs()"
          >
            Não
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="excludeRoute()"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="onReportShowing"
      fullscreen
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <div>
          <v-toolbar
            dark
            color="primary"
            class="d-print-none"
            dense
          >
            <v-btn
              icon
              dark
              @click="closeDialogs()"
            >
              <v-icon>close</v-icon>
            </v-btn>

            <v-toolbar-title>Produtores por Rota</v-toolbar-title>

            <v-spacer />

            <v-toolbar-items>
              <v-progress-circular
                v-if="onLoading"
                indeterminate
                color="white"
              />
              <v-btn
                v-if="!onLoading"
                color="white darken-1"
                text
                @click="reportToXLS()"
              >
                <v-icon left>
                  grid_on
                </v-icon> EXCEL
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </div>

        <v-card-text
          ref="print"
          class="mt-10 print-area"
        >
          <v-container
            fluid
            class="ma-0 pa-0"
            style="max-width: 100% !important;"
          >
            <v-row>
              <v-col class="text-center">
                <span class="text-h6">{{ report.route.descricao }}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col class="text-left">
                <span class="text-subtitle-1">
                  Técnicos/Motoristas: {{ report.technical }}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col class="text-left">
                <span class="text-subtitle-1">
                  Produtores: {{ report.producers.length }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-data-table
                  :headers="[
                    { text: 'Código', value: 'codigo_laticinio', align: 'start', },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Cidade', value: 'end_cidade' },
                  ]"
                  :items="report.producers"
                  disable-pagination
                  hide-default-footer
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="qrCodeDialog.isOpen"
      width="380"
    >
      <v-card>
        <v-card-title class="text-h5">
          Tamanho da impressão
        </v-card-title>
        <v-card-text>
          <v-btn-toggle
            v-if="qrCodeDialog.isOpen"
            class="d-flex justify-center"
          >
            <v-btn @click="printQrCode('100mm')">
              Etiqueta

              <v-icon
                right
                color="purple darken-1"
              >
                qr_code_2
              </v-icon>
            </v-btn>

            <v-btn @click="printQrCode('A4_Alt')">
              Grande

              <v-icon
                right
                color="green darken-1"
              >
                qr_code_2
              </v-icon>
            </v-btn>

            <v-btn @click="printQrCode('A4')">
              Padrão

              <v-icon
                right
                color="blue darken-1"
              >
                qr_code_2
              </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="onLoading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.route-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.lista-rotas-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

@media print {
  body,
  * {
    visibility: hidden;
  }

  .print-area,
  * {
    visibility: visible;
  }

  .print-area {
    top: 0;
    position: absolute;
    background: white !important;
    margin: 0;
    height: 100%;
  }
}
</style>

<script>
import qs from "qs";

import _ from "lodash";

import printJS from 'print-js';

import XLSX from "xlsx-js-style";
import RouteTypesFormItem from "@/Domains/Routes/Components/Form/RouteTypesFormItem.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

export default {
  components: {
    RouteTypesFormItem,
    PersonAutocompleteFilter
  },

  props: {
    type: {
      type: String,
      default: 'CAPTACAO'
    },
    tab: String,
  },

  data() {
    return {
      // Loaders
      onLoading: true,
      onFiltersLoading: true,

      // Dialogs
      onExcluding: false,
      onReportShowing: false,

      // Dados brutos do backend
      routes: [],

      // Filtros da tabela
      filters: {
        types: [],
        search: "",
        user: "",
      },

      // Usado no dialogo de confirmação de exclusão
      excluding: {},

      // Dados do relatório da rota
      report: {
        route: "",
        producers: [],
      },

      //
      selectedRoutes: [],

      qrCodeDialog: {
        isOpen: false,
        ids: null
      }
    };
  },

  computed: {
    headers() {
      let headers = [
        { text: 'Código Rota', value: 'codigo_rota', align: 'center' },
        { text: 'Descrição', value: 'descricao', align: 'start' },
        { text: 'Tipos', value: 'tipos' },
        { text: 'Regiões', value: 'regioes' },
        { text: 'Km', value: 'distancia', align: 'center',  width: 70 },
      ];

      if (this.type === 'CAPTACAO') {
        headers.push(
          { text: 'Produtores', value: 'total_produtor', align: 'center', width: 111 },
          { text: 'Vol.', value: 'litros', width: 95 },
          { text: 'L/Km', value: 'litersPerDistance', width: 80 },
          { text: 'CCS', value: 'media_ccs', width: 95 },
          { text: 'CPP', value: 'media_cbt', width: 95 },
        );
      }
      else {
        headers.push({ text: 'Clientes', value: 'total_produtor', width: 111 });
      }

      headers.push(
        { align: 'end', text: '', value: 'publicado', sortable: false, width: 40 },
        { align: 'end', text: '', value: 'acoes', sortable: false, width: 40 },
      );

      return headers;
    },

    /**
     * Exibe as rotas com base nos filtros selecionados em tela,
     * o resultado deve ser filtrado para garantir as permissões e o acesso a tela correta (comercial ou captação)
     */
    filteredRoutes() {
      return this.routes.filter(route =>
        this.filters.types.length
          ? (route.tipos || []).some(rt => this.filters.types.some(ft => rt.id_tipo === ft.id))
          : false);
    },

    /**
     * Total de produtores de todas as rotas
     */
    totalProducers() {
      return this.filteredRoutes.reduce(
        (sum, cur) => sum + cur.total_produtor,
        0
      );
    },

    /**
     * Total de rotas
     */
    totalRoutes() {
      return this.filteredRoutes.length;
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasRouteExcludeAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso == 'route-delete' && o.tipo === "COMPONENTE");
    },

    hasRouteSimulateAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso == 'route-simulate' && o.tipo === "COMPONENTE");
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = `${this.$options._componentTag}-${this.type}`;
        this.$store.commit("setFilter", { [filterId]: value });
      }
    }
  },

  created() {
    this.getFiltersFromStore();
  },

  mounted() {
    this.loadRoutes();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = `${this.$options._componentTag}-${this.type}`;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = this.$store.state.settings.filters[filterId];
      }
    },
    /**
     * Método responsável por carregar todas as rotas disponíveis para o laticínio
     * @returns {Promise<void>}
     */
    async loadRoutes() {
      try {
        this.onLoading = true;

        let query = { tipo: this.type };
        if (this.filters.user) {
          query.id_pessoa = this.filters.user.id;
        }

        const { data } = await this.$axios.post(`/rota/listaRotaResumidaJson`, query);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.routes = data.map(({ litros_ultimas_coletas, distancia, ...rest }) => {
          litros_ultimas_coletas = parseInt(litros_ultimas_coletas) || 0;
          distancia = parseFloat(distancia) || 0;

          return {
            ...rest,
            litros: litros_ultimas_coletas || null,
            distancia: distancia ? distancia.toFixed(1) : null,
            litersPerDistance: litros_ultimas_coletas && distancia ? (litros_ultimas_coletas / distancia).toFixed(1) : null,
          };
        });
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as rotas!",
          "Atenção"
        );

        console.log(err);
      } finally {
        this.onLoading = false;
      }
    },

    /**
     * Mostra o dialogo de confirmação de exclusão
     */
    showConfirmationDialog(route) {
      this.onExcluding = true;
      this.excluding = route;
    },

    /**
     * Fecha todos os dialogos abertos na tela
     */
    closeDialogs() {
      // Confirmação de exclusão
      this.onExcluding = false;
      this.excluding = {};

      // Relatório de produtores
      this.onReportShowing = false;
      this.report = {
        route: "",
        producers: [],
      };
    },

    /**
     * Apaga uma rota
     * @returns {Promise<void>}
     */
    async excludeRoute() {
      try {
        this.onExcluding = false;
        this.onLoading = true;

        const route = this.excluding;

        const { data } = await this.$axios.post(
          `/rota/salva`,
          {
            rotas: [{ id_rota: route.id_rota, remover: 1 }],
          }
        );

        const response = data[0];

        if (!response.codigo) {
          throw "Erro ao excluir a rota!";
        }

        await this.loadRoutes();
      } catch (error) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao remover a rota!",
          "Atenção"
        );

        console.log(error);
      } finally {
        // Garante que todos o modal esteja fechado e limpo!
        this.closeDialogs();

        this.onLoading = false;
      }
    },

    /**
     * Exibe o dialogo do relatório
     */
    async showRouteReport(route) {
      try {
        this.onLoading = true;
        this.report.route = route;

        const { data } = await this.$axios.post(
          `/relatorios/produtoresPorRota`,
          qs.stringify({
            id_rota: route.id_rota,
          })
        );

        this.report.producers = data.produtores;
        this.report.technical = data.tecnicos.map(tecnico => tecnico.nome).join(', ');
        this.onReportShowing = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.onLoading = false;
      }
    },

    async emptyReport(item) {
      try {
        this.onLoading = true;

        const { data } = await this.$axios.post(`/rota/emptyReport`, {
          id_rota: item.id_rota,
        });

        if (data.codigo === 0) {
          throw data.mensagem;
        }

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });

      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir!",
          "Atenção"
        );

      } finally {
        this.onLoading = false;
      }
    },

    /**
     * Gera o relatório em XLS
     * @returns {Promise<void>}
     */
    async reportToXLS() {
      try {
        this.onLoading = true;

        const reportData = this.report.producers.map(({ nome, codigo_laticinio, descricao, end_cidade }) => ({
          "Rota": descricao,
          "Código Produtor": codigo_laticinio,
          "Nome Produtor": nome,
          "Cidade": end_cidade,
        }));

        let data = XLSX.utils.json_to_sheet(reportData);
        const workbook = XLSX.utils.book_new();
        const filename = "Produtores por rota";
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      } catch (e) {
        console.log(e);
      } finally {
        this.onLoading = false;

        this.closeDialogs();
      }
    },

    /**
     * @event void
     *
     * Evento de criação de uma nova rota
     */
    onRouteCreating() {
      return this.$emit("onRouteCreating");
    },

    /**
     * @event void
     *
     * Evento que starta a simulação de rotas
     */
    onRouteSimulation() {
      const ids = this.selectedRoutes.map(route => route.id_rota);

      return this.$emit("onRouteSimulation", ids);
    },

    /**
     * @event object
     *
     * Evento de edição de uma nova rota
     */
    onRouteEditing(route) {
      return this.$emit("onRouteEditing", route);
    },

    /**
     * @event Object
     *
     * Evento acionado para exibir os detalhes de uma região
     */
    onRegionSelect(route) {
      return this.$emit("onRegionSelect", route);
    },

    onRouteSelect({ item }) {
      if (this.selectedRoutes.length > 3) {
        this.selectedRoutes = this.selectedRoutes.filter(route => route.id_rota !== item.id_rota);
      }
    },

    onPrintQRcodes(item) {
      this.qrCodeDialog.isOpen = true;
      this.qrCodeDialog.ids = item.id_rota;
    },

    async printQrCode(size) {
      try {
        this.qrCodeDialog.isOpen = false;
        this.$root.$progressBar.loading();

        const ids = this.qrCodeDialog.ids;

        const { data } = await this.$axios.post('/producer/rotaQrCode', { ids: ids, size })

        return printJS({
          printable: data,
          type: 'raw-html'
        })
      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao imprimir as etiquetas!', 'Atenção');
        console.error(e)
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async onPrintDownloadInstructions(route) {
      try {
        this.onLoading = true;

        const { data } = await this.$axios.post(
          `/rota/rotaFlyerDownloadApp`,
          {
            id_rota: route.id_rota
          }
        );

        if (_.has(data, "codigo")) {
          if (!data.codigo) {
            this.$snotify.warning(
              `${data.mensagem}`,
              "Atenção"
            );
          }

          return true;
        }

        return printJS({
          printable: data,
          type: 'raw-html',
          css: [
            'https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css',
            'https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap-theme.min.css',
          ]
        });
      } catch (e) {

        this.$snotify.error(
          "Oops, erro ao imprimir Flyer",
          "Atenção"
        );
      } finally {
        this.onLoading = false;
      }
    },

    onFilterChange() {

      this.loadRoutes();
    }
  },
};
</script>
