<template>
  <v-card
    :value="loading"
    flat
  >
    <v-card-text>
      <v-row>
        <v-col
          v-if="!listOnly"
          cols="6"
          offset="3"
          class="text-center rounded text-button font-weight-black py-2 mb-2"
          style="border: #a8a6b5 1px dashed; cursor: pointer"
          @click="onCreate"
        >
          Adicionar Status
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :cols="!listOnly ? '6' : '12'"
          :offset="!listOnly ? '3' : undefined"
          style="max-height: 75vh"
          class="timeline-container pt-0"
        >
          <producer-status-timeline
            :history="localHistory"
            :list-only="listOnly"
            :show-username="showUsername"
            @edit="onEdit"
            @exclude="onExclude"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <status-producer-dialog
      ref="statusProducerDialog"
      history-only
      @save="onRowSaved"
    />
  </v-card>
</template>

<style lang="scss">

.timeline-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.timeline-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow: auto;
}

</style>

<script>
import _ from "lodash";
import moment from "moment";

import { StatusEnum } from "@/Domains/Producer/Management/Enums/StatusEnum.js";
import { ReasonsEnum } from "@/Domains/Producer/Management/Enums/ReasonsEnum.js";

import StatusProducerDialog from "@/Domains/Producer/Management/Components/Dialogs/StatusProducerDialog.vue";
import ProducerStatusTimeline from "@/Domains/Producer/Management/Components/Timeline/ProducerStatusTimeline.vue";

export default {
  components: {
    ProducerStatusTimeline,
    StatusProducerDialog,
  },

  props: {
    history: {
      type: Array,
      default: () => []
    },

    producerId: {
      type: String,
    },

    producer: {
      type: Object,
      default: () => ({}),
    },

    listOnly: Boolean,
    showUsername: Boolean,
  },

  data() {
    return {
      loading: false,

      StatusEnum,
      ReasonsEnum,
    };
  },

  computed: {
    localHistory() {
      const historyRows = this.history.map(row => {
        return {
          id: row.id_vinculacao,
          date: moment(row.data_entrada).toDate(),
          status: row.status,
          competitor: {
            id: row.id_laticinio_concorrente,
            name: row.laticinio_concorrente,
          },
          reason: row.motivo,
          otherReasons: row.outros_motivos,
          userName: row.nome_pessoa_registro,
          groupProducersHistory: row.historico_produtores_grupo,
          params: {
            showExcludeBtn: true,
            showStatusSelect: true,
            minInputDateLimit: null,
            maxInputDateLimit: null,
          },
        }
      });

      let history = [
        ...historyRows,
        {
          date: this.producer.data_cadastro_app ? this.producer.data_cadastro_app : null,
          status: 'CADASTRO',
          params: {
            showExcludeBtn: false, // Desabilita exclusão da data de cadastro (Impossível excluir)
            showStatusSelect: true,
            minInputDateLimit: null,
            maxInputDateLimit: null,
          },
        }
      ];

      history = history.reduce((acm, currentValue, currentIndex) => {
        const lastValue = _.last(acm) || {};
        const nextValue = history[currentIndex + 1] || {};

        acm.push({
          ...currentValue,
          params: {
            ...currentValue.params,
            minInputDateLimit: nextValue.date || null,
            maxInputDateLimit: lastValue.date || null,
          }
        });

        return acm;
      }, []);

      for (const row of history) {
        // Desabilita o botão de exclusão de status para o primeiro registro igual ao status atual
        if (row.status === this.producer.status) {
          row.params.showStatusSelect = false;
          row.params.showExcludeBtn = false;
          break;
        }
      }

      return history;
    }
  },

  methods: {
    onCreate() {
      const maxInputDateLimit = _.get(_.first(this.localHistory), 'date');
      const minInputDateLimit = _.get(_.last(this.localHistory), 'date');

      return this.$refs.statusProducerDialog.show({
        title: 'Inserir Status (Histórico)',
        producer: {
          id: this.producerId,
          name: this.producer.nome,
        },
        form: {
          status: '',
          date: maxInputDateLimit,
          params: {
            showStatusSelect: true,
            minInputDateLimit,
            maxInputDateLimit,
          },
        },
      });
    },

    onEdit(row) {
      return this.$refs.statusProducerDialog.show({
        title: 'Alterar Status (Histórico)',
        producer: {
          id: this.producerId,
          name: this.producer.nome,
        },
        form: row
      });
    },

    onRowSaved() {
      return this.$emit('save');
    },

    async onExclude(row) {
      try {
        const confirm = await this.$root.$confirm("Atenção", "Deseja realmente Excluir esta status?", { color: "red" });

        if (!confirm) {
          return;
        }

        this.loading = true;

        const data = await this.$axios.post(
          `/produtores/inativaStatus`,
          {
            id_vinculacao: row.id,
            id_produtor: this.producerId,
          }
        );

        if (data.codigo === 0) {
          throw "Erro ao deletar status";
        }

        return this.$emit('save');
      } catch (error) {
        this.$snotify.error(error, "Atenção");

        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    dateFormat(date, format) {
      return moment(date).format(format);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  }
}
</script>
