<template>
  <div>
    <v-card
      class="v-tamanho"
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg
            class="pt-0"
          >
            <date-range-picker
              v-model="filters.date.input"
              @change="onDateFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg
            class="pt-0"
          >
            <person-autocomplete-filter
              v-model="filters.driver"
              label="Motorista"
              type="DRIVER"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg
            class="pt-0"
          >
            <vehicle-autocomplete-filter
              v-model="filters.vehicle"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg
            class="pt-0"
          >
            <routes-autocomplete-filter
              v-model="filters.route"
              label="Rota"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg
            class="pt-0"
          >
            <v-select
              v-model="filters.type"
              :items="[
                { value: 'coleta', text: 'COLETA' },
                { value: 'spot', text: 'SPOT' },
                { value: 'transferencia', text: 'TRANSFERÊNCIA' },
              ]"
              clearable
              filled
              hide-details
              label="Tipo"
              prepend-inner-icon="local_shipping"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg
            class="pt-0"
          >
            <v-select
              v-model="filters.operation"
              :items="['CARGA', 'DESCARGA']"
              clearable
              filled
              hide-details
              label="Operação"
              prepend-inner-icon="swap_vert"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg
            class="pt-0"
          >
            <v-select
              v-model="filters.status"
              :items="[
                'CONFORME',
                'INCONFORME',
                'DESCARTE',
                'RESTRITO',
                'DEVOLUÇÃO',
              ]"
              clearable
              filled
              hide-details
              label="Status"
              prepend-inner-icon="biotech"
              multiple
              small-chips
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg
            class="pt-0"
          >
            <v-select
              v-model="filters.rawMaterialId"
              label="Matéria Prima"
              :items="rawProducts"
              item-value="id"
              item-text="name"
              prepend-inner-icon="category"
              clearable
              filled
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg
            class="pt-0"
          >
            <v-text-field
              v-model="filters.dairyCode"
              label="Código Itinerário/Nº Nota"
              filled
              dark
              type="number"
              @change="loadFinishedUnloadsList"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        v-model="selectedUnloads"
        :headers="[
          { align: 'start', text: 'ID Carga', value: 'id', width: 80, show: false },
          { align: 'center', text: 'Código', value: 'itinerary.code'},
          { align: 'start', text: 'Nº Nota', value: 'invoiceNumber'},
          { align: 'start', text: 'Motorista', value: 'driver.name', width: 150},
          { align: 'start', text: 'Tipo', value: 'itinerary.type' },
          { align: 'center', text: 'Matéria Prima', value: 'rawProduct', formatter: value => (value.rawName || value.name || '').toUpperCase() },
          { align: 'center', text: 'Operação', value: 'operation' },
          { align: 'center', text: 'Placa', value: 'vehicle.plate' },
          { align: 'center', text: 'Data/Hora Operação', value: 'arrivalTime'},
          { align: 'start', text: 'Origem/Destino/Rota', value: 'route.description', width: 200},
          { align: 'center', text: 'Vale', value: 'vale.original' },
          { align: 'center', text: 'Vale Ajustado', value: 'vale.current'},
          { align: 'center', text: 'Ajuste', value:'adjustment' },
          { align: 'center', text: 'Descartado', value: 'discarted'},
          { align: 'center', text: 'Devolvido', value: 'returned'},
          { align: 'center', text: 'Vale Liquido', value: 'liquidMensured'},
          { align: 'center', text: 'Medido Liquido', value: 'netMensured'},
          { align: 'center', text: 'Medidos', value: 'measured' },
          { align: 'center', text: 'Diferença', value: 'difference' },
          { align: 'center', text: 'Desconto', value:'discount' },
          { align: 'start', text: '', altText: 'Ações de recebimento', value: 'unloadOperations', sortable: false, width: 180 },
          { align: 'center', text: '', altText: 'Logs', value: 'justifications', sortable: false, width: 45, show: isJustificationEnabled },
          { align: 'center', text: '', altText: 'Opções', value: 'actions', sortable: false, width: 60 },
        ]"
        :items="filteredUnloads"
        :loading="loading"
        class="elevation-1"
        mobile-breakpoint="1000"
        editing
        show-select
      >
        <template #[`item.itinerary.type`]="{ value, item }">
          <v-chip small>
            {{ itineraryTypes[value] }}
          </v-chip>
          <v-icon
            v-if="!!item.originId"
          >
            settings_backup_restore
          </v-icon>
        </template>

        <template #item.rawProduct="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                small
                v-on="on"
              >
                {{ (value.rawName || value.name || '').toUpperCase() | truncate(15) }}
              </v-chip>
            </template>

            {{ value.rawName || value.name }}
          </v-tooltip>
        </template>

        <template #[`item.operation`]="{ item }">
          <v-chip small>
            <v-avatar left>
              <v-icon
                v-if="item.operation === 'DESCARGA'"
                color="red"
                v-text="'arrow_circle_up'"
              />
              <v-icon
                v-if="item.operation === 'CARGA'"
                color="green"
                v-text="'arrow_circle_down'"
              />
            </v-avatar>

            <span>
              {{ item.operation }}
            </span>
          </v-chip>
        </template>

        <template v-slot:[`item.unloadOperations`]="{ item }">
          <unload-operations
            :ref="`operations-${item.id}`"
            :row="item"
            class="pa-3"
            editing
            :raw-products="rawProducts"
            @onRawProductUpdated="onRawProductUpdated"
            @onUnloadConclude="onUnloadConclude"
          />
        </template>

        <template #[`item.arrivalTime`]="{ item }">
          <v-chip small>
            {{ item.arrivalTime }}
          </v-chip>
        </template>

        <template #[`item.adjustment`]="{ item }">
          <v-chip
            small
          >
            <v-avatar
              left
              style="margin-right:1px"
            >
              <v-icon :color="item.adjustment > 0 ? 'green':'red'">
                {{ item.adjustment > 0 ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </v-icon>
            </v-avatar>
            {{ item.adjustment | santizeNumber() }}
          </v-chip>
        </template>

        <template #[`item.justifications`]="{ item }">
          <v-btn
            v-if="item.justifications.length > 0"
            icon
            @click.stop="showJustifications(item.justifications)"
          >
            <v-icon>
              info
            </v-icon>
          </v-btn>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="showUnloadAnalysisReportDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>receipt</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Laudos
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="canReverseTransfers"
                @click="showRevertConfirmationDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>undo</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Retornar para a Fila
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.itinerary.type === 'spot' && item.operation === 'CARGA'"
                @click="showReturnSpotDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>settings_backup_restore</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Gerar devolução
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasInactivateAccess"
                @click="excludeUnload(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <unload-analysis-report-dialog
      ref="unloadAnalysisReportDialog"
    />

    <generate-spot-return-dialog
      ref="generateSpotReturnDialog"
    />

    <analysis-log-dialog
      ref="analysisLogDialog"
    />

    <print-settings-dialog
      ref="print-settings"
      @print="print"
    />

    <checklist-platform-print-dialog
      ref="checklistPlatformPrintDialog"
    />

    <v-dialog
      v-model="confirmationDialog.show"
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Atenção
        </v-card-title>
        <v-card-text>
          <v-form
            ref="confirmationDialogForm"
            lazy-validation
            @submit.prevent=""
          >
            <div v-html="confirmationDialog.content" />

            <v-textarea
              v-if="confirmationDialog.showMotive"
              v-model="confirmationDialog.motive"
              filled
              label="Motivo da reversão"
              auto-grow
              counter="25"
              :rules="[
                v => !!v || 'Campo obrigatório!',
                v => (v || '').length >= 25 || 'Justificativa muito curta',
              ]"
              class="counter-success"
            />

            <div v-html="confirmationDialog.subContent" />

            <v-text-field
              v-model.number="confirmationDialog.typedToken"
              :rules="[v => (v && v === confirmationDialog.confirmationToken) || 'Token de confirmação incorreto!']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialogs()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmationDialog.handler()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          class="mb-0"
          color="white"
          indeterminate
        />
      </v-card-text>
    </v-overlay>
    <v-speed-dial
      bottom
      dark
      direction="top"
      fixed
      open-on-hover
      right
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          fab
          large
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        color="green darken-1"
        dark
        fab
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selectedUnloads.length"
        color="orange darken-1"
        dark
        fab
        @click="onPrint"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              receipt_long
            </v-icon>
          </template>
          Imprimir Recibo
        </v-tooltip>
      </v-btn>

      <v-badge
        v-if="selectedUnloads.length"
        color="white"
        overlap
        class="btn-badge"
      >
        <template #badge>
          <v-icon color="green darken-3">
            backup_table
          </v-icon>
        </template>
        <v-btn
          color="green darken-1"
          dark
          fab
          @click="exportAnalysisReport"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                biotech
              </v-icon>
            </template>
            Relatório Análises (Excel)
          </v-tooltip>
        </v-btn>
      </v-badge>

      <v-badge
        v-if="selectedUnloads.length"
        color="white"
        overlap
        class="btn-badge"
      >
        <template #badge>
          <v-icon color="orange darken-3">
            print
          </v-icon>
        </template>
        <v-btn
          color="orange"
          dark
          fab
          @click="printAnalysisReport"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                biotech
              </v-icon>
            </template>
            Relatório Análises (PDF)
          </v-tooltip>
        </v-btn>
      </v-badge>

      <v-btn
        v-if="hasChecklistsAccess"
        color="blue"
        dark
        fab
        @click="showChecklistPlatformPrintDialog()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              checklist
            </v-icon>
          </template>
          Checklists Plataforma
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<style lang="scss">
.counter-success {
  .v-counter.error--text {
    color: #43A047 !important;
    caret-color: #43A047 !important;
  }
}
</style>

<script>
import _ from "lodash";
import moment from "moment";
import printJS from "print-js";

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import VehicleAutocompleteFilter from "@/Support/Components/Filters/VehicleAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";

import UnloadOperations from "@/Domains/Platform/Unload/Components/UnloadOperations.vue";
import UnloadAnalysisReportDialog from "@/Domains/Platform/Unload/Components/UnloadAnalysisReportDialog.vue";
import GenerateSpotReturnDialog from "@/Domains/Platform/Unload/Components/GenerateSpotReturnDialog.vue";
import AnalysisLogDialog from "@/Domains/Platform/Unload/Components/AnalysisLogDialog.vue";
import ChecklistPlatformPrintDialog from "@/Domains/Platform/Unload/Components/ChecklistPlatformPrintDialog.vue";

import analysisParams from '@/Domains/Settings/Components/Analysis/Services/AnalysisParams.js';

export default {

  components: {
    DateRangePicker,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
    VehicleAutocompleteFilter,
    PrintSettingsDialog,
    UnloadOperations,
    UnloadAnalysisReportDialog,
    GenerateSpotReturnDialog,
    AnalysisLogDialog,
    ChecklistPlatformPrintDialog,
  },

  filters: {

    santizeNumber(value) {
      return value > 0 ? value : (-1) * value
    },

    dateTime(value) {
      return moment(value).format('DD/MM/YYYY HH:mm');
    },

    truncate(value, length) {
      return _.truncate(value, { length });
    },
  },

  mixins: [ReportMixin],

  data() {
    return {
      // Loaders
      loading: true,

      // Dados brutos do backend
      unloads: [],

      // Lista de descarregamentos selecionados para impressão do relatório
      selectedUnloads: [],

      // Filtros da tabela
      filters: {
        vehicle: {},

        driver: {},

        route: {},

        date: {
          input: "today",
          range: [],
        },

        type: null,

        operation: null,

        status: [],

        rawMaterialId: '',

        dairyCode: '',
      },

      // Matérias primas disponíveis
      rawProducts: [],

      confirmationDialog: {
        show: false,
        unload: {},
        content: '',
        subContent: '',
        handler: () => {},
        showMotive: false,
        motive: null,
        confirmationToken: '',
      },

      itineraryTypes: {
        'coleta': 'COLETA',
        'transferencia': 'TRANSFERÊNCIA',
        'spot': 'SPOT',
      },
    };
  },

  computed: {
    /**
     * Exibe as rotas com base nos filtros selecionados em tela
     */
    filteredUnloads() {
      if (_.isEmpty(this.filters.vehicle) && _.isEmpty(this.filters.driver) && _.isEmpty(this.filters.route) && !this.filters.type && !this.filters.operation && this.filters.status.length === 0 && !this.filters.rawMaterialId) {
        return this.unloads;
      }

      return this.unloads.filter(unload => {

        const hasDriver = !this.filters.driver.id || this.filters.driver.id === unload.driver.id;
        const hasVehicle = !this.filters.vehicle.plate || this.filters.vehicle.plate === unload.vehicle.plate;
        const hasRoute = !this.filters.route.id || this.filters.route.id === unload.route.id;
        const hasType = !this.filters.type || this.filters.type === unload.itinerary.type;
        const hasOperation = !this.filters.operation || this.filters.operation === unload.operation;
        const hasConforming = !this.filters.status.includes('CONFORME') || (this.filters.status.includes('CONFORME') && !unload.hasTanksError);
        const hasNonconforming = !this.filters.status.includes('INCONFORME') || (this.filters.status.includes('INCONFORME') && unload.hasTanksError);
        const hasDiscard = !this.filters.status.includes('DESCARTE') || (this.filters.status.includes('DESCARTE') && unload.hasDiscard);
        const hasRestriction = !this.filters.status.includes('RESTRITO') || (this.filters.status.includes('RESTRITO') && unload.hasRestriction);
        const hasReturn = !this.filters.status.includes('DEVOLUÇÃO') || (this.filters.status.includes('DEVOLUÇÃO') && unload.hasReturn);
        const hasRawMaterial = !this.filters.rawMaterialId || this.filters.rawMaterialId === unload.rawProduct.id;

        return hasDriver && hasVehicle && hasRoute && hasType && hasOperation && hasConforming && hasNonconforming && hasDiscard && hasRestriction && hasReturn && hasRawMaterial;
      });
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasInactivateAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-unload-inactivate" && o.tipo === "COMPONENTE");
    },

    hasChecklistsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "platform-checklist" && o.tipo === "COMPONENTE");
    },

    isJustificationEnabled() {
      const settings = this.$store.state.settings.plataforma || {};

      return settings.justificar_edicao_analise || false
    },

    canReverseTransfers() {
      return this.$store.state.settings.user.id_cargo === 1 || this.userResources.some(o => o.recurso === 'reverse-transfers' && o.tipo === 'COMPONENTE');
    },

    hasItineraryData() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'hide-itinerary-data' && o.tipo === "COMPONENTE");
    },
  },

  async mounted() {
    await this.loadRawProductsList();

    await this.loadFinishedUnloadsList();
  },

  methods: {

    async loadRawProductsList() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: 'TODOS'
        } });

        this.rawProducts = data.map(rawProduct => {
          return {
            id: rawProduct.id_item,
            name: rawProduct.nome,
            type: rawProduct.tipo,
            rawMaterialGroupId: rawProduct.id_agrupador_materia_prima,
          };
        });
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as matérias primas!",
          "Atenção"
        );

        console.log(err);
      } finally {

        this.loading = false;
      }
    },

    /**
     * Método responsável por carregar todas as rotas disponíveis para o laticínio
     * @returns {Promise<void>}
     */
    async loadFinishedUnloadsList() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/descargaColeta/listaResumida`,
          {
            data_inicio: _.first(this.filters.date.range) || moment().format("YYYY-MM-DD"),
            data_fim: _.last(this.filters.date.range) || moment().format("YYYY-MM-DD"),
            estado: ['CARREGADO', 'DESCARREGADOS'],
            cod_itinerario: this.filters.dairyCode ? this.filters.dairyCode : null,
          }
        );

        if (!_.isArray(data)) {
          throw "PHP Error";
        }

        const availableStatus = {
          'DESCARREGADOS': 'UNLOADED',
          'CARREGADO': 'LOADED',
        }

        this.unloads = data.map(item => {

          const analysts = JSON.parse(item.analistas) || [];

          let rawProduct = this.rawProducts.find(rawProduct => rawProduct.id === item.id_materia_prima);
          const rawProductInput = { ...rawProduct };

          // Caso seja materia prima agrupada é realizada a busca pelo id da materia prima pai
          if (rawProduct.rawMaterialGroupId) {
            rawProduct = {
              rawId: rawProduct.id,
              rawName: rawProduct.name,
              ...this.rawProducts.find(({ id }) => id === rawProduct.rawMaterialGroupId),
            }
          }

          return {
            id: item.id_descarga_coletas,
            status: availableStatus[item.estado],
            departureTime: item.data_hora_inicio,
            arrivalTime: item.data_hora_descarga ? moment(item.data_hora_descarga).format("DD/MM/YYYY HH:mm") : '',
            entrancedAt: item.data_hora_baixa_portaria ? moment(item.data_hora_baixa_portaria).format("DD/MM/YYYY HH:mm") : '',
            departureAt: item.data_hora_saida_portaria ? moment(item.data_hora_saida_portaria).format("DD/MM/YYYY HH:mm") : '',
            sampleTakenAt: item.hora_retirada_amostra ? moment(item.hora_retirada_amostra).format("DD/MM/YYYY HH:mm") : '',
            releasedAt: item.hora_liberacao ? moment(item.hora_liberacao).format("DD/MM/YYYY HH:mm") : '',
            inletWeight: parseInt(item.peso_entrada) || 0,
            outletWeight: parseInt(item.peso_saida) || 0,
            measured: parseInt(item.peso_medido) || 0,
            difference: parseInt(item.diferenca_peso) || 0,
            discount: parseInt(item.desconto) || 0,
            discarted: parseInt(item.descartado) || 0,
            returned: parseInt(item.devolvido) || 0,
            liquidMensured: parseInt(item.peso_vale_original || 0) - parseInt(item.descartado || 0) - parseInt(item.devolvido || 0),
            netMensured: parseInt(item.peso_medido || 0) - parseInt(item.descartado || 0) - parseInt(item.devolvido || 0),
            obs: item.observacao,
            reports: JSON.parse(item.anexos) || [],
            operation: item.operacao,
            hasDiscard: item.descartes || false,
            hasRestriction: item.restricoes || false,
            hasReturn: item.devolucoes || !!item.id_descarga_origem || false,
            invoiceNumber: item.numero_nota,
            cargaLacre: item.carga_lacre,
            cip: item.cip,
            analysts: analysts.map(analyst => {
              return {
                id: analyst.id_pessoa,
                name: analyst.nome_pessoa,
                signature: analyst.assinatura,
              };
            }),
            driver: {
              id: item.id_motorista,
              name: item.nome_motorista,
            },
            vehicle: {
              plate: item.placa_veiculo,
            },
            route: {
              id: item.id_rota,
              description: item.nome_rota,
            },
            itinerary: {
              id: item.id_itinerario,
              type: item.tipo,
              code: item.cod_itinerario,
            },
            vale: {
              original: parseInt(item.peso_vale_original) || 0,
              current: parseInt(item.peso_vale) || 0,
            },
            transfers: [],
            tanks: [],
            adjustment: parseInt(item.peso_vale || 0) - parseInt(item.peso_vale_original || 0),
            rawProduct: rawProduct || {},
            rawProductInput,
            hasTanksError: item.inconformidades_tanque,
            hasProducersError: item.inconformidades_produtor,
            originId: item.id_descarga_origem,
            justifications: JSON.parse(item.justificativas_alteracao) || [],
          };
        });
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as rotas!",
          "Atenção"
        );

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async onUnloadConclude() {
      return this.loadFinishedUnloadsList();
    },

    onDateFilter(event) {
      this.filters.date.range = event;

      return this.loadFinishedUnloadsList();
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.date.range;
      return `Relatorio da plataforma - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    getReportJson(isExcel = false) {
      const json = this.$refs.report.getReportJson(isExcel);

      if (json.length > 0) {
        const firstHeader = Object.keys(json[0])[0];

        const totals = {
          [firstHeader]: 'Total',
          'Vale': _.sumBy(json, 'Vale'),
          'Medidos': _.sumBy(json, 'Medidos'),
        };

        json.push(totals);
      }

      return json;
    },

    exportExcel() {
      const filename = this.getReportTitle();

      const json = this.getReportJson(true);

      this.$refs.report.exportExcel(json, filename);
    },

    showUnloadAnalysisReportDialog(unload) {
      return this.$refs.unloadAnalysisReportDialog.show(unload);
    },

    showRevertConfirmationDialog(unload) {
      this.confirmationDialog.unload = unload;
      this.confirmationDialog.show = true;
      this.confirmationDialog.handler = this.revertUnload;
      this.confirmationDialog.showMotive = true;
      this.confirmationDialog.content = `
      Deseja realmente reverter esta ${unload.operation.toLowerCase()} para a fila?
        <br /> <br />
      - Rota: <b>${unload.route.description}</b>
        <br />
      - Motorista: <b>${unload.driver.name}</b>
        <br />
      - Veiculo: <b>${unload.vehicle.plate || ''}</b>
        <br /> <br />
      ${ unload.operation === 'CARGA' ? `<div class="v-alert v-sheet v-sheet--outlined theme--light elevation-0 v-alert--dense v-alert--outlined red--text body-2">
          As análises efetuadas serão excluídas!
        </div>` : '' }
      `;
      this.confirmationDialog.subContent = `
          <br />
        Esta ação não poderá ser revertida!
          <br /> <br />
        Para continuar, por favor digite <b>REVERTER</b> no campo abaixo:
      `;
      this.confirmationDialog.confirmationToken = 'REVERTER';
    },

    showReturnSpotDialog(unload) {
      return this.$refs.generateSpotReturnDialog.show(unload);
    },

    onRawProductUpdated(id, rawProduct) {
      const row = this.unloads.find(item => item.id === id);

      row.rawProduct = { ...rawProduct };
    },

    closeDialogs() {
      this.confirmationDialog = {
        show: false,
        unload: {},
        content: '',
        subContent: '',
        confirmationToken: '',
        handler: () => {},
        showMotive: false,
        motive: null,
      };
    },

    async revertUnload() {
      try {
        this.loading = true;

        const valid = this.$refs.confirmationDialogForm.validate();

        if (!valid) {
          return;
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/reverteDescargaFila`,
          {
            id_descarga_coletas: this.confirmationDialog.unload.id,
            motivo: this.confirmationDialog.motive,
          }
        );

        if (!_.isObject(data)) {
          throw data;
        }

        const { codigo } = data;

        if (codigo !== 1) {
          throw data;
        }

        await this.loadFinishedUnloadsList();

        return this.closeDialogs();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao reverter a descarga!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async excludeUnload(unload) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente excluir esta descarga?
          <br /> <br />
        - Rota: <b>${unload.route.description}</b>
          <br />
        - Motorista: <b>${unload.driver.name}</b>
          <br />
        - Veiculo: <b>${unload.vehicle.plate || ''}</b>
          <br /> <br />
        Esta ação não poderá ser revertida!
        <br /> <br />`,
        { color: 'red', token: 'EXCLUIR' }
      ))) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/descargaColeta/inativa`,
          { id_descarga_coletas: unload.id }
        );

        if (!_.isObject(data)) {
          throw data;
        }

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        await this.loadFinishedUnloadsList();
      } catch (e) {
        console.log(e);
        const message = e.message || e;
        this.$snotify.error(message, "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onPrint() {
      const params = [
        { title: 'Movimentações do Silo', key: 'mostra_movimentacoes_silo', value: false },
      ];

      if (this.hasItineraryData) {
        params.push({ title: 'Dados do Itinerário', key: 'mostra_dados_itinerario', value: false },)
      }

      if (this.isJustificationEnabled) {
        params.push({ title: 'Justificativas de edição', key: 'mostra_justificativas', value: false })
      }

      if (this.hasChecklistsAccess) {
        params.push({ title: 'Checklists', key: 'mostra_checklists', value: false })
      }

      this.$refs['print-settings'].show({
        item: {
          ids: this.selectedUnloads.map(unload => unload.id)
        },
        params
      });
    },

    async print({ item,  params }) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/descargaColeta/reciboDescarga`, {
          ids: item.ids,
          params,
        });

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir as descargas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async printAnalysisReport() {
      try {
        this.loading = true;

        if (_.isEmpty(this.selectedUnloads)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/impressaoAnalises`,
          { ids: this.selectedUnloads.map(unload => unload.id) }
        );

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.warn(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao gerar o relatório!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async exportAnalysisReport() {
      try {
        this.loading = true;

        if (_.isEmpty(this.selectedUnloads)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/detalhesAnalises`,
          { ids: this.selectedUnloads.map(unload => unload.id) }
        );

        /**
         * Styles
         */
        const BG_YELLOW = { fill: { fgColor: { rgb: 'FFEB3B' } } };
        const BG_INDIGO = { fill: { fgColor: { rgb: '3F51B5' } }, font: { color: { rgb: 'FFFFFF' } } };
        const BG_GREY = { fill: { fgColor: { rgb: '9E9E9E' } }, font: { color: { rgb: 'FFFFFF' } } };
        const BG_DANGER = { fill: { fgColor: { rgb: 'F8BBD0' } }, font: { color: { rgb: 'B71C1C' } } };

        const styles = {
          headers: [ BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW ],
          rows: [],
        };

        const tanques = Math.max(...data.map(r => r.analises.length));

        let fields = [
          { key: 'temperatura', title: 'TEMP.', unit: '°C', style: BG_DANGER },
          { key: 'crioscopia', title: 'CRI.', unit: '°C' },
          { key: 'alizarol', title: 'ALIZ.', unit: '°GL' },
          { key: 'acidez', title: 'ACID.', unit: '°D' },
          { key: 'gordura', title: 'GORD.', unit: '%/100g' },
          { key: 'densidade', title: 'DENS.', unit: 'g/ml' },
          { key: 'est', title: 'EST' },
          { key: 'esd', title: 'ESD', unit: 'g/100g' },
          { key: 'proteina', title: 'PROTEÍNA', unit: 'g/100g' },
          { key: 'porcentagem_agua', title: 'ÁGUA', unit: '%' },
          { key: 'volume_agua', title: 'ÁGUA', unit: 'L' },
          { key: 'solidos_totais', title: 'S.T.' },
          { key: 'ph', title: 'pH' },
          { key: 'brix', title: 'Brix' },
          { key: 'lactose', title: 'LACTOSE' },
          { key: 'redutase', title: 'REDUT.' },
          { key: 'solubilidade', title: 'SOLUB.', unit: 'g/100g' },
          { key: 'base_seca', title: 'BASE SECA', unit: '%' },
        ];

        let analysisCheckboxes = [
          'alizarol_qualit',
          'mastite',
          'sensorial',
          'alcool',
          'fosfatase',
          'grumos',
          'coagulos',
          'peroxidase',
          'outras_especies',
          'antibiotico',
          'neutralizantes',
          'reconstituintes',
          'conservantes',
        ];

        // Busca a matéria prima para filtrar os campos configurados e fazer map buscando a unidade de medida correta
        const rawProducts = _.uniq(data.map(r => r.id_materia_prima));
        if (rawProducts.length === 1) {
          const analysisParams = _.get(this.$store.state.settings, `plataforma.parametros_analise.materia_prima.${rawProducts[0]}`);

          if (!_.isEmpty(analysisParams)) {
            fields = fields
              .map(field => {
                const param = analysisParams.find(param => param.parametro === field.key);

                if (!param) {
                  return null;
                }

                return {
                  ...field,
                  unit: param?.unidade_medida?.unidade || field.unit,
                };
              })
              .filter(field => !!field);

            analysisCheckboxes = analysisCheckboxes
              .filter(cb => !!analysisParams.find(param => param.parametro === cb));
          }
        }

        const checkboxes = analysisParams.filter(item => analysisCheckboxes.includes(item.param));

        for (let field of fields) {
          for (let i = 0; i < tanques; i++) {
            styles.headers.push(field.style || BG_INDIGO);
          }
          styles.headers.push(field.style || BG_GREY);
        }

        for (let i = 0; i < (tanques * checkboxes.length); i++) {
          styles.headers.push(BG_YELLOW);
        }

        // Analistas
        styles.headers.push(BG_INDIGO);
        //Valor Spot
        styles.headers.push(BG_YELLOW);

        const getCbResult = (cb, analise) => {
          const resultado = analise?.[cb.param];

          if (cb.standardDescription === 'Ausência') {
            return resultado ? 'Presença' : 'Ausência';
          }

          if (cb.depends?.length) {
            if (cb.depends.some(d => analise?.[d.param])) {
              const depends = cb.depends.filter(d => analise?.[d.param]).map(d => d.text).join(', ')
              return `Positivo (${depends})`;
            }

            return 'Negativo';
          }

          return resultado ? 'Positivo' : 'Negativo';
        }

        /**
         * Report
         */
        const title = 'Relatório de Análises';

        const report = data.map(item => {

          const rawProduct = this.rawProducts.find(rawProduct => rawProduct.id === item.id_materia_prima) || {};

          const rowStyle = [ BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW, BG_YELLOW ];

          let unload = {
            'DATA RECEBIMENTO (PCP)': this.formatDate(item.data_hora_descarga, 'DD/MM/YYYY'),
            'HORA RECEBIMENTO': this.formatDate(item.data_hora_descarga, 'HH:mm'),
            'TIPO': item.tipo,
            'MATÉRIA PRIMA': rawProduct.name,
            'OPERAÇÃO': item.operacao,
            'ROTA': item.nome_rota,
            'MOTORISTA': item.nome_motorista,
            'PLACA': item.placa_veiculo,
            'PESO VALE': this.formatNumber(item.peso_vale),
            'PESO MEDIDO': this.formatNumber(item.peso_medido),
            'DATA CHEGADA PORTARIA': this.formatDate(item.data_hora_baixa_portaria, 'DD/MM/YYYY'),
            'HORA CHEGADA PORTARIA': this.formatDate(item.data_hora_baixa_portaria, 'HH:mm'),
            'DATA SAÍDA PORTARIA': this.formatDate(item.data_hora_saida_portaria, 'DD/MM/YYYY'),
            'HORA SAÍDA PORTARIA': this.formatDate(item.data_hora_saida_portaria, 'HH:mm'),
            'HORA RET. AMOSTRA': this.formatDate(item.hora_retirada_amostra, 'HH:mm'),
            'HORA LIBERAÇÃO': this.formatDate(item.hora_liberacao, 'HH:mm'),
            'LACRE DA CARGA': item.carga_lacre,
            'CIP': item.cip,
          }

          for (let field of fields) {
            const unit = field.unit ? `(${field.unit})` : '';
            const resultados = [];

            for (let i = 0; i < tanques; i++) {
              const resultado = item.analises[i]?.[field.key];
              unload[`${field.title} ${i + 1} ${unit}`] = this.formatNumber(resultado);
              if (resultado != null) resultados.push(parseFloat(resultado));
            }

            unload[`MÉDIA ${field.title} ${unit}`] = this.formatNumber(_.mean(resultados));
          }

          for (let cb of checkboxes) {
            for (let i = 0; i < tanques; i++) {
              unload[`${cb.text} ${i + 1}`] = getCbResult(cb, item.analises[i]);
            }
          }

          unload['ANALISTAS'] = item.analistas;
          unload['VALOR LITRO SPOT'] = this.formatNumber(item.valor_litro);

          for (let i = 0; i < tanques; i++) {
            const analise = item.analises[i] || {};
            unload[`LACRE. ${i + 1} `] = analise.numero_lacre;
          }

          styles.rows.push(rowStyle);

          return unload;
        })

        const masks = {
          'VALOR LITRO SPOT': 'R$ #,##0.00',
        }

        const headers = Object.keys(report[0]).map(value => {
          const header = { text: value, value: value }
          if (value in masks) {
            header.mask = masks[value]
          }
          return header
        });

        this.exportToFile({ report, headers, title, styles });
      } catch (e) {
        console.warn(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao gerar o relatório!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    showJustifications(justifications) {
      return this.$refs.analysisLogDialog.show(justifications);
    },

    showChecklistPlatformPrintDialog() {
      const startDate = this.filters.date.range?.[0] || moment().format('YYYY-MM-DD');
      const endDate = this.filters.date.range?.[1] || moment().format('YYYY-MM-DD');

      return this.$refs.checklistPlatformPrintDialog.show(startDate, endDate);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => value == null || isNaN(value) ? '' : parseFloat(value),
  },

};
</script>
