<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    persistent
    max-width="1000"
    scrollable
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="questionnaire-dialog">
      <v-card-title class="text-h5">
        {{ form.titulo }}

        <v-spacer />

        <v-menu
          v-if="answerId"
          bottom
          right
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="onPrint()"
            >
              <v-list-item-icon>
                <v-icon>print</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Imprimir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row
            v-if="object === 'DESCARGA' && unload"
            class="mb-8"
          >
            <v-col
              cols="3"
              md
              lg
              class="pb-0"
            >
              <v-text-field
                :value="unload.itinerary.code"
                label="Itinerário"
                hide-details
                disabled
                dense
              />
            </v-col>
            <v-col
              cols="3"
              md
              lg
              class="pb-0"
            >
              <v-text-field
                :value="unload.route.description"
                label="Origem/Destino/Rota"
                hide-details
                disabled
                dense
              />
            </v-col>
            <v-col
              cols="3"
              md
              lg
              class="pb-0"
            >
              <v-text-field
                :value="unload.vehicle.plate"
                label="Placa"
                hide-details
                disabled
                dense
              />
            </v-col>
            <v-col
              cols="3"
              md
              lg
              class="pb-0"
            >
              <v-text-field
                :value="unload.driver.name"
                label="Motorista"
                hide-details
                return-unmasked
                disabled
                dense
              />
            </v-col>
          </v-row>

          <v-row
            v-for="(answer, idx) of form.questions"
            :key="idx"
          >
            <template v-if="isConditionalHidden(answer)" />
            <template v-else-if="answer.tipo_campo === 'TITULO'">
              <v-col
                cols="12"
                class="py-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="text-h6 black--text"
              >
                {{ answer.rotulo }}
              </v-col>
            </template>
            <template v-else-if="answer.tipo_campo === 'CHECKBOX'">
              <v-col
                cols="12"
                class="py-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="pb-0"
              >
                <span class="text-body-2 black--text">
                  {{ answer.rotulo }}
                </span>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-row no-gutters>
                  <v-radio-group
                    v-model="answer.resposta"
                    :rules="[answer.obrigatorio ? rules.required : true]"
                  >
                    <v-radio
                      v-for="option of answer.valores"
                      :key="`${idx}-${option}`"
                      :label="option"
                      :value="option"
                    />
                  </v-radio-group>
                </v-row>
              </v-col>
            </template>
            <template v-else-if="answer.tipo_campo === 'MULTIPLA-ESCOLHA'">
              <v-col
                cols="12"
                class="py-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="pb-0"
              >
                <span class="text-body-2 black--text">
                  {{ answer.rotulo }}
                </span>
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-row no-gutters>
                  <v-checkbox
                    v-for="option of answer.valores"
                    :key="`${idx}-${option}`"
                    v-model="answer.respostas"
                    :label="option"
                    :value="option"
                    :disabled="answer.maximo_valores == answer.respostas.length && !answer.respostas.includes(option)"
                    hide-details
                  />
                </v-row>
                <v-input
                  v-if="answer.obrigatorio"
                  :value="answer.respostas.length"
                  :rules="[rules.required]"
                />
              </v-col>
            </template>
            <template v-else-if="answer.tipo_campo === 'TEXTO'">
              <v-col
                v-if="!answer.condicional"
                cols="12"
                class="pt-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-textarea
                  v-model="answer.resposta"
                  :label="answer.rotulo"
                  rows="1"
                  auto-grow
                  :filled="!!answer.condicional"
                  :rules="[answer.obrigatorio ? rules.required : true]"
                />
              </v-col>
            </template>
            <template v-else-if="answer.tipo_campo === 'NUMERO'">
              <v-col
                v-if="!answer.condicional"
                cols="12"
                class="pt-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-text-field
                  v-model.number="answer.resposta"
                  :label="answer.rotulo"
                  type="number"
                  inputmode="numeric"
                  :filled="!!answer.condicional"
                  v-bind="getRangeInfo(answer)"
                  :rules="[answer.obrigatorio ? rules.required : true]"
                  @keypress="disableDot"
                />
              </v-col>
            </template>
            <template v-else-if="answer.tipo_campo === 'DATA'">
              <v-col
                v-if="!answer.condicional"
                cols="12"
                class="pt-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <masked-text-field
                  v-model="answer.resposta"
                  :label="answer.rotulo"
                  prepend-inner-icon="event"
                  placeholder="00/00/0000"
                  :mask="{ mask: '00/00/0000' }"
                  :max-length="10"
                  inputmode="numeric"
                  clearable
                  :filled="!!answer.condicional"
                  :rules="[rules.date, answer.obrigatorio ? rules.required : true]"
                />
              </v-col>
            </template>
            <template v-else-if="answer.tipo_campo === 'DATA_HORA'">
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <masked-text-field
                  v-model="answer.resposta"
                  :label="answer.rotulo"
                  prepend-inner-icon="event"
                  placeholder="00/00/0000 00:00"
                  :mask="{ mask: '00/00/0000 00:00' }"
                  :max-length="16"
                  inputmode="numeric"
                  clearable
                  :rules="[rules.dateTime, answer.obrigatorio ? rules.required : true]"
                />
              </v-col>
            </template>
            <template v-else-if="answer.tipo_campo === 'HORA'">
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <masked-text-field
                  v-model="answer.resposta"
                  :label="answer.rotulo"
                  prepend-inner-icon="event"
                  placeholder="00:00"
                  :mask="{ mask: '00:00' }"
                  :max-length="5"
                  inputmode="numeric"
                  clearable
                  :rules="[rules.time, answer.obrigatorio ? rules.required : true]"
                />
              </v-col>
            </template>
            <template v-else-if="answer.tipo_campo === 'FOTO'">
              <v-col
                v-if="!answer.condicional"
                cols="12"
                class="py-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="pb-0"
              >
                <span class="text-body-2 black--text">
                  {{ answer.rotulo }}
                </span>
              </v-col>
              <v-col
                cols="12"
              >
                <v-card
                  v-if="!answer.resposta"
                  class="text-center"
                  :outlined="!answer.condicional"
                  :style="answer.condicional ? 'background-color: rgba(0, 0, 0, 0.07);' : undefined"
                >
                  <v-card-text>
                    <v-btn
                      outlined
                      class="mb-5 mt-5 mx-2"
                      color="blue darken-1"
                      @click="showCamera(answer)"
                    >
                      <v-icon left>
                        camera
                      </v-icon>
                      Câmera
                    </v-btn>
                    <v-btn
                      outlined
                      class="mb-5 mt-5 mx-2"
                      color="orange darken-1"
                      @click="showFileUpload(answer)"
                    >
                      <v-icon left>
                        add_a_photo
                      </v-icon>
                      Anexos
                    </v-btn>
                  </v-card-text>
                </v-card>
                <file-viewer
                  v-else
                  :value="answer.image"
                  :preview-height="165"
                  @removeFile="onRemoveFile(answer)"
                />
                <v-input
                  v-if="answer.obrigatorio"
                  :value="answer.resposta"
                  :rules="[rules.required]"
                />
              </v-col>
            </template>
            <template v-else>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-divider class="my-0" />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                {{ answer }}
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <camera-dialog
      v-model="camera.show"
      @capture="onCapture"
    />

    <file-upload
      v-model="fileUpload.show"
      extensions="jpe,jpeg,jpg,tiff,png,webp,bmp"
      accept="image/*"
      :multiselect="false"
      @insertFiles="onSelectImage"
    />
  </v-dialog>
</template>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import FileUpload from '@/Support/Components/FileUpload.vue';
import FileViewer from '@/Support/Components/FileViewer.vue';
import CameraDialog from '@/Support/Components/CameraDialog.vue';

import moment from 'moment';
import printJS from 'print-js';

export default {

  components: {
    MaskedTextField,
    FileUpload,
    FileViewer,
    CameraDialog,
  },

  props: {
    object: String,
    unload: Object
  },

  data() {
    return {
      dialog: false,
      formId: null,
      answerId: null,
      objectId: null,
      form: {
        questions: [],
      },

      camera: {
        show: false,
        id: null,
      },

      fileUpload: {
        show: false,
        id: null,
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        dateTime: v => this.isDateValid(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!',
        date: v => this.isDateValid(v, 'DD/MM/YYYY') || 'Data Inválida!',
        time: v => this.isDateValid(v, 'HH:mm') || 'Hora Inválida!',
      },
    }
  },

  computed: {
    answersByIds() {
      return this.form.questions
        .filter(item => !item.condicional)
        .reduce((acc, cur) => ({ ...acc, [cur.id_rotulo]: cur.resposta }), {})
    },
  },

  methods: {
    async load() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/questionnaires/forms/${this.formId}`, { params: {
          id_formulario_customizado_cabecalho: this.answerId,
        } });

        const images = data.questions
          .filter(answer => answer.tipo === 'FOTO' && answer.resposta)
          .map(answer => answer.resposta);

        let files = [];

        if (images.length > 0) {
          files = await Promise.all(images.map(url => this.fileInfo(url)));
        }

        this.form = {
          ...data,
          questions: data.questions
            .map((answer) => {
              answer.tipo_campo = answer.tipo;

              if (answer.tipo === 'CHECKBOX' && answer.maximo_valores > 0) {
                answer.tipo_campo = 'MULTIPLA-ESCOLHA';
                answer.respostas = answer.respostas || [];
              }
              if (answer.tipo === 'TEXTO' && answer.entrada === 'NUMERO') {
                answer.tipo_campo = 'NUMERO';
              }
              if (answer.tipo === 'FOTO' && answer.resposta) {
                answer.image = files.find(file => file.url === answer.resposta) || { url: answer.resposta, size: 0 };
              }
              if (answer.tipo === 'DATA_HORA' && answer.resposta) {
                answer.resposta = moment(answer.resposta).format('DD/MM/YYYY HH:mm')
              }

              return answer;
            })
        };

      } catch (error) {
        this.$snotify.error('Erro ao carregar produtor', 'Atenção');
        console.warn(error);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    show({ id, answerId, objectId }) {
      this.dialog = true;
      this.formId = id;
      this.answerId = answerId;
      this.objectId = objectId;
      this.load();

      this.$refs.form?.resetValidation();
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const payload = {
          id_formulario_customizado_cabecalho: this.answerId,
          id_formulario: this.form.id_formulario,
          titulo: this.form.titulo,
          tipo: this.form.tipo,
          id_objeto: this.objectId,
          nome_objeto: this.object || this.form.objeto,
          id_laticinio: this.form.id_laticinio,
          data_cadastro_app: this.form.data_cadastro_app,
          id_usuario: this.form.id_usuario,
          // id_itinerario: this.form.id_itinerario,
          // id_visita: this.form.id_visita,
          // id_produtor: this.form.id_produtor,
          // nome_produtor: this.form.nome_produtor,
          respostas: this.form.questions.map(item => {
            let resposta = item.resposta;

            if (item.tipo === 'DATA_HORA' && resposta) {
              resposta = moment(resposta, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
            }

            return {
              id_rotulo: item.id_rotulo,
              rotulo: item.rotulo,
              valores: item.valores,
              tipo_campo: item.tipo,
              condicional: item.condicional,
              //pontos: 0,
              respostas: item.respostas || [resposta],
            }
          })
        };

        const { data } = await this.$axios.post(`/questionnaires`, payload);

        this.answerId = data.id;

        this.$snotify.success('Checklist salvo com sucesso!', 'Sucesso');

        this.$emit('save', data.id);

        // Se houver alguma imagem, recarrega o questionário para atualizar a urk
        if (this.form.questions.some(({ tipo, resposta }) => tipo === 'FOTO' && resposta && !resposta.includes('formulario_customizado'))) {
          await this.load();
        }
      } catch (error) {
        const message = error?.response?.data?.message || 'Erro ao salvar checklist';
        this.$snotify.error(message);
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.dialog = false;
      this.form = {
        questions: [],
      };
      this.$emit('close');
    },

    showCamera(answer) {
      this.camera = {
        show: true,
        id: answer.id_rotulo,
      }
    },

    showFileUpload(answer) {
      this.fileUpload = {
        show: true,
        id: answer.id_rotulo,
      }
    },

    onSelectImage([ image ]) {
      const answer = this.form.questions.find(item => item.id_rotulo === this.fileUpload.id);

      answer.resposta = image.url;
      answer.image = image;
    },

    onCapture(image) {
      const answer = this.form.questions.find(item => item.id_rotulo === this.camera.id);

      answer.resposta = image.url;
      answer.image = { ...image, active: true, success: true };
    },

    async onRemoveFile(answer) {
      try {
        const idx = this.form.questions.findIndex(item => item.id_rotulo === answer.id_rotulo);

        const fileUrl = answer.resposta;

        if (fileUrl.includes('googleapis.com') && fileUrl.includes('formulario_customizado')) {
          if (!await this.$refs.form.validate()) {
            return;
          }

          if (!(await this.$root.$confirm('Remover anexo', 'Tem certeza que deseja excluir este anexo?', { color: 'red' }))) {
            return;
          }

          this.$root.$progressBar.loading();

          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });

          this.form.questions.splice(idx, 1, {
            ...answer,
            resposta: null,
            image: null,
          });

          await this.save();
        } else {
          this.form.questions.splice(idx, 1, {
            ...answer,
            resposta: null,
            image: null,
          });
        }
      } catch (e) {
        const message = e?.response?.data?.message || 'Erro ao salvar';
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async fileInfo(url) {
      try {
        const { data } = await this.$axios.post(`/arquivos/storageInfoFile`, {
          fileUrl: url
        });

        if (!data.fileInfo?.name) {
          throw data.fileInfo;
        }

        return {
          ...data.fileInfo,
          active: true,
          success: true,
          url
        };
      } catch (error) {
        console.warn(error);
        return {
          active: true,
          success: true,
          size: 0,
          url
        };
      }
    },

    async onPrint() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/formularioCustomizado/impressaoRespotasFormulario`, {
          id_formulario: this.formId,
          id_respostas: [this.answerId],
          tipo: 'others',
        });

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (error) {
        this.$snotify.error('Erro ao imprimir questionário', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    isConditionalHidden(answer) {
      if (!answer.condicional) {
        return false;
      }

      const value = this.answersByIds[answer.condicional.id_campo_comparacao];

      if (!value) {
        return true;
      }

      if (answer.condicional.condicao === '==') {
        return value !== answer.condicional.comparacao;
      }

      if (answer.condicional.condicao === '...') {
        const rule = answer.condicional;

        return this.isValidRange(value, rule);
      }

      return true;
    },

    isDateValid(value, format) {
      if (!value) {
        return true;
      }

      if (value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },

    getRangeInfo(answer) {
      if (!answer.faixa_padrao) {
        return {};
      }

      const value = answer.resposta;
      const rule = answer.faixa_padrao;

      const error = !this.isValidRange(value, rule);

      const max = (rule.max || '').toString().replace('.', ',');
      const min = (rule.min || '').toString().replace('.', ',');

      let hint = '';

      if (rule.max && rule.min) {
        hint = `Entre ${(min).toString().replace('.', ',')} e ${max} ${this.unit || ''}`;
      } else if (rule.max) {
        hint = `Máximo ${max} ${this.unit || ''}`;
      } else if (rule.min) {
        hint = `Mínimo ${min} ${this.unit || ''}`;
      }

      return {
        hint,
        error,
        errorMessages: error ? hint : undefined,
      }
    },

    isValidRange(value, rule = {}) {
      if (value === null || value === '') {
        return true;
      }

      if (rule.max && rule.min) {

        const _value = parseFloat(value);
        const max = parseFloat(rule.max);
        const min = parseFloat(rule.min);

        return _value >= min && _value <= max;
      }

      if (rule.max) {
        const _value = parseFloat(value);
        const max = parseFloat(rule.max);

        return _value <= max;
      }

      if (rule.min) {
        const _value = parseFloat(value);
        const min = parseFloat(rule.min);

        return _value >= min;
      }

      return true;
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
  }
}
</script>
