<template>
  <div>
    <v-dialog
      v-model="isDialogListVisible"
      width="400"
      scrollable
    >
      <v-card>
        <v-card-title>
          Novidades
        </v-card-title>

        <v-card-text>
          <v-skeleton-loader
            v-if="isLoading"
            class="mb-3"
            boilerplate
            type="list-item-avatar-two-line, list-item-avatar-two-line"
          />
          <v-list v-else>
            <v-list-item
              v-for="item of items"
              :key="item.id"
              @click="showVersionNotes(item)"
            >
              <v-list-item-icon>
                <v-icon color="orange">
                  new_releases
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ formatDate(item.date, 'LL') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isDialogVisible"
      width="400"
    >
      <v-card>
        <v-card-title>
          Novidades
          <v-spacer />
          <v-btn
            icon
            depressed
            @click="isDialogVisible = false"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>{{ formatDate(versionNotes.date, 'LL') }}</v-card-subtitle>
        <v-divider />
        <v-card-text
          class="text-body-1 pt-4"
          v-html="versionNotes.notes"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import moment from 'moment'
import truncate from 'lodash/truncate'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

const { notify } = useUtils()

const isLoading = ref(false)
const isDialogListVisible = ref(false)
const items = ref([])

const show = () => {
  isDialogListVisible.value = true
  loadNotes()
}

const loadNotes = async () => {
  try {
    isLoading.value = true

    const { data } = await axios.get(`/settings/web-versions/notes-list`);

    items.value = data.map(item => ({
      date: item.data,
      modules: item.modulos,
      description: truncate(item.notas?.replace(/\n/g, '; '), { length: 100 }),
      notes: item.notas?.replace(/\n/g, '<br>')
    }))

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar as novidades!', 'Atenção')

  } finally {
    isLoading.value = false
  }
}

const isDialogVisible = ref(false)
const versionNotes = ref({})

const showVersionNotes = (version) => {
  versionNotes.value = version
  isDialogVisible.value = true
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)

// eslint-disable-next-line no-undef
defineExpose({
  show
})
</script>
