<template>
  <div>
    <v-dialog
      v-model="visible"
      width="900"
      scrollable
      persistent
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          Nova Análise
        </v-card-title>

        <v-stepper
          v-model="step"
        >
          <v-stepper-step
            :complete="step
              > 1"
            step="1"
          >
            Itinerario de Coleta
          </v-stepper-step>

          <v-stepper-content
            step="1"
            class="sub-menu-stepper"
          >
            <v-card-text>
              <v-row>
                <v-col cols="2">
                  <masked-input
                    v-model="dateItinerary.input"
                    label="Data"
                    :mask="DateMask"
                    @change="onDateFilterItinerary"
                  />
                </v-col>
                <v-col cols="5">
                  <v-autocomplete
                    v-model="motorista"
                    label="Motorista"
                    :items="motoristas"
                    item-text="motorista"
                    item-value="id_motorista"
                    :disabled="dateItinerary.input ? false : true"
                    :loading="loadingMotorista"
                  />
                </v-col>

                <v-col cols="5">
                  <person-autocomplete-filter
                    v-model="producer.item"
                    label="Produtor *"
                    prepend-inner-icon="person_outline"
                    type="PRODUCER"
                    clearable
                    return-object
                    :filled="false"
                    :show-producers-and-groups="true"
                    :show-family-group="true"
                    :hide-details="false"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    :disabled="motorista ? false : true"
                    @input="onRoutes"
                  >
                    <template #[`item`]="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.code? `${item.code} -`:'' }}  {{ item.nome }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template #[`selection`]="{ item }">
                      <v-list-item-title>
                        {{ item.code? `${item.code} -`:'' }} {{ item.nome }}
                      </v-list-item-title>
                    </template>
                  </person-autocomplete-filter>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="rotaItinerario"
                    label="Rota"
                    :items="rotaItinerarios"
                    item-text="concat_descricao_rota"
                    item-value="id_rota"
                    :disabled="typeof producer.item?.id !== 'undefined' ? false : true"
                    :loading="loadingRoutes"
                    @input="setDataCollection"
                  />
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="typeMilk"
                    label="Tipo do Leite"
                    :disabled="true"
                    readonly
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="itineraryCode"
                    label="Cód. Itinerário"
                    :disabled="true"
                    readonly
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="quantityCollected"
                    label="Volume (L)"
                    :disabled="true"
                    readonly
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="rotaItinerario ? false : true"
                @click="nextStep"
              >
                Próximo
              </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step
            :complete="step
              > 2"
            step="2"
          >
            Análise
          </v-stepper-step>

          <v-stepper-content
            step="2"
            class="sub-menu-stepper"
          >
            <v-card-text
              class="pb-0"
              style="max-height: 500px; overflow-y: auto;"
            >
              <v-form ref="form">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="quantityCollected"
                      label="Volume (L)"
                      :disabled="true"
                      readonly
                    />
                  </v-col>
                  <v-col
                    md="6"
                    class="pb-0"
                  >
                    <v-menu
                      v-model="analysisAtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      content-class="menu-select"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="analysisAt"
                          label="Data da análise*"
                          prepend-inner-icon="event"
                          readonly
                          v-bind="attrs"
                          clearable
                          :rules="[v => !!v ||'Campo obrigatorio']"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="datePicker.date"
                        @input="analysisAtMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    md="6"
                    class="pb-0"
                  >
                    <v-select
                      v-model="sampleType"
                      :items="['Subsequente', 'Mensal', 'Semestral', 'Trimestral']"
                      label="Tipo de amostra"
                      prepend-inner-icon="change_circle"
                      clearable
                    />
                  </v-col>
                </v-row>

                <v-card outlined>
                  <analysis
                    v-if="!!analysis.acidity"
                    v-model="analysis"
                    :visible="visibleAnalysisParams"
                    auto-mark-out-of-range
                    @onAutoMarkOutOfRange="onAutoMarkOutOfRange"
                  />

                  <v-divider class="mt-3 mx-3" />

                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-textarea
                        v-model="observation"
                        label="Observações"
                        placeholder=" "
                        hide-details
                        rows="2"
                        auto-grow
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      class="pb-0"
                    >
                      <span class="group-label text-body-2 grey--text text--darken-1">Anexos</span>

                      <v-row>
                        <v-col
                          v-for="(file, idx) in attachments"
                          :key="idx"
                        >
                          <file-viewer
                            :value="file"
                            @removeFile="onRemoveFile(idx)"
                          />
                        </v-col>
                        <v-col
                          v-if="attachments.length < 3"
                          class="py-0 text-center align-self-center flex-column justify-center"
                        >
                          <v-btn
                            outlined
                            class="mb-5"
                            @click="showFileUpload = !showFileUpload"
                          >
                            <v-icon>attach_file</v-icon>
                            <span class="ml-2">Adicionar anexo</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="prevStep"
              >
                Voltar
              </v-btn>
              <v-spacer />
              <v-btn
                color="success"
                @click="createRoutineAnalysis"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>

      <file-upload
        v-model="showFileUpload"
        extensions="pdf,xml,gif,jpe,jpeg,jpg,tiff,png,webp,bmp"
        accept="application/pdf,text/xml,image/*"
        @insertFiles="onSelectFiles"
      />
    </v-dialog>
  </div>
</template>

<style lang="scss">
  .sub-menu-stepper {
    padding: 0 18px !important;
  }
</style>

<script>
import qs from "qs";
import * as _ from "lodash";
import moment from "moment";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

import Analysis from "@/Domains/Platform/Unload/Components/Analysis.vue";
import FileUpload from '@/Support/Components/FileUpload.vue';
import FileViewer from '@/Support/Components/FileViewer.vue';

import AnalysisDictionary from "@/Domains/Platform/Unload/Services/AnalysisDictionary.js";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

const AnalysisDict = _.invert(AnalysisDictionary);

export default {
  components: {
    Analysis,
    FileUpload,
    FileViewer,
    MaskedInput,
    PersonAutocompleteFilter
  },

  props: {
    analysisParameters: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      // flag do dialog
      visible: false,

      analysisAtMenu: false,

      datePicker: {
        date: null
      },

      sampleType: null,

      producer: {
        item: null,
        list: [],
        loading: false,
      },

      // lista de analises
      analysis: {},

      status: 'DENTRO_PADRAO',
      problems: [],

      dialog: false,
      step: 1,

      DateMask: {
        mask: "00/00/0000",
        maxlength: 14,
      },

      dateItinerary: {
        input: null,
        range: [],
      },

      loading: false,
      loadingRoutes: false,
      loadingMotorista: false,

      filterMotorista: null,

      rotaItinerarios: [],
      rotaItinerario: '',

      motoristas: [],
      motorista: '',

      produtores: [],
      produtor: [],

      quantityCollected: '',
      collectId: '',
      rawMaterialId: '',
      typeMilk: '',
      itineraryCode: '',

      formData: {
        nome: '',
        email: '',
        endereco: '',
        telefone: ''
      },

      showFileUpload: false,
      observation: null,

      attachments: [],
    };
  },

  computed: {
    analysisAt() {

      if (!this.datePicker.date) {
        return null;
      }

      return moment(this.datePicker.date).format("DD/MM/YYYY");
    },

    visibleAnalysisParams() {
      if (!this.analysisParameters?.length) {
        return [
          'acidity',
          'cryoscopy',
          'waterPercentage',
          'waterLiters',
          'density',
          'esd',
          'fat',
          'protein',
          'ph',
          'alizarol',
          'totalSolids',
          'brix',
          'est',
          'lactose',
          'sng',
          'mastitis',
          'chlorides',
          'betaLactams',
          'tetracycline',
          'antibiotic',
          'sulphonamide',
          'sodiumHydroxide',
          'bicarbonateOfSoda',
          'formol',
          'peroxide',
          'chlorine',
          'ureia',
          'starch',
          'sucrose',
          'neutralising',
          'restoratives',
          'conservatives',
          'sensorial',
          'quinolones',
          'aminoglykosides',
          'macrolides',
          'anfenicois',
          'alkalinePhosphatase',
          'alcohol',
          'clumps',
          'clots',
          'peroxidase',
          'solubilidade',
          'base_seca',
          'neomicina',
        ];
      }

      const hidden = ['numero_lacre', 'temp', 'otherSpecies', 'others'];

      return this.analysisParameters
        .map(({ name }) => name)
        .filter(name => !hidden.includes(name));
    },
  },

  watch: {
    visible(value) {
      if (value) {
        this.analysisAtMenu = false;

        this.datePicker.date = null;

        this.producer = {
          item: null,
          list: [],
          loading: false,
        };

        this.status = 'DENTRO_PADRAO';
        this.problems = [];

        const settings = _.keyBy(this.analysisParameters, 'name');

        const getAnalysisRule = (param, field = 'rule') => {
          return _.get(settings, `${param}.${field}`, null);
        }

        const getDefaultValue = (param, field = 'rule') => {
          return _.get(settings, `${param}.${field}.default`, null);
        }

        this.analysis = {
          // temp: {
          //   value: null,
          //   rule: getAnalysisRule('temp'),
          //   restrictionRule: getAnalysisRule('temp', 'restrictionRule'),
          //   unit: `ºC`,
          //   decimals: 1,
          // },
          acidity: {
            value: getDefaultValue('acidity'),
            rule: getAnalysisRule('acidity'),
            restrictionRule: getAnalysisRule('acidity', 'restrictionRule'),
            unit: getAnalysisRule('acidity', 'unit') || `ºD`,
            decimals: 3,
          },
          cryoscopy: {
            value: getDefaultValue('cryoscopy'),
            rule: getAnalysisRule('cryoscopy'),
            restrictionRule: getAnalysisRule('cryoscopy', 'restrictionRule'),
            unit: getAnalysisRule('cryoscopy', 'unit') || `ºC`,
            decimals: 3,
          },
          waterPercentage: {
            value: getDefaultValue('waterPercentage'),
            rule: getAnalysisRule('waterPercentage'),
            restrictionRule: getAnalysisRule('waterPercentage', 'restrictionRule'),
            decimals: 2,
          },
          waterLiters: {
            value: getDefaultValue('waterLiters'),
            rule: getAnalysisRule('waterLiters'),
            restrictionRule: getAnalysisRule('waterLiters', 'restrictionRule'),
            decimals: 2,
          },
          density: {
            value: getDefaultValue('density'),
            rule: getAnalysisRule('density'),
            restrictionRule: getAnalysisRule('density', 'restrictionRule'),
            unit: `g/m3`,
            decimals: 1,
          },
          fat: {
            value: getDefaultValue('fat'),
            rule: getAnalysisRule('fat'),
            restrictionRule: getAnalysisRule('fat', 'restrictionRule'),
            unit: getAnalysisRule('fat', 'unit') || `%/100g`,
            decimals: 3,
          },
          esd: {
            value: getDefaultValue('esd'),
            rule: getAnalysisRule('esd'),
            restrictionRule: getAnalysisRule('esd', 'restrictionRule'),
            unit: `g/100g`,
            decimals: 2,
          },
          est: {
            value: getDefaultValue('est'),
            rule: getAnalysisRule('est'),
            restrictionRule: getAnalysisRule('est', 'restrictionRule'),
            decimals: 2,
          },
          acidityRatio: {
            value: getDefaultValue('acidityRatio'),
            rule: getAnalysisRule('acidityRatio'),
            restrictionRule: getAnalysisRule('acidityRatio', 'restrictionRule'),
            decimals: 3,
          },
          protein: {
            value: getDefaultValue('protein'),
            rule: getAnalysisRule('protein'),
            restrictionRule: getAnalysisRule('protein', 'restrictionRule'),
            unit: `g/100g`,
            decimals: 2,
          },
          ph: {
            value: getDefaultValue('ph'),
            rule: getAnalysisRule('ph'),
            restrictionRule: getAnalysisRule('ph', 'restrictionRule'),
            unit: ``,
            decimals: 2,
          },
          alizarol: {
            value: getDefaultValue('alizarol'),
            rule: getAnalysisRule('alizarol'),
            restrictionRule: getAnalysisRule('alizarol', 'restrictionRule'),
            unit: getAnalysisRule('alizarol', 'unit') || `°GL`,
            decimals: 0,
          },
          totalSolids: {
            value: getDefaultValue('totalSolids'),
            rule: getAnalysisRule('totalSolids'),
            restrictionRule: getAnalysisRule('totalSolids', 'restrictionRule'),
            decimals: 2,
          },
          brix: {
            value: getDefaultValue('brix'),
            rule: getAnalysisRule('brix'),
            restrictionRule: getAnalysisRule('brix', 'restrictionRule'),
            decimals: 2,
          },
          lactose: {
            value: getDefaultValue('lactose'),
            rule: getAnalysisRule('lactose'),
            restrictionRule: getAnalysisRule('lactose', 'restrictionRule'),
            decimals: 2,
          },
          reductase: {
            value: getDefaultValue('reductase'),
            rule: getAnalysisRule('reductase'),
            restrictionRule: getAnalysisRule('reductase', 'restrictionRule'),
          },

          // Inconformidades (Checkbox)
          alizarolQualit: {
            value: getDefaultValue('alizarolQualit'),
            rule: getAnalysisRule('alizarolQualit'),
            restrictionRule: getAnalysisRule('alizarolQualit', 'restrictionRule'),
          },
          mastitis: {
            value: getDefaultValue('mastitis'),
            rule: getAnalysisRule('mastitis'),
            restrictionRule: getAnalysisRule('mastitis', 'restrictionRule'),
          },
          sensorial: {
            value: getDefaultValue('sensorial'),
            rule: getAnalysisRule('sensorial'),
            restrictionRule: getAnalysisRule('sensorial', 'restrictionRule'),
            hint: `Característico`,
          },
          alcohol: {
            value: getDefaultValue('alcohol'),
            rule: getAnalysisRule('alcohol'),
            restrictionRule: getAnalysisRule('alcohol', 'restrictionRule'),
          },
          alkalinePhosphatase: {
            value: getDefaultValue('alkalinePhosphatase'),
            rule: getAnalysisRule('alkalinePhosphatase'),
            restrictionRule: getAnalysisRule('alkalinePhosphatase', 'restrictionRule'),
          },
          clumps: {
            value: getDefaultValue('clumps'),
            rule: getAnalysisRule('clumps'),
            restrictionRule: getAnalysisRule('clumps', 'restrictionRule'),
          },
          clots: {
            value: getDefaultValue('clots'),
            rule: getAnalysisRule('clots'),
            restrictionRule: getAnalysisRule('clots', 'restrictionRule'),
          },
          peroxidase: {
            value: getDefaultValue('peroxidase'),
            rule: getAnalysisRule('peroxidase'),
            restrictionRule: getAnalysisRule('peroxidase', 'restrictionRule'),
          },
          // otherSpecies: {
          //   value: getDefaultValue('otherSpecies'),
          //   rule: getAnalysisRule('otherSpecies'),
          //   restrictionRule: getAnalysisRule('otherSpecies', 'restrictionRule'),
          // },

          // Antibióticos
          antibiotic: {
            value: getDefaultValue('antibiotic'),
            rule: getAnalysisRule('antibiotic'),
            restrictionRule: getAnalysisRule('antibiotic', 'restrictionRule'),
          },
          betaLactams: {
            value: getDefaultValue('betaLactams'),
            rule: getAnalysisRule('betaLactams'),
            restrictionRule: getAnalysisRule('betaLactams', 'restrictionRule'),
          },
          tetracycline: {
            value: getDefaultValue('tetracycline'),
            rule: getAnalysisRule('tetracycline'),
            restrictionRule: getAnalysisRule('tetracycline', 'restrictionRule'),
          },
          sulphonamide: {
            value: getDefaultValue('sulphonamide'),
            rule: getAnalysisRule('sulphonamide'),
            restrictionRule: getAnalysisRule('sulphonamide', 'restrictionRule'),
          },
          quinolones: {
            value: getDefaultValue('quinolones'),
            rule: getAnalysisRule('quinolones'),
            restrictionRule: getAnalysisRule('quinolones', 'restrictionRule'),
          },
          aminoglykosides: {
            value: getDefaultValue('aminoglykosides'),
            rule: getAnalysisRule('aminoglykosides'),
            restrictionRule: getAnalysisRule('aminoglykosides', 'restrictionRule'),
          },
          macrolides: {
            value: getDefaultValue('macrolides'),
            rule: getAnalysisRule('macrolides'),
            restrictionRule: getAnalysisRule('macrolides', 'restrictionRule'),
          },
          anfenicois: {
            value: getDefaultValue('anfenicois'),
            rule: getAnalysisRule('anfenicois'),
            restrictionRule: getAnalysisRule('anfenicois', 'restrictionRule'),
          },
          neomicina: {
            value: getDefaultValue('neomicina'),
            rule: getAnalysisRule('neomicina'),
            restrictionRule: getAnalysisRule('neomicina', 'restrictionRule'),
          },
          cefalosporina: {
            value: getDefaultValue('cefalosporina'),
            rule: getAnalysisRule('cefalosporina'),
            restrictionRule: getAnalysisRule('cefalosporina', 'restrictionRule'),
          },
          tianfenicol: {
            value: getDefaultValue('tianfenicol'),
            rule: getAnalysisRule('tianfenicol'),
            restrictionRule: getAnalysisRule('tianfenicol', 'restrictionRule'),
          },
          florfenicol: {
            value: getDefaultValue('florfenicol'),
            rule: getAnalysisRule('florfenicol'),
            restrictionRule: getAnalysisRule('florfenicol', 'restrictionRule'),
          },

          // Neutralizantes
          neutralising: {
            value: getDefaultValue('neutralising'),
            rule: getAnalysisRule('neutralising'),
            restrictionRule: getAnalysisRule('neutralising', 'restrictionRule'),
            hint: `Ausência`,
          },
          sodiumHydroxide: {
            value: getDefaultValue('sodiumHydroxide'),
            rule: getAnalysisRule('sodiumHydroxide'),
            restrictionRule: getAnalysisRule('sodiumHydroxide', 'restrictionRule'),
          },
          bicarbonateOfSoda: {
            value: getDefaultValue('bicarbonateOfSoda'),
            rule: getAnalysisRule('bicarbonateOfSoda'),
            restrictionRule: getAnalysisRule('bicarbonateOfSoda', 'restrictionRule'),
          },

          // Reconstituintes
          restoratives: {
            value: getDefaultValue('restoratives'),
            rule: getAnalysisRule('restoratives'),
            restrictionRule: getAnalysisRule('restoratives', 'restrictionRule'),
            hint: `Ausência`,
          },
          sucrose: {
            value: getDefaultValue('sucrose'),
            rule: getAnalysisRule('sucrose'),
            restrictionRule: getAnalysisRule('sucrose', 'restrictionRule'),
          },
          starch: {
            value: getDefaultValue('starch'),
            rule: getAnalysisRule('starch'),
            restrictionRule: getAnalysisRule('starch', 'restrictionRule'),
          },
          chlorides: {
            value: getDefaultValue('chlorides'),
            rule: getAnalysisRule('chlorides'),
            restrictionRule: getAnalysisRule('chlorides', 'restrictionRule'),
          },
          chlorine: {
            value: getDefaultValue('chlorine'),
            rule: getAnalysisRule('chlorine'),
            restrictionRule: getAnalysisRule('chlorine', 'restrictionRule'),
          },
          ureia: {
            value: getDefaultValue('ureia'),
            rule: getAnalysisRule('ureia'),
            restrictionRule: getAnalysisRule('ureia', 'restrictionRule'),
          },

          // Conservantes
          conservatives: {
            value: getDefaultValue('conservatives'),
            rule: getAnalysisRule('conservatives'),
            restrictionRule: getAnalysisRule('conservatives', 'restrictionRule'),
            hint: `Ausência`,
          },
          peroxide: {
            value: getDefaultValue('peroxide'),
            rule: getAnalysisRule('peroxide'),
            restrictionRule: getAnalysisRule('peroxide', 'restrictionRule'),
          },
          formol: {
            value: getDefaultValue('formol'),
            rule: getAnalysisRule('formol'),
            restrictionRule: getAnalysisRule('formol', 'restrictionRule'),
          },
          alkali: {
            value: getDefaultValue('alkali'),
            rule: getAnalysisRule('alkali'),
            restrictionRule: getAnalysisRule('alkali', 'restrictionRule'),
          },
          solubilidade: {
            value: getDefaultValue('solubilidade'),
            rule: getAnalysisRule('solubilidade'),
            restrictionRule: getAnalysisRule('solubilidade', 'restrictionRule'),
          },
          base_seca: {
            value: getDefaultValue('base_seca'),
            rule: getAnalysisRule('base_seca'),
            restrictionRule: getAnalysisRule('base_seca', 'restrictionRule'),
          },
          phenolphthalein: {
            value: getDefaultValue('phenolphthalein'),
            rule: getAnalysisRule('phenolphthalein'),
            hint: `Ausência`,
          },
          bloodPresence: {
            value: getDefaultValue('bloodPresence'),
            rule: getAnalysisRule('bloodPresence'),
            hint: `Ausência`,
          },
          rosolicAcid: {
            value: getDefaultValue('rosolicAcid'),
            rule: getAnalysisRule('rosolicAcid'),
            hint: `Ausência`,
          },

          // Outras inconformidades
          // others: {
          //   value: getDefaultValue('others'),
          //   rule: getAnalysisRule('others'),
          //   restrictionRule: getAnalysisRule('others', 'restrictionRule'),
          // },
        };
      }
    }
  },
  mounted() {
    this.rawMaterialId = this.$store.state.settings?.gerais?.id_materia_prima_principal
  },
  methods: {
    openDialog() {
      this.visible = true;
    },
    closeDialog() {
      this.visible = false;
      this.clear();
    },

    async createRoutineAnalysis() {
      if (!this.$refs.form.validate()) {
        this.$snotify.error("Existem Campos obrigatórios não preenchidos!", "Atenção");
        return true;
      }
      try {
        this.$root.$progressBar.saving();

        this.dialog = false;
        this.step = 1;
        this.clear();

        await this.$axios.post(
          `/laboratorioAnalise/insereAnaliseRotina`,
          {
            'data': this.datePicker.date,
            id_produtor: this.producer.item.id,
            grupo: this.producer.item.isGrouping,
            tipo_amostra: this.sampleType,
            id_coleta: this.collectId,
            quantidade_coleta: this.quantityCollected,
            id_materia_prima: this.rawMaterialId,
            estado: this.status,
            lista_inconformidades: this.problems,
            //temperatura: this.analysis.temp.value,
            acidez: this.analysis.acidity.value || 0,
            crioscopia: this.analysis.cryoscopy.value || 0,
            porcentagem_agua: this.analysis.waterPercentage.value || 0,
            volume_agua: this.analysis.waterLiters.value || 0,
            densidade: this.analysis.density.value || 0,
            esd: this.analysis.esd.value || 0,
            gordura: this.analysis.fat.value || 0,
            proteina: this.analysis.protein.value || 0,
            ph: this.analysis.ph.value || 0,
            alizarol: this.analysis.alizarol.value || 0,
            antibiotico: this.analysis.antibiotic.value,
            betalactamico: this.analysis.betaLactams.value,
            tetraciclina: this.analysis.tetracycline.value,
            sulfonamida: this.analysis.sulphonamide.value,
            cloretos: this.analysis.chlorides.value,
            mastite: this.analysis.mastitis.value,
            soda: this.analysis.sodiumHydroxide.value,
            bicarbonato: this.analysis.bicarbonateOfSoda.value,
            formol: this.analysis.formol.value,
            peroxido: this.analysis.peroxide.value,
            cloro: this.analysis.chlorine.value,
            ureia: this.analysis.ureia.value,
            amido: this.analysis.starch.value,
            sacarose: this.analysis.sucrose.value,
            neutralizantes: this.analysis.neutralising.value,
            reconstituintes: this.analysis.restoratives.value,
            conservantes: this.analysis.conservatives.value,
            sensorial: this.analysis.sensorial.value,
            quinolonas: this.analysis.quinolones.value,
            aminoglicosideos: this.analysis.aminoglykosides.value,
            macrolideos: this.analysis.macrolides.value,
            anfenicois: this.analysis.anfenicois.value,
            fosfatase: this.analysis.alkalinePhosphatase.value,
            solidos_totais: this.analysis.totalSolids.value || 0,
            brix: this.analysis.brix.value || 0,
            est: this.analysis.est.value || 0,
            relacao_acidez: this.analysis.acidityRatio.value || 0,
            alizarol_qualit: this.analysis.alizarolQualit.value,
            alcool: this.analysis.alcohol.value,
            lactose: this.analysis.lactose.value || 0,
            grumos: this.analysis.clumps.value,
            coagulos: this.analysis.clots.value,
            // sng: this.analysis.sng,
            redutase: this.analysis.reductase.value,
            alcalino: this.analysis.alkali.value,
            peroxidase: this.analysis.peroxidase.value,
            solubilidade: this.analysis.solubilidade.value,
            base_seca: this.analysis.base_seca.value,
            neomicina: this.analysis.neomicina.value,
            presenca_sangue: this.analysis.bloodPresence.value,
            fenolftaleina: this.analysis.phenolphthalein.value,
            acido_rosolico: this.analysis.rosolicAcid.value,
            cefalosporina: this.analysis.cefalosporina.value,
            tianfenicol: this.analysis.tianfenicol.value,
            florfenicol: this.analysis.florfenicol.value,
            observacao: this.observation,
            anexos: this.attachments,
          }
        );

        this.$snotify.success("Analise de rotina salva com sucesso!", "Sucesso");

        this.closeDialog();
        this.$emit("onSave");

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar a análise!", "Atenção");
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
        this.clear();
      }
    },

    onAutoMarkOutOfRange: _.debounce(function (fields) {
      this.problems = fields.problems.map(field => ({
        campo: AnalysisDict[field],
        padrao: this.analysis[field].rule,
        restricao: this.analysis[field].restrictionRule,
        valor: this.analysis[field].value,
      }));

      if (fields.discard) {
        this.status = 'FORA_PADRAO';
        return;
      }

      if (fields.restriction) {
        this.status = 'RESTRICAO';
        return;
      }

      this.status = 'DENTRO_PADRAO';
    }, 1000),

    onSelectFiles(reports) {
      for (let file of reports) {
        if (!this.attachments.find(o => o.name === file.name)) {
          this.attachments.push({
            success: true,
            name: file.name,
            size: file.size,
            url: file.url
          });
        }
      }
    },

    onRemoveFile(idx) {
      this.attachments.splice(idx, 1);
    },

    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },

    onDateFilterItinerary(event) {
      this.loadingMotorista = true;
      const formattedDate = moment(event, 'DD/MM/YYYY').format('YYYY-MM-DD');
      this.dateItinerary.range = formattedDate;

      this.listaItinerarioColeta();
    },

    async listaItinerarioColeta() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/coleta/listaItinerarioColeta`,
          qs.stringify({
            data_inicio: this.dateItinerary.range,
            id_motorista: this.filterMotorista
          })
        );

        if (_.isString(data)) {
          throw data;
        }

        this.motoristas = data.map(x => {
          return {
            id_itinerario: x.id_itinerario,
            id_motorista: x.id_motorista,
            motorista: x.motorista,
            id_rota: x.id_rota,
            name: x.descricao_rota,
            concat_descricao_rota: x.data_hora_inicio + ' - ' + x.descricao_rota,
            data_hora_inicio: x.data_hora_inicio
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
        this.loadingMotorista = false;
      }
    },
    onRoutes() {
      if (typeof this.producer.item?.id === 'undefined') {
        this.rotaItinerario = '';
        this.quantityCollected = '';
        this.typeMilk = '';
        this.itineraryCode = '';

        return;
      }
      this.loadingRoutes = true;
      this.filterMotorista = this.motorista;
      this.listaItinerario();
    },
    async listaItinerario() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/coleta/listaItinerarioColetaProdutor`,
          qs.stringify({
            data_inicio: this.dateItinerary.range,
            id_motorista: this.filterMotorista,
            id_produtor: this.producer.item.id,
            grupo: this.producer.item.isGrouping,
          })
        );

        if (_.isString(data)) {
          throw data;
        }

        this.rotaItinerarios = data.map(x => {
          return {
            id_itinerario: x.id_itinerario,
            id_rota: x.id_rota + '|' + x.data_hora_inicio,
            name: x.descricao_rota,
            data_hora_inicio: x.data_hora_inicio,
            concat_descricao_rota: ' Data/Hora início: ' + moment(x.data_hora_inicio).format('DD/MM/YYYY HH:mm') + ' | Rota: ' + x.descricao_rota,
            id_coleta: x.id_coleta,
            quantidade_coleta: this.formatNumber(x.quantidade_coleta),
            tipo_leite: x.qualidade_leite_entrega,
            cod_itinerario: x.cod_itinerario,
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
        this.loadingRoutes = false;
      }
    },
    clear() {
      this.rotaItinerario = null;
      this.motorista = null;
      this.produtor = null;
    },
    setDataCollection() {
      if (this.rotaItinerario == '') {
        this.quantityCollected = '';
        this.typeMilk = '';
        this.itineraryCode = '';
        return;
      }

      const itinerarioSelected = this.rotaItinerarios.find(item => item.id_rota === this.rotaItinerario);
      this.collectId = itinerarioSelected.id_coleta;
      this.quantityCollected = itinerarioSelected.quantidade_coleta ?? '-';
      this.typeMilk = itinerarioSelected.tipo_leite ?? '-';
      this.itineraryCode = itinerarioSelected.cod_itinerario ?? '-';
    },
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
  },
};
</script>