<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="producer-vouchers"
  >
    <v-row v-if="!isImporting">
      <v-col md="4">
        <v-date-range-picker
          v-model="filter.range"
          dark
          hide-details
          @change="getBills"
        />
      </v-col>
      <v-spacer />
      <v-col md="4">
        <v-text-field
          v-model="filter.search"
          label="Pesquisar"
          prepend-inner-icon="search"
          dark
          filled
          hide-details
          clearable
        />
      </v-col>
    </v-row>

    <v-row v-if="!isImporting">
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Descontos a Receber
            <v-spacer />
            <div class="d-flex">
              <data-info
                title="Total Valor"
                icon="account_balance_wallet"
                :value="`R$ ${ formatCurrency(totalAmount) }`"
              />
              <data-info
                title="Total Saldo"
                icon="payments"
                :value="`R$ ${ formatCurrency(totalBalance) }`"
              />
            </div>
          </v-card-title>
          <data-table
            ref="bills"
            name="Contas Produtor"
            :headers="bills.headers"
            :items="filteredBills"
            :loading="loading"
            :sort-by.sync="filter.sort.by"
            :sort-desc.sync="filter.sort.desc"
            dark
            dense
            class="elevation-1 report-table"
            @click:row="showBill"
          >
            <template #[`item.type`]="{ value }">
              <v-chip
                x-small
                color="primary"
              >
                {{ value }}
              </v-chip>
            </template>
            <template #[`item.button`]="{ item }">
              <v-btn
                v-if="item.balance != 0"
                block
                small
                :disabled="item.cashInmilk"
                color="blue darken-1"
                @click.stop="discharge(item)"
              >
                Receber
              </v-btn>
              <v-btn
                v-else
                block
                small
                color="orange"
                @click.stop="chargeback(item)"
              >
                Estornar
              </v-btn>
            </template>
          </data-table>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Recebimentos
            <v-spacer />
            <div class="d-flex">
              <data-info
                title="Total Quitado"
                icon="paid"
                :value="`R$ ${ formatCurrency(totalBilleds) }`"
              />
            </div>
          </v-card-title>
          <data-table
            ref="billeds"
            name="Recebimentos Produtor"
            :headers="billeds.headers"
            :items="filteredBilleds"
            :loading="loading"
            :sort-by.sync="filter.sort.by"
            :sort-desc.sync="filter.sort.desc"
            dark
            dense
            class="elevation-1 report-table"
            @click:row="showBill"
          >
            <template #[`item.button`]="{ item }">
              <v-btn
                block
                small
                color="orange"
                @click.stop="chargebackPayment(item)"
              >
                Estornar
              </v-btn>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            Importar descontos a receber
          </v-card-title>
          <v-data-table
            v-model="importReduceToPay.selected"
            :headers="importReduceToPay.headers"
            :items="importReduceToPay.imported"
            disable-sort
            disable-pagination
            dark
            dense
            show-select
            item-key="id"
          >
            <template #[`item.data_emissao`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.data_vencimento`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.valor`]="{ value }">
              R$ {{ formatCurrency(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      type="DESCONTO"
      @input="billDialog.id = null, getBills()"
    />

    <discharge-dialog
      v-model="dischargeDialog.show"
      :installment-id="dischargeDialog.id"
      :recurrence-id="dischargeDialog.recurrenceId"
      :recurrence-due-date="dischargeDialog.recurrenceDueDate"
      @input="dischargeDialog.id = null, dischargeDialog.recurrenceId = null"
      @newPayment="getBills"
    />

    <import-excel-dialog
      v-model="importReduceToPay.show"
      title="Importar Descontos a Receber"
      :fields="importFields"
      show-model
      @import="onImportReduceToPay"
    />

    <v-tooltip
      v-if="isImporting"
      left
    >
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-5"
          :disabled="importReduceToPay.selected.length === 0"
          v-on="on"
          @click="saveImport()"
        >
          <v-icon class="material-icons-outlined">
            save
          </v-icon>
        </v-btn>
      </template>

      <span>Salvar importação</span>
    </v-tooltip>

    <v-speed-dial
      v-else
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="deep-orange"
        @click="newBill"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>
          Novo Desconto
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="teal darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="blue"
        @click="showImportReduceToPay()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              system_update_alt
            </v-icon>
          </template>

          Importar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import DischargeDialog from '@/Domains/Financial/Components/DischargeDialog.vue';
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import DataInfo from '@/Domains/Financial/Components/DataInfo.vue';
import sumBy from "lodash/fp/sumBy";
import ImportExcelDialog from '@/Support/Components/ImportExcelDialog.vue';
import isArray from "lodash/fp/isArray";
import truncate from "lodash/truncate";

import moment from "moment-timezone";

moment.locale('pt');

export default {
  name: "producer-vouchers",

  components: {
    VDateRangePicker,
    BillDialog,
    DischargeDialog,
    DataInfo,
    ImportExcelDialog,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        sort: { by: 'issueDate', desc: false },
        search: ''
      },

      bills: {
        headers: [
          { text: '#', value: 'code' },
          { text: 'GUID', value: 'installmentId', show: false },
          { text: 'Tipo', value: 'type' },
          { text: 'Produtor', value: 'producer' },
          { text: 'Descrição', value: 'description' },
          { text: 'Emissão', value: 'issueDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Vencimento', value: 'dueDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Valor', value: 'amount', formatter: value => 'R$ ' + this.formatCurrency(value), mask: 'R$ #,##0.00' },
          { text: 'Saldo', value: 'balance', formatter: value => 'R$ ' + this.formatCurrency(value), mask: 'R$ #,##0.00' },
          { text: '', altText: 'Ações', value: 'button', width: 100 },
        ],
        items: []
      },

      billeds: {
        headers: [
          { text: '#', value: 'code' },
          { text: 'Produtor', value: 'producer' },
          { text: 'Descrição', value: 'description' },
          { text: 'Emissão', value: 'issueDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Recebimento', value: 'paymentDate', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Valor', value: 'amount', formatter: value => 'R$ ' + this.formatCurrency(value), mask: 'R$ #,##0.00' },
          { text: '', altText: 'Ações', value: 'button', width: 100 },
        ],
        items: []
      },

      billDialog: {
        show: false,
        id: null,
      },

      dischargeDialog: {
        show: false,
        id: null,
        recurrenceId: null,
        recurrenceDueDate: null,
      },

      importReduceToPay: {
        show: false,
        imported: [],

        headers: [
          { text: 'Cod. Lat.', value: 'codigo_laticinio' },
          { text: 'Tipo', value: 'tipo' },
          { text: 'Nome Prod', value: 'nome_produtor' },
          { text: 'Observação', value: 'observacao' },
          { text: 'Número', value: 'numero' },
          { text: 'Emissão', value: 'data_emissao' },
          { text: 'Recebimento', value: 'data_vencimento' },
          { text: 'Valor', value: 'valor' },
          { text: 'Gerar Financeiro', value: 'gerar_financeiro' },
          { text: '', value: 'button', width: 100 },
        ],

        selected: [],
      },

      producers: [],

      types: ['DESCONTO', 'ANTIBIOTICO', 'NORMATIVA', 'FINANCEIRO'],
    };
  },

  computed: {
    filteredBills() {
      const search = typeof this.filter.search === 'string' ? this.filter.search.toUpperCase().trim() : null;
      if (!search) {
        return this.bills.items
      }
      return this.bills.items.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(search))
    },
    filteredBilleds() {
      const search = typeof this.filter.search === 'string' ? this.filter.search.toUpperCase().trim() : null;
      if (!search) {
        return this.billeds.items
      }
      return this.billeds.items.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(search))
    },
    totalAmount() {
      return sumBy(x => parseFloat(x.amount) || 0, this.filteredBills)
    },
    totalBalance() {
      return sumBy(x => parseFloat(x.balance) || 0, this.filteredBills)
    },
    totalBilleds() {
      return sumBy(x => parseFloat(x.amount) || 0, this.filteredBilleds)
    },

    importFields() {
      /**
       * Obtém as colunas da última importação,
       * desse modo, seleciona automaticamente as colunas
       */
      const settings = this.$store.state.settings.gerais.campos_importados_descontos || {};
      return [
        { text: 'Código do Produtor', keys: ['Código do Produtor', settings['Código do Produtor'] ].filter(o => o), required: true, example: '000' },
        { text: 'Tipo', keys: ['Tipo', settings['Tipo'] ].filter(o => o), required: true, example: 'DESCONTO - ANTIBIOTICO - NORMATIVA' },
        { text: 'Observação', keys: ['Observação', settings['Observação'] ].filter(o => o), example: 'DESCONTOS A RECEBER' },
        { text: 'Número', keys: ['Número', settings['Número'] ].filter(o => o), example: '000' },
        { text: 'Data da Emissão', keys: ['Data da Emissão', settings['Data da Emissão'] ].filter(o => o), required: true, example: moment().format('DD/MM/YYYY') },
        { text: 'Data do Vencimento', keys: ['Data do Vencimento', settings['Data do Vencimento'] ].filter(o => o), required: true, example: moment().format('DD/MM/YYYY') },
        { text: 'Valor', keys: ['Valor', settings['Valor'] ].filter(o => o), required: true, example: 1000.50 },
        { text: 'Gerar Financeiro', keys: ['Gerar Financeiro', settings['Gerar Financeiro'] ].filter(o => o), required: true, example: 'N - Não | S - Sim' },
      ]
    },

    isImporting() {
      return this.importReduceToPay.imported.length > 0
    },
  },

  mounted() {
    this.loadProducers();
  },

  methods: {
    async getBills() {
      const [startDate, endDate] = this.filter.range;

      const types = ['DESCONTO', 'ANTIBIOTICO', 'NORMATIVA', 'FINANCEIRO'];

      let [{ data: bills }, { data: billeds }, { data: recurrences }] = await Promise.all([
        await this.$axios.post(
          `/financeiro/listaParcelas`,
          { tipos: types, data_inicio: startDate, data_fim: endDate }
        ),
        await this.$axios.post(
          `/financeiro/listaLancamentos`,
          { tipos: types, data_inicio: startDate, data_fim: endDate }
        ),
        await this.$axios.post(
          `/financeiro/listaRecorrencias`,
          { tipos: types, data_inicio: startDate, data_fim: endDate }
        ),
      ]);

      bills = bills.map(p => ({
        type: 'PARCELA',
        recurrenceId: null,
        installmentId: p.id_parcela,
        accountId: p.id_titulo,
        code: p.cod_parcela,
        producer: `${p.codigo_laticinio || ''} - ${p.nome_produtor}`,
        description: p.descricao || p.observacao,
        issueDate: p.data_emissao,
        dueDate: p.data_vencimento,
        amount: parseFloat(p.valor),
        balance: parseFloat(p.saldo),
        cashInmilk: p.descontar_no_leite,
      }))

      recurrences = recurrences.map(o => ({
        type: 'RECORRENTE',
        recurrenceId: o.id_titulo_recorrencia,
        installmentId: o.id_parcela || null,
        accountId: o.id_titulo,
        code: o.cod_titulo_recorrencia,
        producer: `${o.codigo_laticinio || ''} - ${o.nome_produtor}`,
        description: o.descricao || o.observacao,
        issueDate: o.data_emissao,
        dueDate: o.data_vencimento,
        amount: parseFloat(o.valor),
        balance: parseFloat(o.saldo),
      }))

      this.bills.items = [...bills, ...recurrences];

      this.billeds.items = billeds.map(o => ({
        id: o.id_lancamento,
        accountId: o.id_titulo,
        code: o.cod_parcela,
        producer: `${o.codigo_laticinio || ''} - ${o.nome_produtor}`,
        description: o.descricao || o.observacao,
        issueDate: o.data_emissao,
        paymentDate: o.data_lancamento,
        amount: parseFloat(o.valor),
      }))
    },

    newBill() {
      this.billDialog.id = null;
      this.billDialog.show = true;
    },

    showBill({ accountId }) {
      this.billDialog.id = accountId;
      this.billDialog.show = true;
    },

    discharge({ recurrenceId, dueDate, installmentId }) {
      this.dischargeDialog.id = installmentId;
      this.dischargeDialog.show = true;
      this.dischargeDialog.recurrenceId = recurrenceId;
      this.dischargeDialog.recurrenceDueDate = dueDate;
    },

    async chargeback({ installmentId }) {
      if (!(await this.$root.$confirm('Estornar Parcela', 'Tem certeza que deseja estornar esta parcela?', { color: 'orange' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/financeiro/estorno`, { id_parcela: installmentId });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Estorno efetuado com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao estornar a parcela", "Atenção");
        console.warn(error);
      } finally {
        this.getBills();
      }
    },

    async chargebackPayment({ id }) {
      if (!(await this.$root.$confirm('Estornar Recebimento', 'Tem certeza que deseja estornar este recebimento?', { color: 'orange' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/financeiro/estorno`, { id_lancamento: id });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Estorno efetuado com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao estornar o recebimento", "Atenção");
        console.warn(error);
      } finally {
        this.getBills();
      }
    },

    getBillsJson(isExcel = false) {
      return this.$refs.bills.getReportJson(isExcel);
    },

    getBilledsJson(isExcel = false) {
      return this.$refs.billeds.getReportJson(isExcel);
    },

    getReportTitle() {
      const [startDate, endDate] = this.filter.range;
      return `Descontos - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      const bills = this.getBillsJson(true);
      const billeds = this.getBilledsJson(true);
      const title = this.getReportTitle();

      if (bills.length === 0 && billeds.length === 0) {
        return;
      }

      const worksheet = this.XLSX.utils.json_to_sheet([]);
      const workbook = this.XLSX.utils.book_new();

      let currentRow = 0;

      if (bills.length > 0) {
        const headers = this.$refs.bills.filteredHeaders.filter(header => !!header.text);
        const json = bills

        worksheet[`A${currentRow + 1}`] = { v: 'Descontos' };
        currentRow += 1;

        this.XLSX.utils.sheet_add_json(worksheet, json, { origin: currentRow });
        this.applyStyles(worksheet, json, null, false, currentRow);
        this.applyMasks(worksheet, headers, json.length, currentRow);

        currentRow += json.length + 2;
      }

      if (billeds.length > 0) {
        const headers = this.$refs.billeds.filteredHeaders.filter(header => !!header.text);
        const json = billeds

        worksheet[`A${currentRow + 1}`] = { v: 'Recebimentos' };
        currentRow += 1;

        this.XLSX.utils.sheet_add_json(worksheet, json, { origin: currentRow });
        this.applyStyles(worksheet, json, null, false, currentRow);
        this.applyMasks(worksheet, headers, json.length, currentRow);
      }

      const billCols = this.getColsWidth(bills)
      const billedCols = this.getColsWidth(billeds)

      worksheet['!cols'] = Array.from({ length: Math.max(billCols.length, billedCols.length) })
        .map((_, i) => ({
          width: Math.max(billCols[i]?.width || 0, billedCols[i]?.width || 0)
        }))

      this.XLSX.utils.book_append_sheet(workbook, worksheet, truncate(title, { length: 31 }));
      this.XLSX.writeFile(workbook, `${title}.xlsx`);
    },

    async print() {
      const bills = this.getBillsJson();
      const billeds = this.getBilledsJson();
      const title = this.getReportTitle();

      if (bills.length === 0 && billeds.length === 0) {
        return;
      }

      const body = [];

      if (bills.length > 0) {
        body.push({
          tag: 'section',
          class: 'row',
          children: [{
            title: 'Descontos',
            tag: 'datatable',
            headers: Object.keys(bills[0])
              .filter(text => text)
              .map(header => ({
                key: header,
                label: header,
                class: 'text-left'
              })),
            items: bills
          }]
        });
      }

      if (billeds.length > 0) {
        body.push({
          tag: 'section',
          class: 'row',
          children: [{
            title: 'Recebimentos',
            tag: 'datatable',
            headers: Object.keys(billeds[0])
              .filter(text => text)
              .map(header => ({
                key: header,
                label: header,
                class: 'text-left'
              })),
            items: billeds
          }]
        });
      }

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.printPdf({ pages: [{ title, header, body }] });
    },

    async loadProducers() {
      try {
        const { data } = await this.$axios.post(`/produtores/listaJson`);

        if (!isArray(data)) {
          throw data;
        }

        this.producers = data.map(person => ({
          id: person.id_pessoa,
          name: person.nome,
          code: person.codigo_laticinio,
          status: person.status,
        }));
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      }
    },

    async saveLastImportedFields(fields) {
      try {
        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          this.$qs.stringify({
            configuracaoQualidade: JSON.stringify([{
              campos_importados_descontos: fields.reduce((acc, cur) => ({ ...acc, [cur.text]: cur.value }), {})
            }]),
          })
        );

        [data] = data;

        if (data.codigo !== 1) {
          throw data;
        }

        this.$store.dispatch('updateStoreSetting');
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.error(error);
      }
    },

    showImportReduceToPay() {
      this.importReduceToPay.show = true;
    },

    async onImportReduceToPay({ data, fields }) {
      try {
        this.importReduceToPay.show = false;

        this.loading = true;

        this.saveLastImportedFields(fields);

        const failed = [];

        const [startDate, endDate] = this.filter.range;

        let id = 1;

        data.forEach(item => {

          const dateFormat = (item['Data da Emissão'] || '').includes('/') ? 'DD/MM/YYYY' : 'YYYY-MM-DD';

          const accountValue = parseFloat(item['Valor']) || 0;
          const issueDate = moment(item['Data da Emissão'], dateFormat).format('YYYY-MM-DD');
          const dueDate = moment(item['Data do Vencimento'], dateFormat).format('YYYY-MM-DD');

          if (!dueDate || !issueDate || !accountValue) {
            failed.push(`[Código: ${item['Código do Produtor']} - Motivo: Dados inválidos]`);
            return;
          }

          if (dueDate < startDate || dueDate > endDate) {
            failed.push(`[Código: ${item['Código do Produtor']} - Motivo: Vencimento fora do período selecionado]`);
            return;
          }

          if (this.types.indexOf(item['Tipo']) === -1) {
            failed.push(`[Tipo: ${item['Tipo']} - Motivo: Tipo não encontrado]`);
            return;
          }

          const producer = this.producers.find((producer) => producer.code === item['Código do Produtor']);

          if (!producer) {
            failed.push(`[Código: ${item['Código do Produtor']} - Motivo: Produtor não encontrado]`);
            return;
          }

          if (item['Gerar Financeiro'] === 'S') {
            item['Tipo'] = 'FINANCEIRO'
          }

          this.importReduceToPay.imported.push({
            id: id++,
            tipo: item['Tipo'],
            debito_credito: 'C',
            periodo_recorrencia: 0,
            parcela_quantidade: 1,
            descontar_no_leite: 1,
            observacao: item['Observação'] || null,
            numero: item['Número'] || null,
            codigo_laticinio: producer.code,
            id_produtor: producer.id,
            nome_produtor: producer.name,
            data_emissao: issueDate,
            data_vencimento: dueDate,
            valor: accountValue,
            parcelas: [
              {
                data_vencimento: dueDate,
                valor: accountValue,
              }
            ],
            gerar_financeiro: item['Gerar Financeiro'],
          });

        })

        if (failed.length > 0) {
          console.log({ failed });
          this.$snotify.warning([
            `${failed.length} itens não puderam ser importados`,
            ...failed.slice(0, 3)
          ].join("\r\n"));
          return;
        }

        this.$snotify.info("Salve para concluir a importação!");

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao importar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async saveImport() {
      try {
        this.loading = true;

        const bills = this.importReduceToPay.selected;

        const { data } = await this.$axios.post(`/financeiro/insereContas`, {
          contas: bills
        });

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Descontos a receber importados com successo!");

        this.importReduceToPay.imported = [];
        this.importReduceToPay.selected = [];
        this.getBills();
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao importar!", "Atenção");

        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
};
</script>
