
const analysisParams = [
  // Inputs
  {
    param: "numero_lacre",
    text: "Número do Lacre",
    standard: false,
  },
  {
    param: "temperatura",
    text: "Temperatura",
    standard: { max: 7 },
    restriction: {},
  },
  {
    param: "acidez",
    text: "Acidez",
    standard: { min: 0.14, max: 0.18 },
    restriction: {},
    units: [
      {
        description: "Ácido Lático",
        unit: "G",
        selected: true,
      },
      {
        description: "Graus Dornic",
        unit: "°D",
        selected: false,
      },
    ],
  },
  {
    param: "crioscopia",
    text: "Crioscopia",
    standard: { min: -0.531, max: -0.512 },
    restriction: {},
    units: [
      {
        description: "Graus Celsius",
        unit: "°C",
        selected: true,
      },
      {
        description: "Graus Hortvet",
        unit: "°H",
        selected: false,
      },
    ],
  },
  {
    param: "porcentagem_agua",
    text: "% de Água",
    standard: { max: 0 },
    restriction: {},
  },
  {
    param: "volume_agua",
    text: "Água (L)",
    standard: { max: 0 },
    restriction: {},
  },
  {
    param: "densidade",
    text: "Densidade",
    standard: { min: 1028, max: 1034 },
    restriction: {},
    units: [
      {
        description: "Grama/Mililitro",
        unit: "g/ml",
        selected: true,
      },
    ],
  },
  {
    param: "gordura",
    text: "Gordura",
    standard: { min: 0.5, max: 3 },
    restriction: {},
    units: [
      {
        description: null,
        unit: "%/100g",
        selected: true,
      },
    ],
  },
  {
    param: "esd",
    text: "ESD (Ext. seco desengordurado)",
    standard: { min: 8.4 },
    restriction: {},
  },
  {
    param: "est",
    text: "EST (Ext. seco total)",
    standard: { min: 11.4 },
    restriction: {},
  },
  {
    param: "relacao_acidez",
    text: "Relação de Acidez",
    standard: { min: 0 },
    restriction: {},
  },
  {
    param: "proteina",
    text: "Proteína",
    standard: { min: 2.9 },
    restriction: {},
  },
  {
    param: "ph",
    text: "PH",
    standard: { min: 6.6, max: 6.9 },
    restriction: {},
  },
  {
    param: "alizarol",
    text: "Alizarol (Quantit.)",
    standard: { min: 0, max: 0 },
    restriction: {},
    units: [
      {
        description: "Gay-Lussac",
        unit: "°GL",
        selected: true,
      },
    ],
  },
  {
    param: "solidos_totais",
    text: "Sólidos Totais",
    standard: { min: 11.4 },
    restriction: {},
  },
  {
    param: "brix",
    text: "Brix",
    standard: { min: 0, max: 10000 },
    restriction: {},
  },
  {
    param: "lactose",
    text: "Lactose",
    standard: { min: 4.3 },
    restriction: {},
    units: [
      {
        description: null,
        unit: "g/100g",
        selected: true,
      },
    ],
  },
  {
    param: "redutase",
    text: "Redutase",
    standard: { min: 90, max: 360 },
    restriction: {},
  },
  {
    param: "solubilidade",
    text: "Solubilidade",
    standard: { max: 0.4 },
    restriction: {},
  },
  {
    param: "base_seca",
    text: "% de Base Seca",
    standard: { max: 1 },
    restriction: {},
  },

  // Checkboxes
  {
    param: "alizarol_qualit",
    text: "Alizarol (Qualit.)",
    standard: false,
    standardDescription: 'Negativo',
  },
  {
    param: "mastite",
    text: "Mastite",
    standard: false,
    standardDescription: 'Negativo',
  },
  {
    param: "sensorial",
    text: "Sensorial",
    standard: false,
    standardDescription: 'Característico',
  },
  {
    param: "alcool",
    text: "Substâncias redutoras voláteis",
    standard: false,
    standardDescription: 'Negativo',
  },
  {
    param: "fosfatase",
    text: "Fosfatase alcalina",
    standard: false,
    standardDescription: 'Positivo',
  },
  {
    param: "grumos",
    text: "Grumos",
    standard: false,
    standardDescription: 'Ausência',
  },
  {
    param: "coagulos",
    text: "Coágulos",
    standard: false,
    standardDescription: 'Ausência',
  },
  {
    param: "peroxidase",
    text: "Peroxidase",
    standard: true,
    standardDescription: 'Positivo',
  },
  {
    param: "outras_especies",
    text: "Outras Espécies",
    standard: false,
    standardDescription: 'Negativo',
  },
  {
    param: "fenolftaleina",
    text: "Fenolftaleína",
    standard: false,
    standardDescription: 'Ausência',
  },
  {
    param: "presenca_sangue",
    text: "Presença de Sangue",
    standard: false,
    standardDescription: 'Negativo',
  },

  // "Dependentes"
  {
    param: "antibiotico",
    text: "Antibiótico",
    standard: false,
    standardDescription: 'Negativo',
    depends: [
      { param: "betalactamico", standard: true, text: "Betalactâmico", standardDescription: 'Negativo', },
      { param: "tetraciclina", standard: true, text: "Tetraciclina", standardDescription: 'Negativo', },
      { param: "sulfonamida", standard: true, text: "Sulfonamida", standardDescription: 'Negativo', },
      { param: "quinolonas", standard: true, text: "Quinolonas", standardDescription: 'Negativo', },
      { param: "aminoglicosideos", standard: true, text: "Aminoglicosídeos", standardDescription: 'Negativo', },
      { param: "macrolideos", standard: true, text: "Macrolídeos", standardDescription: 'Negativo', },
      { param: "anfenicois", standard: true, text: "Anfenicóis", standardDescription: 'Negativo', },
      { param: "neomicina", standard: true, text: "Neomicina", standardDescription: 'Negativo', },
      { param: "cefalosporina", standard: true, text: "Cefalosporina", standardDescription: 'Negativo', },
      { param: "tianfenicol", standard: true, text: "Tianfenicol", standardDescription: 'Negativo', },
      { param: "florfenicol", standard: true, text: "Florfenicol", standardDescription: 'Negativo', },
    ],
  },
  {
    param: "neutralizantes",
    text: "Neutralizantes",
    standard: false,
    standardDescription: 'Negativo',
    depends: [
      { param: "soda", standard: true, text: "Soda", standardDescription: 'Negativo', },
      { param: "bicarbonato", standard: true, text: "Bicarbonato", standardDescription: 'Negativo', },
      { param: "alcalino", text: "Alcalino", standard: false, standardDescription: 'Negativo', },
      { param: "acido_rosolico", text: "Ácido Rosólico", standard: false, standardDescription: 'Negativo', },
    ],
  },
  {
    param: "reconstituintes",
    text: "Reconstituintes",
    standard: false,
    standardDescription: 'Negativo',
    depends: [
      { param: "sacarose", standard: true, text: "Sacarose", standardDescription: 'Negativo', },
      { param: "amido", standard: true, text: "Amido", standardDescription: 'Negativo', },
      { param: "cloretos", standard: true, text: "Cloretos", standardDescription: 'Negativo', },
      { param: "cloro", standard: true, text: "Cloro/Hipoclorito", standardDescription: 'Negativo', },
      { param: "ureia", standard: true, text: "Ureia/Urina", standardDescription: 'Negativo', },
    ],
  },
  {
    param: "conservantes",
    text: "Conservantes",
    standard: false,
    standardDescription: 'Negativo',
    depends: [
      { param: "peroxido", standard: true, text: "Peróxido de hidrogenio", standardDescription: 'Negativo', },
      { param: "formol", standard: true, text: "Formaldeído Lento", standardDescription: 'Negativo', },
      { param: "formol_rapido", standard: true, text: "Formaldeído Rápido", standardDescription: 'Negativo', },
    ],
  },

  // Campo aberto para outras inconformidades
  {
    param: "outros",
    text: "Outros",
    standard: false,
    standardDescription: 'Negativo',
  },
];

export default analysisParams;

