<template>
  <div
    ref="app"
    @fullscreenchange="onFullscreenChange"
  >
    <v-app>
      <v-app-bar
        app
        :extended="$vuetify.breakpoint.xsOnly"
        class="toolbar d-print-none"
        extension-height="60"
        fixed
        dark
        :style="{ background }"
      >
        <router-link
          class="d-flex mr-4"
          :to="{ name: 'Index' }"
        >
          <img
            alt="MilkUp"
            class="logo"
            src="@/Assets/logo-transparent-light.png"
          >
        </router-link>

        <v-spacer />

        <v-tooltip
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              icon
              dark
              class="mr-4"
              :color="isSocketConnected ? 'green' : 'red'"
              v-on="on"
            >
              <v-icon>
                {{ isSocketConnected ? 'wifi' : 'wifi_off' }}
              </v-icon>
            </v-btn>
          </template>
          Sistema {{ isSocketConnected ? 'conectado' : 'desconectado' }}
        </v-tooltip>

        <v-tooltip
          v-if="isSocketConnected && integrator.access"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              icon
              dark
              class="mr-4"
              :color="integrator.connected ? 'green' : 'red'"
              v-on="on"
            >
              <v-icon>
                {{ integrator.connected ? 'cloud_done' : 'cloud_off' }}
              </v-icon>
            </v-btn>
          </template>
          Integrador {{ integrator.connected ? 'conectado' : 'desconectado' }}
        </v-tooltip>

        <v-btn
          v-if="$vuetify.breakpoint.mobile && installPrompt && !isInStandaloneMode && $route.path === '/index'"
          dark
          outlined
          color="info"
          class="mr-4"
          @click="installApp"
        >
          <v-icon left>
            get_app
          </v-icon>
          Instalar App
        </v-btn>

        <v-btn
          v-if="$vuetify.breakpoint.width < 1000 && !isInStandaloneMode"
          icon
          dark
          class="mr-2"
          @click="toggleFullScreen"
        >
          <v-icon
            color="rgba(255, 255, 255, 0.7)"
            style="font-size: 28px"
          >
            {{ !isFullscreen ? 'fullscreen' : 'fullscreen_exit' }}
          </v-icon>
        </v-btn>

        <div
          v-if="!$vuetify.breakpoint.xsOnly"
          class="mr-2"
        >
          <v-autocomplete
            v-model="laticinio"
            :items="laticinios"
            :loading="loadingLaticinios"
            autocomplete="off"
            clearable
            dense
            outlined
            return-object
            hide-details
            item-value="id"
            item-text="name"
            label="Laticínio"
            :disabled="produtor && !!produtor.id_pessoa"
            @change="selectLaticinio"
          />
        </div>

        <notifications-menu
          ref="notifications"
        />

        <v-menu
          v-model="mobileMenu"
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :elevation="0"
              large
              color="transparent"
              class="profile mx-2 font-weight-regular"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                left
                color="rgba(255, 255, 255, 0.7)"
              >
                account_circle
              </v-icon>
              {{ $store.state.settings.user.nome }}
              <v-icon
                right
                color="rgba(255, 255, 255, 0.7)"
              >
                arrow_drop_down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showVersionNotes()">
              <v-list-item-icon>
                <v-icon>new_releases</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Novidades
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <template v-if="$vuetify.breakpoint.xsOnly">
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>factory</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <v-autocomplete
                    v-model="laticinio"
                    :items="laticinios"
                    :loading="loadingLaticinios"
                    autocomplete="off"
                    clearable
                    hide-details
                    item-value="id"
                    item-text="name"
                    label="Laticínio"
                    :disabled="produtor && !!produtor.id_pessoa"
                    return-object
                    @change="selectLaticinio"
                  />
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list>
              <v-list-item @click="exitLogin()">
                <v-list-item-icon>
                  <v-icon>power_settings_new</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Desconectar</v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </v-menu>

        <v-btn
          v-if="!$vuetify.breakpoint.xsOnly"
          class="mx-2 logout-button"
          icon
          @click="exitLogin()"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon v-on="on">
                power_settings_new
              </v-icon>
            </template>
            Desconectar
          </v-tooltip>
        </v-btn>
        <template #extension>
          <favorites-top-bar
            v-if="!loadingMenuItems"
            :style="{ background }"
          />
        </template>
      </v-app-bar>

      <v-main
        :class="['menu-content', { 'changing-route': changingRoute }, 'background-animated', 'bg-' + $route.name]"
        :style="{ background }"
      >
        <v-card
          dark
          :class="['background-animated', 'bg-' + $route.name]"
          :style="{ background }"
          elevation="0"
        >
          <v-card-text class="pa-0 pb-4">
            <transition
              :name="$route.meta.transitionName"
              @before-enter="changingRoute = true"
              @after-leave="changingRoute = false"
            >
              <router-view :key="$route.name" />
            </transition>

            <AppGeneralAlerts />
          </v-card-text>
        </v-card>
      </v-main>

      <VersionNotesListDialog ref="version-notes-list" />

      <VersionNotesDialog />

      <confirm ref="confirm" />

      <progress-bar ref="progress-bar" />

      <vue-snotify />
    </v-app>
  </div>
</template>

<style lang="scss">
.logout-button:hover {
  color: #F44336 !important;
}
</style>

<script>
import Menu from "@/Support/Components/Menu.vue";
import Components from "@/Support/Resources/router.components.js";
import Confirm from "@/Support/Components/Confirm.vue";
import ProgressBar from "@/Support/Components/ProgressBar.vue";
import NotificationsMenu from "@/Domains/Notifications/Components/NotificationsMenu.vue";
import AppGeneralAlerts from "@/Units/Alerts/AppGeneralAlerts.vue";
import FavoritesTopBar from "@/Units/Favorites/FavoritesTopBar.vue";
import VersionNotesDialog from "@/Units/Versions/VersionNotesDialog.vue";
import VersionNotesListDialog from "@/Units/Versions/VersionNotesListDialog.vue";

import _ from "lodash";

require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  name: "App",

  components: {
    Confirm,
    ProgressBar,
    NotificationsMenu,
    AppGeneralAlerts,
    FavoritesTopBar,
    VersionNotesDialog,
    VersionNotesListDialog,
  },

  data() {
    return {
      changingRoute: false,
      loadingMenuItems: true,
      loadingLaticinios: false,
      loadingSessao: false,
      produtores: [],
      recursosUsuario: [],

      installPrompt: null,
      isInStandaloneMode: false,
      isFullscreen: false,
      mobileMenu: null,

      ws: {
        socket: null,
        connected: false,
      },

      integrator: {
        access: null,
        connected: false,
      },

    };
  },

  computed: {
    background() {
      if (this.$route.path === '/index' && this.searchMenu) {
        return '#4527a0';
      }
      return this.$route.meta.background || '#4527a0'
    },
    // fix scroll behind a dialog
    searchMenu: {
      get() {
        return this.$store.state.settings.searchMenu;
      },
      set(value) {
        this.$store.commit("setSearchMenu", value);
      },
    },
    menuItems: {
      get() {
        return this.$store.state.settings.menuItems;
      },
      set(value) {
        this.$store.commit("setMenuItems", value);
      },
    },
    laticinios: {
      get() {
        return this.$store.state.settings.laticinios || [];
      },
      set(value) {
        this.$store.commit("setLaticinios", value);
      },
    },
    laticinio: {
      get() {
        return this.$store.state.settings.laticinio;
      },
      set(value) {
        this.$store.commit("setLaticinio", value);
      },
    },
    produtor: {
      get() {
        return this.$store.state.settings.produtor;
      },
      set(value) {
        this.$store.commit("setProdutor", value);
      },
    },
    coordenadas: {
      get() {
        return this.$store.state.settings.coordenadas;
      },
      set(value) {
        this.$store.commit("setCoordenadas", value);
      },
    },
    isSocketConnected() {
      return this.$store.state.socket.isConnected;
    },
  },

  async created() {
    await this.$socket.connect();
    await this.loadDairies();
    this.loadMenuItems();
    this.loadSessao();
    this.$store.dispatch('updateStoreSetting');
    this.$store.dispatch('updatePlatformSettings');
    this.$store.dispatch('updateReportsSettings');
    this.$store.dispatch('updateWebSettings');
    this.$store.dispatch('updateVersionNotes');
    //this.$store.dispatch('updateFiscalOperations');
    this.loadUserResources();
  },

  mounted() {
    this.initMobileEvent();
    this.$root.$confirm = this.$refs.confirm.open;
    this.$root.$progressBar = this.$refs['progress-bar'];

    this.setUpSocket();
  },

  methods: {
    async exitLogin() {
      try {
        this.$root.$progressBar.loading();
        await this.$axios.get(`/login/logout`);

        window.localStorage.removeItem('vuex');

        if (this.$socket) {
          this.$socket.disconnect();
        }
      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        window.location.replace(process.env.VUE_APP_HOME_URL);
      }
    },

    toggleMenuEditable() {
      this.$store.commit("toggleMenuEditable");
    },

    async loadMenuItems() {
      try {
        const route = this.$router.options.routes.find(route => route.path === '/');

        if (route.children) {
          this.navigateToIndex();
          return;
        }

        this.loadingMenuItems = true;
        const { data } = await this.$axios.get(`/inicio/menuJson`);

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        const generateRoutes = (itens, parents = ['']) => {
          return itens
            .map(element => {
              const title = element.nome;
              const path = _.kebabCase(element.nome);
              const fullPath = [...parents, path].join('/');

              if (Array.isArray(element.itens)) {
                const children = generateRoutes(element.itens, [...parents, path]);

                return {
                  name: _.kebabCase(title),
                  path: fullPath,
                  children,
                  component: Menu,
                  props: {
                    title,
                    itens: children.map(item => item.meta)
                  },
                  meta: {
                    title,
                    fullPath,
                    icon: element.icone,
                    background: element.background,
                    isDairyRequired: element.requer_laticinio ? true : false,
                    isProducerRequired: element.requer_produtor ? true : false,
                    isMenu: true,
                  },
                }
              } else {
                const componentName = _.startCase(element.componente).replace(/\s/g, '');

                return {
                  name: _.kebabCase(element.componente),
                  path: fullPath,
                  meta: {
                    title,
                    fullPath,
                    background: element.background,
                    icon: element.icone,
                    isDairyRequired: element.requer_laticinio ? true : false,
                    isProducerRequired: element.requer_produtor ? true : false,
                  },
                  component: Components[componentName] || null,
                }
              }
            })
            .filter(element => element.component);
        };

        const flatRoutes = (itens) => {
          return itens
            .reduce((acc, element) => {
              if (Array.isArray(element.children)) {
                const children = flatRoutes(element.children);
                delete element.children;
                return acc.concat(element, children);
              } else {
                return acc.concat(element);
              }
            }, []);
        }

        const children = generateRoutes(data);

        const routes = [{
          name: 'Index',
          path: '/index',
          children,
          component: Menu,
          props: {
            isIndex: true,
            itens: children
              .filter(item => item.name !== 'notifications')
              .map(item => item.meta)
          },
          meta: {
            isMenu: true,
          },
        }]

        route.children = flatRoutes(routes);

        this.$router.addRoute(route);

        const redirect = this.$route.query.redirect;

        if (redirect && route.children.some(route => route.path === redirect)) {
          this.$router.replace(redirect);
        }
        else {
          this.navigateToIndex();
        }
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o menu!", "Atenção");
        console.log(error);
      } finally {
        this.loadingMenuItems = false;
      }
    },

    async loadDairies() {
      this.loadingLaticinios = true;
      try {
        const { data } = await this.$axios.get(`/pessoa/listaLaticiniosUsuario`);

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.laticinios = data.map(dairy => ({
          id: dairy.id_pessoa,
          name: dairy.nome,
          doc: dairy.cnpj_cpf,
          ie: dairy.inscricao_estadual,
          location: {
            lat: parseFloat(dairy.end_latitude) || null,
            lng: parseFloat(dairy.end_longitude) || null,
          },
          address: {
            city: dairy.end_cidade,
            state: dairy.end_estado,
            street: dairy.end_rua,
            number: dairy.end_numero,
          }
        }));
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os laticínios!", "Atenção");
        console.log(error);
      } finally {
        this.loadingLaticinios = false;
      }
    },

    async loadSessao() {
      this.loadingSessao = true;
      try {
        const { data } = await this.$axios.get(`/login/listaSessao`);

        if (!_.isEmpty(data.sistema)) {
          this.$store.commit("setSistema", data.sistema);
        }

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        if (!data.id_produtor && !data.id_laticinio) {
          await this.loadDairies();
        }

        if (data.id_laticinio) {
          this.laticinio = this.laticinios.find(dairy => dairy.id === data.id_laticinio);
        }

        if (data.id_produtor) {
          this.produtor = {
            id_pessoa: data.id_produtor,
            nome: data.nome_produtor,
            descricao: data.nome_produtor,
            id_laticinio: data.id_laticinio,
            laticinio: data.nome_laticinio,
          };
          this.produtores = [this.produtor];
        }

        if (data.latitude_laticinio && data.longitude_laticinio) {
          this.coordenadas = {
            lat: parseFloat(data.latitude_laticinio),
            lng: parseFloat(data.longitude_laticinio),
          };
        }

        if (data.tipo_admin) {
          this.$store.commit("setTipoAcesso", "admin");
        } else if (data.tipo_produtor) {
          this.$store.commit("setTipoAcesso", "produtor");
        } else {
          this.$store.commit("setTipoAcesso", "outros");
        }

        this.$store.commit("setUserData", {
          id: data.id_usuario,
          nome: data.nome_usuario,
          id_cargo: data.id_cargo,
          cargo: data.cargo,
          id_perfil_comercial: data.id_perfil_comercial,
          system: data.system,
        });

        await this.$socket.emit('user', {
          id: data.id_usuario,
          name: data.nome_usuario,
          system: data.sistema,
          dairyId: data.id_laticinio,
          userMilkup: data.system === 'milkup',
        });
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a sessão!", "Atenção");
        console.log(error);
      } finally {
        this.loadingSessao = false;
      }
    },

    async loadUserResources() {
      try {
        const { data } = await this.$axios.get(`/login/listaRecursosUsuario`);

        if (!_.isArray(data)) {
          throw 'Dados inválidos';
        }

        return this.$store.commit("setRecursosUsuario", data);
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as configurações!", "Atenção");
      }
    },

    // TODO precisa arrumar o retorno
    async selectLaticinio(event) {
      try {
        await this.$nextTick();

        this.mobileMenu = null;

        if (_.isEmpty(event)) {
          await this.loadDairies();

          await this.$axios.post(
            `/login/selectLaticinio`,
            this.$qs.stringify({
              nome: "",
              id_pessoa: "",
            })
          );
        }

        this.navigateToIndex();

        if (event.location.lat && event.location.lng) {
          this.coordenadas = {
            lat: parseFloat(event.location.lat),
            lng: parseFloat(event.location.lng),
          };
        }

        await this.$store.dispatch('updateStoreSetting');
        await this.$store.dispatch('updatePlatformSettings');
        await this.$store.dispatch('updateReportsSettings');
      } catch (error) {
        console.log(error);
      }
    },

    navigateToIndex() {
      if (this.$route.path !== '/index') {
        this.$router.push('/index')
      }
      this.searchMenu = '';
    },

    showVersionNotes() {
      this.$refs['version-notes-list'].show()
    },

    /**
     * @event void
     *
     * This becomes important when the user doesn't use the button to exit
     * fullscreen but hits ESC on desktop, pushes a physical back button on
     * mobile etc.
     */
    onFullscreenChange() {
      this.isFullscreen = !!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || null)
    },

    toggleFullScreen() {
      if (this.isFullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      else {
        const element = this.$refs['app'];

        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullScreen) {
          element.msRequestFullScreen();
        }
      }
    },

    initMobileEvent() {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.installPrompt = e;
      })

      this.isInStandaloneMode = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

    },

    installApp() {
      this.installPrompt.prompt()
      // Wait for the user to respond to the prompt
      this.installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //TODO
        }
        this.installPrompt = null
      })
    },

    setUpSocket() {
      this.$socket.on('notitication', this.onNotification);
    },

    onNotification(notification) {
      notification.read = false;

      if (notification.type === 'INTEGRADOR' && notification.connected !== undefined) {
        this.integrator.access = true;
        this.integrator.connected = notification.connected;

        // Notification received on user login, it is only to show the connection
        if (!notification.id) {
          return;
        }
      }

      this.$refs.notifications.add(notification);
    },
  },
};
</script>
